import { useState } from "react";
import { Container, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Logo from "app/assets/images/logo.svg";
import LoginMainImage from "app/assets/images/login-right.png";
import SendCode from "./step-one/SendCode";
import ChangePassword from "./step-two/ChangePassword";
import CongratsScreen from "./step-three/CongratsScreen";

const LargeImage = styled("img")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [currentStep, setCurrentStep] = useState(0);

  const displaySteps = () => {
    switch (currentStep) {
      case 0:
        return (
          <SendCode
            setCurrentStep={setCurrentStep}
            email={email}
            setEmail={setEmail}
          />
        );
      case 1:
        return (
          <ChangePassword
            setCurrentStep={setCurrentStep}
            email={email}
            setPassword={setPassword}
          />
        );
      case 2:
        return <CongratsScreen email={email} password={password} />;
    }
  };

  return (
    <Grid container style={{ height: "100vh" }}>
      <Grid item xs={12} sm={12} md={8} style={{ height: "80%" }}>
        <Container
          sx={{ m: 8 }}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <img src={Logo} alt="login image" style={{ width: "150px" }} />
          {displaySteps()}
        </Container>
      </Grid>
      <Grid
        container
        item
        xs={4}
        justifyContent="flex-end"
        style={{ height: "100%" }}
      >
        <LargeImage
          src={LoginMainImage}
          alt="login image"
          style={{ height: "100%" }}
        />
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
