import React, { useRef, useState } from "react";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";

const scriptOptions: any = {
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  libraries: ["places"],
};

const AddressAutoComplete = ({
  placeholder,
  ctaPlace,
  clinicLocation,
}: any) => {
  const { isLoaded, loadError } = useLoadScript(scriptOptions);
  const [autocomplete, setAutocomplete] = useState<any>(null);
  const inputEl = useRef(null);

  const onKeypress = (e: any) => {
    // On enter pressed
    if (e.key === "Enter") {
      e.preventDefault();
      return false;
    }
  };

  const onLoad = (autocompleteObj: any) => {
    setAutocomplete(autocompleteObj);
  };

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if ("place_id" in place) {
        ctaPlace(place);
      }
    }
  };

  return (
    <div>
      {loadError && (
        <div>
          Google Map script can&apos;t be loaded, please reload the page
        </div>
      )}

      {isLoaded && (
        <div className="addresss-autocomplete">
          <Autocomplete
            onLoad={onLoad}
            fields={[
              "place_id",
              "name",
              "geometry",
              "address_components",
              "rating",
            ]}
            onPlaceChanged={onPlaceChanged}
          >
            <input
              ref={inputEl}
              defaultValue={clinicLocation}
              type="text"
              placeholder={placeholder || "Type keywords..."}
              onKeyPress={onKeypress}
              style={{
                width: "100%",
                height: "55px",
                padding: "16.5px 14px",
                fontSize: "15px",
              }}
            />
          </Autocomplete>
        </div>
      )}
    </div>
  );
};

export default AddressAutoComplete;
