import { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import PrimaryButton from "generic/Buttons/PrimaryButton";
import InputWithLabel from "generic/Inputs/InputWithLabel";
import { useUpdateConsultationMutation } from "services/appointments";
import { Typography } from "@mui/material";

const EditOrganizationNote = ({
  consultation,
  type,
  handleErrorPopup,
}: any) => {
  const [updateConsultation, updateResult] = useUpdateConsultationMutation();

  const validationSchema = yup.object({
    organization_notes: yup.string().required(),
  });

  useEffect(() => {
    if (updateResult?.isSuccess) {
      handleErrorPopup("Note updated successfully");
    }
  }, [updateResult]);

  const formik = useFormik({
    initialValues: {
      organization_notes: consultation?.organization_notes
        ? consultation?.organization_notes
        : "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      await updateConsultation({
        type,
        id: consultation.id,
        updates: { organization_notes: values.organization_notes },
      });
    },
  });
  return (
    <>
      <form style={{ margin: "auto 0" }} onSubmit={formik.handleSubmit}>
        <Typography
          style={{
            color: "#172B4D",
            fontSize: "16px",
            fontWeight: 700,
            marginBottom: "20px",
          }}
        >
          Organization Notes
        </Typography>
        <InputWithLabel
          // label="Organization Notes"
          id="organization_notes"
          name="organization_notes"
          placeholder="Enter note here"
          multiline={true}
          rows={4}
          value={formik.values.organization_notes}
          onChange={formik.handleChange}
          error={
            formik.touched.organization_notes &&
            Boolean(formik.errors.organization_notes)
          }
          helperText={
            formik.touched.organization_notes &&
            formik.errors.organization_notes
          }
        />
        <PrimaryButton
          title="Save"
          type="submit"
          width="fit-content"
          height="40px"
          margin={0}
        />
      </form>
    </>
  );
};

export default EditOrganizationNote;
