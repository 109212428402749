import { Divider, Snackbar } from "@mui/material";

const SnackbarComponent = ({ open, onClose, message }: any) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
      message={message}
      sx={{ ml: 14 }}
    />
  );
};

export default SnackbarComponent;
