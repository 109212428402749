import { Auth } from "aws-amplify";

export const signUp = async (form: any) => {
  const {
    email,
    password,
    firstName,
    lastName,
    gender,
    birthdate,
    address,
    specialty,
  } = form;
  const signUpResult = await Auth.signUp({
    username: email,
    password: password,
    attributes: {
      given_name: firstName,
      family_name: lastName,
      gender,
      birthdate,
      address,
      "custom:specialty": specialty,
    },
  });
  return signUpResult;
};

export const isEmailExist = async ({ email, password }: any) => {
  const isExistResult = await Auth.signUp({
    username: email,
    password: password,
  });
  return isExistResult;
};

export const confirmSignUp = async (email: string, code: string) => {
  const result = await Auth.confirmSignUp(email, code);
  return result;
};

export const resendConfirmationCode = async (email: string) => {
  const result = await Auth.resendSignUp(email);
  return result;
};

export const verifyCurrentEmail = async (): Promise<any> => {
  return Auth.verifyCurrentUserAttribute("email")
    .then(() => {
      console.log("a verification code is sent");
      return {
        success: true,
        message: "a verification code is sent",
      };
    })
    .catch((e) => {
      console.log("failed with error", e);
      return {
        error: true,
        message: e.message,
      };
    });
};

export const verifyEmailSubmit = async (code: string): Promise<any> => {
  return Auth.verifyCurrentUserAttributeSubmit("email", code)
    .then(() => {
      console.log("email verified");
      return {
        success: true,
        message: "email verified",
      };
    })
    .catch((e) => {
      console.log("failed with error", e);
      return {
        error: true,
        message: e.message,
      };
    });
};
