import { noRoleapi } from "./noRole";

export const authApi = noRoleapi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ id_token, role }) => ({
        url: "/api/v1/auth/login",
        method: "POST",
        body: { auth: { id_token, role } },
      }),
      transformResponse: (response: any, meta: any) => {
        const accessToken = meta.response.headers.get("access-token");
        const uid = meta.response.headers.get("uid");
        const client = meta.response.headers.get("client");

        return {
          ...response,
          token: accessToken,
          uid,
          client,
        };
      },
    }),
  }),
});

export const { useLoginMutation } = authApi;
