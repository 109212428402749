import { createApi } from "@reduxjs/toolkit/query/react";
import { staggeredBaseQueryWithBailOut } from "utils/servicesUtilities";

const api = createApi({
  reducerPath: "api",
  baseQuery: staggeredBaseQueryWithBailOut(
    `${process.env.REACT_APP_API_URL}`,
    true
  ),
  tagTypes: [
    "Profile",
    "Biographies",
    "Institutions",
    "AvailabilityDays",
    "InsuranceCompanies",
    "Embassies",
    "Specialities",
    "TreatmentAreas",
    "Operations",
    "Appointments",
    "Appointment",
    "Notifications",
    "FAQs",
    "Terms",
    "DoctorPatients",
    "HomeStats",
    "Revenues",
    "FilteredAppointments",
    "Reviews",
    "ViewsAndSearchFilters",
    "EmergencyAppointments",
    "MedicalHistory",
    "Invitations",
  ],
  endpoints: () => ({}),
});

export const urlRole =
  localStorage.getItem("role") == "doctor" ? "doctor" : "organization";

export const profileUser =
  localStorage.getItem("role") === "doctor" ? "user" : "admin";

export default api;
