import { Typography } from "@mui/material";
import bookIcon from "app/assets/icons/book.svg";

const PatientUploadedDocuments = ({ consultation }: any) => {
  return (
    <>
      <Typography
        style={{
          color: "#172B4D",
          fontSize: "16px",
          fontWeight: 700,
          marginBottom: "20px",
        }}
      >
        Patient attachted documents
      </Typography>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {consultation?.patient_uploaded_documents?.map((doc: any) => {
          return (
            <div
              style={{
                background: "#F4F5F8",
                padding: "10px 15px",
                borderRadius: "10px",
                marginBottom: "10px",
                marginRight: "10px",
                width: "40%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={bookIcon}
                alt="Book icon"
                style={{ marginRight: "10px" }}
              />
              <a
                href={doc.url}
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "#252D59",
                }}
              >
                <p style={{ color: "#252D59", fontSize: "14px" }}>
                  {doc?.name?.slice(0, 13)}
                </p>
                <p style={{ color: "#8690A3", fontSize: "14px" }}>
                  {(doc.byte_size / 1024).toFixed(1)} KB
                </p>
              </a>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default PatientUploadedDocuments;
