import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { useState, useEffect, useRef } from "react";
import { Divider, Switch, Typography } from "@mui/material";
import { PatientUploadedDocuments } from "./leftCardSection";
import Avatar from "app/assets/images/avatar.png";
import TranslationIcon from "app/assets/icons/translation.svg";
import moment from "moment";
import PrimaryButton from "generic/Buttons/PrimaryButton";
import { firestore, ConversationsCollection } from "../../../firebase";
import { doc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { urlRole, profileUser } from "services";
import { dateFormat, timeFormat } from "utils/dateFormat";
import { useContactOrganizationMutation } from "services/appointments";
import Snackbar from "generic/Snackbar/Snackbar";
import { handleTranslation } from "utils/helpers";
import { useListOrganizationDoctorsQuery } from "services/organizationDoctors";
import OrganizationDoctorsMenu from "modules/layout/menus/OrganizationDoctors/OrganizationDoctorsMenu";

const PatientInfoCard = ({ consultation }: any) => {
  const navigate = useNavigate();
  const [symptoms, setSymptoms] = useState("");
  const [symptomsLang, setSymptomsLang] = useState("en");
  const [translatedSymptoms, setTranslatedSymptoms] = useState("");
  const [loading, setLoading] = useState<any>();
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [contactOrganization] = useContactOrganizationMutation();
  const { data: organizationDoctors } = useListOrganizationDoctorsQuery(0);

  const loadingRef = useRef<any>(true);
  const patientChatRef =
    consultation?.[urlRole] &&
    firestore
      .collection(ConversationsCollection)
      .doc(`${consultation?.[urlRole]?.user?.id}-${consultation.patient.id}`);
  const toggleChat = async () => {
    startChat();
    loadingRef.current.value = loadingRef.current.value === true ? false : true;
    setLoading(loadingRef.current.value);
    await patientChatRef.update({
      Disabled: loadingRef.current.value,
    });
  };

  const startChat = async () => {
    patientChatRef.get().then((docSnapshot: any) => {
      if (docSnapshot.exists) {
      } else {
        setDoc(
          patientChatRef,
          {
            Disabled: loadingRef.current.value,
            DoctorId: consultation?.[urlRole]?.user?.id,
            PatientId: consultation.patient.id,
            LastMessageTimestamp:
              firebase.firestore.FieldValue.serverTimestamp(),
            SpecialistId: consultation?.[urlRole].id,
            role: urlRole,
          },
          { merge: true }
        );
      }
    });
  };
  const goToChat = async () => {
    await startChat();
    await patientChatRef.update({
      Disabled: false,
    });
    navigate("/messages", {
      state: {
        patientId: consultation.patient.id,
        patientInfo: {
          id: consultation.patient.id,
          first_name: consultation.patient.first_name,
          last_name: consultation.patient.last_name,
          avatar_url: consultation.patient.avatar_url,
        },
      },
    });
  };

  useEffect(() => {
    firestore
      .collection(ConversationsCollection)
      .doc(
        `${consultation?.[urlRole]?.[profileUser]?.id}-${consultation.patient.id}`
      )
      .get()
      .then((res: any) => {
        loadingRef.current.value = res.exists ? res.data()?.Disabled : true;
        setLoading(loadingRef.current.value);
      });
  }, []);

  useEffect(() => {
    if (consultation) setSymptoms(consultation?.symptoms);
  }, [consultation]);

  const handleTranslate = ({ toLang }: any) => {
    let url = `https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_CLOUD_API_KEY}`;
    url += "&q=" + encodeURI(symptoms);
    // url += `&source=${fromLang}`;
    url += `&target=${toLang}`;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.data?.translations?.length) {
          setTranslatedSymptoms(response?.data?.translations[0].translatedText);
          setSymptomsLang(toLang);
        }
      })
      .catch((error) => {});
  };

  let patientAge = consultation?.patient?.birthdate
    ? moment().diff(consultation?.patient?.birthdate, "years")
    : null;
  let patientGender = consultation?.patient?.gender
    ? consultation?.patient?.gender
    : null;

  let patientSubtitle = "Age not specified";

  if (patientAge) patientSubtitle = `${patientAge} year old`;
  if (patientGender) patientSubtitle += ` - ${patientGender}`;
  else patientSubtitle += ` - Gender not specified`;

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "30px",
        }}
      >
        <Typography
          style={{ color: "#172B4D", fontSize: "16px", fontWeight: 700 }}
        >
          Patient Info
        </Typography>
        <div
          style={{ display: "flex", alignItems: "center", margin: "20px 0" }}
        >
          <img
            src={
              consultation?.patient?.avatar_url
                ? consultation?.patient?.avatar_url
                : Avatar
            }
            style={{ width: "100px", height: "100px", borderRadius: "50%" }}
            alt="avatar"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "20px",
            }}
          >
            <Typography
              style={{ color: "#172B4D", fontSize: "22px", fontWeight: 700 }}
            >
              {consultation?.patient?.first_name}{" "}
              {consultation?.patient?.last_name}
            </Typography>
            <p
              style={{
                color: "#172B4D",
                fontSize: "14px",
                fontWeight: 400,
                marginTop: "10px",
              }}
            >
              {patientSubtitle}
            </p>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <p
              style={{
                color: "#172B4D",
                fontSize: "16px",
                fontWeight: 600,
                marginRight: "10px",
              }}
            >
              Disable Patient's Chat
            </p>

            <Switch
              checked={loading}
              ref={loadingRef}
              onChange={toggleChat}
              name="loadingRef"
              color="primary"
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <PrimaryButton
                title=" Chat With Patient"
                height="40px"
                width="220px"
                margin={0}
                disabled={loadingRef.current.value}
                onClick={goToChat}
              />
            </div>
            {urlRole === "doctor" &&
              !consultation?.contact_request?.has_contact_request &&
              consultation?.doctor?.belongs_to_organization && (
                <div style={{ marginTop: "10px" }}>
                  <PrimaryButton
                    title="Contact International Office"
                    height="40px"
                    width="220px"
                    margin={0}
                    // disabled={consultation?.status !== "completed"}
                    sx={{ padding: 0 }}
                    onClick={async () => {
                      const result: any = await contactOrganization(
                        consultation?.id
                      );
                      if (!result?.error) {
                        setIsError(true);
                        setErrorMsg(
                          "Successfully Contacted International Office"
                        );
                      } else {
                        setIsError(true);
                        setErrorMsg(result?.error?.data?.message);
                      }
                    }}
                  />
                </div>
              )}
            {urlRole === "organization" &&
              !consultation?.contact_request?.has_contact_request &&
              organizationDoctors?.data?.length && (
                <OrganizationDoctorsMenu
                  consultationId={consultation?.id}
                  // isDisabled={consultation?.status !== "completed"}
                  setIsError={setIsError}
                  setErrorMsg={setErrorMsg}
                />
              )}
          </div>
        </div>
        <Divider sx={{ my: 3 }} />

        <Typography
          style={{ color: "#172B4D", fontSize: "16px", fontWeight: 700 }}
        >
          Specialty
        </Typography>

        {consultation?.specialities?.length
          ? consultation?.specialities?.map((spec: any) => {
              return (
                <p
                  style={{
                    color: "#172B4D",
                    fontSize: "14px",
                    fontWeight: 400,
                    marginTop: "10px",
                  }}
                >
                  {spec?.speciality?.name}
                </p>
              );
            })
          : null}

        <Divider sx={{ my: 3 }} />

        {consultation?.contact_request?.is_contact_request && (
          <>
            <Typography
              style={{ color: "#172B4D", fontSize: "16px", fontWeight: 700 }}
            >
              Related Appointment
            </Typography>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "20px 0",
              }}
            >
              <img
                src={
                  consultation?.contact_request?.referred_from?.user?.avatar_url
                    ? consultation?.contact_request?.referred_from?.user
                        ?.avatar_url
                    : Avatar
                }
                style={{ width: "60px", height: "60px", borderRadius: "50%" }}
                alt="avatar"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "20px",
                }}
              >
                <Typography
                  style={{
                    color: "#172B4D",
                    fontSize: "16px",
                    fontWeight: 700,
                  }}
                >
                  {consultation?.contact_request?.referred_from?.name}
                </Typography>
                <p
                  style={{
                    color: "#172B4D",
                    fontSize: "12px",
                    fontWeight: 400,
                    marginTop: "6px",
                  }}
                >
                  {dateFormat(
                    consultation?.last_action_date || consultation?.created_at,
                    "dd/MM/yyyy"
                  )}{" "}
                  •{" "}
                  {timeFormat(
                    consultation?.last_action_date || consultation?.created_at
                  )}
                </p>
              </div>
            </div>

            <Divider sx={{ my: 3 }} />
          </>
        )}

        <div style={{ display: "flex" }}>
          <Typography
            style={{ color: "#172B4D", fontSize: "16px", fontWeight: 700 }}
          >
            Symptoms
          </Typography>
          <span style={{ margin: "0 auto" }}></span>
          <p
            style={{
              cursor: "pointer",
              color: "#136588",
              fontWeight: 600,
              fontSize: "14px",
            }}
            // onClick={() => handleTranslate({ toLang: "en" })}
            onClick={() => {
              handleTranslation(
                {
                  originalText: symptoms,
                  toLang: "en",
                },
                (translatedText: string) => {
                  if (translatedText) {
                    setTranslatedSymptoms(translatedText);
                    setSymptomsLang("en");
                  }
                }
              );
            }}
          >
            Translate to English{" "}
          </p>
          -
          <p
            style={{
              cursor: "pointer",
              color: "#136588",
              fontWeight: 600,
              fontSize: "14px",
            }}
            // onClick={() => handleTranslate({ toLang: "de" })}
            onClick={() => {
              handleTranslation(
                {
                  originalText: symptoms,
                  toLang: "de",
                },
                (translatedText: string) => {
                  if (translatedText) {
                    setTranslatedSymptoms(translatedText);
                    setSymptomsLang("de");
                  }
                }
              );
            }}
          >
            {" "}
            Translate to German
          </p>
        </div>
        <p
          style={{
            color: "#172B4D",
            fontSize: "14px",
            fontWeight: 400,
            marginTop: "10px",
            wordWrap: "break-word",
          }}
        >
          {translatedSymptoms ? `Original Text: ${symptoms}` : symptoms}
        </p>
        {translatedSymptoms && (
          <p
            style={{
              color: "#172B4D",
              fontSize: "14px",
              fontWeight: 400,
              marginTop: "10px",
              wordWrap: "break-word",
            }}
          >
            {symptomsLang == "de" ? "Übersetzung" : "Translation"}:{" "}
            {translatedSymptoms}
          </p>
        )}

        <Divider sx={{ my: 3 }} />

        {consultation?.patient_uploaded_documents?.length ? (
          <>
            <PatientUploadedDocuments consultation={consultation} />
            <Divider sx={{ my: 3 }} />
          </>
        ) : null}

        {consultation?.translate_from && (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "15px 0",
                padding: "10px 15px",
                background: "#13658825",
                width: "fit-content",
                borderRadius: "10px",
              }}
            >
              <img
                src={TranslationIcon}
                style={{ width: "28px", height: "28px" }}
                alt="Input icon"
              />
              <Typography
                style={{
                  color: "#172B4D",
                  fontSize: "14px",
                  fontWeight: 700,
                  marginLeft: "10px",
                }}
              >
                Patient assigned a translator
              </Typography>
            </div>
            <p
              style={{
                color: "#172B4D",
                fontSize: "14px",
                fontWeight: 400,
                marginTop: "10px",
              }}
            >
              Translated From: {consultation?.translate_from}
            </p>
            <p
              style={{
                color: "#172B4D",
                fontSize: "14px",
                fontWeight: 400,
                marginTop: "10px",
              }}
            >
              Translator Gender: {consultation?.translator_gender}
            </p>
            <Divider sx={{ my: 3 }} />
          </>
        )}
      </div>
      <Snackbar
        open={isError}
        onClose={() => setIsError(false)}
        message={errorMsg}
      />
    </>
  );
};

export default PatientInfoCard;
