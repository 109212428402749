import { useState } from "react";
import { Box, Grid, Card } from "@mui/material";
import InputWithLabel from "generic/Inputs/InputWithLabel";
import * as yup from "yup";
import { useFormik } from "formik";
import { useInviteDoctorMutation } from "services/invitations";
import PrimaryButton from "generic/Buttons/PrimaryButton";
import Snackbar from "generic/Snackbar/Snackbar";
import {
  useListInvitationsQuery,
  useRemoveDoctorMutation,
} from "services/invitations";
import InvitationsTable from "generic/tables/InvitationsTable";
import Text from "generic/Text/Text";
import AppointmentsCard from "modules/home/components/AppointmentsCard";
import UserRow from "generic/UserRow/UserRow";
import Modal from "generic/Modals/Modal";
import Pagination from "generic/Pagination/Pagination";

const validationSchema = yup.object({
  email: yup.string().required(),
});

const getInvitationStatus = (status: string) => {
  let color = "#136588";
  let background = "#B8D0DB";
  let FEStatus = status;

  switch (status) {
    case "pending":
      FEStatus = "Pending";
      color = "#FFAB00";
      background = "#FFAB0010";
      break;

    case "accepted":
      FEStatus = "Accepted";
      color = "#1D93C7";
      background = "#1D93C710";
      break;

    case "declined":
      FEStatus = "Declined";
      color = "#EB3131";
      background = "#EB313110";
      break;
  }

  return { FEStatus, color, background };
};

const Invitations = () => {
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [doctorId, setDoctorId] = useState(0);
  const [currPage, setCurrPage] = useState(1);
  const [isRemoveDoctorConfirmationOpen, setIsRemoveDoctorConfirmationOpen] =
    useState(false);

  const [inviteDoctor] = useInviteDoctorMutation();
  const { data: invitations } = useListInvitationsQuery({
    page: currPage,
    perPage: 3,
  });
  const [removeDoctor] = useRemoveDoctorMutation();

  const handleRemovingDoctor = async () => {
    const result: any = await removeDoctor(doctorId);
    if (!result?.error) {
      setIsError(true);
      setErrorMsg("Successfully removed the doctor");
      setIsRemoveDoctorConfirmationOpen(false);
    } else {
      setIsError(true);
      setErrorMsg(result?.error?.data?.message);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async ({ email }) => {
      setLoading(true);
      const result: any = await inviteDoctor({ email });
      setLoading(false);
      setIsError(true);
      if (!result?.error) {
        setErrorMsg("successfully invited the doctor");
      } else {
        setErrorMsg(result?.error?.data?.data);
      }
    },
  });
  return (
    <>
      <Text
        sx={{ color: "#172B4D", fontSize: "18px", fontWeight: 700, mb: 1 }}
        label="List of Doctors"
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        <Box>
          {invitations?.data.length ? (
            <Pagination
              perPage={3}
              currPage={currPage}
              onChange={setCurrPage}
              pagination={invitations?.pagination}
            />
          ) : null}
        </Box>
      </div>
      {invitations?.data?.map((invitation: any) => {
        const status = getInvitationStatus(invitation?.status);
        return (
          <UserRow
            avatarUrl={invitation?.doctor?.avatar_url}
            name={invitation?.doctor?.name || invitation?.email}
            date={invitation?.created_at}
            status={status}
            mainBtnLabel="Delete"
            mainBtnOnClick={() => {
              setDoctorId(invitation?.id);
              setIsRemoveDoctorConfirmationOpen(true);
            }}
          />
        );
      })}
      <form
        style={{ margin: "auto 0", maxWidth: "100%" }}
        onSubmit={formik.handleSubmit}
      >
        <Grid container>
          <Grid item xs={12} sm={12} md={5}>
            <InputWithLabel
              label="send invitation"
              id="email"
              name="email"
              placeholder="Enter Email  Address"
              required={false}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
        </Grid>
        <PrimaryButton
          title="Send Invitation"
          type="submit"
          loading={loading}
          sx={{ fontSize: "1rem", fontWeight: 600 }}
        />
      </form>
      {/* {invitations?.data?.length > 0 && (
        <InvitationsTable
          users={invitations?.data}
          setIsError={setIsError}
          setErrorMsg={setErrorMsg}
        />
      )} */}

      <Snackbar
        open={isError}
        onClose={() => setIsError(false)}
        message={errorMsg}
      />
      <Modal
        open={isRemoveDoctorConfirmationOpen}
        setOpen={setIsRemoveDoctorConfirmationOpen}
        primaryTitle="Yes"
        secondaryTitle="Cancel"
        primaryOnclick={handleRemovingDoctor}
      >
        Are you sure you want to remove this doctor?
      </Modal>
    </>
  );
};

export default Invitations;
