import { useState } from "react";
import { InputLabel, Typography, MenuItem, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { dateFormat } from "utils/dateFormat";
import InputWithLabel from "generic/Inputs/InputWithLabel";
import PrimaryButton from "generic/Buttons/PrimaryButton";
import { NameIcon, LocationIcon } from "app/assets/icons";
import InputSelect from "generic/Inputs/InputSelect";
import EngCountryList from "countries/en/countries.json";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  useGetAllCountriesQuery,
  useLazyGetAllStatesQuery,
  useLazyGetAllCitiesQuery,
} from "services/countries";
import Snackbar from "generic/Snackbar/Snackbar";

const validationSchema = yup.object({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  address: yup.string().required(),
  birthdate: yup.date().required(),
  gender: yup.string().required(),
  phone_number: yup.string().required(),
  country: yup.string().required(),
  // state: yup.number(),
  // city: yup.number(),
  speciality: yup.string().required(),
});

const AddDetails = ({ handleSignup, isError, setIsError, errorMsg }: any) => {
  const { data: countries } = useGetAllCountriesQuery(1);

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      address: "",
      birthdate: "",
      gender: "",
      phone_number: "",
      country: "",
      // state: "",
      // city: "",
      speciality: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      const formattedDate = dateFormat(values.birthdate, "MM-dd-yyyy");
      handleSignup({
        ...values,
        birthdate: formattedDate,
      });
    },
  });

  return (
    <form
      style={{ margin: "auto 0", maxWidth: "60%" }}
      onSubmit={formik.handleSubmit}
    >
      <Typography
        sx={{ fontWeight: 600, fontSize: "32px", color: "#172B4D", mb: 3 }}
      >
        Add Details
      </Typography>

      <InputWithLabel
        label="First Name*"
        id="first_name"
        name="first_name"
        placeholder="Enter First Name"
        autoFocus={true}
        startIcon={<NameIcon />}
        value={formik.values.first_name}
        onChange={formik.handleChange}
        error={formik.touched.first_name && Boolean(formik.errors.first_name)}
        helperText={formik.touched.first_name && formik.errors.first_name}
      />

      <InputWithLabel
        label="Last Name*"
        id="last_name"
        name="last_name"
        placeholder="Enter Last Name"
        startIcon={<NameIcon />}
        value={formik.values.last_name}
        onChange={formik.handleChange}
        error={formik.touched.last_name && Boolean(formik.errors.last_name)}
        helperText={formik.touched.last_name && formik.errors.last_name}
      />

      <InputWithLabel
        label="Work Address*"
        id="address"
        name="address"
        placeholder="Enter Address"
        startIcon={<LocationIcon />}
        value={formik.values.address}
        onChange={formik.handleChange}
        error={formik.touched.address && Boolean(formik.errors.address)}
        helperText={formik.touched.address && formik.errors.address}
      />

      <InputLabel
        sx={{ mb: 1, fontWeight: 600, fontSize: "14px", color: "#172B4D" }}
      >
        Birth Date*
      </InputLabel>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        sx={{ border: "none" }}
      >
        <DatePicker
          label="Enter Birth Date"
          openTo="year"
          value={formik.values.birthdate}
          onChange={(newValue) => formik.setFieldValue("birthdate", newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              id="birthdate"
              name="birthdate"
              error={
                formik.touched.birthdate && Boolean(formik.errors.birthdate)
              }
              helperText={formik.touched.birthdate && formik.errors.birthdate}
              sx={{ width: "100%", mb: 1 }}
            />
          )}
        />
      </LocalizationProvider>

      <InputSelect
        id="gender"
        name="gender"
        label={"Gender*"}
        value={formik.values.gender}
        onChange={formik.handleChange}
        error={formik.touched.gender && Boolean(formik.errors.gender)}
        placeholder={"Select Your Gender"}
      >
        <MenuItem value={"male"}>Male</MenuItem>
        <MenuItem value={"female"}>Female</MenuItem>
      </InputSelect>

      <InputWithLabel
        label="Phone Number*"
        id="phone_number"
        name="phone_number"
        placeholder="Enter Phone Number"
        value={formik.values.phone_number}
        onChange={formik.handleChange}
        error={
          formik.touched.phone_number && Boolean(formik.errors.phone_number)
        }
        helperText={formik.touched.phone_number && formik.errors.phone_number}
      />

      <InputSelect
        id="country"
        name="country"
        label={"Country*"}
        value={formik.values.country}
        onChange={formik.handleChange}
        error={formik.touched.country && Boolean(formik.errors.country)}
        placeholder={"Select Country"}
      >
        {countries?.data.map((element: any) => {
          return <MenuItem value={element.name}>{element.name}</MenuItem>;
        })}
      </InputSelect>

      {/* {states?.data?.length ? (
        <InputSelect
          id="state"
          name="state"
          label={"State"}
          value={
            states?.data?.find((state: any) => state.id == formik.values.state)
              ?.name
          }
          onChange={(e: any) => {
            formik.handleChange(e);
            getAllCities({
              countryId: formik.values.country,
              cityId: e.target.value,
            });
            formik.setFieldValue("city", "");
          }}
          error={formik.touched.state && Boolean(formik.errors.state)}
          placeholder={"Select State"}
        >
          {states?.data.map((element: any) => {
            return <MenuItem value={element.id}>{element.name}</MenuItem>;
          })}
        </InputSelect>
      ) : null}

      {cities?.data?.length ? (
        <InputSelect
          id="city"
          name="city"
          label={"City"}
          value={
            cities?.data?.find((city: any) => city.id == formik.values.city)
              ?.name
          }
          onChange={formik.handleChange}
          error={formik.touched.city && Boolean(formik.errors.city)}
          placeholder={"Select City"}
        >
          {cities?.data.map((element: any) => {
            return <MenuItem value={element.id}>{element.name}</MenuItem>;
          })}
        </InputSelect>
      ) : null} */}

      <InputWithLabel
        label="Specialty*"
        id="speciality"
        name="speciality"
        placeholder="Tell people something about yourself"
        multiline={true}
        rows={4}
        value={formik.values.speciality}
        onChange={formik.handleChange}
        error={formik.touched.speciality && Boolean(formik.errors.speciality)}
        helperText={formik.touched.speciality && formik.errors.speciality}
      />

      <PrimaryButton title="Next" type="submit" />
      <Snackbar
        open={isError}
        onClose={() => setIsError(false)}
        message={errorMsg}
      />
    </form>
  );
};

export default AddDetails;
