import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import "./app/styles/main-theme.scss";
import { store } from "./app/store/store";
import DoctorRoutes from "./routes/DoctorRoutes";
import "utils/amplifyConfigure";
import "./App.css";
import { useEffect } from "react";
import { Auth } from "aws-amplify";
import { getIdToken } from "firebase/auth";

async function runOneSignal() {
  const OneSignal = window.OneSignal || [];

  OneSignal.push(function () {
    OneSignal.init({
      appId: process.env.REACT_APP_ONE_SIGNAL_APP_ID,
    }).then(() => OneSignal.showSlidedownPrompt());
  });
}

const App = () => {
  useEffect(() => {
    runOneSignal();
  });

  useEffect(() => {
    async function getIdToken() {
      return Auth.currentSession()
        .then((user: any) => {
          localStorage.setItem("idToken", user?.idToken?.jwtToken);
        })
        .catch((err) => {
          localStorage.removeItem("idToken");
        });
    }
    getIdToken();
  }, []);

  return (
    <ThemeProvider theme={THEME}>
      <Provider store={store}>
        <DoctorRoutes />
      </Provider>
    </ThemeProvider>
  );
};

const THEME = createTheme({
  typography: {
    fontFamily: `Montserrat, Arial, sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,

    body2: {
      fontFamily: "Noto Sans JP, sans-serif",
    },
    subtitle2: {
      fontFamily: "Noto Sans JP, sans-serif",
    },
  },
});

export default App;
