import { Grid } from "@mui/material";
import ContactMethod from "./ContactMethod";

const ContactInfo = () => {
  return (
    <>
      <Grid container sx={{ mb: 4 }} spacing={4}>
        <Grid item xs={12} sm={12} md={3.5}>
          <ContactMethod label="Email" value="support@leviomed.com" />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <ContactMethod
            label="Address"
            value="Leviomed Gmbh Rainweg 1101 69118 Heinsberg"
            fontSize="0.875rem"
            valueColor="#7F8CA9"
          />
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={3.5}>
          <ContactMethod label="Customer Service" value="+49622139256503" />
        </Grid>
        <Grid item xs={12} sm={12} md={3.5}>
          <ContactMethod label="WhatsApp" value="+491733033343" />
        </Grid>
        <Grid item xs={12} sm={12} md={3.5}>
          <ContactMethod label="Messages" value="+491733033343" />
        </Grid>
      </Grid>
    </>
  );
};

export default ContactInfo;
