import calendarIcon from "app/assets/icons/calendar.svg";

const WaitingClientApproveDate = () => {
  return (
    <div
      style={{
        display: "flex",
        background: "#13658820",
        borderRadius: "10px",
        padding: "15px",
      }}
    >
      <img
        src={calendarIcon}
        style={{ width: "24px", height: "24px" }}
        alt="calendar icon"
      />
      <div style={{ marginLeft: "10px" }}>
        <p
          style={{
            color: "#172B4D",
            fontSize: "14px",
            fontWeight: 600,
          }}
        >
          New date suggested
        </p>
        <p
          style={{
            color: "#172B4D",
            fontSize: "14px",
            marginTop: "10px",
          }}
        >
          Waiting for the client to approve new appointment date.
        </p>
      </div>
    </div>
  );
};

export default WaitingClientApproveDate;
