import { Grid } from '@mui/material'
import { useEffect, useState } from 'react';
import { useHomeStatsQuery } from 'services/insights';
import StatsCard, { Stat } from './StatsCard';

interface HomeStats {
  newPatients: Stat,
  totalAppointments: Stat,
  totalPatients: Stat,
};

const TITLES = {
  newPatients: "New Patients",
  totalAppointments: "Total Appointments",
  totalPatients: "Total Patients",
};

const Stats = () => {
  const [stats, setStats] = useState<HomeStats>();
  const [period, setPeriod] = useState('month');
  const {data, error, isLoading, isSuccess} = useHomeStatsQuery(period);

  useEffect(() => {
    if(isLoading) return;

    const {
      new_patients, new_patients_percent, 
      consultations, consultations_percent,
      total_patients, total_patients_percent
    } = data.home_statistics;
    
    setStats({
      newPatients: {count: new_patients, percentage: new_patients_percent, title: TITLES.newPatients},
      totalAppointments: {count: consultations, percentage: consultations_percent, title:  TITLES.totalAppointments},
      totalPatients: {count: total_patients, percentage: total_patients_percent, title: TITLES.totalPatients },
    });
  }, [isLoading]);

  // if(!stats) return <></>
  return (
    <Grid container item direction="row">
      <StatsCard 
        stat={stats?.newPatients} 
        showPeriodFilter={true} 
        activePeriod={period} 
        handlePeriodChange={setPeriod}
        md={6}
      />
      <StatsCard stat={stats?.totalPatients}/>
      <StatsCard stat={stats?.totalAppointments}/>
    </Grid>
  )
}

export default Stats