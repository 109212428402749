import { useState, useEffect } from "react";
import NewWindow from "react-new-window";
import { InputLabel } from "@mui/material";
import BlueButton from "../BlueButton";
import { VideoCameraIcon } from "app/assets/icons";
import { useGetMyProfileQuery } from "services/profileSettings";
import {
  useGetVideoTokenQuery,
  useGetGuestTokenQuery,
} from "services/appointments";
import Agora from "modules/agora/Agora";
import { useCountdown } from "modules/requestManagement/hooks/useCountdown";
import { Typography } from "@mui/material";
import { useUpdateConsultationMutation } from "services/appointments";
import InputWithLabel from "generic/Inputs/InputWithLabel";
import { ShareIcon, CopyIcon } from "app/assets/icons";
import { urlRole, profileUser } from "services";

export const StartVideoCall = ({
  consultation,
  type,
  handleErrorPopup,
}: any) => {
  const [isAgoraButtonClicked, setIsAgoraButtonClicked] = useState(false);
  const [guestUrl, setGuestUrl] = useState("");

  const { data: myProfile } = useGetMyProfileQuery();
  const { data: videoCallToken } = useGetVideoTokenQuery(consultation?.id);
  const { data: guestToken } = useGetGuestTokenQuery(consultation?.id);
  const [updateConsultation] = useUpdateConsultationMutation();

  const [days, hours, minutes, _] = useCountdown(consultation.slot);

  useEffect(() => {
    if (guestToken?.token)
      setGuestUrl(
        `${process.env.REACT_APP_FE_URL}/agora?channel=${
          consultation?.channel_key
        }&token=${encodeURIComponent(guestToken?.token)}`
      );
  }, [guestToken]);

  // if (
  //   days < -2 ||
  //   (days === -1 && hours <= -2) ||
  //   (days === -1 && hours === -1 && minutes <= -15)
  // )
  //   return (
  //     <>
  //       <h4
  //         style={{
  //           color: "#172B4D",
  //           fontSize: "16px",
  //           fontWeight: 700,
  //         }}
  //       >
  //         Video Call Expired
  //       </h4>
  //     </>
  //   );

  return (
    <>
      {days < -2 ||
      (days === -1 && hours <= -2) ||
      (days === -1 && hours === -1 && minutes <= -15) ? (
        <h4
          style={{
            color: "#172B4D",
            fontSize: "16px",
            fontWeight: 700,
          }}
        >
          Video Call Expired
        </h4>
      ) : (
        <>
          <Typography
            style={{
              color: "#172B4D",
              fontSize: "16px",
              fontWeight: 700,
              marginBottom: "20px",
            }}
          >
            Video call
          </Typography>
          {days <= 0 && hours <= 0 && minutes <= 0 && -15 < minutes ? (
            <VideoCallBtn
              btnText={`Start Video Call. ${15 + minutes} minutes remaining`}
              videoBtnOnclick={() => {
                setIsAgoraButtonClicked(true);
                updateConsultation({
                  type,
                  id: consultation.id,
                  updates: { doctor_started_call: new Date() },
                });
              }}
              copyIconOnClick={() => {
                navigator.clipboard.writeText(guestUrl);
                handleErrorPopup("Copied!");
              }}
            />
          ) : (
            <VideoCallBtn
              btnText={`Video Call Starts in ${days}D : ${hours}H : ${minutes}M`}
              disabled={true}
              videoBtnOnclick={() => {
                setIsAgoraButtonClicked(true);
                updateConsultation({
                  type,
                  id: consultation.id,
                  updates: { doctor_started_call: new Date() },
                });
              }}
              copyIconOnClick={() => {
                navigator.clipboard.writeText(guestUrl);
                handleErrorPopup("Copied!");
              }}
            />
          )}
        </>
      )}
      {isAgoraButtonClicked && (
        <NewWindow
          url={`/agora?uid=${myProfile?.[urlRole]?.[profileUser]?.id}&channel=${
            consultation?.channel_key
          }&token=${encodeURIComponent(
            videoCallToken?.token
          )}&consultation_id=${consultation?.id}`}
          onUnload={() => {
            setIsAgoraButtonClicked(false);
            updateConsultation({
              type,
              id: consultation.id,
              updates: { doctor_ended_call: new Date() },
            });
          }}
        >
          {/* <Agora
            uid={myProfile?.[urlRole]?.[profileUser]?.id}
            channel={consultation?.channel_key}
            token={videoCallToken?.token}
            setIsAgoraButtonClicked={setIsAgoraButtonClicked}
          /> */}
        </NewWindow>
      )}
    </>
  );
};

const VideoCallBtn = ({
  btnText,
  videoBtnOnclick,
  copyIconOnClick,
  disabled,
}: any) => {
  return (
    <>
      <BlueButton
        onClick={videoBtnOnclick}
        disabled={disabled}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "23rem",
          marginBottom: "10px",
        }}
      >
        <VideoCameraIcon style={{ marginRight: "10px" }} />
        {btnText}
      </BlueButton>

      <div style={{ display: "flex", alignItems: "center" }}>
        <InputLabel
          sx={{
            fontWeight: 600,
            fontSize: "14px",
            color: "#172B4D",
          }}
        >
          Copy Invitation Link:
        </InputLabel>
        <span style={{ margin: "0 5px" }}></span>
        <CopyIcon
          style={{ width: "20px", height: "20px", cursor: "pointer" }}
          onClick={copyIconOnClick}
        />
      </div>
    </>
  );
};

export default StartVideoCall;
