import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import PrimaryButton from "generic/Buttons/PrimaryButton";
import InputWithLabel from "generic/Inputs/InputWithLabel";
import Text from "generic/Text/Text";
import { sendConfirmationCode } from "../service";
import { useAppSelector, useAppDispatch } from "app/store/hooks";
import { isLoading, setLoading } from "app/store/genericSlice";
import Snackbar from "generic/Snackbar/Snackbar";

const SendCode = ({ setCurrentStep, email, setEmail }: any) => {
  const loading = useAppSelector(isLoading);
  const dispatch = useAppDispatch();

  const [isValid, setIsValid] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  let validateEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  useEffect(() => {
    if (validateEmail.test(email)) setIsValid(true);
    else setIsValid(false);
  }, [email]);

  const handleSendingCode = async () => {
    try {
      dispatch(setLoading(true));
      await sendConfirmationCode(email);
      dispatch(setLoading(false));
      setCurrentStep((prevStep: number) => ++prevStep);
    } catch (e: any) {
      dispatch(setLoading(false));
      setIsError(true);
      setErrorMsg(e.message);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      sx={{ my: "auto", mx: 0, maxWidth: "60%" }}
    >
      <Text
        sx={{ fontWeight: 600, fontSize: "32px", color: "#172B4D" }}
        label="Forgot Password"
      />
      <Text
        sx={{
          mb: 4,
          fontWeight: 400,
          fontSize: "14px",
          color: "#172B4D",
        }}
        label="Enter you email here and we will send you a confirmation code to change your password."
      />

      <InputWithLabel
        label="Email Address*"
        id="email"
        name="email"
        type="email"
        placeholder="Enter Email Address"
        autoFocus={true}
        value={email}
        onChange={(e: any) => setEmail(e.target.value)}
      />

      <PrimaryButton
        title="Send Code"
        type="button"
        disabled={!isValid}
        loading={loading}
        onClick={handleSendingCode}
      />

      <Snackbar
        open={isError}
        onClose={() => setIsError(false)}
        message={errorMsg}
      />
    </Box>
  );
};

export default SendCode;
