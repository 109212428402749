import { Trans } from "react-i18next";
import { SxProps, Theme, Typography } from "@mui/material";

interface IText {
  sx?: SxProps<Theme> | undefined;
  label: string;
  variant?: "h1" 
  | "h2" 
  | "h3" 
  | "h4" 
  | "h5" 
  | "h6" 
  | "subtitle1" 
  | "subtitle2" 
  | "body1" 
  | "body2" 
  | "caption" 
  | "button" 
  | "overline" 
  | "inherit" 
  | undefined;
};

const Text = ({ sx, label, variant }: IText) => (
  <Typography sx={sx} variant={variant}>
    <Trans>{label}</Trans>
  </Typography>
);

export default Text;
