import { Auth } from "aws-amplify";

export const cognitoLoginService = async (email: string, password: string) => {
  const loginResult = await Auth.signIn(email, password);
  return loginResult;
};

export const serverLoginService = async (token: string) => {
  const loginResult = await Auth.signIn(token);
  return loginResult;
};
