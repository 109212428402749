import api from "./index";
import { urlRole } from "./index";

export const patients = api.injectEndpoints({
  endpoints: (builder) => ({
    doctorPatients: builder.query<any, string>({
      query: (keyword) =>
        `/api/v1/${urlRole}/users?filters[keyword]=${keyword}`,
      providesTags: () => [{ type: "DoctorPatients" }],
    }),
    getPatientInfo: builder.query<any, number>({
      query: (id) => `/api/v1/${urlRole}/users/${id}`,
      providesTags: () => [{ type: "DoctorPatients" }],
    }),
  }),
});

export const { useDoctorPatientsQuery, useLazyGetPatientInfoQuery } = patients;
