import { Typography } from "@mui/material";
import Avatar from "app/assets/images/avatar.png";
import { dateFormat, timeFormat } from "utils/dateFormat";

const UserRow = ({
  avatarUrl,
  name,
  date,
  status,
  mainBtnLabel,
  mainBtnOnClick,
}: any) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "1.5rem",
      }}
    >
      <img
        src={avatarUrl ? avatarUrl : Avatar}
        style={{ width: "3.75rem", height: "3.75rem", borderRadius: "50%" }}
        alt="avatar"
      />
      <div style={{ marginLeft: "1rem" }}>
        <Typography
          variant="body2"
          sx={{
            fontSize: "16px",
            color: "#172B4D",
            fontWeight: 700,
            lineHeight: "1.685rem",
          }}
        >
          {name}
          {/* {`${consultation?.patient?.first_name} ${consultation?.patient?.last_name}`} */}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            color: "#7F8CA9",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "1.474rem",
          }}
        >
          {dateFormat(date, "dd/MM/yyyy")} • {timeFormat(date)}
        </Typography>
      </div>
      <span style={{ margin: "0 auto" }}></span>
      <Typography
        sx={{
          color: status?.color,
          background: status?.background,
          padding: "5px 10px",
          borderRadius: "20px",
          fontSize: "0.875rem",
          fontWeight: 600,
          width: "fit-content",
        }}
      >
        {status?.FEStatus}
      </Typography>
      <span style={{ margin: "0 20px" }}></span>

      {mainBtnLabel && (
        <button
          onClick={mainBtnOnClick ? mainBtnOnClick : null}
          style={{
            color: "white",
            textDecoration: "none",
            background: "#136588",
            padding: "10px 15px",
            borderRadius: "5px",
            fontWeight: 600,
            border: "none",
            cursor: "pointer",
            fontSize: "15px",
            width: "141px",
            height: "42px",
          }}
        >
          {mainBtnLabel}
        </button>
      )}
    </div>
  );
};

export default UserRow;
