import { useState, useEffect } from "react";
import {
  Button,
  Typography,
  TextField,
  MenuItem,
  Grid,
  InputLabel,
  Box,
} from "@mui/material";
import moment from "moment";
import { getStatus } from "utils/getStatus";
import Modal from "generic/Modals/Modal";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useGetMyProfileQuery } from "services/profileSettings";
import * as yup from "yup";
import { useFormik } from "formik";
import InputSelect from "generic/Inputs/InputSelect";
import { getTimeslots } from "utils/getTimeslots";
import { appointmentType } from "utils/enums";
import {
  useRequestChangesMutation,
  useNextAppointmentMutation,
  useLazyGetReservedSlotsQuery,
} from "services/appointments";
import { urlRole } from "services";

const weekday = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

const validationSchema = yup.object({
  date1: yup.date().required(),
  date2: yup.date().required(),
  date3: yup.date().required(),
  time1: yup.string().required(),
  time2: yup.string().required(),
  time3: yup.string().required(),
});

const ChooseAppointmentDateHeader = ({ consultation, setRequestId }: any) => {
  const [open, setOpen] = useState(false);
  // const [availableDays, setAvailableDays] = useState<any[]>([]);
  const { FEStatus, color, background } = getStatus(consultation.status);

  const [date1, setDate1] = useState<any>(null);
  const [onlyDate1, setOnlyDate1] = useState<any>(null);
  const [time1, setTime1] = useState<any>("");
  const [slots1, setSlots1] = useState<any>("");

  const [date2, setDate2] = useState<any>(null);
  const [onlyDate2, setOnlyDate2] = useState<any>(null);
  const [time2, setTime2] = useState<any>("");
  const [slots2, setSlots2] = useState<any>("");

  const [date3, setDate3] = useState<any>(null);
  const [onlyDate3, setOnlyDate3] = useState<any>(null);
  const [time3, setTime3] = useState<any>("");
  const [slots3, setSlots3] = useState<any>("");

  const [errorMsg, setErrorMsg] = useState("");
  const [isNextAppointment, setIsNextAppointment] = useState(false);

  const { data: myProfile } = useGetMyProfileQuery();
  const [requestChanges] = useRequestChangesMutation();
  const [setNextAppointment] = useNextAppointmentMutation();

  const [getReservedSlots, { data: reservedSlots }] =
    useLazyGetReservedSlotsQuery();

  useEffect((): any => {
    if (date1) {
      GetSlots({ date: date1, setSlots: setSlots1, setTime: setTime1 });
    }
  }, [date1]);

  useEffect((): any => {
    if (date2) {
      GetSlots({ date: date2, setSlots: setSlots2, setTime: setTime2 });
    }
  }, [date2]);

  useEffect((): any => {
    if (date3) {
      GetSlots({ date: date3, setSlots: setSlots3, setTime: setTime3 });
    }
  }, [date3]);

  const GetSlots = async ({ date, setSlots, setTime }: any) => {
    let day = weekday[date?.getDay()];
    let slots = getDayTimeSlots(day);
    let fullEndDate = moment(date, "YYYY/MM/DD").add(1, "days");
    let startDate = moment(date).format("YYYY/MM/DD");
    let endDate = moment(fullEndDate).format("YYYY/MM/DD");
    const reservedSlots = await getReservedSlots({
      startDate,
      endDate,
    });

    let filteredSlots = slots;

    if (reservedSlots?.data?.data.length) {
      reservedSlots?.data?.data.map((singleSlot: any) => {
        let slot = singleSlot.slot;
        let formattedSlot = moment(slot).format("h:mm A");
        const entryFiltered = filteredSlots.filter(
          (slot) => slot != formattedSlot
        );
        filteredSlots = entryFiltered;
      });
    }

    const chosenDate = moment(date).format("YYYY/MM/DD");
    const currentDate = moment(new Date()).format("YYYY/MM/DD");

    if (chosenDate == currentDate) {
      const start = moment(new Date());
      const remainder = 30 - (start.minute() % 30);
      const currentDayTimestamp = moment(start)
        .add(remainder, "minutes")
        .format("h:mm A");

      filteredSlots = filteredSlots.filter((entry: any) => {
        let entryFormatted = moment(new Date(`1-1-2020 ${entry}`));
        let currentDateTime = moment(
          new Date(`1-1-2020 ${currentDayTimestamp}`)
        );
        if (entryFormatted > currentDateTime) return entry;
      });
    }

    setSlots(filteredSlots);
    setTime("");
    setErrorMsg("");
  };

  const getDayTimeSlots = (day: any) => {
    let from: any;
    let to: any;

    // let timezoneOffset = new Date().getTimezoneOffset() / -60;
    myProfile?.[urlRole]?.availability_days?.map((single: any) => {
      if (single?.day === day) {
        let fromDate = moment(new Date(`1-1-2020 00:00`.replace(/-/g, "/")));
        from = moment(fromDate).format("H:mm");

        let toDate = moment(new Date(`1-1-2020 23:30`.replace(/-/g, "/")));
        to = moment(toDate).format("H:mm");
      }
    });
    return getTimeslots(from, to);
  };

  const formik = useFormik({
    initialValues: {
      date1: null,
      date2: null,
      date3: null,
      time1: "",
      time2: "",
      time3: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      console.log({ values });
    },
  });

  // function disableNotAvailableDays(date: any) {
  //   if (!availableDays[date.getDay()]) return true;
  //   else return false;
  // }

  const handleSubmitTimeslots = async () => {
    if (onlyDate1 && onlyDate2 && onlyDate3 && time1 && time2 && time3) {
      const slot1: any = moment(`${onlyDate1} ${time1}`, "MM-DD-YYYY hh:mm A");
      const slot2: any = moment(`${onlyDate2} ${time2}`, "MM-DD-YYYY hh:mm A");
      const slot3: any = moment(`${onlyDate3} ${time3}`, "MM-DD-YYYY hh:mm A");
      setErrorMsg("");
      let result: any;
      if (isNextAppointment)
        result = await setNextAppointment({
          id: consultation?.id,
          type: consultation.type,
          slots: {
            slot1: new Date(slot1),
            slot2: new Date(slot2),
            slot3: new Date(slot3),
          },
        });
      else
        result = await requestChanges({
          id: consultation?.id,
          type: consultation.type,
          slots: {
            slot1: new Date(slot1),
            slot2: new Date(slot2),
            slot3: new Date(slot3),
          },
        });
      if (result?.error) {
        setErrorMsg(result?.error?.data?.data);
      } else {
        setOpen(false);
      }
    } else {
      setErrorMsg("Please select all timeslots!");
    }
  };

  const renderSelectSlot = ({
    date,
    setDate,
    setOnlyDate,
    time,
    setTime,
    slots,
    renderTop,
  }: any) => (
    <Grid container style={{ marginTop: "10px" }}>
      <Grid item xs={6}>
        <InputLabel
          sx={{
            mb: 1,
            fontWeight: 600,
            fontSize: "14px",
            color: "#172B4D",
          }}
        >
          Choose Appointment Day
        </InputLabel>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          sx={{ border: "none" }}
        >
          <DatePicker
            label="Choose Appointment Day"
            // shouldDisableDate={disableNotAvailableDays}
            disablePast
            value={date}
            onChange={(newValue) => {
              setDate(newValue);
              setOnlyDate(moment(newValue).format("MM-DD-YYYY"));
            }}
            PopperProps={
              renderTop
                ? {
                    placement: "top",
                    modifiers: [
                      {
                        name: "flip",
                        options: {
                          fallbackPlacements: ["top"],
                        },
                      },
                      {
                        name: "preventOverflow",
                        options: {
                          mainAxis: false, // true by default
                        },
                      },
                    ],
                  }
                : {}
            }
            // onChange={(newValue) => formik.setFieldValue("date1", newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                id="test"
                name="test"
                // error={formik.touched.date && Boolean(formik.errors.date)}
                // helperText={formik.touched.date && formik.errors.date}
                sx={{ width: "100%", mb: 1 }}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <span style={{ margin: "0 10px" }}></span>
      <Grid item xs={5}>
        <InputSelect
          // id="time1"
          name="time"
          label={"Choose timeslot"}
          value={time}
          onChange={(e: any) => setTime(e.target.value)}
          placeholder={"Choose timeslot"}
        >
          {slots.length
            ? slots?.map((slot: any) => {
                return <MenuItem value={slot}>{slot}</MenuItem>;
              })
            : null}
        </InputSelect>
      </Grid>
    </Grid>
  );

  return (
    <form
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
      }}
      onSubmit={formik.handleSubmit}
    >
      <Typography
        style={{
          color: "#172B4D",
          fontSize: "16px",
          fontWeight: 600,
          marginRight: "10px",
        }}
      >
        Choose one appointment date
      </Typography>
      {true && (
        <Typography
          style={{
            color,
            background,
            padding: "5px 10px",
            borderRadius: "20px",
            fontWeight: 500,
          }}
        >
          {FEStatus}
        </Typography>
      )}
      <span style={{ margin: "0 auto" }}></span>
      {/* {true && ( */}
      {(consultation.status === "waiting_doctor_confirmation" ||
        consultation.status === "waiting_patient_confirmation") && (
        <Button
          sx={{ textTransform: "none", color: "#EB3131", fontWeight: 600 }}
          disabled={consultation.status === "waiting_patient_confirmation"}
          onClick={() => {
            setOpen(true);
            setIsNextAppointment(false);
          }}
        >
          {consultation.type === appointmentType.EmergencyConsultation
            ? "Set Date"
            : "Edit Date"}
        </Button>
      )}
      {consultation.status === "completed" &&
        (consultation.next_appointment_id ? (
          <Button
            sx={{ textTransform: "none", color: "#EB3131", fontWeight: 600 }}
            disabled={consultation.status === "waiting_patient_confirmation"}
            onClick={() => setRequestId(consultation.next_appointment_id)}
          >
            Get next appointment
          </Button>
        ) : (
          <Button
            sx={{ textTransform: "none", color: "#EB3131", fontWeight: 600 }}
            disabled={consultation.status === "waiting_patient_confirmation"}
            onClick={() => {
              setOpen(true);
              setIsNextAppointment(true);
            }}
          >
            Set next appointment
          </Button>
        ))}
      <div>
        <Modal
          open={open}
          setOpen={setOpen}
          primaryTitle={
            isNextAppointment
              ? "Set Next Appointment"
              : consultation.type === appointmentType.EmergencyConsultation
              ? "Save"
              : "Request Changes"
          }
          primaryOnclick={handleSubmitTimeslots}
          sx={{ marginTop: "150px" }}
        >
          <div>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Choose new appointment date
            </Typography>
            {renderSelectSlot({
              date: date1,
              setDate: setDate1,
              setOnlyDate: setOnlyDate1,
              time: time1,
              setTime: setTime1,
              slots: slots1,
            })}

            {renderSelectSlot({
              date: date2,
              setDate: setDate2,
              setOnlyDate: setOnlyDate2,
              time: time2,
              setTime: setTime2,
              slots: slots2,
            })}

            {renderSelectSlot({
              date: date3,
              setDate: setDate3,
              setOnlyDate: setOnlyDate3,
              time: time3,
              setTime: setTime3,
              slots: slots3,
              renderTop: true,
            })}

            <p style={{ color: "red" }}>{errorMsg}</p>
          </div>
        </Modal>
      </div>
    </form>
  );
};

export default ChooseAppointmentDateHeader;
