import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store/store";

export interface LayoutState {
  isActive: boolean[];
}

const initialState: LayoutState = {
  isActive: [false, false, false, false, false, false, false, false],
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    activateOne: (state, action: PayloadAction<number>) => {
      state.isActive = [false, false, false, false, false, false, false, false];
      state.isActive[action.payload] = true;
    },
  },
});

export const { activateOne } = layoutSlice.actions;

export const isActive = (state: RootState) => state.layout.isActive;

export default layoutSlice.reducer;
