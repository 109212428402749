import PrimaryButton from "generic/Buttons/PrimaryButton";
import CancelAppointment from "./CancelAppointment";

const ApproveAndCancelAppointments = ({
  onApprove,
  disablePrimary,
  consultation,
  type,
}: any) => {
  return (
    <>
      <div style={{ display: "flex" }}>
        <PrimaryButton
          title={type == "WrittenConsultation" ? "Accept" : "Approve Date"}
          type="submit"
          height="40px"
          disabled={disablePrimary}
          onClick={onApprove}
        />
        <span style={{ margin: "0 10px" }}></span>
        <CancelAppointment type={type} consultationId={consultation?.id} />
      </div>
    </>
  );
};

export default ApproveAndCancelAppointments;
