import { useEffect, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import Layout from "modules/layout/Layout";
import Card from "generic/Cards/Card";
import PersonalInformation from "./tabs/PersonalInformation";
import SocialMedia from "./tabs/SocialMedia";
import Expertise from "./tabs/Expertise";
import Badges from "./tabs/Badges";
import Insurance from "./tabs/Insurance";
import Specialities from "./tabs/Specialities";
import Translator from "./tabs/Translator";
import Photos from "./tabs/Photos";
import Videos from "./tabs/Videos";
import Clinic from "./tabs/Clinic";
import { useGetMyProfileQuery } from "services/profileSettings";
import Tabs from "generic/Tabs/Tabs";
import Tab from "generic/Tabs/Tab";
import Documents from "./tabs/Documents";
import Invitations from "./tabs/Invitations";
import { urlRole } from "services";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ProfileSettings = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState(searchParams.get("tabId"));
  useEffect(() => setSearchParams({ tabId: value ? value : "0" }), []);

  const { data, error, isLoading, isSuccess } = useGetMyProfileQuery();

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
    setSearchParams({ tabId: newValue });
  };

  const renderDoctorTabs = () => (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "#DFE1E6", px: "1.5rem" }}>
        <Tabs
          value={value ? parseInt(value) : 0}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="profile tabs"
        >
          <Tab label="Personal information" {...a11yProps(0)} />
          <Tab label="Social media" {...a11yProps(1)} />
          <Tab label="Expertise" {...a11yProps(2)} />
          <Tab label="Badges" {...a11yProps(3)} />
          <Tab label="Insurance" {...a11yProps(4)} />
          <Tab label="Specialties" {...a11yProps(5)} />
          {/* <Tab label="Translator" {...a11yProps(6)} /> */}
          <Tab label="Photos" {...a11yProps(6)} />
          <Tab label="Videos" {...a11yProps(7)} />
          <Tab label="Clinic" {...a11yProps(8)} />
          <Tab label="Documents" {...a11yProps(9)} />
        </Tabs>
      </Box>
      <Box sx={{ px: "1.5rem" }}>
        <TabPanel value={value ? parseInt(value) : 0} index={0}>
          <PersonalInformation doctorData={data?.[urlRole]} />
        </TabPanel>
        <TabPanel value={value ? parseInt(value) : 0} index={1}>
          <SocialMedia doctorData={data?.[urlRole]} />
        </TabPanel>
        <TabPanel value={value ? parseInt(value) : 0} index={2}>
          <Expertise
            biographies={data?.[urlRole]?.biographies}
            institutions={data?.[urlRole]?.academic_institutions}
            operations={data?.[urlRole]?.operations}
          />
        </TabPanel>
        <TabPanel value={value ? parseInt(value) : 0} index={3}>
          <Badges doctorData={data?.[urlRole]} />
        </TabPanel>
        <TabPanel value={value ? parseInt(value) : 0} index={4}>
          <Insurance doctorData={data?.[urlRole]} />
        </TabPanel>
        <TabPanel value={value ? parseInt(value) : 0} index={5}>
          <Specialities doctorData={data?.[urlRole]} />
        </TabPanel>
        {/* <TabPanel value={value ? parseInt(value) : 0} index={6}>
              <Translator doctorData={data?.[urlRole]} />
            </TabPanel> */}
        <TabPanel value={value ? parseInt(value) : 0} index={6}>
          <Photos doctorData={data?.[urlRole]} />
        </TabPanel>
        <TabPanel value={value ? parseInt(value) : 0} index={7}>
          <Videos doctorData={data?.[urlRole]} />
        </TabPanel>
        <TabPanel value={value ? parseInt(value) : 0} index={8}>
          <Clinic doctorData={data?.[urlRole]} />
        </TabPanel>
        <TabPanel value={value ? parseInt(value) : 0} index={9}>
          <Documents doctorData={data?.[urlRole]} />
        </TabPanel>
      </Box>
    </Box>
  );

  const renderOrganizationTabs = () => (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "#DFE1E6", px: "1.5rem" }}>
        <Tabs
          value={value ? parseInt(value) : 0}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="profile tabs"
        >
          <Tab label="Personal information" {...a11yProps(0)} />
          <Tab label="Social media" {...a11yProps(1)} />
          <Tab label="List of Doctors" {...a11yProps(2)} />
          <Tab label="Insurance" {...a11yProps(3)} />
          <Tab label="Specialties" {...a11yProps(4)} />
          <Tab label="Photos" {...a11yProps(5)} />
          <Tab label="Videos" {...a11yProps(6)} />
          <Tab label="Clinic" {...a11yProps(7)} />
        </Tabs>
      </Box>
      <Box sx={{ px: "1.5rem" }}>
        <TabPanel value={value ? parseInt(value) : 0} index={0}>
          <PersonalInformation doctorData={data?.[urlRole]} />
        </TabPanel>
        <TabPanel value={value ? parseInt(value) : 0} index={1}>
          <SocialMedia doctorData={data?.[urlRole]} />
        </TabPanel>
        <TabPanel value={value ? parseInt(value) : 0} index={2}>
          <Invitations />
        </TabPanel>
        <TabPanel value={value ? parseInt(value) : 0} index={3}>
          <Insurance doctorData={data?.[urlRole]} />
        </TabPanel>
        <TabPanel value={value ? parseInt(value) : 0} index={4}>
          <Specialities doctorData={data?.[urlRole]} />
        </TabPanel>
        <TabPanel value={value ? parseInt(value) : 0} index={5}>
          <Photos doctorData={data?.[urlRole]} />
        </TabPanel>
        <TabPanel value={value ? parseInt(value) : 0} index={6}>
          <Videos doctorData={data?.[urlRole]} />
        </TabPanel>
        <TabPanel value={value ? parseInt(value) : 0} index={7}>
          <Clinic doctorData={data?.[urlRole]} />
        </TabPanel>
      </Box>
    </Box>
  );

  return (
    <Layout title="Profile Settings">
      <Grid container item direction="row" sx={{ minHeight: "85vh" }}>
        <Card xs={12} sx={{ py: "1.5rem", px: 0, pt: 0, pb: "1.5rem" }}>
          {urlRole == "doctor" ? renderDoctorTabs() : renderOrganizationTabs()}
        </Card>
      </Grid>
    </Layout>
  );
};

export default ProfileSettings;
