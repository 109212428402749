export const handleTranslationToEn = async ({
  originalText,
  setTranslatedAnswer,
}: any) => {
  try {
    let url = `https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_CLOUD_API_KEY}`;
    url += "&q=" + encodeURI(originalText);
    // url += `&source=${fromLang}`;
    url += `&target=en`;

    const response: any = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error: ${response.status}`);
    }
    const data = await response.json();
    if (
      data?.data?.translations?.length &&
      data?.data?.translations[0].detectedSourceLanguage !== "en"
    )
      setTranslatedAnswer(data?.data?.translations[0].translatedText);
  } catch (e) {
    console.log(e);
  }
  return null;
};
