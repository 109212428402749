import { RadialBarChart, PolarAngleAxis, RadialBar } from "recharts";

interface ICrcularPieChart {
  percentage: number;
  primaryColor: string;
}

const circleSize = 88;

const CrcularPieChart = ({percentage, primaryColor}: ICrcularPieChart) => {
  return (
    <RadialBarChart
      width={circleSize}
      height={circleSize}
      cx={circleSize / 2}
      cy={circleSize / 2}
      innerRadius={40}
      outerRadius={40}
      barSize={8}
      data={[{value: percentage}]}
      startAngle={90}
      endAngle={-270}
    >
      <PolarAngleAxis
        type="number"
        domain={[0, 100]}
        angleAxisId={0}
        tick={false}
      />
      <RadialBar
        background={{fill: '#8f92a11a'}}
        dataKey="value"
        cornerRadius={circleSize / 2}
        fill={primaryColor}
      />
    </RadialBarChart>
  );
}

export default CrcularPieChart