import { InputLabel, Link, styled } from "@mui/material";
import MuiTextField, { TextFieldProps } from "@mui/material/TextField";

const TextField = styled((props: TextFieldProps) => (
  <MuiTextField {...props} />
))(() => ({
  input: {
    color: "#172B4D",
    fontSize: "1rem",
    "&::placeholder": {
      color: "#B3BAC5",
      fontSize: "1rem",
      opacity: 1,
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "0.5rem",
    "&.Mui-focused fieldset": {
      border: "1px solid #172B4D",
    },
    "& fieldset": { border: "1px solid #DFE1E6" },
  },
}));

const InputWithLabel = ({
  label,
  id,
  name,
  placeholder,
  type = "text",
  value,
  onChange,
  error,
  helperText,
  multiline = false,
  autoFocus = false,
  rows = 1,
  maxRows = 1,
  startIcon,
  endIcon,
  endIconOnClick,
  required = false,
  disabled = false,
  fontSize = 15,
  maxLength = "1000",
  sx,
  mt,
}: any) => {
  return (
    <>
      <InputLabel
        sx={{
          mb: -1,
          fontWeight: 600,
          fontSize: "14px",
          color: "#172B4D",
          mt,
        }}
      >
        {label}
      </InputLabel>
      <TextField
        margin="normal"
        required={required}
        fullWidth
        id={id}
        name={name}
        placeholder={placeholder}
        type={type}
        autoFocus={autoFocus}
        value={value}
        onChange={onChange}
        error={error}
        helperText={helperText}
        multiline={multiline}
        rows={rows}
        maxRows={maxRows}
        disabled={disabled}
        sx={{
          mb: 2,
          ...sx,
        }}
        inputProps={{ maxLength }}
        onInput={
          type == "number"
            ? (e: any) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, maxLength);
              }
            : () => {}
        }
        InputProps={{
          style: { fontSize },
          startAdornment: startIcon ? (
            <span style={{ marginRight: "10px" }}>{startIcon}</span>
          ) : null,
          endAdornment: endIcon ? (
            <Link
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                ml: 1,
                mr: 1,
                width: "20px",
                height: "20px",
              }}
              onClick={endIconOnClick}
            >
              {endIcon}
            </Link>
          ) : null,
        }}
      />
    </>
  );
};

export default InputWithLabel;
