import { Grid, Box, Typography } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import PrimaryButton from "generic/Buttons/PrimaryButton";
import InputWithLabel from "generic/Inputs/InputWithLabel";
import { useReportProblemMutation } from "services/accountSettings";
import { useEffect, useState } from "react";
import Snackbar from "generic/Snackbar/Snackbar";

const validationSchema = yup.object({
  problem: yup.string().required("Please describe your problem"),
});

const ReportProblem = () => {
  const [msg, setMsg] = useState<string | null>(null);
  const [reportProblem, { isLoading }] = useReportProblemMutation();

  const formik = useFormik({
    initialValues: {
      problem: "",
    },
    validationSchema: validationSchema,
    onSubmit: (form_data, { resetForm }) => {
      reportProblem(form_data.problem)
        .then(() => {
          setMsg("Successfully reported your problem");
          resetForm();
        })
        .catch(() =>
          setMsg(
            "An error occurred while reporting your problem, please try again"
          )
        );
    },
  });

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Box
        sx={{
          color: "#172B4D",
          display: "flex",
          flexDirection: "column",
          gap: "0.75rem",
        }}
      >
        <Typography sx={{ fontSize: "1.125rem", fontWeight: 600 }}>
          Report a problem
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ fontSize: "1rem", fontWeight: 400 }}
        >
          Let us know what problem you have.
        </Typography>
      </Box>
      <form style={{ marginTop: "2rem" }} onSubmit={formik.handleSubmit}>
        <InputWithLabel
          id="problem"
          name="problem"
          label="Reason"
          placeholder="Add text here"
          multiline={true}
          rows={4}
          sx={{ mb: 0 }}
          value={formik.values.problem}
          onChange={formik.handleChange}
          error={formik.touched.problem && Boolean(formik.errors.problem)}
          helperText={formik.touched.problem && formik.errors.problem}
        />
        <PrimaryButton
          type="submit"
          title="Report a problem"
          disabled={isLoading}
          sx={{
            boxShadow: "none",
            margin: "3rem 0 0 0",
            borderRadius: "0.5rem",
            fontSize: "1rem",
          }}
        />
        <Snackbar
          open={msg !== null}
          onClose={() => setMsg(null)}
          message={msg}
        />
      </form>
    </Grid>
  );
};

export default ReportProblem;
