import { useState } from "react";
import { Typography } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import PrimaryButton from "generic/Buttons/PrimaryButton";
import InputWithLabel from "generic/Inputs/InputWithLabel";
import {PasswordIcon, EyeIcon} from "app/assets/icons";

const validationSchema = yup.object({
  password: yup
    .string()
    .required("Password is required.")
    .matches(
      /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/,
      "Password must be at least 8 charcters and have: 1 uppercase, 1 lowercase, 1 number and 1 special character."
    ),
  confirm_password: yup
    .string()
    .required("Confirm Password is required.")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const CreatePassword = ({ setCurrentStep, setPassword }: any) => {
  const [type, setType] = useState("password");
  const [type2, setType2] = useState("password");

  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      setPassword(values.password);
      setCurrentStep((prevStep: number) => ++prevStep);
    },
  });

  return (
    <form
      style={{ margin: "auto 0", maxWidth: "60%" }}
      onSubmit={formik.handleSubmit}
    >
      <Typography
        sx={{ fontWeight: 600, fontSize: "32px", color: "#172B4D", mb: 3 }}
      >
        Create a Password
      </Typography>
      <InputWithLabel
        label="Password*"
        id="password"
        name="password"
        type={type}
        placeholder="Enter Password"
        autoFocus={true}
        startIcon={<PasswordIcon />}
        endIcon={<EyeIcon />}
        endIconOnClick={() =>
          setType((prevType: string) =>
            prevType === "password" ? "text" : "password"
          )
        }
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
      />
      <InputWithLabel
        label="Confirm Password*"
        id="password"
        name="confirm_password"
        type={type2}
        placeholder="Re Enter Password"
        startIcon={<PasswordIcon />}
        endIcon={<EyeIcon />}
        endIconOnClick={() =>
          setType2((prevType: string) =>
            prevType === "password" ? "text" : "password"
          )
        }
        value={formik.values.confirm_password}
        onChange={formik.handleChange}
        error={
          formik.touched.confirm_password &&
          Boolean(formik.errors.confirm_password)
        }
        helperText={
          formik.touched.confirm_password && formik.errors.confirm_password
        }
      />
      <PrimaryButton title="Next" type="submit" />
    </form>
  );
};

export default CreatePassword;
