import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import Layout from "modules/layout/Layout";
import Card from "generic/Cards/Card";
import AppointmentsCard from "./components/AppointmentsCard";
import LatestReviews from "./components/LatestReviews";
import Stats from "./components/Stats/Stats";

const Home = () => {
  const { t: translate } = useTranslation();

  return (
    <Layout title={translate("Dashboard", "Dashboard")}>
      <Stats />

      <Grid container item direction="row">
        <Card xs={12} sm={12} md={6}>
          <AppointmentsCard
            title={"Upcoming Appointments"}
            cardStyle={"status"}
            status={"upcoming"}
            perPage={4}
            sort={"last_action_date"}
          />
        </Card>

        <LatestReviews />
      </Grid>
    </Layout>
  );
};

export default Home;
