import { sanitize } from "dompurify";
import { Box, Grid, Typography } from "@mui/material";
import Text from "generic/Text/Text";
import { useGetTermsQuery } from "services/accountSettings";
import { useRef } from "react";
import Linkify from "react-linkify";

const PrivacyPolicy = () => {
  const {
    data: response,
    error,
    isLoading,
    isSuccess,
  } = useGetTermsQuery({ lang: "en", termType: 1 });

  return (
    <Grid container sx={{ height: "100%" }}>
      <Grid
        item
        xs={12}
        sm={12}
        md={7}
        sx={{ height: "92%", display: "flex", flexDirection: "column" }}
      >
        <Box>
          <Text
            sx={{ fontSize: "1.125rem", fontWeight: 600, color: "#172B4D" }}
            label="Privacy Policy"
          />
        </Box>
        <Box sx={{ mt: "1.5rem" }}>
          {isSuccess && (
            <Linkify
              componentDecorator={(
                decoratedHref: any,
                decoratedText: any,
                key: any
              ) => (
                <a
                  target="_blank"
                  href={decoratedHref}
                  key={key}
                  aria-label="this link opens in a new tab"
                >
                  {decoratedText}
                </a>
              )}
            >
              <p
                style={{
                  fontSize: "1rem",
                  fontWeight: 400,
                  color: "#172B4D",
                  whiteSpace: "pre-line",
                }}
              >
                {response.data.terms}
              </p>
            </Linkify>

            // <Typography
            //   variant="body2"
            //   sx={{ fontSize: "1rem", fontWeight: 400, color: "#172B4D" }}
            //   dangerouslySetInnerHTML={{
            //     __html: sanitize(response.data.terms),
            //   }}
            // />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default PrivacyPolicy;
