import { useState, useEffect } from "react";
import { Box, Grid, CircularProgress, Divider } from "@mui/material";
import { useUpdateProfileMutation } from "services/profileSettings";
import { useCreatePresignedURLMutation } from "services/user";
import { useUploadAWSMutation } from "services/noHeaders";
import { uploadFileAndUpdate } from "utils/fileUpload";
import { useAppSelector, useAppDispatch } from "app/store/hooks";
import { isLoading, setLoading } from "app/store/genericSlice";
import SecondaryButton from "generic/Buttons/SecondaryButton";
import Modal from "generic/Modals/Modal";
import Snackbar from "generic/Snackbar/Snackbar";
// import { getVideoDurationInSeconds } from "get-video-duration";
import { profileDataInterface } from "utils/doctorOrganizationInterface";

const Videos = ({ doctorData }: any) => {
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [videoPreview, setVideoPreview] = useState<any[]>([]);
  const [videoFile, setVideoFile] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [videoIndex, setVideoIndex] = useState(0);

  const [createPresignedURL, urlResponse] = useCreatePresignedURLMutation();
  const [uploadAWS, uploadResponse] = useUploadAWSMutation();
  const [updateProfile, updateProfileResult] = useUpdateProfileMutation();

  const [videoLengths, setVideoLengths] = useState<any>([]);

  const loading = useAppSelector(isLoading);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (doctorData) {
      setVideoPreview(doctorData?.[profileDataInterface?.videos]);
    }
  }, [doctorData]);

  useEffect(() => {
    setTimeout(() => {
      if (
        videoPreview?.length &&
        videoPreview.filter((vid) => !vid.duration).length > 0
      ) {
        const videosWithTime = videoPreview.map((singleVid, i) => {
          let videoId = `myvid${i}`;
          var myvid: any = document.getElementById(videoId);
          let durationInSeconds = parseInt(myvid?.duration);
          var minutes = Math.floor(durationInSeconds / 60);
          var seconds = durationInSeconds - minutes * 60;
          var finalTime =
            str_pad_left(minutes, "0", 2) + ":" + str_pad_left(seconds, "0", 2);
          return { ...singleVid, duration: finalTime };
        });
        setVideoPreview(videosWithTime);
      }
    }, 1500);
  }, [videoPreview]);

  const handleVideoPreview = (e: any) => {
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      let file = files.item(i);
      let image_as_base64 = URL.createObjectURL(file);
      let image_as_files = file;

      if (file?.size > 52428800) {
        setIsError(true);
        setErrorMsg("File upload size limit is @ 50 MB");
        e.target.value = null;
      } else {
        dispatch(setLoading(true));
        uploadFileAndUpdate({
          imageFile: image_as_files,
          createPresignedURL,
          uploadAWS,
          UpdateHook: async (signed_id: any, url: any) => {
            const blobIds = videoPreview.map((vid) => vid.signed_id);
            await updateProfile({
              [profileDataInterface?.videos]: [...blobIds, signed_id],
            });
            setVideoPreview((prevVideos) => {
              return [...prevVideos, { signed_id, url }];
            });
            dispatch(setLoading(false));
            setIsError(true);
            setErrorMsg("successfully updated your profile");
          },
        });
      }
    }
  };

  const getVideoDuration = (videoId: string) => {
    var myvid: any = document.getElementById(videoId);
    let durationInSeconds = parseInt(myvid?.duration);
    var minutes = Math.floor(durationInSeconds / 60);
    var seconds = durationInSeconds - minutes * 60;
    var finalTime =
      str_pad_left(minutes, "0", 2) + ":" + str_pad_left(seconds, "0", 2);
    return finalTime;
  };

  function str_pad_left(string: any, pad: any, length: any) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  }

  return (
    <Box sx={{ my: "auto", mx: 0, maxWidth: "100%" }}>
      <Grid container>
        {videoPreview?.length
          ? videoPreview.map((singleVid, i) => {
              let videoId = `myvid${i}`;
              return (
                <>
                  <Grid item xs={12} sm={12} md={5}>
                    <div style={{ display: "flex", marginBottom: "20px" }}>
                      <video
                        id={videoId}
                        width="280"
                        // height="180"
                        controls
                        style={{ borderRadius: "0.75rem" }}
                      >
                        <source src={singleVid.url} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "20px",
                          marginTop: "5px",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "1.125rem",
                            fontWeight: 600,
                            color: "#172B4D",
                          }}
                        >
                          {singleVid.name}
                        </p>
                        {/* {singleVid.duration != "aN:aN" ? (
                          <p
                            style={{
                              fontSize: "12px",
                              fontWeight: 400,
                              color: "#7F8CA9",
                            }}
                          >
                            Duration: {singleVid.duration}
                          </p>
                        ) : null} */}
                        <span style={{ margin: "auto 0" }}></span>
                        <SecondaryButton
                          title="Delete Video"
                          type="button"
                          width="140px"
                          height="40px"
                          loading={false}
                          onClick={() => {
                            setVideoIndex(i);
                            setIsDeleteModalOpen(true);
                          }}
                          sx={{
                            mb: 0,
                            borderRadius: "0.5rem",
                            fontSize: "0.938rem",
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                  <span style={{ margin: "0 20px" }}></span>
                </>
              );
            })
          : null}
      </Grid>

      <br />
      <label
        style={{
          border: "1px solid #ccc",
          display: "inline-block",
          padding: loading ? "5px 40px" : "10px 40px",
          cursor: "pointer",
          background: "#136588",
          color: "#FFFFFF",
          fontWeight: 600,
          fontSize: "1rem",
          borderRadius: "10px",
          marginTop: "20px",
        }}
      >
        <input
          type="file"
          hidden
          disabled={loading}
          accept="video/*"
          onChange={handleVideoPreview}
        />
        {loading ? <CircularProgress /> : "Add videos"}
      </label>
      <Snackbar
        open={isError}
        onClose={() => setIsError(false)}
        message={errorMsg}
      />
      <Modal
        open={isDeleteModalOpen}
        setOpen={setIsDeleteModalOpen}
        primaryTitle="Delete"
        secondaryTitle="Cancel"
        primaryOnclick={async () => {
          dispatch(setLoading(true));
          const videosFiltered = doctorData?.[profileDataInterface?.videos].filter(
            (value: any, index: any, arr: any) => {
              return index != videoIndex;
            }
          );
          await updateProfile({
              [profileDataInterface?.videos]: videosFiltered.map((vid: any) => vid.signed_id),
            },
          );
          setIsDeleteModalOpen(false);
          dispatch(setLoading(false));
          setIsError(true);
          setErrorMsg("successfully updated your profile");
        }}
      >
        Are you sure you want to delete this video?
      </Modal>
    </Box>
  );
};

export default Videos;
