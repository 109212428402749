import { useState, useEffect } from "react";
import { Container, Grid, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Folder from "app/assets/images/folder.png";
import SignupMainImage from "app/assets/images/signup-right.png";
import PrimaryButton from "generic/Buttons/PrimaryButton";
import SecondaryButton from "generic/Buttons/SecondaryButton";
import { cognitoLogin, loginSuccess } from "./authSlice";
import { useAppSelector, useAppDispatch } from "app/store/hooks";
import { isLoading, setLoading } from "app/store/genericSlice";
import { useCreateCustomTokenFirebaseMutation } from "services/user";
import { useLoginMutation } from "services/authApi";
import { Auth } from "aws-amplify";
import { storeUserData } from "./authSlice";
import { useUpdatePersonalInfoMutation } from "services/profileSettings";
import Snackbar from "generic/Snackbar/Snackbar";
import { auth } from "../../firebase";
import { signInWithCustomToken } from "firebase/auth";
import { useHandleInviteMutation } from "services/invitations";
import { useSearchParams } from "react-router-dom";
import { useGetInvitationDataQuery } from "services/noHeaders";
import { dateFormat, timeFormat } from "utils/dateFormat";
import { signOut } from "firebase/auth";
import { useLazyCheckEmailQuery } from "services/noHeaders";

const LargeImage = styled("img")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const CongratsScreen = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");

  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  let navigate = useNavigate();
  const location: any = useLocation();
  const loading = useAppSelector(isLoading);
  const dispatch = useAppDispatch();

  const [checkEmail] = useLazyCheckEmailQuery();

  const { data: userInvitationData, isSuccess: isFetchedInvitationData } =
    useGetInvitationDataQuery(token);
  const [handleInvite] = useHandleInviteMutation();

  const logout = async () => {
    localStorage.removeItem("idToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    await Auth.signOut();
    await signOut(auth);
  };

  const checkEmailAndRoute = async () => {
    const result = await checkEmail(userInvitationData?.data?.email);
    localStorage.setItem("invitationToken", token!);
    if (!result?.isError) {
      navigate("/signup", {
        state: {
          email: userInvitationData?.data?.email,
          step: 1,
          role: "doctor",
        },
      });
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    if (isFetchedInvitationData)
      Auth.currentAuthenticatedUser()
        .then(async (user) => {
          if (user?.attributes?.email !== userInvitationData?.data?.email) {
            await logout();
            await checkEmailAndRoute();
          }
        })
        .catch(async (err) => await checkEmailAndRoute());
  }, [isFetchedInvitationData]);

  const onSubmit = async (action: string) => {
    const result: any = await handleInvite({ token, action });
    if (result?.error) {
      setIsError(true);
      setErrorMsg(result?.error?.data?.message);
    } else {
      if (action == "decline") {
        setIsError(true);
        setErrorMsg("Successfully declined the invitation");
      } else {
        setIsError(true);
        setErrorMsg(
          `You’re successfully added to ${userInvitationData?.data?.organization?.name}`
        );
      }
      localStorage.removeItem("invitationToken");
      setTimeout(() => {
        window.location.href = "/";
      }, 1000);
    }
  };

  return (
    <Grid container style={{ height: "100vh" }}>
      <Grid item xs={12} sm={12} md={8} style={{ height: "80%" }}>
        <Container
          sx={{ m: 8 }}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Typography
            sx={{ fontWeight: 600, fontSize: "32px", color: "#172B4D" }}
          >
            Organization Invitation
          </Typography>
          <Typography
            sx={{ mb: 4, fontWeight: 400, fontSize: "14px", color: "#172B4D" }}
          >
            You’ve been invited to join organization:{" "}
            {userInvitationData?.data?.organization?.name}.
          </Typography>
          <Typography
            sx={{ mb: 4, fontWeight: 400, fontSize: "14px", color: "#172B4D" }}
          >
            <strong>Email:</strong> {userInvitationData?.data?.email}
          </Typography>
          <Typography
            sx={{ mb: 4, fontWeight: 400, fontSize: "14px", color: "#172B4D" }}
          >
            <strong>Invitation Expires at:</strong>{" "}
            {dateFormat(userInvitationData?.data?.expires_at, "dd/MM/yyyy")}
          </Typography>
          <div style={{ display: "flex" }}>
            <PrimaryButton
              title="Accept"
              type="button"
              onClick={() => onSubmit("accept")}
            />
            <span style={{ margin: "0 15px" }}></span>
            <SecondaryButton
              title="Decline"
              type="button"
              onClick={() => onSubmit("decline")}
            />
          </div>
          <Snackbar
            open={isError}
            onClose={() => setIsError(false)}
            message={errorMsg}
          />
        </Container>
      </Grid>
      <Grid
        container
        item
        xs={4}
        justifyContent="flex-end"
        style={{ height: "100%" }}
      >
        <LargeImage
          src={SignupMainImage}
          alt="signup image"
          style={{ height: "100%" }}
        />
      </Grid>
    </Grid>
  );
};

export default CongratsScreen;
