import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Layout from "modules/layout/Layout";
import Card from "generic/Cards/Card";
import { GoBackArrowIcon } from "app/assets/icons";
import ChooseDateCard from "./components/ChooseDateCard";
import PatientInfoCard from "./components/PatientInfoCard";
import { useGetConsultationQuery } from "services/appointments";

const Request = () => {
  const [consultation, setConsultation] = useState<any>();
  const navigate = useNavigate();
  const location: any = useLocation();

  let { type, id }: any = useParams();
  const [requestId, setRequestId] = useState(0);
  const [requestType, setRequestType] = useState();

  const {
    data: appointment,
    error: appointmentError,
    isLoading: appointmentLoading,
    isSuccess: appointmentSuccess,
    refetch,
  } = useGetConsultationQuery({
    id: requestId,
    type: requestType,
  });

  useEffect(() => {
    refetch();
  }, [location]);

  useEffect(() => {
    if (id) setRequestId(parseInt(id));
    if (type) setRequestType(type);
  }, [id, type]);

  useEffect(() => {
    if (appointment?.written_consultation)
      setConsultation(appointment?.written_consultation);
    if (appointment?.video_consultation)
      setConsultation(appointment?.video_consultation);
    if (appointment?.data?.emergency_consultation)
      setConsultation(appointment?.data?.emergency_consultation);
  }, [appointment, appointmentSuccess]);

  return (
    <Layout
      title={
        consultation?.patient?.first_name
          ? `${consultation?.patient?.first_name}’s Appointment`
          : ""
      }
      icon={<GoBackArrowIcon />}
      handleIconClick={() => navigate(-1)}
    >
      {consultation && (
        <Grid container item direction="row" sx={{ minHeight: "85vh" }}>
          <Grid item xs={6}>
            <Card sx={{ minHeight: "100%", paddingBottom: 0 }}>
              <ChooseDateCard
                consultation={consultation}
                type={requestType}
                setRequestId={setRequestId}
              />
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card sx={{ minHeight: "100%", paddingBottom: 0 }}>
              <PatientInfoCard consultation={consultation} />
            </Card>
          </Grid>
        </Grid>
      )}
    </Layout>
  );
};

export default Request;
