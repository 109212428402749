import { Box } from "@mui/material";
import { Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { IRevenue } from "services/insights";

interface IMonthlyRevenueLineChart {
  revenues: IRevenue[];
}

const MonthlyRevenueLineChart = ({ revenues }: IMonthlyRevenueLineChart) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            color: "#7F8CA9",
            fontWeight: 400,
            transform: "rotate(180deg)",
            writingMode: "vertical-rl",
            textAlign: "center",
            pl: "2px",
          }}
        >
          revenue
        </Box>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={revenues} margin={{ top: 8, right: 1 }}>
            <XAxis
              dataKey="group"
              stroke="#7F8CA9"
              height={20}
              tickFormatter={(label) => `${+label.split("-")[0]}`}
            />
            <YAxis
              interval={0}
              stroke="#7F8CA9"
              width={
                Math.max(
                  ...revenues.map(
                    (r) => Math.trunc(r.revenues).toString().length
                  )
                ) * 15
              }
            />
            <Line
              type="monotone"
              dataKey="revenues"
              stroke="#09C0D1"
              strokeWidth={4}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
      <Box sx={{ color: "#7F8CA9", textAlign: "center", fontWeight: 400 }}>
        month
      </Box>
    </Box>
  );
};

export default MonthlyRevenueLineChart;
