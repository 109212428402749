import { Autocomplete, styled } from "@mui/material"
import MuiTextField, { TextFieldProps } from '@mui/material/TextField';
import { useCallback, useEffect, useRef, useState } from "react";

const TextField = styled((props: TextFieldProps) => (
  <MuiTextField {...props} />
))(() => ({
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      border: '1px solid #172B4D'
    },
  },
}));

interface IDebounceSearch {
  options: any[];
  getOptionLabel?: (option: any) => string;
  onSearch: (query: string) => void;
  onSelect: (option: any) => void;
  isLoading: boolean,
  formik?: any,
}

const DebounceSearch = ({
  options, 
  getOptionLabel, 
  onSearch, 
  onSelect, 
  isLoading, 
  formik
}: IDebounceSearch) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const lastEmitted = useRef('');

  useEffect(() => {
    const timer = setTimeout(() => {
      if (lastEmitted.current !== searchQuery) {
        lastEmitted.current = searchQuery;
        onSearch(searchQuery)
      }
    }, 400);

    return () => clearTimeout(timer);
  }, [searchQuery]);

  const onInputChange = useCallback(
    (_, value: string) => setSearchQuery(value),
  []);

  return (
    <Autocomplete
      options={options || []}
      onChange={(_, value: any | null) => onSelect(value)}
      onInputChange={onInputChange}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => 
        <TextField 
          {...params} 
          placeholder='Search patients'
          error={
            formik.touched.patientId && Boolean(formik.errors.patientId)
          }
          helperText={
            formik.touched.patientId && formik.errors.patientId
          }
        />
      }
      loading={isLoading}
      filterOptions={(options: any[]) => options}
      blurOnSelect={true}
      autoHighlight={true}
      popupIcon={null}
    />
  )
}

export default DebounceSearch