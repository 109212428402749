import api from "./index";

export interface IOrganizationDoctor {
  id: number;
  first_name: string;
  last_name: string;
  avatar_url: string;
}

export const organizationDoctors = api.injectEndpoints({
  endpoints: (builder) => ({
    listOrganizationDoctors: builder.query<any, any>({
      query: (keyword) =>
        keyword
          ? `/api/v1/organization/doctors?sort=first_name&filters[keyword]=${keyword}`
          : "/api/v1/organization/doctors?sort=first_name",
    }),

    contactDoctor: builder.mutation({
      query: ({ consultation_id, doctor_id }) => ({
        url: `/api/v1/organization/consultations/${consultation_id}/contact_doctor`,
        method: "POST",
        body: {
          doctor_id,
        },
      }),
      invalidatesTags: [{ type: "Appointment" }],
    }),
  }),
});

export const {
  useListOrganizationDoctorsQuery,
  useLazyListOrganizationDoctorsQuery,
  useContactDoctorMutation,
} = organizationDoctors;
