import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const RedirectPage = () => {
  const location: any = useLocation();
  useEffect(() => {
    window.location.replace(
      location?.state?.url ? location?.state?.url : process.env.REACT_APP_FE_URL
    );
  }, []);

  // Render some text when redirecting
  // You can use a loading gif or something like that
  return (
    <div>
      <h3>Redirecting...</h3>
    </div>
  );
};

export default RedirectPage;
