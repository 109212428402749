import { Box, Typography } from "@mui/material";
import Text from "generic/Text/Text";
import MonthlyViewsBarChart from "./MonthlyViewsBarChart";
import ChangePercentage from "./ChangePercentage";
import NoContentCard from "generic/NoContentCard/NoContentCard";
import { IView } from "services/insights";
import { fillZeroMonths } from "utils/fillZeroMonths";

interface IViews {
  this_month_increase_percent: number;
  visits_count: number;
  monthly_visits: IView[];
}

const Views = ({
  visits_count,
  this_month_increase_percent,
  monthly_visits,
}: IViews) => {
  if (monthly_visits.length === 0) {
    return <NoContentCard message="No views history" />;
  }

  const last5MonthsViews = fillZeroMonths(
    monthly_visits.slice(-5),
    "count"
  ).slice(-5);

  return (
    <>
      <Text
        sx={{ fontSize: "1.125rem", fontWeight: 600, color: "#172B4D" }}
        label={"Profile visited"}
      />
      <Box sx={{ display: "flex" }}>
        <Box>
          <Box sx={{ mt: "2rem" }}>
            <Typography
              sx={{
                fontSize: "2rem",
                fontWeight: 600,
                lineHeight: "2.438rem",
                color: "#172B4D",
              }}
            >
              {visits_count}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ fontSize: "0.875rem", fontWeight: 400, color: "#7F8CA9" }}
            >
              Views
            </Typography>
          </Box>
          <Box
            sx={{
              mt: "2rem",
            }}
          >
            {last5MonthsViews && last5MonthsViews.length > 1 && (
              <ChangePercentage
                changePercentage={this_month_increase_percent}
              />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
          }}
        >
          {last5MonthsViews && last5MonthsViews.length > 1 && (
            <MonthlyViewsBarChart monthlyVisits={last5MonthsViews} />
          )}
        </Box>
      </Box>
    </>
  );
};

export default Views;
