import api from "./index";

export const knowledge = api.injectEndpoints({
  endpoints: (builder) => ({
    getKnowledgeVideos: builder.query({
      query: (page) =>
        `/api/v1/knowledge?filters[type]=video&page=${page}&per_page=10`,
    }),
  }),
});

export const { useGetKnowledgeVideosQuery } = knowledge;
