import { Auth } from "aws-amplify";

export const sendConfirmationCode = async (email: string) => {
  const result = await Auth.forgotPassword(email);
  return result;
};

export const changePassword = async (
  email: string,
  code: string,
  newPassword: string
) => {
  const result = await Auth.forgotPasswordSubmit(email, code, newPassword);
  return result;
};
