import { Box, CircularProgress, Grid } from "@mui/material";
import bookIcon from "app/assets/icons/book.svg";
import xIcon from "app/assets/icons/x-shadow.svg";
import Modal from "generic/Modals/Modal";
import Snackbar from "generic/Snackbar/Snackbar";
import { useEffect, useState } from "react";
import { useUploadAWSMutation } from "services/noHeaders";
import { useUpdateProfileMutation } from "services/profileSettings";
import { useCreatePresignedURLMutation } from "services/user";
import { uploadFileAndUpdate } from "utils/fileUpload";

const Documents = ({ doctorData }: any) => {
  const [msg, setMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [docIndexToDelete, setDocIndexToDelete] = useState(0);

  const [docs, setDocs] = useState<any[]>([]);

  const [createPresignedURL] = useCreatePresignedURLMutation();
  const [uploadAWS] = useUploadAWSMutation();
  const [updateProfile, { data: doctorProfile }] = useUpdateProfileMutation();

  const handleUploadDocs = async (e: any) => {
    const files = e.target.files;
    const blobIds = docs.map((doc) => doc.signed_id);
    const newFiles: any = [];
    setIsLoading(true);

    for (const file of files) {
      if (file.size > 52428800) {
        setMsg("File upload size limit is 50 MB");
      } else {
        await uploadFileAndUpdate({
          imageFile: file,
          createPresignedURL,
          uploadAWS,
          UpdateHook: (signed_id: any, url: any) => {
            blobIds.push(signed_id);
            newFiles.push({ signed_id, url });
            if (files.length === newFiles.length)
              updateProfileImages(blobIds, newFiles);
          },
        });
      }
    }
  };

  const updateProfileImages = async (blobIds: any, newDocs: any) => {
    await updateProfile({
      doctor_documents: blobIds,
    });
    setIsLoading(false);
    setMsg("successfully uploaded documents");
  };

  useEffect(() => {
    if (doctorData) setDocs(doctorData?.doctor_documents);
    if (doctorProfile) setDocs(doctorProfile.doctor_documents);
  }, [doctorData, doctorProfile]);

  return (
    <Box>
      <Grid container>
        {docs?.map((doc: any, index: number) => {
          return (
            <div
              style={{
                background: "#F4F5F8",
                padding: "10px 15px",
                borderRadius: "10px",
                marginBottom: "10px",
                marginRight: "10px",
                width: "40%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={bookIcon}
                alt="Book icon"
                style={{ marginRight: "10px" }}
              />
              <a
                href={doc.url}
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "#252D59",
                }}
                rel="noreferrer"
              >
                <p style={{ color: "#252D59", fontSize: "14px" }}>
                  {doc.name.length > 13
                    ? `${doc.name.slice(0, 14)}...`
                    : doc.name}
                </p>
                <p style={{ color: "#8690A3", fontSize: "14px" }}>
                  {(doc.byte_size / 1024).toFixed(1)} KB
                </p>
              </a>
              <span style={{ margin: "0 auto" }}></span>
              <img
                src={xIcon}
                style={{
                  cursor: "pointer",
                  width: "20px",
                  height: "20px",
                }}
                alt="x icon"
                onClick={() => {
                  setDocIndexToDelete(index);
                  setIsDeleteModalOpen(true);
                }}
              />
            </div>
          );
        })}
      </Grid>

      <label
        style={{
          border: "1px solid #ccc",
          display: "inline-block",
          padding: isLoading ? "5px 40px" : "10px 40px",
          cursor: "pointer",
          background: "#136588",
          color: "#FFFFFF",
          fontWeight: 600,
          fontSize: "1rem",
          borderRadius: "10px",
          marginTop: "20px",
        }}
      >
        <input
          type="file"
          hidden
          multiple
          disabled={isLoading}
          onChange={handleUploadDocs}
        />
        {isLoading ? <CircularProgress /> : "Add Documents"}
      </label>

      <Snackbar open={msg !== ""} onClose={() => setMsg("")} message={msg} />

      <Modal
        open={isDeleteModalOpen}
        setOpen={setIsDeleteModalOpen}
        primaryTitle="Delete"
        secondaryTitle="Cancel"
        primaryOnclick={async () => {
          setIsLoading(true);
          const docsFiltered = docs.filter((_, index: number) => {
            return index !== docIndexToDelete;
          });

          await updateProfile({
            doctor_documents: docsFiltered.map((pic: any) => pic.signed_id),
          });

          setIsDeleteModalOpen(false);
          setIsLoading(false);
          setMsg("successfully deleted the document");
        }}
      >
        Are you sure you want to delete this document?
      </Modal>
    </Box>
  );
};

export default Documents;
