import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import api from "services";
import { noHeadersApi } from "services/noHeaders";
import { noRoleapi } from "services/noRole";
// import { profileSettings } from "services/profileSettings";
import genericReducer from "./genericSlice";
import counterReducer from "../../modules/counter/counterSlice";
import authReducer from "../../modules/auth/authSlice";
import layoutReducer from "../../modules/layout/layoutSlice";

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [noHeadersApi.reducerPath]: noHeadersApi.reducer,
    [noRoleapi.reducerPath]: noRoleapi.reducer,
    generic: genericReducer,
    counter: counterReducer,
    auth: authReducer,
    layout: layoutReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
