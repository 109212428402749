import { useState, useEffect } from "react";
import styles from "./chatMessage.module.css";
import Avatar from "app/assets/images/avatar.png";
import { ReactComponent as DownloadDocIcon } from "app/assets/icons/downloadDoc.svg";
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../../../../firebase";
import { handleTranslation } from "utils/helpers";
interface IChatMessage {
  doctorId: Number;
  msg: any;
  PatientInfo: any;
  translation: { active: boolean; language: string };
}

const ChatMessage = ({
  doctorId,
  msg,
  PatientInfo,
  translation,
}: IChatMessage) => {
  const [messageText, setMessageText] = useState(msg?.TextMessage?.Text);

  useEffect(() => {
    if (translation?.active && msg.FromUserId !== doctorId) {
      handleTranslation(
        {
          originalText: messageText,
          toLang: translation?.language,
        },
        (translatedText: string) => {
          if (translatedText) {
            setMessageText(translatedText);
          }
        }
      );
    }
  }, [translation]);

  function formatDate(ut: { seconds: number; nanoseconds: number }) {
    if (!ut) return " ";

    const date = new Date(ut.seconds * 1000 + ut.nanoseconds / 1000000);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const day = date.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return `${day} ${hours}:${minutes}`;
  }
  const messageClass = msg.FromUserId === doctorId ? "sent" : "received";
  const downloadDocument = (url: any, fileName: any) => {
    getDownloadURL(ref(storage, url))
      .then((url) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = () => {
          const blob = xhr.response;
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        };
        xhr.open("GET", url);
        xhr.send();
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/object-not-found":
            // File doesn't exist
            break;
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect the server response
            break;
        }
      });
  };
  return (
    <>
      <div
        className={` ${
          messageClass === "sent" ? styles.sent : styles.received
        }  `}
      >
        {messageClass === "received" && (
          // eslint-disable-next-line jsx-a11y/img-redundant-alt
          <img
            src={PatientInfo?.avatar_url ? PatientInfo?.avatar_url : Avatar}
            alt="avatar"
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
            }}
          />
        )}
        <div>
          {msg.MessageType === "TextMessage" && (
            <div>
              <p
                className={` ${
                  messageClass === "sent" && styles.sentBackground
                } ${messageClass === "received" && styles.receivedBackground}`}
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "100%",
                  whiteSpace: "pre-line",
                }}
              >
                {messageText
                  .split(/\r?\n/)
                  .filter((line: string) => line.trim() !== "")
                  .join("\n")}
                <p
                  style={{
                    fontSize: "8px",
                    textAlign: "right",
                    marginTop: "4px",
                  }}
                >
                  {formatDate(msg.Timestamp)}
                </p>
              </p>
            </div>
          )}

          {msg.MessageType === "AttachmentMessage" && (
            <>
              <div
                className={` ${styles.filesBacground} `}
                style={{
                  marginTop: "10px",
                  cursor: "pointer",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    maxHeight: "200px",
                    maxWidth: "300px",
                    height: "200px",
                    width: "300px",
                    cursor: "pointer",
                    display: "block",
                  }}
                  onClick={() =>
                    window.open(msg.AttachmentMessage.FileUrl, "_blank")
                  }
                >
                  <embed
                    onClick={() =>
                      window.open(msg.AttachmentMessage.FileUrl, "_blank")
                    }
                    src={msg.AttachmentMessage.FileUrl}
                    style={{
                      maxHeight: "195px",
                      maxWidth: "300px",
                      height: "195px",
                      width: "300px",
                    }}
                  ></embed>
                  <p
                    style={{
                      fontSize: "8px",
                      textAlign: "right",
                      marginBottom: "5px",
                    }}
                  >
                    {formatDate(msg.Timestamp)}
                  </p>
                  {}{" "}
                </div>

                <DownloadDocIcon
                  className={styles.DownloadIcon}
                  onClick={() =>
                    downloadDocument(
                      msg.AttachmentMessage.FileUrl,
                      msg.AttachmentMessage.FileName
                    )
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ChatMessage;
