import { useState, useEffect } from "react";
import { Box, Grid, Divider, InputLabel, MenuItem, Link } from "@mui/material";
import InputSelect from "generic/Inputs/InputSelect";
import Text from "generic/Text/Text";
import InputWithLabel from "generic/Inputs/InputWithLabel";
import StyledRadio from "generic/Inputs/StyledRadio";
import StyledCheckbox from "generic/Inputs/StyledCheckbox";
import PrimaryButton from "generic/Buttons/PrimaryButton";
import { Trans } from "react-i18next";
import {
  useUpdatePersonalInfoMutation,
  useUpdateProfileMutation,
  useGetAllCountriesQuery,
  useLazyGetAllStatesQuery,
  useLazyGetAllCitiesQuery,
} from "services/profileSettings";
import { PlusIcon, XIcon } from "app/assets/icons";
import AddressAutoComplete from "generic/Inputs/AddressAutoComplete";
import Snackbar from "generic/Snackbar/Snackbar";
import { urlRole, profileUser } from "services";
import { clinicTabData } from "utils/doctorOrganizationInterface";

const Clinic = ({ doctorData }: any) => {
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [countryId, setCountryId] = useState("");
  const [stateId, setStateId] = useState("");
  const [cityId, setCityId] = useState("");

  const [region, setRegion] = useState("");
  const [link, setLink] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [consultationFees, setConsultationFees] = useState("");
  const [callFees, setCallFees] = useState("");
  const [emergencyFees, setEmergencyFees] = useState("");
  const [consultationTime, setConsultationTime] = useState("");
  const [clinicType, setClinicType] = useState([]);
  const [practices, setPractices] = useState<string[]>([]);
  const [aboutMe, setAboutMe] = useState("");
  const [aboutPraxis, setAboutPraxis] = useState("");
  const [clinicLat, setClinicLat] = useState("");
  const [clinicLng, setClinicLng] = useState("");
  const [clinicLocation, setClinicLocation] = useState("");

  const [practice, setPractice] = useState("");

  const { data: countries } = useGetAllCountriesQuery(1);
  const [getAllStates, { data: states }] = useLazyGetAllStatesQuery();
  const [getAllCities, { data: cities }] = useLazyGetAllCitiesQuery();

  useEffect(() => {
    if (doctorData) {
      setCountry(
        doctorData?.[profileUser]?.country?.id
          ? doctorData?.[profileUser]?.country?.name
          : ""
      );
      setState(
        doctorData?.[profileUser]?.state?.id
          ? doctorData?.[profileUser]?.state?.name
          : ""
      );
      setCity(
        doctorData?.[profileUser]?.city?.id
          ? doctorData?.[profileUser]?.city?.name
          : ""
      );
      setCountryId(
        doctorData?.[profileUser]?.country?.id
          ? doctorData?.[profileUser]?.country?.id
          : ""
      );
      setStateId(
        doctorData?.[profileUser]?.state?.id
          ? doctorData?.[profileUser]?.state?.id
          : ""
      );
      setCityId(
        doctorData?.[profileUser]?.city?.id
          ? doctorData?.[profileUser]?.city?.id
          : ""
      );

      setRegion(doctorData?.[clinicTabData?.region]);
      setLink(doctorData?.[clinicTabData?.link]);
      setAddress1(doctorData?.[clinicTabData?.address1]);
      setAddress2(doctorData?.[clinicTabData?.address2]);
      setConsultationFees(doctorData.written_consultation_price);
      setCallFees(doctorData.video_consultation_price);
      setEmergencyFees(doctorData.emergency_consultation_price);
      setConsultationTime(doctorData.consultation_time);
      setClinicType(doctorData?.[clinicTabData?.type]);
      if (doctorData.practice_services)
        setPractices(doctorData.practice_services.split(","));
      setAboutMe(doctorData.about_me);
      setAboutPraxis(doctorData.about_praxi);
      setClinicLat(doctorData.latitude);
      setClinicLng(doctorData.longitude);
      setClinicLocation(doctorData?.[clinicTabData?.location]);
    }
  }, [doctorData]);

  useEffect(() => {
    if (country) getAllStates({ countryId });
  }, [country]);

  useEffect(() => {
    if (state)
      getAllCities({
        countryId,
        stateId,
      });
  }, [state]);

  const [updatePersonalInfo] = useUpdatePersonalInfoMutation();
  const [updateProfile] = useUpdateProfileMutation();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    if ((!callFees && consultationTime) || (callFees && !consultationTime)) {
      setLoading(false);
      setIsError(true);
      setErrorMsg(
        "Video call fees & consultation time has to be filled together!"
      );
    } else {
      await updatePersonalInfo({
        user: {
          country: country,
          state_id: stateId,
          city_id: cityId,
        },
      });
      await updateProfile({
        [clinicTabData?.region]: region,
        [clinicTabData?.link]: link,
        [clinicTabData?.address1]: address1,
        [clinicTabData?.address2]: address2,
        written_consultation_price: consultationFees || 0,
        emergency_consultation_price: emergencyFees || 0,
        video_consultation_price: callFees || 0,
        consultation_time: consultationTime,
        [clinicTabData?.type]: clinicType,
        practice_services: practices.toString(),
        about_me: aboutMe,
        about_praxi: aboutPraxis,
        latitude: clinicLat,
        longitude: clinicLng,
        [clinicTabData?.location]: clinicLocation,
      });

      setLoading(false);
      setIsError(true);
      setErrorMsg("successfully updated your profile");
    }
  };

  // useEffect(() => {
  //   getAddress(doctorData.latitude, doctorData.longitude);
  // }, []);

  const handleAddPracticeService = () => {
    setPractices((prevLangs: string[]) => {
      return !practice || practices.includes(practice)
        ? prevLangs
        : [...prevLangs, practice];
    });
    setPractice("");
  };

  // const getAddress = (lat: number, lng: number) => {
  //   console.log("Finding Address");
  //   if (navigator.geolocation) {
  //     let geocoder = new google.maps.Geocoder();
  //     let latlng = new google.maps.LatLng(lat, lng);
  //     let request = { LatLng: latlng };
  //     geocoder.geocode({ location: latlng }, (results, status) => {
  //       if (status === google.maps.GeocoderStatus.OK) {
  //         let result = results?.length ? results[0] : null;
  //         let rsltAdrComponent = result?.address_components;
  //         let resultLength = rsltAdrComponent?.length;
  //         if (result != null && rsltAdrComponent && resultLength) {
  //           console.log(rsltAdrComponent[resultLength - 8].short_name);
  //           // this.address = rsltAdrComponent[resultLength - 8].short_name;
  //         } else {
  //           console.log("No address available!");
  //         }
  //       }
  //     });
  //   }
  // };

  return (
    <Box
      component="form"
      onSubmit={(e: any) => handleSubmit(e)}
      sx={{ my: "auto", mx: 0, maxWidth: "100%" }}
    >
      <Grid container>
        <Grid item xs={12} sm={12} md={5}>
          <Text
            sx={{ color: "#172B4D", fontSize: "18px", fontWeight: 700, mb: 4 }}
            label="Contact Info"
          />
        </Grid>
        {urlRole === "doctor" && (
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <span style={{ margin: "0 auto" }}></span>
            <Link
              href="/clinic-settings"
              variant="body2"
              sx={{
                color: "#EB3131",
                textDecoration: "none",
                fontWeight: 700,
              }}
            >
              <Trans>Manage appointments preferred time</Trans>
            </Link>
          </Grid>
        )}
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={5}>
          {countries?.data?.length ? (
            <InputSelect
              id="country"
              name="country"
              label={"Country"}
              value={country}
              onChange={(e: any) => {
                setCountry(e.target.value?.name);
                setCountryId(e.target.value?.id);
                // getAllStates({ countryId: e.target.value?.id });
                setState("");
                setCity("");
              }}
              placeholder={"Select Country"}
            >
              {countries?.data.map((element: any) => {
                return <MenuItem value={element}>{element.name}</MenuItem>;
              })}
            </InputSelect>
          ) : null}

          {/* {cities?.data?.length ? ( */}
          {true ? (
            <InputSelect
              id="city"
              name="city"
              label={"City"}
              value={city}
              onChange={(e: any) => {
                setCity(e.target.value?.name);
                setCityId(e.target.value?.id);
              }}
              placeholder={"Select City"}
            >
              {cities?.data.map((element: any) => {
                return <MenuItem value={element}>{element.name}</MenuItem>;
              })}
            </InputSelect>
          ) : null}

          {/* <InputWithLabel
            label="Region"
            id="region"
            name="region"
            placeholder="Enter Region"
            required={false}
            value={region}
            onChange={(e: any) => setRegion(e.target.value)}
          /> */}
          <InputWithLabel
            label="Address1"
            id="address1"
            name="address1"
            placeholder="Enter Address"
            required={false}
            value={address1}
            onChange={(e: any) => setAddress1(e.target.value)}
          />
        </Grid>
        <span style={{ margin: "0 20px" }}></span>
        <Grid item xs={12} sm={12} md={5}>
          {/* {states?.data?.length ? ( */}
          {true ? (
            <InputSelect
              id="state"
              name="state"
              label={"State"}
              value={state}
              onChange={(e: any) => {
                setState(e.target.value?.name);
                setStateId(e.target.value?.id);
                // getAllCities({
                //   countryId,
                //   stateId: e.target.value?.id,
                // });
                setCity("");
              }}
              placeholder={"Select State"}
            >
              {states?.data.map((element: any) => {
                return <MenuItem value={element}>{element.name}</MenuItem>;
              })}
            </InputSelect>
          ) : null}

          <InputLabel
            sx={{ mb: 1, fontWeight: 600, fontSize: "14px", color: "#172B4D" }}
          >
            Location
          </InputLabel>
          <AddressAutoComplete
            placeholder="Enter Location"
            ctaPlace={(place: any) => {
              setClinicLat(place?.geometry?.location?.lat());
              setClinicLng(place?.geometry?.location?.lng());
              setClinicLocation(place?.name);
            }}
            clinicLocation={clinicLocation}
          />

          <InputWithLabel
            label="Link"
            id="link"
            name="link"
            placeholder="Enter Link"
            required={false}
            value={link}
            onChange={(e: any) => setLink(e.target.value)}
            mt={2}
          />
          {/* <InputWithLabel
            label="Address2"
            id="address2"
            name="address2"
            placeholder="Enter Address"
            required={false}
            value={address2}
            onChange={(e: any) => setAddress2(e.target.value)}
          /> */}
        </Grid>
      </Grid>

      <Divider sx={{ my: 4 }} />

      <Text
        sx={{ color: "#172B4D", fontSize: "18px", fontWeight: 700, mb: 4 }}
        label="Prices"
      />
      <Grid container>
        <Grid container item direction="row" xs={12} sm={12} md={5}>
          <Grid item sx={{ width: "48%" }}>
            <InputWithLabel
              label="Written Consultation (€)"
              id="writtenConsultation"
              name="writtenConsultation"
              placeholder="Enter Consultation Fees"
              type="number"
              fontSize={12}
              required={false}
              value={consultationFees}
              onChange={(e: any) => setConsultationFees(e.target.value)}
            />
          </Grid>
          <span style={{ margin: "0 auto" }}></span>
          {urlRole === "doctor" && (
            <Grid item sx={{ width: "48%" }}>
              <InputWithLabel
                label="Emergency Appointment (€)"
                id="emergencyAppointment"
                name="emergencyAppointment"
                placeholder="Enter Emergency Fees"
                type="number"
                fontSize={12}
                required={false}
                value={emergencyFees}
                onChange={(e: any) => setEmergencyFees(e.target.value)}
              />
            </Grid>
          )}
        </Grid>
        <span style={{ margin: "0 20px" }}></span>
        <Grid container item direction="row" xs={12} sm={12} md={5}>
          {urlRole === "doctor" && (
            <Grid item sx={{ width: "48%" }}>
              <InputWithLabel
                label="Video Call (€)"
                id="address2"
                name="address2"
                placeholder="Enter Call Fees"
                type="number"
                fontSize={12}
                required={false}
                value={callFees}
                onChange={(e: any) => setCallFees(e.target.value)}
              />
            </Grid>
          )}
          <span style={{ margin: "0 auto" }}></span>
          {urlRole === "doctor" && (
            <Grid item sx={{ width: "48%" }}>
              <InputWithLabel
                label="Consultation Time (min)"
                id="consultationTime"
                name="consultationTime"
                placeholder="Enter Consultation Time"
                type="number"
                fontSize={12}
                required={false}
                value={consultationTime}
                onChange={(e: any) => setConsultationTime(e.target.value)}
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      <Divider sx={{ my: 4 }} />

      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Text
            sx={{ color: "#172B4D", fontSize: "18px", fontWeight: 700, mb: 2 }}
            label="Operations"
          />

          {urlRole === "doctor" ? (
            <>
              <StyledCheckbox
                title="Private Clinic"
                name="private_clinic"
                state={clinicType}
                setState={setClinicType}
                marginLeft={"35px"}
              />

              <StyledCheckbox
                title="University Clinic"
                name="university_clinic"
                state={clinicType}
                setState={setClinicType}
                ml={3}
                marginLeft={"10px"}
              />

              <StyledCheckbox
                title="Governmental Hospital"
                name="governmental_hospital"
                state={clinicType}
                setState={setClinicType}
                ml={3}
                marginLeft={"10px"}
              />
            </>
          ) : (
            <>
              <StyledCheckbox
                title="Hospital"
                name="hospital"
                state={clinicType}
                setState={setClinicType}
                ml={3}
                marginLeft={"35px"}
              />
              <StyledCheckbox
                title="Rehab"
                name="rehab"
                state={clinicType}
                setState={setClinicType}
                ml={3}
                marginLeft={"10px"}
              />
            </>
          )}
        </Grid>
        {/* <span style={{ margin: "0 20px" }}></span>
        <Grid item xs={12} sm={12} md={5}>
          <InputWithLabel
            label="Practice Services"
            id="practiceServices"
            name="practiceServices"
            placeholder="Enter Practice Service"
            type="text"
            required={false}
            value={practice}
            onChange={(e: any) => {
              setPractice(e.target.value);
            }}
            endIcon={<PlusIcon />}
            endIconOnClick={handleAddPracticeService}
          />

          <Grid container>
            {practices.map((practice) => (
              <Grid item sx={{ mb: 2 }}>
                <InputLabel
                  sx={{
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "#136588",
                    display: "inline",
                    background: "#F4F5F8",
                    padding: "10px",
                    mr: 2,
                    borderRadius: "5px",
                  }}
                >
                  {practice}{" "}
                  <IconButton
                    onClick={() =>
                      setPractices(
                        practices.filter((item) => item !== practice)
                      )
                    }
                  >
                    <XIcon
                      style={{ marginBottom: "2px", marginLeft: "-2px" }}
                    />
                  </IconButton>
                </InputLabel>
              </Grid>
            ))}
          </Grid>
        </Grid> */}
      </Grid>

      <Divider sx={{ my: 4 }} />

      <Grid container>
        <Grid item xs={12} sm={12} md={5}>
          <InputWithLabel
            label="About Me"
            id="aboutMe"
            name="aboutMe"
            placeholder="Add text here"
            required={false}
            multiline={true}
            rows={4}
            value={aboutMe}
            onChange={(e: any) => setAboutMe(e.target.value)}
          />
        </Grid>
        <span style={{ margin: "0 20px" }}></span>
        <Grid item xs={12} sm={12} md={5}>
          <InputWithLabel
            label="About praxis"
            id="aboutPraxis"
            name="aboutPraxis"
            placeholder="Add text here"
            required={false}
            multiline={true}
            rows={4}
            value={aboutPraxis}
            onChange={(e: any) => setAboutPraxis(e.target.value)}
          />
        </Grid>
      </Grid>
      <PrimaryButton title="Save Changes" type="submit" loading={loading} />
      <Snackbar
        open={isError}
        onClose={() => setIsError(false)}
        message={errorMsg}
      />
    </Box>
  );
};

export default Clinic;
