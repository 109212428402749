import { createApi } from "@reduxjs/toolkit/query/react";
import { staggeredBaseQueryWithBailOut } from "utils/servicesUtilities";

export const noRoleapi = createApi({
  reducerPath: "noRoleapi",
  baseQuery: staggeredBaseQueryWithBailOut(
    `${process.env.REACT_APP_API_URL}`,
    true
  ),
  tagTypes: [],
  endpoints: (builder) => ({}),
});

export const {} = noRoleapi;
