import { Button } from "@mui/material";

const SecondaryButton = ({
  title,
  type,
  onClick,
  width = "200px",
  height = "50px",
  sx,
  ...rest
}: any) => (
  <Button
    type={type}
    fullWidth
    variant="contained"
    onClick={onClick}
    sx={{
      my: 3,
      background: "#B8D0DB",
      color: "#136588",
      fontWeight: 600,
      fontSize: "14px",
      width,
      height,
      textTransform: "none",
      ...sx,
      ":hover": {
        background: "#A8C0CB",
        color: "#136588",
      },
      ...rest,
    }}
  >
    {title}
  </Button>
);

export default SecondaryButton;
