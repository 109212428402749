import { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useGetKnowledgeVideosQuery } from "services/knowledge";
import BlueButton from "../BlueButton";
import Modal from "generic/Modals/Modal";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircleCheckRegularIcon, CircleCheckSolidIcon } from "app/assets/icons";
import { PlayButtonBlueIcon } from "app/assets/icons";

export const AttachVideoLink = ({
  consultation,
  type,
  updateAttachedVideos,
}: any) => {
  const [isAttachVideoModalOpen, setIsAttachVideoModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [allVideos, setAllVideos] = useState<any>([]);
  const [attachedVideoIds, setAttachedVideoIds] = useState<number[]>(
    consultation?.consultation_articles?.length > 0
      ? consultation?.consultation_articles?.map((article: any) => article.id)
      : []
  );
  const { data: videos, isLoading: isPatientsLoading } =
    useGetKnowledgeVideosQuery(page);

  useEffect(() => {
    if (videos?.data?.length) {
      if (videos?.pagination?.current == 1) {
        setAllVideos(videos?.data);
      } else {
        setAllVideos((prev: any) => {
          return [...prev, ...videos?.data];
        });
      }
      // setNextCursor(videos?.data?.pagination?.next_cursor);
    }
  }, [videos]);

  const handleSendingVideos = async () => {
    const consultation_articles_attributes = attachedVideoIds.map(
      (videoId: number) => {
        return { article_id: videoId };
      }
    );
    await updateAttachedVideos({
      type,
      id: consultation.id,
      updates: { consultation_articles_attributes },
    });
    setIsAttachVideoModalOpen(false);
  };

  return (
    <>
      <Typography
        style={{
          color: "#172B4D",
          fontSize: "16px",
          fontWeight: 700,
          marginBottom: "20px",
        }}
      >
        Video
      </Typography>
      <BlueButton onClick={() => setIsAttachVideoModalOpen(true)}>
        + Attach video link
      </BlueButton>
      <Modal
        open={isAttachVideoModalOpen}
        setOpen={setIsAttachVideoModalOpen}
        primaryTitle="Send"
        secondaryTitle="Cancel"
        primaryOnclick={handleSendingVideos}
        // primaryOnclick={() => console.log({ attachedVideoIds })}
        top="50%"
      >
        <p style={{ fontSize: "18px", fontWeight: 606, marginBottom: "15px" }}>
          Attach a video
        </p>

        <div style={{ height: "500px", overflow: "scroll" }}>
          <InfiniteScroll
            dataLength={allVideos.length} //This is important field to render the next data
            next={() => setPage(videos?.pagination?.next)}
            hasMore={videos?.pagination?.next ? true : false}
            // hasMore={false}
            loader={<h4>Loading...</h4>}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
            height={500}
          >
            <Grid container>
              {allVideos?.length
                ? allVideos?.map((video: any) => {
                    let remaining = video?.title?.slice(29);
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        style={{ position: "relative" }}
                      >
                        {video?.source == "leviomed" ? (
                          <video
                            id={video?.id}
                            width="260"
                            height="180"
                            controls
                            style={{
                              borderRadius: "10px",
                              margin: "5px",
                              border: attachedVideoIds.find(
                                (vidId) => vidId == video?.id
                              )
                                ? "5px solid #136588"
                                : "none",
                              display: "block",
                            }}
                          >
                            <source src={video?.media?.url} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <div
                            style={{
                              marginBottom: "10px",
                              marginRight: "10px",
                              border: attachedVideoIds.find(
                                (vidId) => vidId == video?.id
                              )
                                ? "3px solid #136588"
                                : "1px dotted #136588",
                              borderRadius: "10px",
                              padding: "10px",
                              backgroundImage: `url(${video?.cover_photo})`,
                              backgroundSize: "cover",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "40px 0",
                              }}
                            >
                              <a href={video?.viewmedica_url} target="_blank">
                                <PlayButtonBlueIcon />
                              </a>
                            </div>
                            <p
                              style={{
                                color: "#136588",
                                fontSize: "14px",
                                fontWeight: 600,
                              }}
                            >
                              {`${video?.title?.slice(0, 28)}${
                                remaining ? "..." : ""
                              }`}
                            </p>
                          </div>
                        )}

                        {attachedVideoIds.find(
                          (vidId) => vidId == video?.id
                        ) ? (
                          <CircleCheckSolidIcon
                            style={{
                              cursor: "pointer",
                              position: "absolute",
                              top: 15,
                              right: 30,
                              width: "25px",
                              height: "25px",
                              zIndex: 1000,
                              fill: "#136588",
                            }}
                            onClick={() =>
                              setAttachedVideoIds((prev) => {
                                return prev.filter(
                                  (vidId) => vidId != video?.id
                                );
                              })
                            }
                          />
                        ) : (
                          <CircleCheckRegularIcon
                            style={{
                              cursor: "pointer",
                              position: "absolute",
                              top: 15,
                              right: 30,
                              width: "25px",
                              height: "25px",
                              zIndex: 1000,
                              fill: "#136588",
                            }}
                            onClick={() =>
                              setAttachedVideoIds((prev) => [
                                ...prev,
                                video?.id,
                              ])
                            }
                          />
                        )}
                      </Grid>
                    );
                  })
                : null}
            </Grid>
          </InfiniteScroll>
        </div>
      </Modal>
    </>
  );
};

export default AttachVideoLink;
