import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { Box, CircularProgress } from "@mui/material";
import { useGetMyProfileQuery } from "services/profileSettings";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import {
  firestore,
  ConversationsCollection,
  LastMessageTimestampField,
  DoctorIdField,
} from "../../../firebase";
import { onSnapshot } from "firebase/firestore";
import React from "react";
import Avatar from "app/assets/images/avatar.png";
import styles from "./conversations.module.css";
import { useLazyGetUserQuery } from "services/user";
import Loader from "generic/Loader/loader";
import { number } from "yup";
import { urlRole, profileUser } from "services";

interface IConversations {
  onClick?: any;
  getPatientInfo?: any;
  setDisabledPatientsID: Dispatch<SetStateAction<number[]>>;
}

interface IConversation {
  DoctorId: number;
  LastMessage: string;
  LastMessageTimestamp: any;
  PatientId: number;
  Disabled: boolean;
  ReadBy: number[];
}

const Conversations = ({
  onClick,
  getPatientInfo,
  setDisabledPatientsID,
}: IConversations) => {
  const { data: myProfile, isSuccess } = useGetMyProfileQuery();
  const [Conversations, setConversations] = useState<any>();
  const [PaitentList, setPaitentList] = useState<any>([]);
  const [getMyValues, { isSuccess: isSuccessmyValues, data: myValues }] =
    useLazyGetUserQuery();
  const countRef = useRef([]);
  const [currentPatient, setCurrentPatient] = useState<any>(0);
  useEffect(() => addConversation(), [isSuccess]);
  useEffect(() => {
    getPatientsList();
  }, [Conversations, isSuccessmyValues, myValues]);
  const getPatientsList = async () => {
    if (Conversations) {
      countRef.current = Conversations.map((conv: any) => {
        return conv.PatientId;
      });
      await setList();
    }
  };

  const setList = async () => {
    let countOfCallsLength = countRef.current.length;

    let countOfCalls = Math.floor(countRef.current.length / 10) + 1;
    if (countOfCallsLength > PaitentList.length) {
      for (let index = 0; index < countOfCalls; index++) {
        getMyValues(countRef.current.slice(10 * index, index * 10 + 10)).then(
          (res) => {
            if (!PaitentList.includes(Object.values(res.data.users))) {
              setPaitentList((oldArray: any) => [
                ...oldArray,
                ...Object.values(res.data.users),
              ]);
            }
          }
        );
      }
    }

    if (countOfCallsLength !== PaitentList.length) {
      setPaitentList(
        PaitentList.filter(
          (value: { id: any }, index: any, self: any[]) =>
            index === self.findIndex((t: { id: any }) => t.id === value.id)
        )
      );
    }
  };

  const addConversation = () => {
    if (isSuccess) {
      return onSnapshot(
        firestore
          .collection(ConversationsCollection)
          .where(DoctorIdField, "==", myProfile?.[urlRole]?.[profileUser].id)
          .orderBy(LastMessageTimestampField, "desc"),

        (snapshot: any) => {
          setConversations(
            snapshot.docs.map((doc: any) => {
              return doc.data();
            })
          );
        }
      );
    }
  };

  useEffect(() => {
    if (Conversations) {
      setDisabledPatientsID(
        Conversations.filter((conv: IConversation) => conv.Disabled).map(
          (conv: IConversation) => conv.PatientId
        )
      );
    }
  }, [Conversations]);

  return (
    <div className={styles.layoutConv}>
      {Conversations && PaitentList && PaitentList.length > 0 ? (
        Conversations.map((c: IConversation) => (
          <div
            key={c.LastMessageTimestamp}
            className={styles.conv}
            onClick={() => {
              onClick(c.PatientId);
              setCurrentPatient(c.PatientId);
              getPatientInfo(
                PaitentList.find((p: any) => p.id === c.PatientId)
              );
            }}
          >
            <Box sx={{ height: "40px" }}>
              {
                <img
                  src={
                    PaitentList.find((p: any) => p?.id === c.PatientId)
                      ?.avatar_url
                      ? PaitentList.find((p: any) => p?.id === c.PatientId)
                          ?.avatar_url
                      : Avatar
                  }
                  alt="pp"
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    border:
                      currentPatient === c.PatientId ? "2px solid" : "0px",
                    borderColor:
                      currentPatient === c.PatientId ? "#88C8EE" : "white",
                  }}
                />
              }
            </Box>
            <div style={{ gap: "10px" }}>
              <p
                style={{
                  color: currentPatient === c.PatientId ? "#136588" : "#172B4D",
                  fontSize: "14px",
                  fontWeight: "700",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "140px",
                  lineHeight: "1.25rem",
                }}
              >
                {PaitentList.find((p: any) => p?.id === c.PatientId)
                  ?.first_name || "Loading.."}{" "}
                {PaitentList.find((p: any) => p?.id === c.PatientId)
                  ?.last_name || ""}
              </p>
              <p
                className={`${
                  c.ReadBy &&
                  c?.ReadBy.includes(myProfile?.[urlRole]?.[profileUser].id)
                    ? styles.ReadMessage
                    : styles.UnReadMessage
                }`}
                style={{
                  fontSize: "12px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "140px",
                  lineHeight: "1.25rem",
                }}
              >
                {c.LastMessage}{" "}
              </p>
            </div>
            <span>
              {c.ReadBy &&
                !c.ReadBy.includes(myProfile?.[urlRole]?.[profileUser].id) && (
                  <span
                    style={{
                      height: "7px",
                      width: "7px",
                      backgroundColor: "red",
                      borderRadius: "50%",
                      display: " inline-block",
                      marginTop: "15px",
                    }}
                  ></span>
                )}
            </span>
          </div>
        ))
      ) : (
        <div
          style={{
            textAlign: "center",
            marginTop: "50px",
            marginRight: "10px",
          }}
        >
          {Conversations === undefined && PaitentList.length === 0 && (
            <CircularProgress />
          )}
        </div>
      )}
    </div>
  );
};
function moviePropsAreEqual(prevProps: any, nextProps: any) {
  return prevProps.onClick === nextProps.onClick ? true : false;
}
export default React.memo(Conversations, moviePropsAreEqual);
