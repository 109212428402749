import styled from "@emotion/styled";
import MuiTabs, { TabsProps } from "@mui/material/Tabs";

const Tabs = styled((props: TabsProps) => <MuiTabs {...props} />)(() => ({
  "&.MuiTabs-root": {
    minHeight: "unset",
  },
  ".MuiTabs-flexContainer": {
    gap: "2rem",
  },
  ".MuiTabs-indicator": {
    backgroundColor: "transparent",
  },
}));

export default Tabs;
