import { useEffect, useState } from "react";
import AgoraUIKit, { layout } from "agora-react-uikit";
import callEndIcon from "app/assets/icons/agora/endcall.svg";
import { useUpdateConsultationMutation } from "services/appointments";

const Agora = () => {
  const [videoCall, setVideoCall] = useState(true);
  const [rtcProps, setRtcProps] = useState<any>();
  const [updateConsultation] = useUpdateConsultationMutation();

  let consultationId: number;

  let appId = process.env.REACT_APP_AGORA_APP_ID;

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const object: any = {};
    params.forEach((value, key) => {
      object[key] = value;
    });
    consultationId = object.consultation_id;
    if (object.uid) {
      setRtcProps({
        appId,
        channel: object.channel,
        token: object.token,
        uid: parseInt(object.uid),
        // layout: layout.grid,
      });
    } else {
      setRtcProps({
        appId,
        channel: object.channel,
        token: object.token,
        // layout: layout.grid,
      });
    }
  }, []);

  const styleProps = {
    customIcon: {
      callEnd: "",
    },
  };

  const callbacks = {
    EndCall: async () => {
      // setIsAgoraButtonClicked(false);
      await updateConsultation({
        type: "VideoConsultation",
        id: consultationId,
        updates: { doctor_ended_call: new Date() },
      });
      window.open("about:blank", "_self");
      window.close();
    },
  };
  return videoCall ? (
    <div style={{ display: "flex", width: "100vw", height: "100vh" }}>
      {rtcProps?.appId ? (
        <>
          <AgoraUIKit rtcProps={rtcProps} callbacks={callbacks} />
        </>
      ) : null}
    </div>
  ) : (
    <h3 onClick={() => setVideoCall(true)}>Start Call</h3>
  );
};

export default Agora;
