import StyledRadio from "generic/Inputs/StyledRadio";
import moment from "moment";
import calendarIcon from "app/assets/icons/calendar.svg";

const SelectTimeslot = ({ consultation, chosenSlot, setChosenSlot }: any) => {
  return (
    <div style={{ display: "flex" }}>
      <StyledRadio
        title={moment(consultation.slot1).format("DD/MM/YYYY")}
        subtitle={moment(consultation.slot1).format("LT")}
        name={1}
        state={chosenSlot}
        setState={setChosenSlot}
        buttonIcon={calendarIcon}
      />
      <span style={{ margin: "0 5px" }}></span>
      <StyledRadio
        title={moment(consultation.slot2).format("DD/MM/YYYY")}
        subtitle={moment(consultation.slot2).format("LT")}
        name={2}
        state={chosenSlot}
        setState={setChosenSlot}
        buttonIcon={calendarIcon}
      />
      <span style={{ margin: "0 5px" }}></span>
      <StyledRadio
        title={moment(consultation.slot3).format("DD/MM/YYYY")}
        subtitle={moment(consultation.slot3).format("LT")}
        name={3}
        state={chosenSlot}
        setState={setChosenSlot}
        buttonIcon={calendarIcon}
      />
    </div>
  );
};

export default SelectTimeslot;
