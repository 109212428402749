import api from "./index";
import { urlRole } from "./index";

export const accountSettings = api.injectEndpoints({
  endpoints: (builder) => ({
    getTerms: builder.query<any, any>({
      query: ({ lang = "en", termType = 0 }: any) =>
        `/api/v1/admin/system_terms?locale=${lang}&filters[term_type]=${termType}&filters[role]=1`,
      providesTags: () => [{ type: "Terms" }],
    }),
    deleteAccount: builder.mutation({
      query: () => ({
        url: "/api/v1/user",
        method: "DELETE",
      }),
    }),
    reportProblem: builder.mutation({
      query: (problem: string) => ({
        url: `/api/v1/${urlRole}/reported_problems`,
        method: "POST",
        body: {
          reported_problem: {
            content: problem,
          },
        },
      }),
    }),
    reportPatient: builder.mutation({
      query: ({
        patientId,
        report,
      }: {
        patientId: number;
        report: string;
      }) => ({
        url: `/api/v1/${urlRole}/reported_problems`,
        method: "POST",
        body: {
          reported_problem: {
            content: report,
            reported_patient_id: patientId,
          },
        },
      }),
    }),
  }),
});

export const {
  useGetTermsQuery,
  useDeleteAccountMutation,
  useReportProblemMutation,
  useReportPatientMutation,
} = accountSettings;
