import { Grid } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import Layout from "modules/layout/Layout";
import Card from "generic/Cards/Card";
import ManageAppointmentCard from "./components/ManageAppointmentCard";
import AppointmentsCard from "../home/components/AppointmentsCard";
import avatar1 from "app/assets/images/avatars/1.svg";
import avatar2 from "app/assets/images/avatars/2.svg";
import avatar3 from "app/assets/images/avatars/3.svg";
import avatar4 from "app/assets/images/avatars/4.svg";
import avatar5 from "app/assets/images/avatars/5.svg";
import { appointmentStatuses } from "utils/enums";
import { urlRole } from "services";

const RequestManagement = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t("Request Management", "Request Management")}>
      <Grid container item direction="row">
        <Grid container item direction="column" xs={6}>
          {urlRole === "doctor" && (
            <Grid item>
              <ManageAppointmentCard />
            </Grid>
          )}
          <Grid item>
            <Card sx={{ minHeight: "100%", paddingBottom: 0 }}>
              <AppointmentsCard
                title={"Appointments Requests"}
                cardStyle={"button"}
                status={"waiting_doctor_confirmation"}
                perPage={5}
                sort={"last_action_date"}
              />
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Card sx={{ minHeight: "100%" }}>
            <AppointmentsCard
              title={"Appointments History"}
              cardStyle={"status"}
              perPage={7}
              sort={"-last_action_date"}
            />
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default RequestManagement;
