import { appointmentStatuses } from "utils/enums";

export const getStatus = (status: string) => {
  let color = "#136588";
  let background = "#B8D0DB";
  let FEStatus = status;

  switch (status) {
    case "patient_created":
    case "doctor_created":
      FEStatus = appointmentStatuses.CREATED;
      break;

    case "waiting_doctor_confirmation":
    case "waiting_patient_confirmation":
      FEStatus = appointmentStatuses.IN_PROGRESS;
      color = "#FFAB00";
      background = "#FFAB0010";
      break;

    case "upcoming":
      FEStatus = appointmentStatuses.UPCOMING;
      color = "#136588";
      background = "#13658810";
      break;

    case "completed":
      FEStatus = appointmentStatuses.COMPLETED;
      color = "#1D93C7";
      background = "#1D93C710";
      break;

    case "cancelled":
      FEStatus = appointmentStatuses.DECLINED;
      color = "#EB3131";
      background = "#EB313110";
      break;

    case "patient_no_show":
    case "doctor_no_show":
      FEStatus = appointmentStatuses.NO_SHOW;
      color = "#EB3131";
      background = "#EB313110";
      break;
  }
  return { FEStatus, color, background };
};
