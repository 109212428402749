import { Box, Grid, Divider } from "@mui/material";
import ContactInfo from "./components/ContactInfo";
import ReportProblem from "./components/ReportProblem";
import contactImg from "app/assets/images/contact.svg"

const Contact = () => {
  const styles = {
    contactImgContainer: {
      height: '6rem', 
      width: '6rem', 
      marginRight: '2rem', 
      display: 'flex', 
      alignItems: 'end', 
      paddingBottom: '0.5rem'}
  };

  return (
    <Box sx={{ my: "auto", mx: 0, maxWidth: "100%" }}>
      <div style={{display: 'flex', flexWrap: 'wrap'}}>
        <div style={styles.contactImgContainer}>
          <img src={contactImg} alt="contact icon" />
        </div>
        <Grid container xs={12} sm={12} md={10} sx={{marginTop: '1.375rem'}}>
          <ContactInfo />
        </Grid>
      </div>
      <Divider sx={{mt:4}}/>
      <Grid container xs={12} sm={12} md={6} sx={{marginTop: '1.375rem'}}>
        <ReportProblem />
      </Grid>
    </Box>
  );
};

export default Contact;
