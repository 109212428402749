import Layout from "modules/layout/Layout";
import { Grid, Typography, Box } from "@mui/material";
import Card from "generic/Cards/Card";
import { INotificationWithAvatar } from "services/notifications";
import { useEffect, useState } from "react";
import { useLazyFetchNotifications } from "utils/hooks/useLazyFetchNotifications";
import NotificationsList from "./components/NotificationsList";

const Notifications = () => {
  const [notifications, setNotifications] =
    useState<INotificationWithAvatar[]>();
  const fetchNotifications = useLazyFetchNotifications();

  useEffect(() => {
    fetchNotifications().then((notifications) =>
      setNotifications(notifications)
    );
  }, []);

  return (
    <Layout title="Notifications">
      <Grid container item direction="row" sx={{ minHeight: "83.5vh" }}>
        <Card
          xs={12}
          sx={{ border: "none", padding: 0, mt: "0.625rem", mb: "2rem" }}
        >
          <Box sx={{ width: "100%" }}>
            <Box sx={{ padding: "1.5rem" }}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "1.125rem",
                  lineHeight: "1.375rem",
                  color: "#172B4D",
                }}
              >
                Latest Notifications
              </Typography>
            </Box>

            <NotificationsList notifications={notifications} />
          </Box>
        </Card>
      </Grid>
    </Layout>
  );
};

export default Notifications;
