import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import Layout from "modules/layout/Layout";
import Card from "generic/Cards/Card";
import PatientsStats from "./components/PatientsStats/PatientsStats";
import RevenueStats from "./components/RevenueStats/RevenueStats";
import PaymentTransactions from "./components/paymentTransactions/PaymentTransactions";

const Revenue = () => {
  const { t: translate } = useTranslation();

  return (
    <Layout title={translate("Revenue", "Revenue")}>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          pt: "0.625rem",
        }}
      >
        <Grid container direction="row" spacing={"1.5rem"}>
          <Grid item xs={12} sm={12} md={6}>
            <Card
              sx={{
                width: "100%",
                height: "16.813rem",
                border: 0,
                padding: "1.5rem",
              }}
            >
              <PatientsStats />
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Card
              sx={{
                width: "100%",
                height: "16.813rem",
                border: 0,
                px: "1.5rem",
                pt: "1.5rem",
                pb: "1rem",
              }}
            >
              <RevenueStats />
            </Card>
          </Grid>
        </Grid>

        <Card
          sx={{
            flexGrow: 1,
            width: "100%",
            mt: "1.25rem",
            border: 0,
            padding: "1.5rem",
          }}
        >
          <PaymentTransactions />
        </Card>
      </Box>
    </Layout>
  );
};

export default Revenue;
