import calendarIcon from "app/assets/icons/calendar.svg";
import moment from "moment";

const DisplayChosenTime = ({ consultation }: any) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        src={calendarIcon}
        style={{ width: "24px", height: "24px" }}
        alt="calendar icon"
      />
      <p
        style={{
          fontSize: "16px",
          fontWeight: 600,
          color: "#172B4D",
          marginLeft: "10px",
        }}
      >
        {moment(consultation.slot).format("DD/MM/YYYY")} -{" "}
        {moment(consultation.slot).format("LT")}
      </p>
    </div>
  );
};

export default DisplayChosenTime;
