import {
  INotificationWithAvatar,
  useLazyGetNotificationsQuery,
} from "services/notifications";
import { useLazyGetUserQuery } from "services/user";

export const useLazyFetchNotifications = () => {
  const [fetchNotifications] = useLazyGetNotificationsQuery();
  const [fetchSenders] = useLazyGetUserQuery();

  const fetchNotificationsWithAvatars = async (): Promise<
    INotificationWithAvatar[]
  > => {
    const notifications = await fetchNotifications(undefined, false).unwrap();

    const { users } = await fetchSenders(
      Array.from(
        new Set(
          notifications.map((notification) => notification.payload.patient_id)
        )
      )
    ).unwrap();

    const senders: any[] = Object.values(users);

    const notificationsWithAvatars = notifications.map((notification) => ({
      ...notification,
      patient_avatar_url: senders.find(
        (sender: any) => sender.id === notification.payload.patient_id
      ).avatar_url,
    }));
    return notificationsWithAvatars;
  };

  return fetchNotificationsWithAvatars;
};
