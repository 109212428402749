import { Button } from "@mui/material";
import LoadingButton from "./LoadingButton";
import { Trans } from "react-i18next";

const PrimaryButton = ({
  title,
  type,
  onClick,
  disabled = false,
  loading = false,
  width = "200px",
  height = "50px",
  margin = "24px 0",
  sx,
}: any) =>
  loading ? (
    <LoadingButton />
  ) : (
    <Button
      type={type}
      fullWidth
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      sx={{
        my: 3,
        background: "#136588",
        color: "#FFFFFF",
        fontWeight: 600,
        fontSize: "14px",
        width,
        height,
        textTransform: "none",
        ":hover": {
          background: "#035578",
          color: "#FFFFFF",
        },
        margin,
        ...sx,
      }}
    >
      <Trans>{title}</Trans>
    </Button>
  );

export default PrimaryButton;
