import { Box, Modal, Grid } from "@mui/material";
import PrimaryButton from "generic/Buttons/PrimaryButton";
import SecondaryButton from "generic/Buttons/SecondaryButton";
import { XIcon } from "app/assets/icons";

const BasicModal = ({
  open,
  setOpen,
  primaryTitle = "Yes",
  secondaryTitle = "Cancel",
  primaryOnclick = () => setOpen(false),
  secondaryOnclick = () => setOpen(false),
  children,
  sx,
  top = "25%",
}: any) => {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ ...sx }}
    >
      <Box
        sx={{
          position: "absolute",
          top,
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 625,
          background: "#FFFFFF",
          boxShadow: 24,
          pl: 4,
          pr: 2,
          borderRadius: "20px",
        }}
      >
        <Grid container direction="row" justifyContent="flex-end">
          <XIcon
            style={{
              cursor: "pointer",
              marginTop: "10px",
            }}
            onClick={() => setOpen(false)}
          />
        </Grid>
        {children}
        <Grid container direction="row" sx={{ mt: 2 }}>
          <PrimaryButton title={primaryTitle} onClick={primaryOnclick} />
          <span style={{ margin: "0 10px" }}></span>
          <SecondaryButton title={secondaryTitle} onClick={secondaryOnclick} />
        </Grid>
      </Box>
    </Modal>
  );
};
export default BasicModal;
