import api from "./index";
import { urlRole } from "./index";

interface IGetReviewsParams {
  page: number;
  perPage: number;
}

export const reviews = api.injectEndpoints({
  endpoints: (builder) => ({
    getReviews: builder.query<any, IGetReviewsParams>({
      query: ({ page, perPage }) =>
        `/api/v1/${urlRole}/consultation_reviews?page=${page}&per_page=${perPage}`,
      providesTags: () => [{ type: "Reviews" }],
    }),
  }),
});

export const { useGetReviewsQuery } = reviews;
