import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { onSnapshot } from "firebase/firestore";

import { Grid, Divider, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Layout from "modules/layout/Layout";
import Card from "generic/Cards/Card";
import ChatRoom from "./chatRoom/chatRoom";
import { useGetMyProfileQuery } from "services/profileSettings";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { ReactComponent as NoMessages } from "app/assets/icons/noMessages.svg";
import styles from "./Messages.module.css";

import Conversations from "./conversations/conversations";
import React from "react";
import { firestore, UsersCollections } from "../../firebase";
import { useLocation } from "react-router-dom";
import Avatar from "generic/Avatar/Avatar";
import { urlRole, profileUser } from "services";

type ModalContent = {
  PatientId: Number | undefined;
  setPatientId: Dispatch<SetStateAction<any>>;
};
interface locationState {
  patientId: number;
  patientInfo: any;
}
export const ModalContext = React.createContext<ModalContent>({
  PatientId: undefined,
  setPatientId: () => {},
});

const Messages = () => {
  const { t } = useTranslation();
  const [PatientId, setPatientId] = useState<Number>();
  const [PatientInfo, setPatientInfo] = useState<any>();
  const [lastMessageTime, setLastMessageTime] = useState<any>();

  const [translationLang, setTranslationLang] = useState<any>("en");
  const [activateTranslation, setActivateTranslation] = useState(false);

  const [disabledPatientsID, setDisabledPatientsID] = useState<number[]>([]);
  const [activeStatus, setActiveStatus] = useState<any>();
  const { data: myProfile, isSuccess } = useGetMyProfileQuery();
  const location = useLocation();
  const state = location.state as locationState;
  const ConversationID = useCallback((id: any) => {
    setPatientId(id);
  }, []);
  const getPatientInfo = useCallback((id: any) => {
    setPatientInfo(id);
  }, []);
  // const getLastMesssageTime = useCallback((id: any) => {
  //   setLastMessageTime(id);
  // }, []);
  const showActiveStatus = () => {
    if (PatientId) {
      return onSnapshot(
        firestore.collection(UsersCollections).doc(`${PatientId}`),
        (snapshot: any) => {
          setActiveStatus(snapshot.data()?.IsOnline);
        }
      );
    }
  };
  // function formatDate(ut: { seconds: number; nanoseconds: number }) {
  //   if (!ut) return " ";

  //   const date = new Date(ut.seconds * 1000 + ut.nanoseconds / 1000000);
  //   const hours = date.getHours();
  //   const minutes = date.getMinutes();
  //   const day = date.toLocaleString("en-US", { weekday: "long" });
  //   return `${hours}:${minutes}, ${day}`;
  // }
  useEffect(() => {
    showActiveStatus();
  });
  useEffect(() => {
    if (state && state.patientId && state.patientInfo) {
      setPatientId(state.patientId);
      setPatientInfo(state.patientInfo);
    }
  }, []);

  return (
    <Layout
      title={t("Messages", "Messages")}
      childrenStyles={styles.ScrollHidden}
    >
      <Grid
        container
        item
        direction="row"
        sx={{ minHeight: "85vh", height: " 100%" }}
      >
        <Card
          xs={12}
          sx={{
            display: "flex",
            width: "100%",
            border: "10px solid #EDF3F7",
            borderRadius: "20px",
            padding: "0",
            margin: "0",
            height: " 100%",
          }}
        >
          <>
            <div
              style={{
                width: "25%",
                paddingTop: "40px",
                paddingLeft: "32px",
                overflowY: "hidden",
              }}
            >
              <p
                style={{
                  color: "#95A0B8",
                  fontWeight: "600",
                  fontSize: "12px",
                  lineHeight: "20px",
                  letterSpacing: "1px",
                  paddingBottom: "5px",
                }}
              >
                {t(" DIRECT MESSAGES", " DIRECT MESSAGES")}
              </p>
              <Conversations
                onClick={ConversationID}
                getPatientInfo={getPatientInfo}
                setDisabledPatientsID={setDisabledPatientsID}
              />
            </div>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{
                margin: 0,
                background: "#8F92A1",
                opacity: "0.1",
                width: "1px",
              }}
            />
            <div
              style={{
                width: "75%",
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                height: "100%",
              }}
            >
              {PatientId &&
              isSuccess &&
              myProfile?.[urlRole]?.[profileUser]?.id ? (
                <>
                  <Box>
                    <Box
                      sx={{
                        py: "2rem",
                        px: "1.5rem",
                        // boxShadow:
                        //   "rgba(100, 100, 111, 0.1) -7px 7px 29px 0px, rgba(100, 100, 111, 0.1) 0px 7px 29px 0px",
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.06)",
                        overflow: "hidden",
                        height: "104px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "1rem",
                          alignItems: "center",
                        }}
                      >
                        <Avatar src={PatientInfo?.avatar_url} size="2.5rem" />
                        <Box>
                          <Typography
                            sx={{
                              color: "#172B4D",
                              fontSize: "14px",
                              fontWeight: "700",
                              lineHeight: "1.25rem",
                            }}
                          >
                            {PatientInfo?.first_name} {PatientInfo?.last_name}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            sx={{
                              color: "#7F8CA9",
                              fontSize: "12px",
                              lineHeight: "1.25rem",
                            }}
                          >
                            {activeStatus && "Active now"}
                          </Typography>
                        </Box>
                        <span style={{ margin: "0 auto" }}></span>
                        <p
                          style={{
                            cursor: "pointer",
                            color: "#136588",
                            fontWeight: 600,
                            fontSize: "14px",
                          }}
                          onClick={() => {
                            setTranslationLang("en");
                            setActivateTranslation(true);
                          }}
                        >
                          Translate to English{" "}
                        </p>
                        -
                        <p
                          style={{
                            cursor: "pointer",
                            color: "#136588",
                            fontWeight: 600,
                            fontSize: "14px",
                          }}
                          onClick={() => {
                            setTranslationLang("de");
                            setActivateTranslation(true);
                          }}
                        >
                          {" "}
                          Translate to German
                        </p>
                      </Box>
                    </Box>
                    {/* <Typography
                      style={{
                        marginTop: "1.5rem",
                        color: "#95A0B8",
                        fontSize: "12px",
                        textTransform: "uppercase",
                        fontWeight: "600",
                        textAlign: "center",
                      }}
                    >
                      {formatDate(lastMessageTime)}
                    </Typography> */}
                  </Box>
                  {PatientInfo && (
                    <ChatRoom
                      // getLastMesssageTime={getLastMesssageTime}
                      PatientInfo={PatientInfo}
                      PatientId={PatientId}
                      doctorId={myProfile?.[urlRole]?.[profileUser]?.id!}
                      disabledPatientsID={disabledPatientsID}
                      SpecialistId={myProfile?.[urlRole].id}
                      role={urlRole}
                      translation={{
                        active: activateTranslation,
                        language: translationLang,
                      }}
                    />
                  )}
                </>
              ) : (
                <div
                  style={{
                    alignSelf: "center",
                    position: "absolute",
                    top: "40%",
                  }}
                >
                  <span>
                    <NoMessages />
                  </span>
                  <p
                    style={{
                      color: "#172B4D",
                      fontWeight: "600",
                      fontSize: "18px",
                      marginTop: "16px",
                    }}
                  >
                    No Messages Yet
                  </p>
                </div>
              )}
            </div>
          </>
        </Card>
      </Grid>
    </Layout>
  );
};

export default Messages;
