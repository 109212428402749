

import firebase from 'firebase/compat/app'; 
import 'firebase/compat/firestore';
import 'firebase/compat/auth'; 
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";


export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const app =firebase.initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage();
export const firestore = firebase.firestore();

export const ConversationsCollection= "Conversations"
export const UsersCollections="Users"
export const MessagesCollection= "Messages"
export const TimestampField= "Timestamp"
export const LastMessageField= "LastMessage"
export const LastMessageTimestampField= "LastMessageTimestamp"
export const DisabledField= "Disabled"
export const DoctorIdField= "DoctorId"
 









