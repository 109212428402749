import React, { SetStateAction, Dispatch } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/calendar.css";
import moment from "moment";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { appointmentStatuses } from "utils/enums";
import {
  PaginationLeftArrowIcon,
  PaginationRightArrowIcon,
} from "app/assets/icons";
import { Appointment } from "..";

type Props = {
  startDate: Date;
  setStartDate: Dispatch<SetStateAction<Date>>;
  statusFilter: string;
  setStatusFilter: Dispatch<SetStateAction<string>>;
  datedAppointments: { [dayName: string]: Appointment[] };
};

const Calender = ({
  startDate,
  setStartDate,
  statusFilter,
  setStatusFilter,
  datedAppointments,
}: Props) => {
  const handleChange = (event: SelectChangeEvent) => {
    setStatusFilter(event.target.value);
  };

  const renderDayContents = (_: any, date: Date) => {
    return (
      <Box
        sx={{
          height: "6.75rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            gap: 14,
            fontSize: 12,
            ...(!moment(date).isSame(startDate, "month") && {
              color: "#95A0B8",
            }),
          }}
          component="span"
        >
          {moment(date).format("D")}
        </Typography>
        {datedAppointments &&
          Boolean(Object.keys(datedAppointments)?.length) &&
          datedAppointments[`DAY${moment(date).date()}`] && (
            <Box
              display={"flex"}
              sx={{
                position: "absolute",
                top: 85,
              }}
            >
              {datedAppointments[`DAY${moment(date).date()}`].map(
                (appointment: any) => {
                  if (moment(appointment.slot).isSame(date, "day")) {
                    return (
                      <Box
                        sx={{
                          borderRadius: "100%",
                          width: 5,
                          height: 5,
                          bgcolor:
                            appointment.status ===
                            appointmentStatuses.COMPLETED.toLocaleLowerCase()
                              ? "#09C0D1"
                              : appointment.status ===
                                appointmentStatuses.UPCOMING.toLocaleLowerCase()
                              ? "#136588"
                              : appointment.status ===
                                appointmentStatuses.IN_PROGRESS.toLocaleLowerCase()
                              ? "#FFAB00"
                              : "red",
                          "& + div": {
                            ml: "3px",
                          },
                        }}
                      />
                    );
                  }

                  return null;
                }
              )}
            </Box>
          )}
      </Box>
    );
  };

  const renderCustomHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: any) => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        paddingBottom: "20px",
        borderBottom: "1px solid #f3f2f2",
        mb: "20px",
        mt: "12px",
        px: "1.5rem",
      }}
    >
      <Button
        sx={{
          borderRadius: " 16px 0 0 16px",
          backgroundColor: "#F4F5F8",
          minWidth: "45px",
          borderRight: "1px solid #e4e5e7",
        }}
        onClick={() => {
          setStartDate((current) =>
            moment(current).subtract(1, "months").toDate()
          );
          decreaseMonth();
        }}
        disabled={prevMonthButtonDisabled}
      >
        <PaginationLeftArrowIcon />
      </Button>

      <Button
        sx={{
          borderRadius: "0 16px 16px 0",
          backgroundColor: "#F4F5F8",
          minWidth: "45px",
        }}
        onClick={() => {
          setStartDate((current) => moment(current).add(1, "months").toDate());
          increaseMonth();
        }}
        disabled={nextMonthButtonDisabled}
      >
        <PaginationRightArrowIcon />
      </Button>

      <Typography
        variant="subtitle2"
        sx={{ ml: 2, color: "#172B4D", fontSize: "1rem", fontWeight: 700 }}
      >
        {moment(date).format("MMMM")}{" "}
        <Typography
          variant="subtitle2"
          color="#95A0B8"
          component="span"
          sx={{ fontWeight: 600 }}
        >
          {" "}
          {moment(date).format("YYYY")}
        </Typography>
      </Typography>

      <Box
        ml="auto"
        sx={{
          backgroundColor: "#F4F5F8",
          display: "flex",
          alignItems: "center",
          borderRadius: "16px",
          px: 2,
          py: "3px",
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{ mr: 1, color: "#95A0B8", fontWeight: "600", fontSize: 14 }}
        >
          Show:
        </Typography>
        <Select
          value={statusFilter}
          onChange={handleChange}
          disableUnderline
          variant="standard"
          sx={{
            width: "150px",
            fontSize: 13,
            fontWeight: 500,
            position: "relative",
            top: "1px",
            color: "#172B4D",
          }}
        >
          <MenuItem value="All">
            <Typography
              variant="subtitle2"
              sx={{
                color: "#172B4D",
                fontWeight: "600",
                fontSize: 14,
              }}
            >
              All schedule
            </Typography>
          </MenuItem>
          {Object.values(appointmentStatuses).map((status) => {
            if (status === "Created" || status === "In Progress") {
              return null;
            }
            return (
              <MenuItem
                key={status}
                value={
                  status === "Declined"
                    ? "cancelled"
                    : status === "Non-appearance"
                    ? "patient_no_show"
                    : status
                }
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "#172B4D",
                    fontWeight: "600",
                    fontSize: 14,
                  }}
                >
                  {status}
                </Typography>
              </MenuItem>
            );
          })}
        </Select>
      </Box>
    </Box>
  );

  return (
    <DatePicker
      renderCustomHeader={renderCustomHeader}
      selected={startDate}
      onChange={(date: Date) => setStartDate(date)}
      formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 3)}
      renderDayContents={renderDayContents}
      calendarStartDay={1}
      inline
    />
  );
};

export default Calender;
