import { Button } from "@mui/material";
import { Trans } from "react-i18next";
import { CheckIcon } from "app/assets/icons";
import { useEffect } from "react";

const StyledCheckbox = ({
  title,
  subtitle,
  name,
  state,
  setState,
  ml = 0,
  buttonIcon,
}: any) => {
  const inactiveChoice = {
    background: "#F4F5F8",
    color: "#172B4D",
    fontWeight: 600,
    fontSize: "16px",
    width: "240px",
    height: buttonIcon ? "60px" : "50px",
    marginLeft: ml ? ml : 0,
    textTransform: "none",
    boxShadow: "none",
    justifyContent: "flex-start",
    borderRadius: "12px",
    border: "none",
    padding: "10px",
    ":active": {
      background: "#F4F5F8",
      color: "red",
      boxShadow: "none",
    },
    ":hover": {
      background: "#F4F5F8",
      color: "#172B4D",
      boxShadow: "none",
    },
  };

  const activeChoice = {
    ...inactiveChoice,
    border: "3px solid #88C8EE",
  };

  return (
    <Button
      type="button"
      fullWidth
      variant="contained"
      onClick={() =>
        setState((prev: any) => {
          if (prev.includes(name))
            return prev.filter((element: any) => element != name);
          else return [...prev, name];
        })
      }
      sx={state.includes(name) ? { ...activeChoice } : { ...inactiveChoice }}
    >
      <Trans>
        {buttonIcon && (
          <img
            src={buttonIcon}
            style={{ marginRight: "10px" }}
            alt="Input icon"
          />
        )}
        {title} <br />
        {subtitle ? subtitle : null}
      </Trans>
      <span style={{ margin: "0 auto" }}></span>
      {state.includes(name) ? <CheckIcon /> : <></>}
    </Button>
  );
};

export default StyledCheckbox;
