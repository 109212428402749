import { useState, useEffect, useRef } from "react";
import {
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuList,
} from "@mui/material";

const Menu = ({
  menuButton,
  onToggle,
  children,
  canClickInside = false,
  forceClose = false,
  disabled = false,
  sx,
}: any) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<any>(null);

  const handleToggle = () => {
    setOpen(!open);
    onToggle(!open);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
    onToggle(!open);
  };

  useEffect(() => {
    if (forceClose) {
      setOpen(false);
      onToggle(!open);
    }
  }, [forceClose]);

  function handleListKeyDown(event: any) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
    onToggle(!open);
  }

  return (
    <div style={{ zIndex: 10 }}>
      <Button
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        disabled={disabled}
        sx={{
          padding: 0,
          minWidth: "unset",
          width: "100%",
          height: "100%",
        }}
      >
        {menuButton}
      </Button>
      <Popper
        sx={{ zIndex: 30 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }: any) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper sx={{ borderRadius: "10px" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                  sx={{ mt: "0.75rem", ...sx }}
                  onClick={(e) => !canClickInside && handleClose(e)}
                >
                  {children}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default Menu;
