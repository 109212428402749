import { Link, Typography } from "@mui/material";
import Avatar from "app/assets/images/avatar.png";
import { useNavigate } from "react-router-dom";
import { getStatus } from "utils/getStatus";
import { dateFormat, timeFormat } from "utils/dateFormat";

const SingleAppointment = ({ consultation, cardStyle, isHomePage }: any) => {
  const navigate = useNavigate();
  const { FEStatus, color, background } = getStatus(consultation?.status);

  let displayedTime = consultation?.last_action_date || consultation?.slot;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "1.5rem",
        cursor: "pointer",
      }}
      onClick={() =>
        navigate(`/request/${consultation?.type}/${consultation?.id}`)
      }
    >
      <img
        src={
          consultation?.patient?.avatar_url
            ? consultation?.patient?.avatar_url
            : Avatar
        }
        style={{ width: "3.75rem", height: "3.75rem", borderRadius: "50%" }}
        alt="avatar"
      />
      <div style={{ marginLeft: "1rem" }}>
        <Typography
          variant="body2"
          sx={{
            fontSize: "16px",
            color: "#172B4D",
            fontWeight: 700,
            lineHeight: "1.685rem",
          }}
        >
          {`${consultation?.patient?.first_name} ${consultation?.patient?.last_name}`}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            color: isHomePage ? "#B8D0DB" : "#7F8CA9",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "1.474rem",
          }}
        >
          {dateFormat(displayedTime, "dd/MM/yyyy")} •{" "}
          {timeFormat(displayedTime)}
        </Typography>
      </div>
      <span style={{ margin: "0 auto" }}></span>
      {cardStyle === "status" && (
        <Typography
          sx={{
            color,
            background,
            padding: "5px 10px",
            borderRadius: "20px",
            fontSize: "0.875rem",
            fontWeight: 600,
          }}
        >
          {FEStatus}
        </Typography>
      )}
      {cardStyle == "button" && (
        <button
          onClick={() =>
            navigate(`/request/${consultation?.type}/${consultation?.id}`)
          }
          style={{
            color: "#136588",
            textDecoration: "none",
            background: "#B8D0DB",
            padding: "10px 15px",
            borderRadius: "5px",
            fontWeight: 600,
            border: "none",
            cursor: "pointer",
            fontSize: '15px',
            width: '141px',
            height: '42px'
          }}
        >
          View Request
        </button>
      )}
    </div>
  );
};

export default SingleAppointment;
