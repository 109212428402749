import arrowUp from "app/assets/images/arrow-up.png";
import arrowDown from "app/assets/images/arrow-down.png";
import Card from "generic/Cards/Card";
import { Box, MenuItem, Typography } from "@mui/material";
import Menu from "generic/Menu/Menu";
import { memo, useState } from "react";
import { StatsArrowIcon } from "app/assets/icons";
import Loader from "generic/Loader/loader";

export interface Stat {
  percentage: number;
  count: number;
  title: string;
}

interface IStatsCard {
  stat: Stat | undefined;
  showPeriodFilter?: boolean;
  activePeriod?: string;
  handlePeriodChange?: (period: string) => void;
  xs?: number;
  sm?: number;
  md?: number;
}

const periodMenuItemStyle = {
  fontSize: "0.875rem",
  fontWeight: 500,
  color: "#172B4D",
  textAlign: "center",
  textTransform: "capitalize",
};

const periods = ["year", "month", "week", "day"];

const StatusIndicator = ({ percentage }: { percentage: number }) => {
  const backgroudColor = () => {
    if (percentage === 0) return "#7f8ca91a";
    if (percentage > 0) return "#09c0d10d";
    if (percentage < 0) return "#eb31310d";
  };

  const arrowColor = percentage > 0 ? "#09C0D1" : "#EB3131";

  const arrowDirection = percentage > 0 ? "" : "rotate(180deg)";

  return (
    <Box
      sx={{
        height: "3rem",
        width: "3rem",
        backgroundColor: backgroudColor(),
        borderRadius: "0.625rem",
        display: "inline-grid",
        placeItems: "center",
      }}
    >
      {percentage === 0 ? (
        <Box
          sx={{ backgroundColor: "#172B4D", width: "1rem", height: "0.2rem" }}
        />
      ) : (
        <StatsArrowIcon
          style={{ color: arrowColor, transform: arrowDirection }}
        />
      )}
    </Box>
  );
};

const StatsCard = memo(
  ({
    stat,
    showPeriodFilter = false,
    activePeriod,
    handlePeriodChange,
    xs = 12,
    sm = 4,
    md = 3,
  }: IStatsCard) => {
    if (!stat)
      return (
        <Card
          xs={xs}
          sm={sm}
          md={md}
          sx={{
            height: "15.25rem",
            padding: "2rem",
            display: "inline-grid",
            placeItems: "center",
          }}
        >
          <Loader />
        </Card>
      );

    const { percentage, count, title } = stat;

    const percentageColor = () => {
      if (percentage === 0) return "#172B4D";
      if (percentage > 0) return "#1D93C7";
      if (percentage < 0) return "#CC0000";
    };

    return (
      <Card xs={xs} sm={sm} md={md} sx={{ padding: "2rem" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <StatusIndicator percentage={percentage} />
          <Typography
            style={{
              color: percentageColor(),
              fontSize: "1rem",
              fontWeight: 600,
              marginLeft: "1.313rem",
            }}
          >
            {Math.abs(+percentage.toFixed(2))} %
          </Typography>
          {showPeriodFilter && (
            <Box sx={{ ml: "auto" }}>
              <Menu
                menuButton={
                  <Box
                    sx={{
                      width: "4rem",
                      ...periodMenuItemStyle,
                      fontWeight: 600,
                    }}
                  >
                    {activePeriod}
                  </Box>
                }
              >
                {periods.map((period) => (
                  <MenuItem
                    onClick={() => {
                      handlePeriodChange!(period);
                    }}
                    sx={{ ...periodMenuItemStyle }}
                  >
                    <Typography sx={{ width: "100%" }}>{period}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
        </div>
        <Typography
          style={{
            color: "#172B4D",
            fontSize: "2.25rem",
            fontWeight: 600,
            marginTop: "2.25rem",
            lineHeight: "2.743rem",
          }}
        >
          {count}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            color: "#7F8CA9",
            fontSize: "1.125rem",
            fontWeight: 700,
            lineHeight: "30.33px",
          }}
        >
          {title}
        </Typography>
      </Card>
    );
  }
);

export default StatsCard;
