import * as yup from "yup";
import { Box, Grid, InputLabel, Typography } from "@mui/material";
import PrimaryButton from "generic/Buttons/PrimaryButton";
import InputWithLabel from "generic/Inputs/InputWithLabel";
import { useState } from "react";
import { useDoctorPatientsQuery } from "services/patients";
import DebounceSearch from "../../../../generic/Inputs/DebounceSearch";
import { useReportPatientMutation } from "services/accountSettings";
import { useFormik } from "formik";
import Snackbar from "generic/Snackbar/Snackbar";

type Option = {
  first_name: string;
  last_name: string;
  id: number;
};

const validationSchema = yup.object({
  patientId: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Please enter name of the patient to report"),
  report: yup.string().required("Please describe your problem"),
});

const ReportPatient = () => {
  const [key, setKey] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [msg, setMsg] = useState<string | null>(null);

  const { data: patients, isLoading: isPatientsLoading } =
    useDoctorPatientsQuery(searchQuery);
  const [ReportPatient, { isLoading: isReportPatientLoading }] =
    useReportPatientMutation();

  const formik = useFormik({
    initialValues: {
      patientId: undefined,
      report: "",
    },
    validationSchema: validationSchema,
    onSubmit: (form_data, { resetForm }) => {
      ReportPatient({
        patientId: form_data.patientId!,
        report: form_data.report,
      })
        .then(() => {
          setMsg("Successfully reported the patient");
          setKey(key === 1 ? 2 : 1);
          setSearchQuery("");
          resetForm();
        })
        .catch(() =>
          setMsg(
            "An error occurred while reporting the patient, please try again"
          )
        );
    },
  });

  const onSearch = (query: string) => setSearchQuery(query);
  const onSelect = (value: Option) =>
    formik.setFieldValue("patientId", value?.id || null);

  return (
    <Box sx={{ my: "auto", mx: 0, maxWidth: "100%" }}>
      <Grid container>
        <Grid item xs={12} sm={12} md={6}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              variant="body2"
              sx={{ fontSize: "1rem", fontWeight: 400, color: "#172B4D" }}
            >
              Search for a patient you wish to report and tell us why.
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ mt: "2rem" }}>
                <InputLabel
                  sx={{
                    fontWeight: 600,
                    fontSize: "0.875rem",
                    color: "#172B4D",
                    mb: "0.625rem",
                  }}
                >
                  Patient
                </InputLabel>
                <DebounceSearch
                  key={key}
                  options={patients?.data}
                  getOptionLabel={(option: Option) =>
                    `${option.first_name} ${option.last_name}`
                  }
                  onSearch={onSearch}
                  onSelect={onSelect}
                  isLoading={isPatientsLoading}
                  formik={formik}
                />
              </Box>
              <Box sx={{ mt: "1.5rem" }}>
                <InputWithLabel
                  id="report"
                  name="report"
                  label="Reason"
                  placeholder="Add reason here"
                  rows={4}
                  multiline={true}
                  sx={{ mb: 0 }}
                  value={formik.values.report}
                  onChange={formik.handleChange}
                  error={formik.touched.report && Boolean(formik.errors.report)}
                  helperText={formik.touched.report && formik.errors.report}
                />
              </Box>
              <PrimaryButton
                type="submit"
                title="Report a patient"
                disabled={isReportPatientLoading}
                sx={{
                  boxShadow: "none",
                  margin: "4rem 0 0 0",
                  borderRadius: "0.5rem",
                  fontSize: "1rem",
                }}
              />
              <Snackbar
                open={msg !== null}
                onClose={() => setMsg(null)}
                message={msg}
              />
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReportPatient;
