import styled from "@emotion/styled";
import MuiTab, { TabProps } from "@mui/material/Tab";

const Tab = styled((props: TabProps) => <MuiTab {...props} />)(() => ({
  color: "#95A0B8",
  fontWeight: 600,
  fontSize: "0.875rem",
  minHeight: "unset",
  minWidth: "unset",
  display: "block",
  padding: 0,
  paddingBottom: "1rem",
  paddingTop: "1.5rem",
  "&.MuiTab-root": {
    textTransform: "none",
    "&.Mui-selected": {
      color: "#172B4D",
      borderBottom: "2px solid #136588",
    },
  },
}));

export default Tab;
