import { Box, Typography } from "@mui/material";
import Avatar from "generic/Avatar/Avatar";
import { useNavigate } from "react-router-dom";
import { INotificationWithAvatar } from "services/notifications";
import { renderTimestamp } from "utils/renderTimestamp";

interface INotificationCard {
  notification: INotificationWithAvatar;
}

const NotificationsMenuCard = ({ notification }: INotificationCard) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        background: notification.read ? "fff" : "rgba(184, 208, 219, 0.3)",
        mb: "3px",
        px: "1rem",
        py: "0.625rem",
        width: "100%",
        display: "flex",
      }}
      onClick={() =>
        navigate(
          `/request/${notification.payload.type}/${notification.payload.id}`
        )
      }
    >
      <Box sx={{ marginRight: "0.75rem" }}>
        <Avatar src={notification.patient_avatar_url} size="2.5rem" />
      </Box>
      <Box sx={{ display: "flex", flexGrow: 1 }}>
        <Box sx={{ maxWidth: "300px" }}>
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 400,
              fontSize: "1rem",
              height: "3rem",
              lineHeight: "1.5rem",
              color: "#172B4D",
              width: "100%",
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
              wordWrap: "break-word",
              wordBreak: "break-word",
            }}
          >
            {notification.body}
          </Typography>
          {/* <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 700,
              fontSize: "1rem",
              lineHeight: "1.685rem",
              color: "#172B4D",
            }}
          >
            {`${notification.payload.patient_first_name} ${notification.payload.patient_last_name}`}
          </Typography> */}
        </Box>
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: 400,
            fontSize: "0.75rem",
            color: "#B8D0DB",
            ml: "1.75rem",
            mt: "0.313rem",
            flexGrow: 1,
            textAlign: "right",
          }}
        >
          {renderTimestamp(notification.created_at)}
        </Typography>
      </Box>
    </Box>
  );
};

export default NotificationsMenuCard;
