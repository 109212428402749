import { useState, memo } from "react";
import { Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandIcon from "./icons/ExpandIcon";
import SidebarIcon from "./icons/SidebarIcon";
import sideImage1 from "app/assets/images/sidebar-icons/1.svg";
import sideImage2 from "app/assets/images/sidebar-icons/2.svg";
import sideImage3 from "app/assets/images/sidebar-icons/3.svg";
import sideImage4 from "app/assets/images/sidebar-icons/4.svg";
import sideImage5 from "app/assets/images/sidebar-icons/5.svg";
import sideImage6 from "app/assets/images/sidebar-icons/6.svg";
import sideImage7 from "app/assets/images/sidebar-icons/7.svg";
import sideImage8 from "app/assets/images/sidebar-icons/8.svg";
import sideImage9 from "app/assets/images/sidebar-icons/9.svg";
import sideImage10 from "app/assets/images/sidebar-icons/10.svg";
import { urlRole } from "services";

const SidebarContainer = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100%",
  background: "#136588",
  padding: "10px",
  borderRadius: "20px",
  width: "90px",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const Sidebar = memo(({ setExpandedBar, unReadMessagesCount }: any) => {
  return (
    <SidebarContainer>
      <Grid container item direction="column">
        <SidebarIcon
          image={sideImage1}
          setExpandedBar={setExpandedBar}
          index={0}
        />
        <SidebarIcon image={sideImage2} index={1} navigationURL={"/"} />
        <SidebarIcon
          image={sideImage3}
          index={2}
          navigationURL={"/request-management"}
        />
        <SidebarIcon
          image={sideImage4}
          index={3}
          navigationURL={"/messages"}
          unReadMessagesCount={unReadMessagesCount}
        />
        <SidebarIcon image={sideImage5} index={4} navigationURL={"/revenue"} />
        {urlRole == "doctor" && (
          <SidebarIcon
            image={sideImage6}
            index={5}
            navigationURL={"/emergency-appointments"}
          />
        )}
        {urlRole == "doctor" && (
          <SidebarIcon
            image={sideImage7}
            index={6}
            navigationURL={"/calendar"}
          />
        )}
        <SidebarIcon image={sideImage8} index={7} navigationURL={"/insights"} />

        <SidebarIcon
          image={sideImage10}
          index={8}
          navigationURL={
            urlRole == "organization"
              ? "/profile-settings?tabId=2"
              : "/profile-settings?tabId=0"
          }
        />

        <div style={{ margin: "auto" }}></div>
        <SidebarIcon
          image={sideImage9}
          index={9}
          navigationURL={"/account-settings?tabId"}
        />
      </Grid>
    </SidebarContainer>
  );
});

export default Sidebar;
