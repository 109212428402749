import { Box, Grid, Typography } from "@mui/material";
import { CancelledIcon, TrueIcon } from "app/assets/icons";
import moment from "moment";

const Status: {
  [key: string]: {
    name: string;
    fontColor: string;
    backgroundColor: string;
    icon: JSX.Element;
  };
} = {
  completed: {
    name: "Completed",
    fontColor: "#14C38E",
    backgroundColor: "#14c38e1a",
    icon: <TrueIcon style={{ color: "#14C38E" }} />,
  },
  cancelled: {
    name: "Cancelled",
    fontColor: "#EB3131",
    backgroundColor: "#eb31311a",
    icon: <CancelledIcon style={{ color: "#EB3131" }} />,
  },
  upcoming: {
    name: "Upcoming",
    fontColor: "#1D93C7",
    backgroundColor: "#1d93c71a",
    icon: <TrueIcon style={{ color: "#1D93C7" }} />,
  },
  others: {
    name: "In progress",
    fontColor: "#FFAB00",
    backgroundColor: "#ffab001a",
    icon: <TrueIcon style={{ color: "#FFAB00" }} />,
  },
};

interface ITransactionCard {
  first_name: string;
  last_name: string;
  consultation_status: string;
  metadata: {
    application_fees: number;
    consultation_fees: number;
    stripe_fees: number;
  };
  created_at: string;
}

const StatusBadge = ({ status }: { status: string }) => {
  const targetStatus = Status[status] || Status.others;
  return (
    <Box
      sx={{
        height: "1.75rem",
        display: "flex",
        alignItems: "center",
        px: "0.625rem",
        borderRadius: "1.25rem",
        backgroundColor: targetStatus.backgroundColor,
      }}
    >
      <Typography
        sx={{
          fontSize: "0.875rem",
          fontWeight: 600,
          color: targetStatus.fontColor,
        }}
      >
        {targetStatus.name}
      </Typography>
    </Box>
  );
};

const StatusIcon = ({ status }: { status: string }) => {
  const targetStatus = Status[status] || Status.others;
  return (
    <Box
      sx={{
        height: "1.75rem",
        width: "1.75rem",
        display: "inline-grid",
        placeItems: "center",
        borderRadius: "1.25rem",
        backgroundColor: targetStatus.backgroundColor,
      }}
    >
      {targetStatus.icon}
    </Box>
  );
};

const TransactionCard = ({
  first_name,
  last_name,
  metadata,
  consultation_status,
  created_at,
}: ITransactionCard) => {
  const dateTimeConverter = moment(created_at);
  const creationDate = () => {
    const formatted = dateTimeConverter.format("M/D/YYYY");
    if (formatted === moment().format("M/D/YYYY")) return "Today";
    if (formatted === moment().subtract(1, "days").format("M/D/YYYY"))
      return "Yesterday";
    return formatted;
  };
  const creationTime = dateTimeConverter.format("h:mmA");

  return (
    <Grid container sx={{ height: "4.25rem" }}>
      <Grid
        item
        xs={3}
        sm={3}
        md={6}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "0.75rem",
          pl: "1rem",
        }}
      >
        <StatusIcon status={consultation_status} />
        <Box>
          <Typography
            variant="subtitle2"
            sx={{
              color: "#172B4D",
              fontWeight: 700,
              fontSize: "0.875rem",
              lineHeight: "1.5rem",
            }}
          >
            Payment from {first_name} {last_name}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ color: "#7F8CA9", fontWeight: 400, fontSize: "0.75rem" }}
          >
            {creationDate()}, {creationTime}
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={3}
        sm={3}
        md={2}
        sx={{ display: "inline-grid", placeItems: "center", color: "#172B4D" }}
      >
        <Typography sx={{ fontSize: "0.875rem", fontWeight: 600 }}>
          + €{metadata.consultation_fees / 100}
        </Typography>
      </Grid>
      <Grid
        item
        xs={3}
        sm={3}
        md={2}
        sx={{ display: "inline-grid", placeItems: "center", color: "#EB3131" }}
      >
        <Typography sx={{ fontSize: "0.875rem", fontWeight: 600 }}>
          - €{metadata.application_fees / 100}
        </Typography>
      </Grid>
      <Grid
        item
        xs={3}
        sm={3}
        md={2}
        sx={{ display: "inline-grid", placeItems: "center", color: "#172B4D" }}
      >
        <StatusBadge status={consultation_status} />
      </Grid>
    </Grid>
  );
};

export default TransactionCard;
