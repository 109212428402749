import { useState } from "react";
import { Typography, Link } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { Trans } from "react-i18next";
import PrimaryButton from "generic/Buttons/PrimaryButton";
import InputWithLabel from "generic/Inputs/InputWithLabel";
import { changePassword } from "../service";
import { useAppSelector, useAppDispatch } from "app/store/hooks";
import { isLoading, setLoading } from "app/store/genericSlice";
import { EyeIcon } from "app/assets/icons";
import { sendConfirmationCode } from "../service";
import Snackbar from "generic/Snackbar/Snackbar";

const validationSchema = yup.object({
  confirmation_code: yup
    .string()
    .required("Confirmation code is required.")
    .test("len", "Must be exactly 6 characters", (val) => val?.length === 6),
  password: yup
    .string()
    .required("Password is required.")
    .matches(
      /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/,
      "Password must be at least 8 charcters and have: 1 uppercase, 1 lowercase, 1 number and 1 special character."
    ),
  confirm_password: yup
    .string()
    .required("Confirm Password is required.")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const ChangePassword = ({ setCurrentStep, email, setPassword }: any) => {
  const [type, setType] = useState("password");
  const [type2, setType2] = useState("password");
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const loading = useAppSelector(isLoading);
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      confirmation_code: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      setPassword(values.password);
      handleChangePassword(values.password, values.confirmation_code);
    },
  });

  const handleResendCode = async () => {
    try {
      const result = await sendConfirmationCode(email);
      setIsError(true);
      setErrorMsg("Code resent successfully");
    } catch (e: any) {
      dispatch(setLoading(false));
      setIsError(true);
      setErrorMsg(e.message);
    }
  };

  const handleChangePassword = async (
    password: string,
    confirmationCode: string
  ) => {
    try {
      dispatch(setLoading(true));
      await changePassword(email, confirmationCode, password);
      dispatch(setLoading(false));
      setCurrentStep((prevStep: number) => ++prevStep);
    } catch (e: any) {
      dispatch(setLoading(false));
      setIsError(true);
      setErrorMsg(e.message);
    }
  };

  return (
    <form
      style={{ margin: "auto 0", maxWidth: "60%" }}
      onSubmit={formik.handleSubmit}
    >
      <Typography
        sx={{ fontWeight: 600, fontSize: "32px", color: "#172B4D", mb: 3 }}
      >
        Change Your Password
      </Typography>

      <Link
        variant="body2"
        sx={{
          color: "#136588",
          textDecoration: "none",
          fontWeight: 600,
          float: "right",
          cursor: "pointer",
        }}
        onClick={handleResendCode}
      >
        <Trans>Resend Code?</Trans>
      </Link>

      <InputWithLabel
        label="Confirmation Code*"
        id="confirmation_code"
        name="confirmation_code"
        type="text"
        placeholder="Enter Confirmation Code"
        autoFocus={true}
        value={formik.values.confirmation_code}
        onChange={(e: any) => {
          const isNumber = /^[0-9]$/i.test(
            e.target.value[e.target.value.length - 1]
          );
          if (isNumber || e.target.value == "") formik.handleChange(e);
        }}
        error={
          formik.touched.confirmation_code &&
          Boolean(formik.errors.confirmation_code)
        }
        helperText={
          formik.touched.confirmation_code && formik.errors.confirmation_code
        }
      />

      <InputWithLabel
        label="Password*"
        id="password"
        name="password"
        type={type}
        endIcon={<EyeIcon />}
        endIconOnClick={() =>
          setType((prevType: string) =>
            prevType === "password" ? "text" : "password"
          )
        }
        placeholder="Enter Password"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
      />

      <InputWithLabel
        label="Confirm Password*"
        id="confirm_password"
        name="confirm_password"
        type={type2}
        endIcon={<EyeIcon />}
        endIconOnClick={() =>
          setType2((prevType: string) =>
            prevType === "password" ? "text" : "password"
          )
        }
        placeholder="Re Enter Password"
        value={formik.values.confirm_password}
        onChange={formik.handleChange}
        error={
          formik.touched.confirm_password &&
          Boolean(formik.errors.confirm_password)
        }
        helperText={
          formik.touched.confirm_password && formik.errors.confirm_password
        }
      />

      <PrimaryButton
        title="Next"
        type="submit"
        // disabled={!isValid}
        // loading={loading}
        // onClick={handleChangePassword}
      />

      <Snackbar
        open={isError}
        onClose={() => setIsError(false)}
        message={errorMsg}
      />
    </form>
  );
};

export default ChangePassword;
