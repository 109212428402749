import { Link, Badge } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "app/store/hooks";
import { activateOne } from "../../layoutSlice";

const SidebarIcon = ({
  image,
  setExpandedBar,
  index,
  navigationURL,
  unReadMessagesCount,
}: any) => {
  const isActive = useAppSelector((state) => state.layout.isActive);
  const dispatch = useAppDispatch();

  let navigate = useNavigate();

  const handleClickIcon = () => {
    dispatch(activateOne(index));
    navigate(navigationURL);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "60px",
      }}
    >
      <Link
        href="#"
        onClick={() =>
          setExpandedBar
            ? setExpandedBar((prev: boolean) => !prev)
            : handleClickIcon()
        }
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: "12px",
          background: isActive[index] ? "rgba(255, 255, 255, 0.2)" : "#136588",
          borderRadius: "10px",
        }}
      >
        <Badge
          color="error"
          invisible={unReadMessagesCount > 0 ? false : true}
          badgeContent={unReadMessagesCount}
        >
          <img src={image} alt="sideImage1" />
        </Badge>
      </Link>
    </div>
  );
};

export default SidebarIcon;
