import { useState } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "app/store/hooks";
import { isLoading, setLoading } from "app/store/genericSlice";
import PrimaryButton from "generic/Buttons/PrimaryButton";
import { useDeleteAccountMutation } from "services/accountSettings";
import Modal from "generic/Modals/Modal";
import Snackbar from "generic/Snackbar/Snackbar";

const DeleteAccount = () => {
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] =
    useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [deleteAccount, result] = useDeleteAccountMutation();

  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  const handleDeletingAccount = async () => {
    try {
      dispatch(setLoading(false));
      const deleteResult: any = await deleteAccount(1);
      // await Auth.deleteUser();
      dispatch(setLoading(false));
      console.log({ deleteResult });
      if (deleteResult?.error?.error?.status == 403) {
        setIsError(true);
        setErrorMsg(deleteResult?.error?.error?.data?.message);
        setIsDeleteAccountModalOpen(false);
      } else {
        setIsError(true);
        setErrorMsg("Successfully deleted your Account");
        localStorage.clear();
        navigate("/login");
      }
    } catch (error) {
      console.log("Error deleting user", error);
    }
  };

  return (
    <form style={{ margin: "auto 0", maxWidth: "100%" }}>
      <p style={{ color: "#172B4D", fontSize: "16px", fontWeight: 600 }}>
        Warning: Deleting your account is an irreversible operation
      </p>

      <PrimaryButton
        sx={{ fontSize: "16px", fontWeight: 600 }}
        title="Delete account"
        onClick={() => setIsDeleteAccountModalOpen(true)}
      />
      <Snackbar
        open={isError}
        onClose={() => setIsError(false)}
        message={errorMsg}
      />
      <Modal
        open={isDeleteAccountModalOpen}
        setOpen={setIsDeleteAccountModalOpen}
        primaryTitle="Delete"
        secondaryTitle="Cancel"
        primaryOnclick={handleDeletingAccount}
      >
        Are you sure you want to delete your account?
      </Modal>
    </form>
  );
};

export default DeleteAccount;
