import api from "./index";

export const accountSettings = api.injectEndpoints({
  endpoints: (builder) => ({
    getFaqs: builder.query<any, string | void>({
      query: (locale = "en") => `/faqs?locale=${locale}&page=-1`,
      providesTags: () => [{ type: "FAQs" }],
    }),
  }),
});

export const { useGetFaqsQuery } = accountSettings;
