import { noHeadersApi } from "./noHeaders";

export const countriesApi = noHeadersApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllCountries: builder.query({
      query: () => {
        return {
          url: "/api/v1/countries",
          headers: {
            "accept-language": `en`
          }
        }
      }
    }),
    getAllStates: builder.query({
      query: ({ countryId }) => {
        return {
          url: `/api/v1/states?filters[country_id]=${countryId}`,
          headers: {
            "accept-language": `en`
          }
        }
      }
    }),
    getAllCities: builder.query({
      query: ({ countryId, cityId }) => {
        return {
          url: `/api/v1/cities?filters[country_id]=${countryId}&filters[state_id]=${cityId}`,
          headers: {
            "accept-language": `en`
          }
        }
      }
    }),
  }),
});

export const {
  useGetAllCountriesQuery,
  useLazyGetAllStatesQuery,
  useLazyGetAllCitiesQuery,
} = countriesApi;
