import { useState, useEffect } from "react";
import {
  FormGroup,
  FormControlLabel,
  Typography,
  Box,
  Checkbox,
} from "@mui/material";
import { isLoading, setLoading } from "app/store/genericSlice";
import { useAppSelector, useAppDispatch } from "app/store/hooks";
import { isEmailExist } from "../service";
import InputWithLabel from "generic/Inputs/InputWithLabel";
import PrimaryButton from "generic/Buttons/PrimaryButton";
import BasicLink from "generic/Links/BasicLink";
import Text from "generic/Text/Text";
import { EmailIcon } from "app/assets/icons";
import Snackbar from "generic/Snackbar/Snackbar";
import { useLazyCheckEmailQuery } from "services/noHeaders";

const CreateNewAccount = ({ setCurrentStep, email, setEmail }: any) => {
  const loading = useAppSelector(isLoading);
  const dispatch = useAppDispatch();

  const [termsChecked, setTermsChecked] = useState(false);
  const [concentChecked, setConcentChecked] = useState(false);
  const [receiveChecked, setReceivedChecked] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [checkEmail] = useLazyCheckEmailQuery();

  let validateEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  useEffect(() => {
    if (
      validateEmail.test(email) &&
      termsChecked &&
      concentChecked &&
      receiveChecked
    )
      setIsValid(true);
    else setIsValid(false);
  }, [email, termsChecked, concentChecked, receiveChecked]);

  const handleVerifyingEmail = async () => {
    dispatch(setLoading(true));
    const result = await checkEmail(email);
    dispatch(setLoading(false));
    if (!result?.isError) {
      setCurrentStep((prevStep: number) => ++prevStep);
    } else {
      setIsError(true);
      setErrorMsg("Email already exists.");
    }
  };

  const CheckBoxLabel1 = () => (
    <p>
      I agree to the{" "}
      <BasicLink title={" terms of use "} href={"/terms?tabId=0"} /> of Leviomed
      GmbH.
    </p>
  );

  const CheckBoxLabel2 = () => (
    <p>
      I agree to the{" "}
      <BasicLink title={" Privacy Policy "} href={"/terms?tabId=1"} /> for of
      Leviomed GmbH.
    </p>
  );

  const CheckBoxLabel3 = () => (
    <p>
      I agree to receive reminders about my appointments and news from Leviomed
      by e-mail.
    </p>
  );

  return (
    <Box sx={{ my: "auto", mx: 0, maxWidth: "60%" }}>
      <Text
        sx={{ fontWeight: 600, fontSize: "32px", color: "#172B4D" }}
        label="Create New Account"
      />
      {/* <Text
        sx={{ mb: 4, fontWeight: 400, fontSize: "14px", color: "#172B4D" }}
        label="Lorem ipsum dolor sit amet, consectetur adipiscing elit sed laoreet."
      /> */}

      <InputWithLabel
        label="Email Address*"
        id="email"
        name="email"
        type="email"
        placeholder="Enter Email Address"
        autoFocus={true}
        startIcon={<EmailIcon />}
        value={email}
        onChange={(e: any) => setEmail(e.target.value)}
      />

      <FormGroup sx={{ mb: 1 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={termsChecked}
              onChange={() => setTermsChecked((prev) => !prev)}
            />
          }
          disableTypography
          label={<CheckBoxLabel1 />}
        />
      </FormGroup>

      <FormGroup sx={{ mb: 1 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={concentChecked}
              onChange={() => setConcentChecked((prev) => !prev)}
            />
          }
          disableTypography
          label={<CheckBoxLabel2 />}
        />
      </FormGroup>

      <FormGroup sx={{ mb: 1 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={receiveChecked}
              onChange={() => setReceivedChecked((prev) => !prev)}
            />
          }
          disableTypography
          label={<CheckBoxLabel3 />}
        />
      </FormGroup>

      <PrimaryButton
        title="Next"
        type="button"
        disabled={!isValid}
        loading={loading}
        onClick={handleVerifyingEmail}
      />

      <Snackbar
        open={isError}
        onClose={() => setIsError(false)}
        message={errorMsg}
      />

      <Typography sx={{ color: "#172B4D", fontWeight: 400 }}>
        Already have an account? Back to
        <BasicLink title={" Log In "} href={"/login"} />
        page.
      </Typography>
    </Box>
  );
};

export default CreateNewAccount;
