import { Typography } from "@mui/material";
import { getStatus } from "utils/getStatus";

const DisplayStatus = ({ status }: any) => {
  const { FEStatus, color, background } = getStatus(status);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
      }}
    >
      <Typography
        style={{
          color: "#172B4D",
          fontSize: "16px",
          fontWeight: 600,
          marginRight: "10px",
        }}
      >
        Appointment Status
      </Typography>
      <Typography
        style={{
          color,
          background,
          padding: "5px 10px",
          borderRadius: "20px",
          fontWeight: 500,
        }}
      >
        {FEStatus}
      </Typography>
    </div>
  );
};

export default DisplayStatus;
