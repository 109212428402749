import { urlRole } from "services";

export const clinicTabData = {
  region: urlRole === "doctor" ? "clinic_region" : "region",
  link: urlRole === "doctor" ? "clinic_link" : "link",
  address1: urlRole === "doctor" ? "clinic_address1" : "address1",
  address2: urlRole === "doctor" ? "clinic_address2" : "address2",
  type: urlRole === "doctor" ? "clinic_type" : "organization_types",
  location: urlRole === "doctor" ? "clinic_location" : "location",
};

export const profileDataInterface = {
  pictures: urlRole === "doctor" ? "clinic_pictures" : "pictures",
  videos: urlRole === "doctor" ? "clinic_videos" : "videos",
};
