import Card from "generic/Cards/Card";
import { Link, Typography } from "@mui/material";
import calendarImg from "app/assets/images/calendar.svg";
import { RightArrowIcon } from "app/assets/icons";

const ManageAppointmentCard = () => {
  return (
    <Card sx={{ background: "#136588", display: "flex", alignItems: "center" }}>
      <img
        src={calendarImg}
        alt="calendar image"
        style={{ marginRight: "15px" }}
      />
      <div>
        <Typography
          style={{ color: "white", fontSize: "24px", fontWeight: 600 }}
        >
          Manage appointments preferred time
        </Typography>
        {/* <Typography
          variant="subtitle2"
          style={{
            color: "white",
            fontSize: "14px",
            marginTop: "15px",
            fontWeight: 700,
          }}
        >
          Lorem ipsum dolor sit kuco ameto
        </Typography> */}
      </div>
      <Link
        href="/clinic-settings"
        variant="body2"
        style={{ marginLeft: "100px" }}
      >
        <RightArrowIcon />
      </Link>
    </Card>
  );
};

export default ManageAppointmentCard;
