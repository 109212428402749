import { Box, Button, Typography } from "@mui/material";
import Avatar from "generic/Avatar/Avatar";
import { useNavigate } from "react-router-dom";
import { INotification } from "services/notifications";
import { renderTimestamp } from "utils/renderTimestamp";

interface INotificationWithAvatar extends INotification {
  patient_avatar_url: string;
}

interface INotificationCard {
  notification: INotificationWithAvatar;
}

const NotificationCard = ({ notification }: INotificationCard) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        height: "5.25rem",
        px: "1.5rem",
        py: "0.75rem",
        display: "flex",
        flexWrap: "wrap",
        // alignItems: "center",
        background: notification.read ? "fff" : "rgba(184, 208, 219, 0.3)",
      }}
    >
      <Box sx={{ mr: "1rem", mb: { xs: "0.5rem", sm: "0.5rem" } }}>
        <Avatar src={notification.patient_avatar_url} size="3.75rem" />
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            paddingTop: "0.5rem",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: "400",
              fontSize: "1rem",
              color: "#172B4D",
              mr: "0.2rem",
              lineHeight: "1.685rem",
            }}
          >
            {notification.body}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "400",
              fontSize: "0.875rem",
              color: "#7F8CA9",
              mr: "0.2rem",
              lineHeight: "1.474rem",
            }}
          >
            {renderTimestamp(notification.created_at)}
          </Typography>
        </Box>
      </Box>
      <Button
        onClick={() =>
          navigate(
            `/request/${notification.payload.type}/${notification.payload.id}`
          )
        }
        sx={{
          background: notification.read ? "#035578" : "#FFFFFF",
          color: notification.read ? "#FFFFFF" : "#035578",
          height: "2.625rem",
          width: "8.813rem",
          fontWeight: 600,
          fontSize: "0.938rem",
          textTransform: "none",
          ml: "auto",
          mt: "9px",
          borderRadius: "0.5rem",
          ":hover": {
            background: notification.read ? "#FFFFFF" : "#035578",
            color: notification.read ? "#035578" : "#FFFFFF",
          },
        }}
      >
        View Request
      </Button>
    </Box>
  );
};

export default NotificationCard;
