import { Box, Button, Typography } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Avatar from "app/assets/images/avatar.png";

interface IEmergencyAppointmentCard {
  id: number;
  avatarUrl: string;
  patient: {
    firstName: string;
    lastName: string;
  };
  createdAt: string;
}

const EmergencyAppointmentCard = ({
  id,
  avatarUrl,
  patient,
  createdAt,
}: IEmergencyAppointmentCard) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          mr: "0.75rem",
          height: "4.375rem",
          width: "4.375rem",
          border: "2px solid #EB3131",
          borderRadius: "50%",
          display: "inline-grid",
          placeItems: "center",
        }}
      >
        <img
          src={avatarUrl || Avatar}
          alt="avatar"
          style={{
            height: "3.75rem",
            width: "3.75rem",
            borderRadius: "50%",
          }}
        />
      </Box>
      <Box>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: "700",
              fontSize: "1rem",
              color: "#172B4D",
              lineHeight: "1.685rem",
            }}
          >
            {patient.firstName} {patient.lastName}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: "400",
              fontSize: "0.875rem",
              color: "#7F8CA9",
              mr: "0.2rem",
              display: "flex",
              alignItems: "center",
              lineHeight: "1.474rem",
            }}
          >
            {`${moment(createdAt).format("M/D/YYYY")} • ${moment(
              createdAt
            ).format("h:mmA")}`}
          </Typography>
        </Box>
      </Box>
      <Button
        onClick={() => navigate(`/request/EmergencyConsultation/${id}`)}
        sx={{
          backgroundColor: "#B8D0DB",
          color: "#136588",
          height: "2.625rem",
          width: "8.813rem",
          fontWeight: 600,
          fontSize: "0.938rem",
          textTransform: "none",
          ml: "auto",
          borderRadius: "0.5rem",
          ":hover": {
            background: "#035578",
            color: "#FFFFFF",
          },
        }}
      >
        View Request
      </Button>
    </Box>
  );
};

export default EmergencyAppointmentCard;
