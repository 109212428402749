import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Layout from "modules/layout/Layout";
import Card from "generic/Cards/Card";
import { useGetEmergencyAppointmentsQuery } from "services/emergencyAppointments";
import { useState } from "react";
import Pagination from "generic/Pagination/Pagination";
import EmergencyAppointmentCard from "./components/EmergencyAppointmentCard";

const perPage = 7;

const EmergencyAppointments = () => {
  const { t } = useTranslation();
  const [currPage, setCurrPage] = useState<number>(1);
  const {
    data: response,
    isLoading,
    isSuccess,
  } = useGetEmergencyAppointmentsQuery({ page: currPage, perPage });

  return (
    <Layout title={t("Emergency Appointments", "Emergency Appointments")}>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          pt: "0.625rem",
        }}
      >
        <Card
          sx={{
            width: "100%",
            height: "100%",
            border: 0,
            padding: "1.5rem",
          }}
        >
          <Box sx={{ display: "flex", mb: "2.125rem" }}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "1.125rem",
                lineHeight: "1.375rem",
                color: "#172B4D",
              }}
            >
              Emergency Requests
            </Typography>

            <Box sx={{ ml: "auto" }}>
              {response?.consultations?.length ? (
                <Pagination
                  perPage={perPage}
                  currPage={currPage}
                  onChange={setCurrPage}
                  pagination={response?.pagination}
                />
              ) : (
                <></>
              )}
            </Box>
          </Box>
          {isSuccess && (
            <>
              {response?.consultations?.length ? (
                <Grid container direction="column" rowSpacing="1.5rem">
                  {response.consultations.map((appointment: any) => (
                    <Grid item>
                      <EmergencyAppointmentCard
                        id={appointment.id}
                        avatarUrl={appointment.patient.avatar_url}
                        patient={{
                          firstName: appointment.patient.first_name,
                          lastName: appointment.patient.last_name,
                        }}
                        createdAt={appointment.created_at}
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Box
                  sx={{
                    height: "80%",
                    width: "100%",
                    display: "inline-grid",
                    placeItems: "center",
                    color: "#7F8CA9",
                    fontSize: "1.5rem",
                  }}
                >
                  No Emergency Requests
                </Box>
              )}
            </>
          )}
        </Card>
      </Box>
    </Layout>
  );
};

export default EmergencyAppointments;
