import { Box, MenuItem } from "@mui/material";
import Loader from "generic/Loader/loader";
import NoContentCard from "generic/NoContentCard/NoContentCard";
import { INotificationWithAvatar } from "services/notifications";
import NotificationsMenuCard from "./NotificationsMenuCard";

interface INotificationsList {
  notifications: INotificationWithAvatar[] | undefined;
}

const NotificationsList = ({ notifications }: INotificationsList) => {
  if (notifications === undefined)
    return (
      <Box
        sx={{
          width: "30rem",
          height: "10rem",
          display: "inline-grid",
          placeItems: "center",
        }}
      >
        <Loader />
      </Box>
    );

  if (notifications.length === 0)
    return (
      <Box
        sx={{
          width: "30rem",
          height: "5rem",
          display: "inline-grid",
          placeItems: "center",
        }}
      >
        <NoContentCard message="No Notifications" />
      </Box>
    );

  return (
    <Box
      sx={{ maxHeight: "50vh", overflowY: "auto", paddingBottom: "0.75rem" }}
    >
      {notifications?.map((notification) => {
        return (
          <MenuItem sx={{ p: 0 }}>
            <NotificationsMenuCard notification={notification} />
          </MenuItem>
        );
      })}
    </Box>
  );
};

export default NotificationsList;
