import { MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Menu from "generic/Menu/Menu";
import { ProfileIcon, SettingsIcon, SignOutIcon } from "app/assets/icons";
import Avatar from "app/assets/images/avatar.png";

const OptionsMenu = ({ avatarURL, setIsLogoutModalOpen }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Menu
      menuButton={
        <img
          src={avatarURL ? avatarURL : Avatar}
          alt="user avatar"
          style={{
            width: "56px",
            height: "56px",
            borderRadius: "50%",
          }}
        />
      }
    >
      <MenuItem
        onClick={() => navigate("/profile-settings")}
        sx={{
          fontWeight: 600,
          fontSize: "14px",
          color: "#172B4D",
        }}
      >
        <ProfileIcon style={{ marginRight: "10px" }} />
        {t("Profile", "Profile")}
      </MenuItem>
      <MenuItem
        onClick={() => navigate("/account-settings")}
        sx={{
          fontWeight: 600,
          fontSize: "14px",
          color: "#172B4D",
        }}
      >
        <SettingsIcon style={{ marginRight: "10px" }} />
        {t("Settings", "Settings")}
      </MenuItem>
      <MenuItem
        onClick={() => setIsLogoutModalOpen(true)}
        sx={{
          fontWeight: 600,
          fontSize: "14px",
          color: "#172B4D",
        }}
      >
        <SignOutIcon style={{ marginRight: "10px" }} />
        {t("Log Out", "Log Out")}
      </MenuItem>
    </Menu>
  );
};

export default OptionsMenu;
