export enum appointmentStatuses {
  CREATED = "Created",
  UPCOMING = "Upcoming",
  IN_PROGRESS = "In Progress",
  COMPLETED = "Completed",
  DECLINED = "Declined",
  NO_SHOW = "Non-appearance",
}

export enum appointmentType {
  VideoConsultation = "VideoConsultation",
  WrittenConsultation = "WrittenConsultation",
  EmergencyConsultation = "EmergencyConsultation",
}
