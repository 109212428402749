import { Box, Typography } from "@mui/material";

interface INoContentCard {
  message: string;
}

const NoContentCard = ({ message }: INoContentCard) => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography sx={{ color: "#7F8CA9", fontSize: "1.5rem" }}>
        {message}
      </Typography>
    </Box>
  );
};

export default NoContentCard;
