import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  borderBottom: '1px solid #F4F5F7',
  padding: '1rem 0',
  '&:before': {
    height: 0,
  }
}));

export default Accordion;