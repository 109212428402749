import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";
import FaqsImg from "app/assets/images/faq.svg";
import { useGetFaqsQuery } from "services/faqs";
import Accordion from "./Accordion";
import AccordionSummary from "./AccordionSummary";
import AccordionDetails from "./AccordionDetails";

interface Faq {
  id: number;
  question: string;
  answer: string;
}

const FAQ = () => {
  const { data: response } = useGetFaqsQuery();
  const faqs: Faq[] = response?.data;

  if (!faqs) return <></>;
  return (
    <Box sx={{ height: "92%", display: "flex", flexDirection: "column" }}>
      <Box>
        <img src={FaqsImg} alt="faqs" />
      </Box>
      <Grid container>
        <Grid item xs={12} sm={12} md={7}>
          {faqs.map(({ id, question, answer }) => (
            <Accordion key={id}>
              <AccordionSummary>
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: "18px", fontWeight: 700 }}
                >
                  {question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2" sx={{ fontSize: "16px" }}>
                  {answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default FAQ;
