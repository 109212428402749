import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import Layout from "modules/layout/Layout";
import Card from "generic/Cards/Card";
import Views from "./components/views/Views";
import SearchFilters from "./components/SearchFilters";
import Reviews from "./components/reviews/Reviews";
import Loader from "generic/Loader/loader";
import { useViewsAndFiltersQuery } from "services/insights";

const Insights = () => {
  const { t: translate } = useTranslation();

  const { data: insights, isLoading } = useViewsAndFiltersQuery();

  return (
    <Layout title={translate("Insights", "Insights")}>
      <Grid container direction="row" sx={{ height: "100%" }}>
        <Grid item md={6} sm={12} xs={12}>
          <Card sx={{ height: "100%" }}>
            <Reviews />
          </Card>
        </Grid>
        <Grid
          item
          md={6}
          sm={12}
          xs={12}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Card sx={{ width: "100%", minHeight: "14.875rem" }}>
            {isLoading ? (
              <Loader />
            ) : (
              <Views
                visits_count={insights?.visits_count || 0}
                this_month_increase_percent={
                  insights?.this_month_increase_percent || 0
                }
                monthly_visits={insights?.monthly_visits || []}
              />
            )}
          </Card>
          <Card sx={{ flexGrow: 1, width: "100%" }}>
            {isLoading ? (
              <Loader />
            ) : (
              <SearchFilters filters={insights?.filters} />
            )}
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Insights;
