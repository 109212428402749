import { Box, Grid } from "@mui/material";
import Loader from "generic/Loader/loader";
import NoContentCard from "generic/NoContentCard/NoContentCard";
import { INotificationWithAvatar } from "services/notifications";
import NotificationCard from "./NotificationCard";

interface INotificationsList {
  notifications: INotificationWithAvatar[] | undefined;
}

const NotificationsList = ({ notifications }: INotificationsList) => {
  if (notifications === undefined)
    return (
      <Box
        sx={{
          width: "100%",
          height: "50vh",
          display: "inline-grid",
          placeItems: "center",
        }}
      >
        <Loader />
      </Box>
    );

  if (notifications.length === 0)
    return (
      <Box
        sx={{
          width: "100%",
          height: "50vh",
          display: "inline-grid",
          placeItems: "center",
        }}
      >
        <NoContentCard message="No Notifications" />
      </Box>
    );

  return (
    <Grid container direction="column" rowSpacing="1px">
      {notifications.map((notification) => (
        <Grid item>
          <NotificationCard notification={notification} />
        </Grid>
      ))}
    </Grid>
  );
};

export default NotificationsList;
