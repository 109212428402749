import { useState } from "react";
import { Auth } from "aws-amplify";
import { Box, Grid, Typography } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useAppSelector, useAppDispatch } from "app/store/hooks";
import { isLoading, setLoading } from "app/store/genericSlice";
import InputWithLabel from "generic/Inputs/InputWithLabel";
import PrimaryButton from "generic/Buttons/PrimaryButton";
import { PasswordIcon, EyeIcon } from "app/assets/icons";
import Snackbar from "generic/Snackbar/Snackbar";

const validationSchema = yup.object({
  current_password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Current Password is required"),
  new_password: yup
    .string()
    .required("New Password is required")
    .matches(
      /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/,
      "Password must be at least 8 charcters and have: 1 uppercase, 1 lowercase, 1 number and 1 special character."
    ),
  confirm_password: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("new_password"), null], "Passwords must match"),
});

const ChangePassword = () => {
  const [type, setType] = useState("password");
  const [type2, setType2] = useState("password");
  const [type3, setType3] = useState("password");

  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      current_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (form_data, { resetForm }) => {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          return Auth.changePassword(
            user,
            form_data.current_password,
            form_data.new_password
          );
        })
        .then((data) => {
          dispatch(setLoading(false));
          setIsError(true);
          setErrorMsg("Successfully changed your password");
          resetForm();
        })
        .catch((err) => {
          resetForm();
          dispatch(setLoading(false));
          setIsError(true);
          setErrorMsg(
            "Error occured while trying to change your password, please try again."
          );
        });
    },
  });

  return (
    <form
      style={{ margin: "auto 0", maxWidth: "100%" }}
      onSubmit={formik.handleSubmit}
    >
      <Typography
        variant="body2"
        style={{ color: "#172B4D", fontSize: "16px", marginBottom: "2rem" }}
      >
        You can change your password by confirming the current one and adding a
        new one.
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={12} md={5}>
          <InputWithLabel
            label="Current Password"
            id="current_password"
            name="current_password"
            type={type}
            placeholder="Enter Password"
            startIcon={<PasswordIcon />}
            endIcon={<EyeIcon />}
            endIconOnClick={() =>
              setType((prevType: string) =>
                prevType === "password" ? "text" : "password"
              )
            }
            value={formik.values.current_password}
            onChange={formik.handleChange}
            error={
              formik.touched.current_password &&
              Boolean(formik.errors.current_password)
            }
            helperText={
              formik.touched.current_password && formik.errors.current_password
            }
          />
          <InputWithLabel
            label="Confirm Password"
            id="confirm_password"
            name="confirm_password"
            placeholder="Enter Password"
            type={type3}
            startIcon={<PasswordIcon />}
            endIcon={<EyeIcon />}
            endIconOnClick={() =>
              setType3((prevType: string) =>
                prevType === "password" ? "text" : "password"
              )
            }
            value={formik.values.confirm_password}
            onChange={formik.handleChange}
            error={
              formik.touched.confirm_password &&
              Boolean(formik.errors.confirm_password)
            }
            helperText={
              formik.touched.confirm_password && formik.errors.confirm_password
            }
          />
        </Grid>
        <span style={{ margin: "0 20px" }}></span>
        <Grid item xs={12} sm={12} md={5}>
          <InputWithLabel
            label="New Password"
            id="new_password"
            name="new_password"
            placeholder="Enter Password"
            type={type2}
            startIcon={<PasswordIcon />}
            endIcon={<EyeIcon />}
            endIconOnClick={() =>
              setType2((prevType: string) =>
                prevType === "password" ? "text" : "password"
              )
            }
            value={formik.values.new_password}
            onChange={formik.handleChange}
            error={
              formik.touched.new_password && Boolean(formik.errors.new_password)
            }
            helperText={
              formik.touched.new_password && formik.errors.new_password
            }
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: "2.5rem" }}>
        <PrimaryButton
          title="Save Changes"
          type="submit"
          sx={{ margin: 0, fontSize: "1rem", borderRadius: "0.5rem" }}
        />
      </Box>
      <Snackbar
        open={isError}
        onClose={() => setIsError(false)}
        message={errorMsg}
      />
    </form>
  );
};

export default ChangePassword;
