import { useState } from "react";
import { Autocomplete, TextField } from "@mui/material";

const SearchSelect = ({
  options,
  value,
  onChange,
  placeholder,
  multiple = false,
}: any) => {
  const [inputValue, setInputValue] = useState("");

  return (
    <Autocomplete
      multiple={multiple}
      value={value}
      onChange={onChange}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      id="controllable-states-demo"
      options={options}
      renderInput={(params) => <TextField {...params} label={placeholder} />}
    />
  );
};

export default SearchSelect;
