import { useState, useEffect } from "react";
import { Box, Divider } from "@mui/material";
import {
  useUpdateConsultationMutation,
  useAddAttachmentsMutation,
  useCancelConsultationMutation,
  useApproveWrittenConsultationMutation,
  useConfirmVideoConsultationMutation,
} from "services/appointments";
import { getStatus } from "utils/getStatus";
import {
  ChooseAppointmentDateHeader,
  SelectTimeslot,
  WaitingClientApproveDate,
  DisplayChosenTime,
  PatientUploadedDocuments,
  ApproveAndCancelAppointments,
  CompleteAppointment,
  AttachVideoLink,
  AttachDoctorDocs,
  EditDoctorNote,
  EditOrganizationNote,
  DisplayNote,
  CancelledHeader,
  DisplayStatus,
  MedicalHistory,
  StartVideoCall,
} from "./leftCardSection";
import { appointmentType } from "utils/enums";
import Snackbar from "generic/Snackbar/Snackbar";
import { urlRole } from "services";

const ChooseDateCard = ({ consultation, type, setRequestId }: any) => {
  const [chosenSlot, setChosenSlot] = useState(0);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [updateConsultation] = useUpdateConsultationMutation();
  const [addAttachments] = useAddAttachmentsMutation();
  const [approveWrittenConsultation, approveResult] =
    useApproveWrittenConsultationMutation();
  const [confirmVideoConsultation, confirmResult] =
    useConfirmVideoConsultationMutation();

  const handleApproveDate = () => {
    switch (type) {
      case appointmentType.VideoConsultation:
        return confirmVideoConsultation({
          id: consultation.id,
          slot_number: chosenSlot,
        });

      case appointmentType.WrittenConsultation:
        return approveWrittenConsultation(consultation.id);
    }
  };

  const handleErrorPopup = (errorMsg: string) => {
    setIsError(true);
    setErrorMsg(errorMsg);
  };

  const renderMedicalConsultation = () => {
    if (
      !consultation.patient_medical_history ||
      Object.keys(consultation.patient_medical_history).length === 0
    ) {
      return (
        <>
          <h4>No Medical History available.</h4>
          <Divider sx={{ my: 3 }} />
        </>
      );
    }

    return (
      <>
        <div>
          <MedicalHistory
            answers={consultation.patient_medical_history}
            speciality={
              consultation?.specialities[consultation?.specialities?.length - 1]
                ?.speciality
            }
          />
        </div>
        <Divider sx={{ my: 3 }} />
      </>
    );
  };

  const displayNote = () =>
    urlRole === "doctor" ? (
      <>
        {consultation?.organization_notes && (
          <>
            <DisplayNote
              title={"Organization note"}
              note={consultation?.organization_notes}
            />
            <Divider sx={{ my: 3 }} />
          </>
        )}
        <EditDoctorNote
          consultation={consultation}
          updateConsultation={updateConsultation}
          type={type}
          handleErrorPopup={handleErrorPopup}
        />
        <Divider sx={{ my: 3 }} />
      </>
    ) : (
      <>
        {consultation?.notes && (
          <>
            <DisplayNote title={"Doctor note"} note={consultation?.notes} />
            <Divider sx={{ my: 3 }} />
          </>
        )}
        <EditOrganizationNote
          consultation={consultation}
          updateConsultation={updateConsultation}
          type={type}
          handleErrorPopup={handleErrorPopup}
        />
        <Divider sx={{ my: 3 }} />
      </>
    );

  const renderSectionsBasedOnStatus = () => {
    switch (consultation.status) {
      case "waiting_doctor_confirmation":
        return (
          <>
            {(type === appointmentType.VideoConsultation ||
              type === appointmentType.EmergencyConsultation) && (
              <>
                <ChooseAppointmentDateHeader consultation={consultation} />
                {type !== appointmentType.EmergencyConsultation && (
                  <SelectTimeslot
                    consultation={consultation}
                    chosenSlot={chosenSlot}
                    setChosenSlot={setChosenSlot}
                  />
                )}
                <Divider sx={{ my: 3 }} />
              </>
            )}
            <ApproveAndCancelAppointments
              onApprove={handleApproveDate}
              disablePrimary={
                (type === appointmentType.VideoConsultation && !chosenSlot) ||
                type === appointmentType.EmergencyConsultation
              }
              consultation={consultation}
              type={type}
            />
            <Divider sx={{ my: 3 }} />
            {renderMedicalConsultation()}

            {displayNote()}
          </>
        );

      case "waiting_patient_confirmation":
      case "doctor_created":
        return (
          <>
            {(type === appointmentType.VideoConsultation ||
              type === appointmentType.EmergencyConsultation) && (
              <>
                <ChooseAppointmentDateHeader consultation={consultation} />
                <WaitingClientApproveDate />
                <Divider sx={{ my: 3 }} />
              </>
            )}
            {renderMedicalConsultation()}
            {displayNote()}
          </>
        );

      case "upcoming":
        return (
          <>
            {(type === appointmentType.VideoConsultation ||
              type === appointmentType.EmergencyConsultation) && (
              <>
                <ChooseAppointmentDateHeader consultation={consultation} />
                <DisplayChosenTime consultation={consultation} />
                <Divider sx={{ my: 3 }} />
              </>
            )}
            {(type === appointmentType.VideoConsultation ||
              type === appointmentType.EmergencyConsultation) && (
              <>
                <StartVideoCall
                  consultation={consultation}
                  type={type}
                  handleErrorPopup={handleErrorPopup}
                />
                <Divider sx={{ my: 3 }} />
              </>
            )}
            <CompleteAppointment consultation={consultation} type={type} />
            <Divider sx={{ my: 3 }} />
            {displayNote()}

            <AttachVideoLink
              consultation={consultation}
              type={type}
              updateAttachedVideos={addAttachments}
            />
            <Divider sx={{ my: 3 }} />

            {renderMedicalConsultation()}
            <AttachDoctorDocs
              consultation={consultation}
              type={type}
              updateConsultation={addAttachments}
              handleErrorPopup={handleErrorPopup}
            />
          </>
        );

      case "completed":
        return (
          <>
            {type === appointmentType.VideoConsultation ||
            type === appointmentType.EmergencyConsultation ? (
              <>
                <ChooseAppointmentDateHeader
                  consultation={consultation}
                  setRequestId={setRequestId}
                />
                <DisplayChosenTime consultation={consultation} />
                <Divider sx={{ my: 3 }} />
              </>
            ) : (
              <>
                <DisplayStatus status={consultation?.status} />
                <Divider sx={{ my: 3 }} />
              </>
            )}

            {displayNote()}

            <AttachVideoLink
              consultation={consultation}
              type={type}
              updateAttachedVideos={addAttachments}
            />
            <Divider sx={{ my: 3 }} />

            {renderMedicalConsultation()}
            <AttachDoctorDocs
              consultation={consultation}
              type={type}
              updateConsultation={addAttachments}
              handleErrorPopup={handleErrorPopup}
            />
          </>
        );

      case "cancelled":
        return (
          <>
            {renderMedicalConsultation()}
            <CancelledHeader consultation={consultation} />
            {displayNote()}
          </>
        );

      case "patient_no_show":
      case "doctor_no_show":
        return (
          <>
            <ChooseAppointmentDateHeader consultation={consultation} />
            <Divider sx={{ my: 3 }} />
            {renderMedicalConsultation()}
            {displayNote()}
          </>
        );
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "30px",
      }}
    >
      {renderSectionsBasedOnStatus()}

      <Snackbar
        open={isError}
        onClose={() => setIsError(false)}
        message={errorMsg}
      />
    </div>
  );
};

export default ChooseDateCard;
