import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import Layout from "modules/layout/Layout";
import Card from "generic/Cards/Card";
import CalenderView from "./components/Calender";
import { useGetFilteredAppointmentsQuery } from "services/appointments";
import moment from "moment";
import SelectedAppointments from "./components/SelectedAppointments";

export interface Appointment {
  id: number;
  slot: string;
  status: string;
  type: string;
  reason: null | string;
  created_at: string;
  updated_at: string;
  patient: {
    id: number;
    address: string;
    birthdate: string;
    country: string;
    email: string;
    first_name: string;
    gender: string;
    last_name: string;
    locale: string;
    phone_number: string;
    role: string;
    sub: string;
    deleted_at: string;
    avatar_url: string;
    one_signal: {
      external_id: string;
      external_id_hash: string;
    };
  };
}

const Calendar = () => {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState(new Date());
  const [statusFilter, setStatusFilter] = useState("All");
  const [selectedDays, setSelectedDays] = useState<number[]>([]);
  const [datedAppointments, setDatedAppointments] = useState<{
    [x: string]: Appointment[];
  }>({});

  const { data, isLoading, isError, isFetching } =
    useGetFilteredAppointmentsQuery(
      {
        dates: {
          start: moment(startDate).startOf("month").format("YYYY/MM/DD"),
          end: moment(startDate).endOf("month").format("YYYY/MM/DD"),
        },
        ...(statusFilter !== "All" && {
          status: statusFilter.toLocaleLowerCase(),
        }),
      },
      { refetchOnMountOrArgChange: true }
    );

  const mapAppointments = () => {
    if (!data || data?.appointments?.length === 0) {
      setDatedAppointments({});
      return;
    }
    const mappedAppointments: { [x: string]: Appointment[] } = {};

    data?.appointments.forEach((app: Appointment) => {
      const currentDayKey = `DAY${moment(app.slot).date()}`;
      if (mappedAppointments[currentDayKey]) {
        mappedAppointments[currentDayKey] = [
          ...mappedAppointments[currentDayKey],
          app,
        ];
      } else {
        mappedAppointments[currentDayKey] = [app];
      }
    });

    setDatedAppointments(mappedAppointments);
    setUpcomingAppointments(mappedAppointments);
  };

  const setUpcomingAppointments = (appointmentsList: {
    [x: string]: Appointment[];
  }) => {
    // starting point with selected day to check for any appointments
    let checkingMonthDayNo = moment(startDate).date();

    // the final selected days number
    const finalSelected = [];

    // going through the days to find the nearest two days that have appointments
    while (finalSelected.length < 2 && checkingMonthDayNo < 32) {
      const targetDayAppointments =
        appointmentsList[`DAY${checkingMonthDayNo}`];

      // if the day have appointments then it's chosen
      if (Boolean(targetDayAppointments) && targetDayAppointments?.length > 0) {
        finalSelected.push(checkingMonthDayNo);
      }

      //adding days number to show next days appointments
      ++checkingMonthDayNo;
    }

    setSelectedDays(finalSelected);
  };

  useEffect(() => {
    if (data?.appointments && !isLoading && !isFetching) {
      mapAppointments();
    }
  }, [moment(startDate).date(), isFetching]);

  if (isLoading) return null;
  return (
    <Layout title={t("Calendar Management", "Calendar Management")}>
      <Grid
        container
        item
        direction="row"
        spacing={0.5}
        sx={{ height: "50vh" }}
      >
        <Grid
          item
          sx={{
            padding: 0,
            width: "62%",
          }}
        >
          <Card sx={{ padding: 0 }}>
            <CalenderView
              startDate={startDate}
              setStartDate={setStartDate}
              statusFilter={statusFilter}
              setStatusFilter={setStatusFilter}
              datedAppointments={datedAppointments}
            />
          </Card>
        </Grid>
        <Grid item sx={{ width: "38%" }}>
          <Card sx={{ height: "100%", padding: "1.5rem" }}>
            {data?.appointments && Boolean(data?.appointments?.length) ? (
              <SelectedAppointments
                datedAppointments={datedAppointments}
                selectedDays={selectedDays}
              />
            ) : (
              "No appointments for this month"
            )}
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Calendar;
