import { useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Link,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as yup from "yup";
import { useFormik } from "formik";
import { useUpdateProfileMutation } from "services/profileSettings";
import InputWithLabel from "generic/Inputs/InputWithLabel";
import SecondaryButton from "generic/Buttons/SecondaryButton";
import Text from "generic/Text/Text";
import { XIcon } from "app/assets/icons";
import { useAppSelector, useAppDispatch } from "app/store/hooks";
import { isLoading, setLoading } from "app/store/genericSlice";
import Modal from "generic/Modals/Modal";
import { PlusIcon } from "app/assets/icons";
import { dateFormat } from "utils/dateFormat";
import CustomDatePicker from "generic/CustomDatePicker/CustomDatePicker";

const biographyValidationSchema = yup.object({
  board_title: yup.string().trim().required(),
  conferences: yup.string().trim().required(),
  publications: yup.string().trim().required(),
});

const institutionValidationSchema = yup.object({
  name: yup.string().trim().required(),
  role: yup.string().trim().required(),
  start_date: yup
    .date()
    .typeError("Please enter a date in the following format MM/DD/YYYY")
    .required(),
  end_date: yup
    .date()
    .typeError("Please enter a date in the following format MM/DD/YYYY")
    .min(yup.ref("start_date"))
    .required(),
});

const Expertise = ({ biographies, institutions, operations }: any) => {
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [profileUpdates, setProfileUpdates] = useState({});

  const [operation, setOperation] = useState("");
  const [operationCount, setOperationCount] = useState("");
  // const [operations, setOperations] = useState<any[]>(operations);

  const loading = useAppSelector(isLoading);
  const dispatch = useAppDispatch();

  const [updateProfile, updateProfileResult] = useUpdateProfileMutation();

  const biographyFormik = useFormik({
    initialValues: {
      board_title: "",
      conferences: "",
      publications: "",
    },
    validationSchema: biographyValidationSchema,
    onSubmit: ({ board_title, conferences, publications }, { resetForm }) => {
      updateProfile({
        biographies_attributes: [
          ...biographies,
          {
            board_title,
            conferences,
            publications,
          },
        ],
      });
      resetForm();
    },
  });

  const institutionFormik = useFormik({
    initialValues: {
      name: "",
      role: "",
      start_date: null,
      end_date: null,
    },
    validationSchema: institutionValidationSchema,
    onSubmit: ({ name, role, start_date, end_date }, { resetForm }) => {
      updateProfile({
        academic_institutions_attributes: [
          ...institutions,
          {
            name,
            role,
            start_date,
            end_date,
          },
        ],
      });
      resetForm();
    },
  });

  const handleAddOperation = () => {
    if (operation && operationCount) {
      updateProfile({
        operations_attributes: [
          ...operations,
          {
            name: operation,
            number_of_times: operationCount,
          },
        ],
      });
      setOperation("");
      setOperationCount("");
    }
  };

  const renderBiographyCard = (
    biographyId: number,
    cardTitle: string,
    cardConferences: string,
    cardPublications: string
  ) => (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        width: "100%",
        background: "#136588",
        color: "white",
        padding: "15px",
        borderRadius: "10px",
        marginBottom: "10px",
      }}
    >
      <div>
        <h3>Board Title: {cardTitle}</h3>
        <p>Conferences: {cardConferences}</p>
        <p>Published Books: {cardPublications}</p>
      </div>
      <span style={{ margin: "0 auto" }}></span>
      <IconButton
        onClick={() => {
          setProfileUpdates({
            biographies_attributes: [
              ...biographies,
              {
                id: biographyId,
                _destroy: 1,
              },
            ],
          });
          setIsDeleteModalOpen(true);
        }}
      >
        {/* <IconButton onClick={() => deleteBiography(biographyId)}> */}
        <XIcon />
      </IconButton>
    </div>
  );

  const renderInstitutionCard = (
    institutionId: number,
    companyName: string,
    role: string,
    startDate: string,
    endDate: string
  ) => (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        width: "100%",
        background: "#136588",
        color: "white",
        padding: "15px",
        borderRadius: "10px",
        marginBottom: "10px",
      }}
    >
      <div>
        <h3>Company Name: {companyName}</h3>
        <p>Role: {role}</p>
        <p>Start Date: {dateFormat(startDate, "yyyy-MM-dd")}</p>
        <p>End Date: {dateFormat(endDate, "yyyy-MM-dd")}</p>
      </div>
      <span style={{ margin: "0 auto" }}></span>
      <IconButton
        onClick={() => {
          setProfileUpdates({
            academic_institutions_attributes: [
              ...institutions,
              {
                id: institutionId,
                _destroy: 1,
              },
            ],
          });
          setIsDeleteModalOpen(true);
        }}
      >
        {/* <IconButton onClick={() => deleteInstitution(institutionId)}> */}
        <XIcon />
      </IconButton>
    </div>
  );

  return (
    <Box sx={{ my: "auto", mx: 0, maxWidth: "100%" }}>
      <Grid container>
        <Grid item xs={12} sm={12} md={5}>
          <Grid container direction="row">
            <Grid item sx={{ width: "48%" }}>
              <InputWithLabel
                label="Operation"
                id="operation"
                name="operation"
                placeholder="Add Operation"
                required={false}
                value={operation}
                onChange={(e: any) => setOperation(e.target.value)}
                maxLength={24}
              />
            </Grid>
            <span style={{ margin: "0 auto" }}></span>
            <Grid item sx={{ width: "32%" }}>
              <InputWithLabel
                label="How many times?"
                id="operation-count"
                name="operation-count"
                placeholder="Add Number"
                type="number"
                required={false}
                value={operationCount}
                onChange={(e: any) => setOperationCount(e.target.value)}
                maxLength={5}
              />
            </Grid>
            <Grid
              item
              sx={{
                width: "16%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mt: 1,
              }}
            >
              <Link
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={handleAddOperation}
              >
                <PlusIcon />
              </Link>
            </Grid>
          </Grid>
          <Grid container>
            {operations?.length
              ? operations.map(
                  (element: any) =>
                    !element._destroy && (
                      <Grid item sx={{ mb: 2 }}>
                        <InputLabel
                          sx={{
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "#136588",
                            display: "inline",
                            background: "#F4F5F8",
                            padding: "10px",
                            mr: 2,
                            borderRadius: "5px",
                          }}
                        >
                          {element.name}
                          {" • "}
                          {element.number_of_times} {"times"}
                          <IconButton
                            onClick={() => {
                              let filtered = operations.filter(
                                (op: any) => op.id != element.id
                              );
                              updateProfile({
                                operations_attributes: [
                                  ...filtered,
                                  { ...element, _destroy: 1 },
                                ],
                              });
                            }}
                          >
                            <XIcon
                              style={{
                                marginBottom: "2px",
                                marginLeft: "-2px",
                              }}
                            />
                          </IconButton>
                        </InputLabel>
                      </Grid>
                    )
                )
              : null}
          </Grid>
          {/* <form onSubmit={biographyFormik.handleSubmit}>
            <Text
              sx={{
                color: "#172B4D",
                fontSize: "18px",
                fontWeight: 600,
                mb: 4,
              }}
              label="Biography"
            />
            <InputWithLabel
              label="Board Title"
              id="board_title"
              name="board_title"
              placeholder="Add board title"
              value={biographyFormik.values.board_title}
              onChange={biographyFormik.handleChange}
              error={
                biographyFormik.touched.board_title &&
                Boolean(biographyFormik.errors.board_title)
              }
              helperText={
                biographyFormik.touched.board_title &&
                biographyFormik.errors.board_title
              }
            />
            <InputWithLabel
              label="Conferences"
              id="conferences"
              name="conferences"
              placeholder="Add conferences"
              value={biographyFormik.values.conferences}
              onChange={biographyFormik.handleChange}
              error={
                biographyFormik.touched.conferences &&
                Boolean(biographyFormik.errors.conferences)
              }
              helperText={
                biographyFormik.touched.conferences &&
                biographyFormik.errors.conferences
              }
            />
            <InputWithLabel
              label="Researches and published books"
              id="publications"
              name="publications"
              placeholder="Add book"
              value={biographyFormik.values.publications}
              onChange={biographyFormik.handleChange}
              error={
                biographyFormik.touched.publications &&
                Boolean(biographyFormik.errors.publications)
              }
              helperText={
                biographyFormik.touched.publications &&
                biographyFormik.errors.publications
              }
            />
            <SecondaryButton
              title="Add"
              type="submit"
              width="140px"
              height="40px"
              loading={false}
            />
          </form>
          {biographies?.map((biography: any) =>
            renderBiographyCard(
              biography.id,
              biography.board_title,
              biography.conferences,
              biography.publications
            )
          )} */}
        </Grid>
        <span style={{ margin: "0 20px" }}></span>
        <Grid container item xs={12} sm={12} md={5} direction="column">
          <form onSubmit={institutionFormik.handleSubmit}>
            <Text
              sx={{
                color: "#172B4D",
                fontSize: "18px",
                fontWeight: 600,
                mb: 4,
              }}
              label="Academic Institution"
            />
            <InputWithLabel
              label="Company Name"
              id="name"
              name="name"
              placeholder="Add company name"
              value={institutionFormik.values.name}
              onChange={institutionFormik.handleChange}
              error={
                institutionFormik.touched.name &&
                Boolean(institutionFormik.errors.name)
              }
              helperText={
                institutionFormik.touched.name && institutionFormik.errors.name
              }
            />
            <InputWithLabel
              label="Role"
              id="role"
              name="role"
              placeholder="Add your role"
              value={institutionFormik.values.role}
              onChange={institutionFormik.handleChange}
              error={
                institutionFormik.touched.role &&
                Boolean(institutionFormik.errors.role)
              }
              helperText={
                institutionFormik.touched.role && institutionFormik.errors.role
              }
            />
            <Grid container direction="row">
              <Grid item sx={{ width: "48%" }}>
                <InputLabel
                  sx={{
                    mb: 1,
                    fontWeight: 600,
                    fontSize: "14px",
                    color: "#172B4D",
                  }}
                >
                  Period
                </InputLabel>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  sx={{ border: "none" }}
                >
                  <CustomDatePicker
                    label="Select date"
                    value={institutionFormik.values.start_date}
                    onChange={(newValue) =>
                      institutionFormik.setFieldValue("start_date", newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="start_date"
                        name="start_date"
                        error={
                          institutionFormik.touched.start_date &&
                          Boolean(institutionFormik.errors.start_date)
                        }
                        helperText={
                          institutionFormik.touched.start_date &&
                          institutionFormik.errors.start_date
                        }
                        sx={{ width: "100%", mb: 1 }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <span style={{ margin: "0 auto" }}></span>
              <Grid item sx={{ width: "48%" }}>
                <InputLabel
                  sx={{
                    mb: 1,
                    fontWeight: 600,
                    fontSize: "14px",
                    color: "#172B4D",
                  }}
                >
                  Through
                </InputLabel>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  sx={{ border: "none" }}
                >
                  <CustomDatePicker
                    label="Select date"
                    minDate={institutionFormik.values.start_date}
                    value={institutionFormik.values.end_date}
                    onChange={(newValue) =>
                      institutionFormik.setFieldValue("end_date", newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="end_date"
                        name="end_date"
                        error={
                          institutionFormik.touched.end_date &&
                          Boolean(institutionFormik.errors.end_date)
                        }
                        helperText={
                          institutionFormik.touched.end_date &&
                          institutionFormik.errors.end_date
                        }
                        sx={{ width: "100%", mb: 1 }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <SecondaryButton
              title="Add"
              type="submit"
              width="140px"
              height="40px"
              loading={false}
              sx={{ fontWeight: 500 }}
            />
          </form>
          {institutions?.map((institution: any) =>
            renderInstitutionCard(
              institution.id,
              institution.name,
              institution.role,
              institution.start_date,
              institution.end_date
            )
          )}
        </Grid>
      </Grid>
      <Modal
        open={isDeleteModalOpen}
        setOpen={setIsDeleteModalOpen}
        primaryTitle="Delete"
        secondaryTitle="Cancel"
        primaryOnclick={async () => {
          await updateProfile(profileUpdates);

          setIsDeleteModalOpen(false);
          setProfileUpdates({});
          dispatch(setLoading(false));
          setIsError(true);
          setErrorMsg("successfully updated your profile");
        }}
      >
        Are you sure you want to delete?
      </Modal>
    </Box>
  );
};

export default Expertise;
