import api from "./index";
import { urlRole } from "./index";

export const invitations = api.injectEndpoints({
  endpoints: (builder) => ({
    inviteDoctor: builder.mutation({
      query: ({ email }) => ({
        url: `/api/v1/${urlRole}/invitations`,
        method: "POST",
        body: { email },
      }),
      invalidatesTags: [{ type: "Invitations" }],
    }),

    handleInvite: builder.mutation({
      query: ({ token, action }) => ({
        url: `/api/v1/doctor/invitations/handle`,
        method: "POST",
        body: { invitation: { token, action } },
      }),
    }),

    listInvitations: builder.query<any, any>({
      query: ({ page = 1, perPage = 3 }) =>
        `/api/v1/organization/invitations?sort=-created_at&page=${page}&per_page=${perPage}`,
      providesTags: () => [{ type: "Invitations" }],
    }),

    removeDoctor: builder.mutation({
      query: (doctorId: number) => ({
        url: `/api/v1/organization/invitations/${doctorId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Invitations" }],
    }),
  }),
});

export const {
  useInviteDoctorMutation,
  useHandleInviteMutation,
  useListInvitationsQuery,
  useRemoveDoctorMutation,
} = invitations;
