import { useState } from "react";
import { useCancelConsultationMutation } from "services/appointments";
import Modal from "generic/Modals/Modal";
import SecondaryButton from "generic/Buttons/SecondaryButton";
import InputWithLabel from "generic/Inputs/InputWithLabel";

const CancelAppointment = ({ type, consultationId }: any) => {
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [reason, setReason] = useState("");

  const [cancelConsultation, cancelResult] = useCancelConsultationMutation();

  return (
    <>
      <SecondaryButton
        title={type == "WrittenConsultation" ? "Cancel" : "Cancel Appointment"}
        type="submit"
        height="40px"
        onClick={() => setIsCancelModalOpen(true)}
      />
      <Modal
        open={isCancelModalOpen}
        setOpen={setIsCancelModalOpen}
        primaryTitle="Cancel Appointment"
        secondaryTitle="Close"
        primaryOnclick={() =>
          cancelConsultation({ type, id: consultationId, reason })
        }
      >
        <InputWithLabel
          label="Are you sure you want to cancel this appointment?"
          id="reason"
          name="reason"
          placeholder="Please enter the reason here"
          multiline={true}
          rows={4}
          value={reason}
          onChange={(e: any) => setReason(e.target.value)}
        />
      </Modal>
    </>
  );
};

export default CancelAppointment;
