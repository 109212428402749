import { useState, useEffect } from "react";
import {
  Grid,
  Link,
  InputLabel,
  IconButton,
  Box,
  Select,
  MenuItem,
} from "@mui/material";
import PrimaryButton from "generic/Buttons/PrimaryButton";
import Text from "generic/Text/Text";
import { XIcon, PlusIcon } from "app/assets/icons";
import InputWithLabel from "generic/Inputs/InputWithLabel";
import StyledRadio from "generic/Inputs/StyledRadio";
import {
  useGetSpecialitiesQuery,
  useLazyGetSpecialityQuery,
} from "services/profileSettings";
import { useUpdateProfileMutation } from "services/profileSettings";
import Multiselect from "multiselect-react-dropdown";
import SecondaryButton from "generic/Buttons/SecondaryButton";
import SearchSelect from "generic/Inputs/SearchSelect";
import Snackbar from "generic/Snackbar/Snackbar";

const Specialities = ({ doctorData }: any) => {
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [speciality, setSpeciality] = useState<any>("");
  const [subspecialty, setSubspecialty] = useState<any>([]);

  const [specialityName, setSpecialityName] = useState<any>("");
  const [subspecialtyName, setSubspecialtyName] = useState("");
  const [customSubspecialty, setCustomSubspecialty] = useState("");

  const [operation, setOperation] = useState("");
  const [operationCount, setOperationCount] = useState("");
  const [operations, setOperations] = useState<any[]>([]);

  useEffect(() => {
    console.log({ subspecialty });
  }, [subspecialty]);

  useEffect(() => {
    setOperations(doctorData?.operations);
  }, [doctorData]);

  const [mySpecialities, setMySpecialities] = useState<any[]>([]);

  const specialityAttribute =
    localStorage.getItem("role") == "doctor"
      ? "doctor_specialities_attributes"
      : "organization_specialities_attributes";

  useEffect(() => {
    if (doctorData?.specialities?.length > 0) {
      let mySpecs: any = [];
      doctorData?.specialities?.map((element: any) => {
        if (element.subspecialities.length > 0) {
          const subspecialitiesArr = element.subspecialities.map((sub: any) => {
            return {
              id: sub.id,
              speciality_id: sub.speciality_id,
              name: sub.name,
              parent_name: element.name,
            };
          });

          mySpecs = [...mySpecs, ...subspecialitiesArr];
        }

        if (element.custom_subspecialites.length > 0) {
          const customSubspecialitiesArr = element.custom_subspecialites.map(
            (sub: any) => {
              return {
                id: sub.id,
                // speciality_id: sub.parent_speciality_id,
                name: sub.name,
                parent_name: element.name,
              };
            }
          );
          mySpecs = [...mySpecs, ...customSubspecialitiesArr];
        }

        if (
          !element.subspecialities.length &&
          !element.custom_subspecialites.length
        ) {
          mySpecs = [
            ...mySpecs,
            {
              id: element.id,
              speciality_id: element.speciality_id,
              name: element.name,
            },
          ];
        }
      });
      setMySpecialities(mySpecs);
    } else if (doctorData?.specialities?.length == 0) {
      setMySpecialities([]);
    }
  }, [doctorData]);

  const {
    data: specialitiesData,
    error: specialitiesError,
    isLoading: specialitiesLoading,
    isSuccess: specialitiesSuccesss,
  } = useGetSpecialitiesQuery();

  const [
    getSpeciality,
    {
      data: specialityData,
      error: specialityError,
      isLoading: specialityLoading,
      isSuccess: specialitySuccess,
    },
  ] = useLazyGetSpecialityQuery();

  const [updateProfile, { isError: isUpdateError, error: updateProfileError }] =
    useUpdateProfileMutation();

  useEffect(() => {
    if (isUpdateError) {
      let error: any = updateProfileError;
      setIsError(true);
      setErrorMsg(error?.data?.data);
    }
  }, [isUpdateError]);

  const handleAddSpeciality = async () => {
    if (speciality) {
      let doctor_profile = {};
      let parent = doctorData?.specialities.filter(
        (spec: any) => spec.speciality_id == speciality
      );
      let isParentExist = parent.length > 0;
      if (subspecialty.length) {
        isParentExist
          ? (doctor_profile = {
              [specialityAttribute]: [
                ...subspecialty.map((sub: any) => {
                  return {
                    speciality_id: sub?.id,
                  };
                }),
              ],
              // [specialityAttribute]: [{ speciality_id: subspecialty }],
            })
          : (doctor_profile = {
              [specialityAttribute]: [
                { speciality_id: speciality },
                ...subspecialty.map((sub: any) => {
                  return {
                    speciality_id: sub?.id,
                  };
                }),
                ,
              ],
            });
      }
      console.log({ old: doctor_profile });

      if (customSubspecialty) {
        isParentExist || subspecialty.length
          ? (doctor_profile = {
              ...doctor_profile,
              custom_subspecialities_attributes: [
                { speciality_id: speciality, name: customSubspecialty },
              ],
            })
          : (doctor_profile = {
              ...doctor_profile,
              [specialityAttribute]: [{ speciality_id: speciality }],
              custom_subspecialities_attributes: [
                { speciality_id: speciality, name: customSubspecialty },
              ],
            });
      }

      console.log({ new: doctor_profile });

      if (!subspecialty.length && !customSubspecialty) {
        doctor_profile = {
          [specialityAttribute]: [{ speciality_id: speciality }],
        };
      }

      await updateProfile(doctor_profile);
      setSpeciality("");
      setSubspecialty([]);
      setSpecialityName("");
      setSubspecialtyName("");
      setCustomSubspecialty("");
    } else {
      setIsError(true);
      setErrorMsg("Please select at least one specialty");
    }
  };

  const handleAddOperation = () => {
    if (operation && operationCount) {
      updateProfile({
        operations_attributes: [
          ...operations,
          {
            name: operation,
            number_of_times: operationCount,
          },
        ],
      });

      setOperation("");
      setOperationCount("");
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    updateProfile({
      operations_attributes: operations,
    });
  };

  return (
    <Box
      component="form"
      onSubmit={(e: any) => handleSubmit(e)}
      sx={{ my: "auto", mx: 0, maxWidth: "100%" }}
    >
      <Grid container>
        <Grid item xs={12} sm={12} md={8}>
          <Text
            sx={{ color: "#172B4D", fontSize: "14px", fontWeight: 600, mb: 1 }}
            label="Select Specialty: "
          />
          <SearchSelect
            options={
              specialitiesData?.length
                ? specialitiesData.map((sub: any) => {
                    return { ...sub, label: sub.name };
                  })
                : []
            }
            placeholder="Select Specialty"
            value={specialityName}
            onChange={(e: any, newValue: any) => {
              setSpeciality(newValue?.id);
              setSpecialityName(newValue?.name);
              setSubspecialty([]);
              setSubspecialtyName("");
              if (newValue) getSpeciality(newValue?.id);
            }}
          />
          <Text
            sx={{
              color: "#172B4D",
              fontSize: "14px",
              fontWeight: 600,
              mb: 1,
              mt: 2,
            }}
            label="Select Subspecialty: "
          />
          <SearchSelect
            options={
              speciality && specialityData?.subspecialities?.length
                ? [
                    ...specialityData?.subspecialities.map((sub: any) => {
                      return { ...sub, label: sub.name };
                    }),
                    {
                      id: "custom",
                      name: "Custom Subspecialty",
                      label: "Custom Subspecialty",
                    },
                  ]
                : []
            }
            placeholder="Select Subspecialty"
            value={subspecialty}
            onChange={(e: any, newValue: any) => {
              console.log({ subspecialty, newValue });
              if (
                !subspecialty.find(
                  (sub: any) => sub.id === newValue[newValue.length - 1].id
                )
              ) {
                setSubspecialty(newValue);
                // setSubspecialtyName(newValue?.name);
                setCustomSubspecialty("");
              }
            }}
            multiple
          />

          <InputWithLabel
            label="Custom Subspecialty: "
            id="custom_subspeciality"
            name="custom_subspeciality"
            placeholder="Enter Custom Subspecialty"
            required={false}
            value={customSubspecialty}
            onChange={(e: any) => setCustomSubspecialty(e.target.value)}
            mt={2}
          />

          <SecondaryButton
            title="Add Specialty"
            type="button"
            width="140px"
            height="40px"
            loading={false}
            onClick={handleAddSpeciality}
          />
          <Snackbar
            open={isError}
            onClose={() => setIsError(false)}
            message={errorMsg}
          />
          <br />
          <Grid container>
            {mySpecialities?.length
              ? mySpecialities.map((element) => (
                  <Grid item sx={{ mb: 2 }}>
                    <div
                      style={{
                        display: "flex",
                        background: "#F4F5F8",
                        padding: "10px",
                        borderRadius: "5px",
                        marginRight: "10px",
                      }}
                    >
                      <InputLabel
                        sx={{
                          fontWeight: 500,
                          fontSize: "1rem",
                          color: "#136588",
                          mr: 2,
                        }}
                      >
                        {element?.parent_name
                          ? `${element?.parent_name}: ${element?.name}`
                          : element?.name}
                      </InputLabel>
                      <IconButton
                        sx={{ padding: 0 }}
                        onClick={() => {
                          if (element.speciality_id) {
                            let filtered = mySpecialities.filter(
                              (op: any) =>
                                op.id != element.id && op.speciality_id
                            );
                            updateProfile({
                              [specialityAttribute]: [
                                ...filtered,
                                { ...element, _destroy: 1 },
                              ],
                            });
                          } else {
                            let filtered = mySpecialities.filter(
                              (op: any) =>
                                op.id != element.id && !op.speciality_id
                            );
                            updateProfile({
                              custom_subspecialities_attributes: [
                                ...filtered,
                                { ...element, _destroy: 1 },
                              ],
                            });
                          }
                        }}
                      >
                        <XIcon
                          style={{ marginBottom: "2px", marginLeft: "-2px" }}
                        />
                      </IconButton>
                    </div>
                  </Grid>
                ))
              : null}
          </Grid>
          <br />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Specialities;
