import api from "./index";

interface INotificationsCount {
  data: { count: number };
}

export interface INotification {
  id: number;
  body: string;
  created_at: string;
  read: boolean;
  payload: {
    id: number;
    patient_id: number;
    type: string;
    patient_first_name: string;
    patient_last_name: string;
  };
}

export interface INotificationWithAvatar extends INotification {
  patient_avatar_url: string;
}

interface INotifications {
  data: INotification[];
}

export const notifications = api.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<INotification[], string | void>({
      query: (lang = "en") => `/api/v1/notifications?locale=${lang}`,
      providesTags: () => [{ type: "Notifications" }],
      transformResponse: (response: INotifications) => {
        return response.data;
      },
    }),
    getNotificationsCount: builder.query<any, void>({
      query: () => `/api/v1/notifications/count`,
      providesTags: () => [{ type: "Notifications" }],
      transformResponse: (response: INotificationsCount) => {
        return response.data.count;
      },
    }),
  }),
});

export const { useLazyGetNotificationsQuery, useGetNotificationsCountQuery } =
  notifications;
