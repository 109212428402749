import { useState, useEffect } from "react";
import {
  Box,
  Grid,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  Divider,
  MenuItem,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import Layout from "modules/layout/Layout";
import Card from "generic/Cards/Card";
import Text from "generic/Text/Text";
import {
  CheckboxCheckedIcon,
  CheckboxUncheckedIcon,
  GoBackArrowIcon,
} from "app/assets/icons";
import PrimaryButton from "generic/Buttons/PrimaryButton";
import InputSelect from "generic/Inputs/InputSelect";
import {
  useGetMyProfileQuery,
  useUpdateProfileMutation,
} from "services/profileSettings";
import Snackbar from "generic/Snackbar/Snackbar";
import { urlRole } from "services";

const ClinicSettings = () => {
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const { data, error, isLoading, isSuccess } = useGetMyProfileQuery();
  const [updateProfile, result] = useUpdateProfileMutation();

  const [week, setWeek] = useState<any>([]);
  const [emergencyAppointment, setEmergencyAppointment] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      setEmergencyAppointment(data?.[urlRole]?.emergency_appointment_within);
      setWeek(
        data?.[urlRole]?.availability_days?.map((day: any) => {
          console.log(day.from);
          let fromWithTimezone = moment(
            new Date(`2020-01-01 ${day.from}`)
          ).format("H:mm");
          // let fromWithTimezone = moment(from)
          //   .add(timezoneOffset, "h")
          //   .format("H:mm");

          let toWithTimezone = moment(new Date(`2020-01-01 ${day.to}`)).format(
            "H:mm"
          );
          // let toWithTimezone = moment(to)
          //   .add(timezoneOffset, "h")
          //   .format("H:mm");

          return { ...day, from: fromWithTimezone, to: toWithTimezone };
        })
      );
    }
  }, [isSuccess]);

  useEffect(() => {
    console.log({ week });
  }, [week]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    const daysWithFromAndTo = week.filter((singleDay: any) => {
      if (singleDay?._destroy) return singleDay;
      return singleDay?.from && singleDay?.to;
    });

    let response = {
      success: true,
      message: "successfully updated your profile",
    };
    const daysWithTimezone = daysWithFromAndTo.map((singleDay: any) => {
      let fromTime: any = moment(new Date(`1-1-2020 ${singleDay.from}`)).format(
        "HH:mm:ssZ"
      );
      // let from = moment.utc(fromTime).format("H:mm");

      let toTime: any = moment(new Date(`1-1-2020 ${singleDay.to}`)).format(
        "HH:mm:ssZ"
      );
      // let to = moment.utc(toTime).format("H:mm");

      if (fromTime >= toTime && singleDay?.destroy) {
        response.success = false;
        response.message = "End time has to be after the start time!";
      }
      return { ...singleDay, from: fromTime, to: toTime };
    });

    if (response.success) {
      await updateProfile({
        emergency_appointment_within: emergencyAppointment,
        availability_days_attributes: daysWithTimezone,
      });
      window.location.reload();
    }

    setLoading(false);
    setIsError(true);
    setErrorMsg(response.message);
  };

  const displayDay = (day: any) => {
    let currentDay = week.find((singleDay: any) => singleDay.day == day);

    if (currentDay?.from && currentDay?.from.length === 4)
      currentDay.from = `0${currentDay?.from}`;

    if (currentDay?.to && currentDay?.to.length === 4)
      currentDay.to = `0${currentDay?.to}`;

    const weekWithDestroyDayChanged = ({ _destroy }: any) => {
      return currentDay
        ? week.map((singleDay: any) => {
            if (singleDay.day !== day) return singleDay;
            else
              return {
                ...singleDay,
                _destroy,
              };
          })
        : [...week, { day, from: null, to: null, _destroy }];
    };

    const weekWithoutThisDay = week.filter(
      (singleDay: any) => singleDay.day !== day
    );

    return (
      <Box sx={{ display: "flex", mb: 3 }}>
        <Box
          sx={{
            mr: "1.5rem",
            width: "8.125rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  // currentDay?.from && currentDay?.to && !currentDay?._destroy
                  currentDay && !currentDay?._destroy ? true : false
                }
                onChange={(e) => {
                  !e.target.checked
                    ? setWeek(weekWithDestroyDayChanged({ _destroy: 1 }))
                    : setWeek(weekWithDestroyDayChanged({ _destroy: 0 }));
                }}
                name={day}
                checkedIcon={<CheckboxCheckedIcon />}
                icon={<CheckboxUncheckedIcon />}
              />
            }
            label={
              <Typography sx={{ fontWeight: 600 }}>
                {`${day}`.charAt(0).toUpperCase() + `${day}`.slice(1)}
              </Typography>
            }
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "16px",
            color: "#B3BAC5",
          }}
        >
          <TextField
            id="start-time"
            type="time"
            value={!currentDay?._destroy ? currentDay?.from : null}
            onChange={(e) => {
              setWeek([
                ...weekWithoutThisDay,
                { ...currentDay, day, from: e.target.value, _destroy: 0 },
              ]);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300,
            }}
            sx={{ mr: 2, width: "140px" }}
          />
          to
          <TextField
            id="end-time"
            type="time"
            value={!currentDay?._destroy ? currentDay?.to : null}
            onChange={(e) => {
              setWeek([
                ...weekWithoutThisDay,
                { ...currentDay, day, to: e.target.value, _destroy: 0 },
              ]);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300,
            }}
            sx={{ ml: 2, width: "140px" }}
          />
        </Box>
      </Box>
    );
  };

  let timezoneOffset: any = new Date().getTimezoneOffset() / -60;
  console.log({ timezoneOffset });

  return (
    <Layout
      title="Clinic Settings"
      icon={<GoBackArrowIcon />}
      handleIconClick={() => navigate(-1)}
    >
      <Grid container item direction="row" sx={{ minHeight: "85vh" }}>
        <Card xs={12}>
          <Box
            component="form"
            onSubmit={(e: any) => handleSubmit(e)}
            sx={{ my: "auto", mx: 0, maxWidth: "100%" }}
          >
            <FormControl
              fullWidth
              sx={{ m: 3 }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">
                <Text
                  sx={{
                    color: "#172B4D",
                    fontSize: "18px",
                    fontWeight: 700,
                    mb: 4,
                  }}
                  label={`Availability: (Current Timezone: UTC${
                    timezoneOffset > 0 ? `+${timezoneOffset}` : timezoneOffset
                  })`}
                />
              </FormLabel>
              <FormGroup>
                {displayDay("monday")}
                {displayDay("tuesday")}
                {displayDay("wednesday")}
                {displayDay("thursday")}
                {displayDay("friday")}
                {displayDay("saturday")}
                {displayDay("sunday")}
              </FormGroup>
            </FormControl>

            <Divider sx={{ my: 4 }} />

            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <Box sx={{ maxWidth: "31.25rem" }}>
                  <InputSelect
                    id={"emergency-appointment"}
                    label={"Emergency Appointment within"}
                    value={emergencyAppointment}
                    onChange={(e: any) =>
                      setEmergencyAppointment(e.target.value)
                    }
                    placeholder={"Emergency Appointment within"}
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                  </InputSelect>
                </Box>
              </Grid>
            </Grid>

            <PrimaryButton
              title="Save Changes"
              type="submit"
              loading={loading}
            />
            <Snackbar
              open={isError}
              onClose={() => setIsError(false)}
              message={errorMsg}
            />
          </Box>
        </Card>
      </Grid>
    </Layout>
  );
};

export default ClinicSettings;
