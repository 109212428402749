import { Box, Typography } from "@mui/material";
import Loader from "generic/Loader/loader";
import NoContentCard from "generic/NoContentCard/NoContentCard";
import { useRevenuesStatsQuery } from "services/insights";
import ChangePercentage from "../shared/ChangePercentage";
import { fillZeroMonths } from "../../../../utils/fillZeroMonths";
import MonthlyRevenueLineChart from "./MonthlyRevenueLineChart";

const RevenueStats = () => {
  const { data: stats, isLoading } = useRevenuesStatsQuery();

  if (isLoading) return <Loader />;

  if (stats && stats.length === 0) {
    return <NoContentCard message="No Revenue Analytics" />;
  }

  const last12MonthStats = fillZeroMonths(stats.slice(-12), "revenues").slice(
    -12
  );

  const currStats = last12MonthStats[last12MonthStats.length - 1];
  const currRevenue = currStats.revenues;

  const prevStats = last12MonthStats[last12MonthStats.length - 2];
  const prevRevenue = prevStats?.revenues || 0;

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {prevStats ? (
        <Box sx={{ flexGrow: 1 }}>
          <MonthlyRevenueLineChart revenues={last12MonthStats} />
        </Box>
      ) : (
        <NoContentCard message="No Revenue History" />
      )}

      <Box sx={{ mt: "1.563rem", display: "flex" }}>
        <Box>
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: 600,
              lineHeight: "1.25rem",
              color: "#172B4D",
            }}
          >
            €{currRevenue}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              fontSize: "0.875rem",
              fontWeight: 400,
              lineHeight: "1.5rem",
              color: "#7F8CA9",
              mt: "0.75rem",
            }}
          >
            Total revenue
          </Typography>
        </Box>
        <Box
          sx={{
            ml: "auto",
            mt: "auto",
            mr: "0.125rem",
            mb: "0.625rem",
          }}
        >
          {prevStats && (
            <ChangePercentage curr={currRevenue} prev={prevRevenue} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default RevenueStats;
