import React, { useState, useEffect } from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { Auth } from "aws-amplify";

export const ProtectedRoute = () => {
  const isAuthenticated = localStorage.getItem("idToken");
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export const ProtectedLogin = () => {
  const isAuthenticated = localStorage.getItem("idToken");
  return isAuthenticated ? <Navigate to="/" /> : <Outlet />;
};

const PrivateRoute2 = ({ component: Component, ...rest }: any) => {
  const [auth, setAuth] = useState(false);
  const [isTokenValidated, setIsTokenValidated] = useState(false);

  setAuth(true);

  if (!isTokenValidated) return <div />; // or some kind of loading animation

  return (
    <Route
      {...rest}
      render={(props: any) => {
        return auth ? <Component {...props} /> : <Navigate to="/login" />;
      }}
    />
  );
};
