import { useState } from "react";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";

const CustomDatePicker = ({ onChange, ...props }: DatePickerProps) => {
  const [newDate, setNewDate] = useState<string>("");

  return (
    <DatePicker
      onChange={() => {}}
      onYearChange={(value: any) => {
        setNewDate(value);
        onChange(value);
      }}
      onAccept={(value: any) => {
        const newYear = new Date(newDate).getFullYear();
        const year = new Date(value).getFullYear();
        const sameYear = newYear === year;
        onChange(sameYear ? value : newDate || value);
        setNewDate("");
      }}
      {...props}
    />
  );
};

export default CustomDatePicker;
