import { Typography } from "@mui/material";
import Box from "@mui/system/Box";
import SingleAppointment from "modules/home/components/SingleAppointment";
import { Appointment } from "..";
import moment from "moment";

interface Props {
  datedAppointments: { [x: string]: Appointment[] };
  selectedDays: number[];
}

function SelectedAppointments({ datedAppointments, selectedDays }: Props) {
  if (
    !Boolean(selectedDays.length) &&
    Boolean(Object.keys(datedAppointments).length)
  ) {
    return (
      <Typography sx={{ color: "#95A0B8", fontWeight: "700", mb: 2 }}>
        Select a day to view it's appointments
      </Typography>
    );
  }
  return (
    <>
      {selectedDays[0] &&
        Boolean(datedAppointments?.[`DAY${selectedDays[0]}`]?.length) && (
          <Box>
            <Typography
              variant="subtitle2"
              sx={{
                color: "#95A0B8",
                fontWeight: "700",
                fontSize: "0.75rem",
                mb: 3,
              }}
            >
              {moment(
                datedAppointments[`DAY${selectedDays[0]}`][0].slot
              ).format("M/D/YYYY") === moment().format("M/D/YYYY")
                ? "Today - "
                : ""}
              {moment(
                datedAppointments[`DAY${selectedDays[0]}`][0].slot
              ).format("MMM D, YYYY")}
            </Typography>
            {datedAppointments[`DAY${selectedDays[0]}`].map(
              (appointment: Appointment) => (
                <SingleAppointment
                  consultation={appointment}
                  key={appointment.id}
                  cardStyle="status"
                />
              )
            )}
          </Box>
        )}

      {selectedDays[1] &&
        Boolean(datedAppointments?.[`DAY${selectedDays[1]}`]?.length) && (
          <Box>
            <Typography
              variant="subtitle2"
              sx={{
                color: "#95A0B8",
                fontWeight: "700",
                fontSize: "0.75rem",
                mb: 3,
                mt: 2,
              }}
            >
              {moment(
                datedAppointments[`DAY${selectedDays[1]}`][0].slot
              ).format("MMM D, YYYY")}
            </Typography>
            {datedAppointments[`DAY${selectedDays[1]}`].map(
              (appointment: Appointment) => {
                return (
                  <SingleAppointment
                    consultation={appointment}
                    key={appointment.id}
                    cardStyle="status"
                  />
                );
              }
            )}
          </Box>
        )}
    </>
  );
}

export default SelectedAppointments;
