import { Box, Typography } from "@mui/material";
import NoContentCard from "generic/NoContentCard/NoContentCard";
import Text from "generic/Text/Text";

type SearchFilter = {
  [key: string]: number;
};

interface ISearchFilters {
  filters: SearchFilter;
}

const SearchFilters = ({ filters }: ISearchFilters) => {
  if (Object.keys(filters).length === 0) {
    return <NoContentCard message="No search filters" />;
  }

  return (
    <>
      <Text
        sx={{
          fontSize: "1.125rem",
          fontWeight: 600,
          color: "#172B4D",
          mb: "0.75rem",
        }}
        label={"Search filters used to find your profile"}
      />
      <Box>
        {Object.entries(filters).map(([keyword, count]) => (
          <Box
            sx={{
              py: "1.25rem",
              borderBottom: "1px solid #DFE1E6",
              display: "flex",
              alignItems: "center",
              gap: "0.75rem",
            }}
          >
            <Typography sx={{ fontSize: "0.875rem", fontWeight: 600 }}>
              {count} x times
            </Typography>
            <Box
              sx={{
                backgroundColor: "#F4F5F8",
                borderRadius: "0.5rem",
                px: "1rem",
                py: "0.5rem",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{ fontSize: "1rem", fontWeight: 400 }}
              >
                {keyword}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default SearchFilters;
