export const handleTranslation = ({ originalText, toLang }: any, cb: any) => {
  let url = `https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_CLOUD_API_KEY}`;
  url += "&q=" + encodeURI(originalText);
  // url += `&source=${fromLang}`;
  url += `&target=${toLang}`;

  fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((res) => res.json())
    .then((response) => {
      if (response?.data?.translations?.length) {
        cb(response?.data?.translations[0].translatedText);
      }
    })
    .catch((error) => {});
};
