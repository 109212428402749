import { MenuItem, Badge, Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import Menu from "generic/Menu/Menu";
import BasicLink from "generic/Links/BasicLink";
import bell from "app/assets/images/sidebar-icons/bell.svg";
import { useEffect, useState } from "react";
import {
  INotificationWithAvatar,
  useGetNotificationsCountQuery,
} from "services/notifications";
import { IOrganizationDoctor } from "services/organizationDoctors";
import { useLazyFetchNotifications } from "utils/hooks/useLazyFetchNotifications";
import PrimaryButton from "generic/Buttons/PrimaryButton";
import DoctorsList from "./DoctorsList";
import { useLazyListOrganizationDoctorsQuery } from "services/organizationDoctors";
import InputWithLabel from "generic/Inputs/InputWithLabel";
import { useContactDoctorMutation } from "services/organizationDoctors";

const NotificationsBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    color: "white",
    backgroundColor: "#EB3131",
  },
});

const styles = {
  notificationBtn: {
    width: "2.25rem",
    height: "2.25rem",
    background: "#FFFFFF",
    borderRadius: "0.5rem",
    display: "inline-grid",
    placeItems: "center",
  },
};

const ONE_SECOND = 1000;

const OrganizationDoctorsMenu = ({
  consultationId,
  isDisabled,
  setIsError,
  setErrorMsg,
}: any) => {
  const [doctors, setDoctors] = useState<IOrganizationDoctor[]>();
  const [keyword, setKeyword] = useState("");
  const [doctorId, setDoctorId] = useState(0);
  const [selectedNum, setSelectedNum] = useState(-1);
  const [forceClose, setForceClose] = useState(false);

  const [fetchOrganizationDoctors] = useLazyListOrganizationDoctorsQuery();
  const [contactDoctor] = useContactDoctorMutation();

  useEffect(() => {
    fetchDoctors();
  }, [keyword]);

  const fetchDoctors = async () => {
    const doctors = await fetchOrganizationDoctors(keyword);
    setDoctors(doctors?.data?.data);
  };

  const handleToggle = async (isMenuOpen: boolean) => {
    if (isMenuOpen) {
      fetchDoctors();
    }
  };

  return (
    <Menu
      sx={{ py: "0.75rem", minWidth: "220px" }}
      canClickInside
      forceClose={forceClose}
      disabled={isDisabled}
      menuButton={
        <div style={{ marginTop: "10px" }}>
          <PrimaryButton
            title="Contact Doctor Request"
            height="40px"
            width="220px"
            margin={0}
            disabled={isDisabled}
            sx={{ padding: 0 }}
          />
        </div>
      }
      onToggle={handleToggle}
    >
      <InputWithLabel
        id="search"
        name="search"
        placeholder="Search"
        value={keyword}
        onChange={(e: any) => {
          setKeyword(e.target.value);
          setDoctorId(0);
          setSelectedNum(-1);
        }}
      />

      <DoctorsList
        doctors={doctors}
        setDoctorId={setDoctorId}
        selectedNum={selectedNum}
        setSelectedNum={setSelectedNum}
      />

      <MenuItem
        sx={{
          fontWeight: 700,
          fontSize: "1rem",
          color: doctorId ? "#136588" : "gray",
          justifyContent: "center",
          paddingTop: "0.25rem",
          paddingBottom: "0.25rem",
          cursor: doctorId ? "pointer" : "default",
        }}
        onClick={async () => {
          if (doctorId) {
            const result: any = await contactDoctor({
              consultation_id: consultationId,
              doctor_id: doctorId,
            });

            if (!result?.error) {
              setIsError(true);
              setErrorMsg("Successfully Contacted Doctor");
              setForceClose(true);
            } else {
              setIsError(true);
              setErrorMsg(result?.error?.data?.message);
            }
          }
        }}
      >
        Confirm
      </MenuItem>
    </Menu>
  );
};

export default OrganizationDoctorsMenu;
