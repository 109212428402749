import { Box, Rating as MuiRating } from "@mui/material";
import { StarFilledIcon, StarEmptyIcon } from "app/assets/icons";

const starStyle = {
  width: "1rem",
  height: "1rem",
};

interface IRating {
  ratingPercentage: number;
}

const Rating = ({ ratingPercentage }: IRating) => {
  return (
    <Box sx={{ mt: "-0.25rem", mb: "0.75rem" }}>
      <MuiRating
        sx={{ display: "flex", gap: "0.25rem" }}
        name="half-rating-read"
        value={(ratingPercentage / 100) * 5}
        precision={0.1}
        readOnly
        icon={
          <Box>
            <StarFilledIcon style={{ ...starStyle }} />
          </Box>
        }
        emptyIcon={
          <Box>
            <StarEmptyIcon style={{ ...starStyle }} />
          </Box>
        }
      />
    </Box>
  );
};

export default Rating;
