import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "modules/home/Home";
import RequestManagement from "modules/requestManagement/RequestManagement";
import Request from "modules/requestManagement/Request";
import Messages from "modules/messages/Messages";
import Revenue from "modules/revenue/Revenue";
import EmergencyAppointments from "modules/emergencyAppointments/EmergencyAppointments";
import Calendar from "modules/calendar";
import Insights from "modules/insights/Insights";
import ProfileSettings from "modules/profileSettings/ProfileSettings";
import AccountSettings from "modules/accountSettings/AccountSettings";
import ClinicSettings from "modules/profileSettings/tabs/ClinicSettings";
import Login from "modules/auth/login/Login";
import Signup from "modules/auth/signup/Signup";
import CongratsScreen from "modules/auth/CongratsScreen";
import ForgotPassword from "modules/auth/forgotPassword/ForgotPassword";
import TermsGeneral from "modules/auth/TermsGeneral";
import { ProtectedRoute, ProtectedLogin } from "./auth";
import Notifications from "modules/notifications/Notifications";
import Redirect from "modules/layout/Redirect";
import Agora from "modules/agora/Agora";
import Invitations from "modules/auth/Invitations";

const DoctorRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ProtectedRoute />}>
          <Route path="/" element={<Home />} />
        </Route>
        <Route path="/request-management" element={<ProtectedRoute />}>
          <Route path="/request-management" element={<RequestManagement />} />
        </Route>
        <Route path="/request/:type/:id" element={<ProtectedRoute />}>
          <Route path="/request/:type/:id" element={<Request />} />
        </Route>
        <Route path="/messages" element={<ProtectedRoute />}>
          <Route path="/messages" element={<Messages />} />
        </Route>
        <Route path="/revenue" element={<ProtectedRoute />}>
          <Route path="/revenue" element={<Revenue />} />
        </Route>
        <Route path="/emergency-appointments" element={<ProtectedRoute />}>
          <Route
            path="/emergency-appointments"
            element={<EmergencyAppointments />}
          />
        </Route>
        <Route path="/calendar" element={<ProtectedRoute />}>
          <Route path="/calendar" element={<Calendar />} />
        </Route>
        <Route path="/insights" element={<ProtectedRoute />}>
          <Route path="/insights" element={<Insights />} />
        </Route>
        <Route path="/profile-settings" element={<ProtectedRoute />}>
          <Route path="/profile-settings" element={<ProfileSettings />} />
        </Route>
        <Route path="/account-settings" element={<ProtectedRoute />}>
          <Route path="/account-settings" element={<AccountSettings />} />
        </Route>
        <Route path="/clinic-settings" element={<ProtectedRoute />}>
          <Route path="/clinic-settings" element={<ClinicSettings />} />
        </Route>
        <Route path="/notifications" element={<ProtectedRoute />}>
          <Route path="/notifications" element={<Notifications />} />
        </Route>
        <Route path="/redirect" element={<ProtectedRoute />}>
          <Route path="/redirect" element={<Redirect />} />
        </Route>

        <Route path="/login" element={<ProtectedLogin />}>
          <Route path="/login" element={<Login />} />
        </Route>
        <Route path="/signup" element={<ProtectedLogin />}>
          <Route path="/signup" element={<Signup />} />
        </Route>
        <Route path="/congrats" element={<ProtectedLogin />}>
          <Route path="/congrats" element={<CongratsScreen />} />
        </Route>
        <Route path="/forgot-password" element={<ProtectedLogin />}>
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Route>

        <Route path="/invitations" element={<Invitations />} />
        <Route path="/terms" element={<TermsGeneral />} />
        <Route path="/agora" element={<Agora />} />
        <Route path="*" element={<p>There's nothing here: 404!</p>} />
      </Routes>
    </BrowserRouter>
  );
};

export default DoctorRoutes;
