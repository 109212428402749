import { styled } from '@mui/material/styles';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { ArrowDownIcon } from 'app/assets/icons';

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowDownIcon/>}
    {...props}
  />
))(() => ({
  padding: 0,
  minHeight: 'unset',
  '& ..MuiAccordionSummary-expandIconWrapper': {
    transition: 'color ease 0.2s',
    color: '#B3BAC5'
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    color: '#136588',
  },
  '& .MuiTypography-root': {
    color: '#172B4D',
    fontWeight: 700,
    transition: 'color ease 0.2s'
  },
  '& .Mui-expanded .MuiTypography-root': { //DONOT REMOVE SPACING
    color: '#136588'
  },
  '& .MuiAccordionSummary-content': {
    margin: 0
  },
}));

export default AccordionSummary;