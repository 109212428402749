import { appointmentType } from "utils/enums";
import api from "./index";
import { urlRole } from "./index";

const appointments: { [key: string]: { urlKey: string; bodyKey: string } } = {
  [appointmentType.WrittenConsultation]: {
    urlKey: "written_consultations",
    bodyKey: "written_consultation",
  },
  [appointmentType.VideoConsultation]: {
    urlKey: "video_consultations",
    bodyKey: "video_consultation",
  },
  [appointmentType.EmergencyConsultation]: {
    urlKey: "emergency_consultations",
    bodyKey: "emergency_consultation",
  },
};

export const appointmentsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMyAppointments: builder.query<any, any>({
      query: ({ status, page, perPage, sort }) =>
        status
          ? `/api/v1/${urlRole}/consultations?filters[status]=${status}&page=${page}&per_page=${perPage}&sort=${sort}`
          : `/api/v1/${urlRole}/consultations?page=${page}&per_page=${perPage}&sort=${sort}`,
      providesTags: () => [{ type: "Appointments" }],
    }),

    getConsultation: builder.query<any, any>({
      query: ({ id, type }) =>
        `/api/v1/${urlRole}/${appointments[type].urlKey}/${id}`,
      providesTags: () => [{ type: "Appointment" }],
    }),

    requestChanges: builder.mutation({
      query: ({ id, type, slots }) => ({
        url: `/api/v1/${urlRole}/${appointments[type].urlKey}/${id}/request_changes`,
        method: "PUT",
        body: { [appointments[type].bodyKey]: slots },
      }),
      invalidatesTags: [{ type: "Appointment" }, { type: "Appointments" }],
    }),

    getVideoToken: builder.query<any, any>({
      query: (consultation_id) =>
        `/api/v1/${urlRole}/video_consultations/${consultation_id}/video_call_token`,
    }),

    getGuestToken: builder.query<any, any>({
      query: (consultation_id) =>
        `/api/v1/${urlRole}/consultations/${consultation_id}/guest_token`,
    }),

    nextAppointment: builder.mutation({
      query: ({ id, type, slots }) => ({
        url: `/api/v1/${urlRole}/${appointments[type].urlKey}/${id}/next_appointment`,
        method: "POST",
        body: {
          next_appointment: slots,
        },
      }),
      invalidatesTags: [{ type: "Appointment" }, { type: "Appointments" }],
    }),

    getFilteredAppointments: builder.query<any, any>({
      query: ({
        status,
        dates,
      }: {
        status?: string;
        dates?: { start: string; end: string };
      }) => ({
        url: `/api/v1/${urlRole}/profile/appointments?${
          status ? `filters[status]=${status}&` : ""
        }${
          dates ? `filters[slot]=${dates.start}...${dates.end}&` : ""
        }sort=-slot&per_page=-1`,
        method: "GET",
      }),
      providesTags: () => [{ type: "FilteredAppointments" }],
    }),

    updateConsultation: builder.mutation({
      query: ({ type, id, updates }) => ({
        url: `/api/v1/${urlRole}/${appointments[type].urlKey}/${id}`,
        method: "PUT",
        body: { [appointments[type].bodyKey]: updates },
      }),
      invalidatesTags: [{ type: "Appointment" }, { type: "Appointments" }],
    }),

    addAttachments: builder.mutation({
      query: ({ type, id, updates }) => ({
        url: `/api/v1/${urlRole}/${appointments[type].urlKey}/${id}/attachments`,
        method: "PUT",
        body: { [appointments[type].bodyKey]: updates },
      }),
      invalidatesTags: [{ type: "Appointment" }, { type: "Appointments" }],
    }),

    cancelConsultation: builder.mutation({
      query: ({ type, id, reason }) => ({
        url: `/api/v1/${urlRole}/${appointments[type].urlKey}/${id}/cancel`,
        method: "PUT",
        body: { [appointments[type].bodyKey]: { reason } },
      }),
      invalidatesTags: [{ type: "Appointment" }, { type: "Appointments" }],
    }),

    approveWrittenConsultation: builder.mutation({
      query: (id) => ({
        url: `/api/v1/${urlRole}/written_consultations/${id}/confirm`,
        method: "PUT",
      }),
      invalidatesTags: [{ type: "Appointment" }, { type: "Appointments" }],
    }),

    confirmVideoConsultation: builder.mutation({
      query: ({ id, slot_number }) => ({
        url: `/api/v1/${urlRole}/video_consultations/${id}/confirm`,
        method: "PUT",
        body: {
          video_consultation: {
            slot_number,
          },
        },
      }),
      invalidatesTags: [{ type: "Appointment" }, { type: "Appointments" }],
    }),

    completeConsultation: builder.mutation({
      query: ({ type, id }) => ({
        url: `/api/v1/${urlRole}/${appointments[type].urlKey}/${id}/complete`,
        method: "PUT",
      }),
      invalidatesTags: [{ type: "Appointment" }, { type: "Appointments" }],
    }),

    getReservedSlots: builder.query({
      query: ({ startDate, endDate }) =>
        `/api/v1/${urlRole}/profile/reserved_slots?filters[slot]=${startDate}...${endDate}`,
    }),

    contactOrganization: builder.mutation({
      query: (consultation_id) => ({
        url: `/api/v1/${urlRole}/consultations/${consultation_id}/contact_organization`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "Appointment" }],
    }),
  }),
});

export const {
  useGetMyAppointmentsQuery,
  useGetConsultationQuery,
  useRequestChangesMutation,
  useGetVideoTokenQuery,
  useGetGuestTokenQuery,
  useNextAppointmentMutation,
  useUpdateConsultationMutation,
  useAddAttachmentsMutation,
  useCancelConsultationMutation,
  useApproveWrittenConsultationMutation,
  useConfirmVideoConsultationMutation,
  useCompleteConsultationMutation,
  useGetFilteredAppointmentsQuery,
  useLazyGetFilteredAppointmentsQuery,
  useLazyGetReservedSlotsQuery,
  useContactOrganizationMutation,
} = appointmentsApi;
