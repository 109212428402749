import { useState } from "react";
import { useCompleteConsultationMutation } from "services/appointments";
import Modal from "generic/Modals/Modal";
import BlueButton from "../BlueButton";
import CancelAppointment from "./CancelAppointment";
import { Typography } from "@mui/material";

const CompleteAppointment = ({ consultation, type }: any) => {
  const [isCompleteAppointmentOpen, setIsCompleteAppointmentOpen] =
    useState(false);

  const [completeConsultation, cancelResult] =
    useCompleteConsultationMutation();

  return (
    <>
      <Typography
        style={{
          color: "#172B4D",
          fontSize: "16px",
          fontWeight: 700,
          // marginBottom: "20px",
        }}
      >
        Complete Appointment
      </Typography>
      <div>
        <BlueButton onClick={() => setIsCompleteAppointmentOpen(true)}>
          + Complete Appointment
        </BlueButton>
        <span style={{ margin: "0 20px" }}></span>
        <CancelAppointment type={type} consultationId={consultation?.id} />
      </div>
      <Modal
        open={isCompleteAppointmentOpen}
        setOpen={setIsCompleteAppointmentOpen}
        primaryTitle="Complete"
        secondaryTitle="Cancel"
        primaryOnclick={() =>
          completeConsultation({ type, id: consultation.id })
        }
      >
        Are you sure you want to complete this appointment?
      </Modal>
    </>
  );
};

export default CompleteAppointment;
