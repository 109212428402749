import { useEffect, useState } from "react";
import { Box, Grid, CircularProgress } from "@mui/material";
import { useUpdateProfileMutation } from "services/profileSettings";
import { useCreatePresignedURLMutation } from "services/user";
import { useUploadAWSMutation } from "services/noHeaders";
import { uploadFileAndUpdate } from "utils/fileUpload";
import { useAppSelector, useAppDispatch } from "app/store/hooks";
import { isLoading, setLoading } from "app/store/genericSlice";
import { XShadowIcon } from "app/assets/icons";
import Modal from "generic/Modals/Modal";
import Snackbar from "generic/Snackbar/Snackbar";
import { profileDataInterface } from "utils/doctorOrganizationInterface";

const Photos = ({ doctorData }: any) => {
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [imagePreview, setImagePreview] = useState<any[]>([]);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    if (doctorData) setImagePreview(doctorData?.[profileDataInterface?.pictures]);
  }, [doctorData]);

  const [createPresignedURL, urlResponse] = useCreatePresignedURLMutation();
  const [uploadAWS, uploadResponse] = useUploadAWSMutation();
  const [updateProfile, updateProfileResult] = useUpdateProfileMutation();

  const loading = useAppSelector(isLoading);
  const dispatch = useAppDispatch();

  const handleImagePreview = async (e: any) => {
    const files = e.target.files;
    console.log({files})
    let blobIds = imagePreview?.length ? imagePreview.map((img) => img.signed_id) : [];
    let new_images: any = [];
    for (let i = 0; i < files.length; i++) {
      let file = files.item(i);
      let image_as_base64 = URL.createObjectURL(file);
      let image_as_files = file;

      dispatch(setLoading(true));
      await uploadFileAndUpdate({
        resize: true,
        imageFile: image_as_files,
        createPresignedURL,
        uploadAWS,
        UpdateHook: (signed_id: any, url: any) => {
          blobIds = [...blobIds, signed_id];
          new_images = [...new_images, { signed_id, url }];
          if (files.length == new_images.length)
            updateProfileImages(blobIds, new_images);
        },
      });
    }
  };

  const updateProfileImages = async (blobIds: any, new_images: any) => {
    await updateProfile({
      [profileDataInterface?.pictures]: blobIds,
    });
    setImagePreview((prevImages) => {
      return [...prevImages, ...new_images];
    });
    dispatch(setLoading(false));
    setIsError(true);
    setErrorMsg("successfully updated your profile");
  };

  return (
    <Box sx={{ my: "auto", mx: 0, maxWidth: "100%" }}>
      <Grid container direction="row">
        {imagePreview?.length
          ? imagePreview.map((singleImg, i) => {
              return (
                <div style={{ position: "relative" }}>
                  <img
                    src={singleImg.url}
                    style={{
                      height: "200px",
                      width: "300px",
                      borderRadius: "10px",
                      marginRight: "15px",
                      zIndex: 1,
                    }}
                    alt="image"
                  />
                  <XShadowIcon
                    style={{
                      position: "absolute",
                      zIndex: 2,
                      top: "10px",
                      left: "270px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setIsDeleteModalOpen(true);
                      setImageIndex(i);
                    }}
                  />
                </div>
              );
            })
          : null}
      </Grid>

      <br />
      <label
        style={{
          border: "1px solid #ccc",
          display: "inline-block",
          padding: loading ? "5px 40px" : "10px 40px",
          cursor: "pointer",
          background: "#136588",
          color: "#FFFFFF",
          fontWeight: 600,
          fontSize: "1rem",
          borderRadius: "10px",
          marginTop: "20px",
        }}
      >
        <input
          type="file"
          hidden
          multiple
          disabled={loading}
          accept="image/png, image/gif, image/jpeg"
          onChange={handleImagePreview}
        />
        {loading ? <CircularProgress /> : "Add media"}
      </label>
      <Snackbar
        open={isError}
        onClose={() => setIsError(false)}
        message={errorMsg}
      />
      <Modal
        open={isDeleteModalOpen}
        setOpen={setIsDeleteModalOpen}
        primaryTitle="Delete"
        secondaryTitle="Cancel"
        primaryOnclick={async () => {
          dispatch(setLoading(true));
          const picturesFiltered = imagePreview.filter(
            (value: any, index: number) => {
              return index != imageIndex;
            }
          );
          await updateProfile({
              [profileDataInterface?.pictures]: picturesFiltered.map(
                (pic: any) => pic.signed_id
              ),
            },
          );
          setIsDeleteModalOpen(false);
          dispatch(setLoading(false));
          setIsError(true);
          setErrorMsg("successfully updated your profile");
        }}
      >
        Are you sure you want to delete this photo?
      </Modal>
    </Box>
  );
};

export default Photos;
