import api from "./index";
import { urlRole } from "./index";

export const medicalHistory = api.injectEndpoints({
  endpoints: (builder) => ({
    getMedicalHistoryQuestions: builder.query<any, any>({
      query: ({
        specialityId,
        locale = "en",
      }: {
        specialityId: number;
        locale: string;
      }) =>
        `/api/v1/${urlRole}/speciality_medical_history/speciality_questions/${specialityId}?locale=${locale}`,
      providesTags: () => [{ type: "MedicalHistory" }],
    }),
  }),
});

export const { useGetMedicalHistoryQuestionsQuery } = medicalHistory;
