import api from "./index";
import { urlRole } from "./index";

interface IRevenueStats {
  revenues: IRevenue[];
}
export interface IRevenue {
  group: string;
  revenues: number;
}

interface IPatientsStats {
  patients: IPatientsStat[];
}
interface IPatientsStat {
  group: string;
  female_count: number;
  male_count: number;
  total_count: number;
}

interface IViewsAndFilters {
  filters: any;
  this_month_increase_percent: number;
  visits_count: number;
  monthly_visits: IView[];
}

export interface IView {
  group: string;
  count: number;
}

export const insights = api.injectEndpoints({
  endpoints: (builder) => ({
    homeStats: builder.query<any, string | void>({
      query: (period = "month") =>
        `/api/v1/${urlRole}/insights/home?period=${period}`,
      providesTags: () => [{ type: "HomeStats" }],
    }),
    viewsAndFilters: builder.query<IViewsAndFilters, void>({
      query: () => `/api/v1/${urlRole}/insights`,
      providesTags: () => [{ type: "ViewsAndSearchFilters" }],
      transformResponse: (response: { insights: IViewsAndFilters }) =>
        response.insights || [],
    }),
    revenuesStats: builder.query<any, string | void>({
      query: (period = "monthly") =>
        `/api/v1/${urlRole}/insights/revenues?period=${period}`,
      providesTags: () => [{ type: "Revenues" }],
      transformResponse: (response: { data: IRevenueStats }) =>
        response.data.revenues || [],
    }),
    patientsStats: builder.query<any, string | void>({
      query: (period = "monthly") =>
        `/api/v1/${urlRole}/insights/patients?period=${period}`,
      providesTags: () => [{ type: "Revenues" }],
      transformResponse: (response: { data: IPatientsStats }) =>
        response.data.patients || [],
    }),
    paymentTransations: builder.query<any, any>({
      query: ({ page, perPage }) =>
        `/api/v1/${urlRole}/insights/payment_transations?page=${page}&per_page=${perPage}`,
      providesTags: () => [{ type: "Revenues" }],
    }),
  }),
});

export const {
  useHomeStatsQuery,
  useViewsAndFiltersQuery,
  useRevenuesStatsQuery,
  usePatientsStatsQuery,
  usePaymentTransationsQuery,
} = insights;
