import { useNavigate } from "react-router-dom";
import { Link, Badge } from "@mui/material";
import { useAppSelector, useAppDispatch } from "app/store/hooks";
import { activateOne } from "../../layoutSlice";
import Text from "generic/Text/Text";

const ExpandIcon = ({
  image,
  setExpandedBar,
  label1,
  label2,
  index,
  navigationURL,
  unReadMessagesCount,
}: any) => {
  const isActive = useAppSelector((state) => state.layout.isActive);
  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  const handleClickIcon = () => {
    dispatch(activateOne(index));
    navigate(navigationURL);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        height: "60px",
      }}
    >
      <Link
        href="#"
        onClick={() =>
          setExpandedBar
            ? setExpandedBar((prev: boolean) => !prev)
            : handleClickIcon()
        }
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ml: 1,
          padding: "12px",
          background: isActive[index] ? "rgba(255, 255, 255, 0.2)" : "#136588",
          borderRadius: "10px",
          textDecoration: "none",
        }}
      >
        <Badge
          color="error"
          invisible={unReadMessagesCount > 0 ? false : true}
          badgeContent={unReadMessagesCount}
        >
          <img src={image} alt="sideImage1" />
        </Badge>

        <div>
          <Text
            sx={{ color: "white", fontWeight: 700, ml: 1 }}
            label={label1}
          />
          <Text
            sx={{ color: "white", fontWeight: 700, ml: 1 }}
            label={label2}
          />
        </div>
      </Link>
    </div>
  );
};

export default ExpandIcon;
