import defaultAvatarUrl from "app/assets/images/avatar.png";

interface IAvatar {
  size: string;
  src: string;
}

const Avatar = ({ size, src }: IAvatar) => {
  return (
    <img
      src={src || defaultAvatarUrl}
      alt="avatar"
      style={{
        width: size,
        height: size,
        borderRadius: "50%",
      }}
    />
  );
};

export default Avatar;
