import { MenuItem, Badge, Box } from "@mui/material";
import { styled } from "@mui/system";
import Menu from "generic/Menu/Menu";
import BasicLink from "generic/Links/BasicLink";
import bell from "app/assets/images/sidebar-icons/bell.svg";
import { useEffect, useState } from "react";
import {
  INotificationWithAvatar,
  useGetNotificationsCountQuery,
} from "services/notifications";
import { useLazyFetchNotifications } from "utils/hooks/useLazyFetchNotifications";
import NotificationsList from "./NotificationsList";

const NotificationsBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    color: "white",
    backgroundColor: "#EB3131",
  },
});

const styles = {
  notificationBtn: {
    width: "2.25rem",
    height: "2.25rem",
    background: "#FFFFFF",
    borderRadius: "0.5rem",
    display: "inline-grid",
    placeItems: "center",
  },
};

const ONE_SECOND = 1000;

const NotificationsMenu = () => {
  const [isNotificationsShown, setIsNotificationsShown] =
    useState<boolean>(false);
  const [badgeCount, setBadgeCount] = useState<number>();
  const [notifications, setNotifications] =
    useState<INotificationWithAvatar[]>();

  const fetchNotifications = useLazyFetchNotifications();

  const { data: notificationsCount } = useGetNotificationsCountQuery(
    undefined,
    {
      pollingInterval: 20 * ONE_SECOND,
    }
  );

  const handleToggle = async (isMenuOpen: boolean) => {
    setIsNotificationsShown(isMenuOpen);
    if (isMenuOpen) {
      setBadgeCount(0);
      const notif = await fetchNotifications();
      setNotifications(notif.splice(0, 3));
    }
  };

  useEffect(() => setBadgeCount(notificationsCount), [notificationsCount]);

  return (
    <Menu
      sx={{ py: "0.75rem" }}
      menuButton={
        <NotificationsBadge
          badgeContent={isNotificationsShown ? 0 : badgeCount}
        >
          <Box
            sx={{
              ...styles.notificationBtn,
              border: isNotificationsShown ? "1px solid #136588" : "none",
            }}
          >
            <img src={bell} alt="notification bell" />
          </Box>
        </NotificationsBadge>
      }
      onToggle={handleToggle}
    >
      <NotificationsList notifications={notifications} />

      <MenuItem
        sx={{
          fontWeight: 600,
          fontSize: "14px",
          color: "#172B4D",
          justifyContent: "center",
          paddingTop: "0.25rem",
          paddingBottom: "0.25rem",
        }}
      >
        <BasicLink
          sx={{ fontSize: "1rem", fontWeight: 700 }}
          title="See all notifications"
          href={"/notifications"}
        />
      </MenuItem>
    </Menu>
  );
};

export default NotificationsMenu;
