import { Typography } from "@mui/material";
import Text from "generic/Text/Text";

interface ContactMethodProps {
  label: string;
  value: string;
  valueColor?: string;
  fontSize?: string;
}

const ContactMethod = ({
  label,
  value,
  valueColor = "#1D93C7",
  fontSize = "1rem",
}: ContactMethodProps) => {
  return (
    <>
      <Text
        sx={{
          fontWeight: 600,
          fontSize: "1.125rem",
          lineHeight: "1.375rem",
          color: "#172B4D",
          mb: 1,
        }}
        label={label}
      />
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: `${fontSize}`,
          lineHeight: "1.25rem",
          color: `${valueColor}`,
          wordWrap: "break-word",
        }}
        variant="body2"
      >
        {value}
      </Typography>
    </>
  );
};

export default ContactMethod;
