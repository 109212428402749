export const fillZeroMonths = (months: any, zeroKey: string) => {
  const len = months.length;
  const result = [];

  const getMonth = (month: number) => (month === 0 ? 12 : month);

  const zeroMonthsInRange = (start: number, end: number) =>
    Array(end - start)
      .fill(undefined)
      .map((_, idx) => ({
        group: getMonth((start + idx) % 12).toString(),
        [zeroKey]: 0,
      }));

  for (let i = 0; i < len - 1; i++) {
    result.push(months[i]);
    const currMonth = +months[i].group.split("-")[0];
    const nextMonth = +months[i + 1].group.split("-")[0];

    if (currMonth < nextMonth && nextMonth - currMonth > 1) {
      result.push(...zeroMonthsInRange(currMonth + 1, nextMonth));
    } else if (currMonth > nextMonth) {
      if (currMonth === 12) result.push(...zeroMonthsInRange(1, nextMonth));
      else result.push(...zeroMonthsInRange(currMonth + 1, nextMonth + 12));
    }
  }
  result.push(months[len - 1]);
  return result;
};
