import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { usePaymentTransationsQuery } from "services/insights";
import Pagination from "../../../../generic/Pagination/Pagination";
import NoContentCard from "generic/NoContentCard/NoContentCard";
import TransactionCard from "./TransactionCard";
import TransactionsHeader from "./TransactionsHeader";

const perPage = 6;
const PaymentTransactions = () => {
  const [currPage, setCurrPage] = useState<number>(1);
  const {
    data: response,
    isLoading,
    isSuccess,
  } = usePaymentTransationsQuery({ page: currPage, perPage });

  if (response && response.data.transactions.length === 0) {
    return <NoContentCard message="No payment transactions" />;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <TransactionsHeader />

      <Box>
        {isSuccess &&
          response.data.transactions.map((transaction: any) => (
            <TransactionCard key={transaction.id} {...transaction} />
          ))}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          marginTop: "auto",
        }}
      >
        <Pagination
          perPage={perPage}
          currPage={currPage}
          onChange={setCurrPage}
          pagination={response?.data.pagination}
        />
      </Box>
    </Box>
  );
};

export default PaymentTransactions;
