import { useState } from "react";
import BasicLink from "generic/Links/BasicLink";
import SingleAppointment from "./SingleAppointment";
import { useGetMyAppointmentsQuery } from "services/appointments";
import Pagination from "generic/Pagination/Pagination";
import { Box, Typography } from "@mui/material";

const AppointmentsCard = ({ title, cardStyle, status, perPage, sort }: any) => {
  const [currPage, setCurrPage] = useState(1);

  const { data: myAppointmments } = useGetMyAppointmentsQuery({
    status,
    page: currPage,
    perPage,
    sort,
  });

  return (
    <>
      <div style={{ display: "flex", marginBottom: "30px" }}>
        <Typography
          style={{ color: "#172B4D", fontSize: "1.125rem", fontWeight: 600 }}
        >
          {title}
        </Typography>
        <span style={{ margin: "0 auto" }}></span>
        {title === "Upcoming Appointments" ? (
          <BasicLink title="Request Management" href="/request-management" />
        ) : (
          <Box sx={{ ml: "auto" }}>
            {myAppointmments?.consultations.length ? (
              <Pagination
                perPage={perPage}
                currPage={currPage}
                onChange={setCurrPage}
                pagination={myAppointmments?.pagination}
              />
            ) : null}
          </Box>
        )}
      </div>
      {myAppointmments?.consultations?.map((consultation: any) => (
        <SingleAppointment consultation={consultation} cardStyle={cardStyle} isHomePage={title === "Upcoming Appointments"} />
      ))}
    </>
  );
};

export default AppointmentsCard;
