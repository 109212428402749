import api from "./index";
import { urlRole } from "./index";

const filters = [
  "filters[status][]=patient_created",
  "filters[status][]=waiting_doctor_confirmation",
  "filters[status][]=waiting_patient_confirmation",
  // "filters[status][]=completed",
];

export const emergencyAppointments = api.injectEndpoints({
  endpoints: (builder) => ({
    getEmergencyAppointments: builder.query<any, any>({
      query: ({ page, perPage }) =>
        `/api/v1/${urlRole}/emergency_consultations?${filters.join(
          "&"
        )}&page=${page}&per_page=${perPage}&sort=-slot`,
      providesTags: () => [{ type: "EmergencyAppointments" }],
    }),
  }),
});

export const { useGetEmergencyAppointmentsQuery } = emergencyAppointments;
