import { useState, useEffect } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useCreatePresignedURLMutation } from "services/user";
import { useUploadAWSMutation } from "services/noHeaders";
import { uploadFileAndUpdate } from "utils/fileUpload";
import uploadAttach from "app/assets/images/upload-attach.svg";
import xIcon from "app/assets/icons/x-shadow.svg";
import bookIcon from "app/assets/icons/book.svg";
import BlueButton from "../BlueButton";

const AttachDoctorDocs = ({
  consultation,
  type,
  updateConsultation,
  handleErrorPopup,
}: any) => {
  const [imageFile, setImageFile] = useState<any>(
    consultation?.doctor_uploaded_documents
  );
  const [imagesBlobIds, setImagesBlobIds] = useState<any>([]);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [clearImages, setClearImages] = useState(false);

  const [createPresignedURL] = useCreatePresignedURLMutation();
  const [uploadAWS] = useUploadAWSMutation();

  useEffect(() => {
    if (imagesBlobIds.length > 0 && imagesBlobIds.length == imageFile.length) {
      handleUpdatingConsultation();
    }
    if (clearImages) handleUpdatingConsultation();
  }, [imagesBlobIds, clearImages]);

  const handleUpdatingConsultation = async () => {
    await updateConsultation({
      type,
      id: consultation.id,
      updates: { doctor_uploaded_documents: imagesBlobIds },
    });
    setIsBtnDisabled(true);
    setIsLoading(false);
    handleErrorPopup("Successfully updated the docs sent to the patient");
    window.location.reload();
  };

  const handleImagePreview = (e: any) => {
    if (e.target.files.length) {
      for (let i = 0; i < e.target.files.length; i++) {
        if (e.target.files[i].size > 52428800) {
          handleErrorPopup("File upload size limit is @ 50 MB");
          e.target.value = null;
        } else {
          setImageFile((prevFile: any) => {
            return [
              ...prevFile,
              {
                file: e.target.files[i],
                url: URL.createObjectURL(e.target.files[i]),
              },
            ];
          });
        }
        setIsBtnDisabled(false);
      }
    }
  };

  const handleSendDocsToPatient = async () => {
    setIsLoading(true);
    console.log({ imageFile });
    if (imageFile.length > 0)
      imageFile.map((file: any) => {
        console.log("hereee");
        if (file?.file)
          uploadFileAndUpdate({
            imageFile: file.file,
            createPresignedURL,
            uploadAWS,
            UpdateHook: (blob_signed_id: any) => {
              setImagesBlobIds((prev: any) => {
                return [...prev, blob_signed_id];
              });
            },
          });
        else {
          setImagesBlobIds((prev: any) => {
            return [...prev, file.signed_id];
          });
        }
      });
    else {
      setImagesBlobIds([]);
      setClearImages(true);
    }
  };

  return (
    <>
      <div style={{ display: "flex", marginBottom: "20px" }}>
        <Typography
          style={{
            color: "#172B4D",
            fontSize: "16px",
            fontWeight: 700,
          }}
        >
          Doctors Documentation
        </Typography>
        <span style={{ margin: "0 auto" }}></span>
        {imageFile.length > 0 && (
          <Button
            variant="contained"
            component="label"
            sx={{
              padding: 0,
              boxShadow: "none",
              border: "none",
              ":hover": { boxShadow: "none", border: "none" },
            }}
          >
            <p
              style={{
                fontWeight: 600,
                textTransform: "none",
                fontSize: "16px",
                background: "white",
                color: "#136588",
              }}
            >
              + Add more
            </p>
            <input type="file" hidden multiple onChange={handleImagePreview} />
          </Button>
        )}
      </div>
      {imageFile.length > 0 ? (
        <Grid container>
          {imageFile?.map((doc: any, i: number) => {
            return (
              <div
                style={{
                  background: "#F4F5F8",
                  padding: "10px 15px",
                  borderRadius: "10px",
                  marginBottom: "10px",
                  marginRight: "10px",
                  width: "40%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={bookIcon}
                  alt="Book icon"
                  style={{ marginRight: "10px" }}
                />
                <a
                  href={doc.url}
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: "#252D59",
                  }}
                >
                  <p style={{ color: "#252D59", fontSize: "14px" }}>
                    {doc?.file
                      ? doc?.file.name.slice(0, 13)
                      : doc?.name.slice(0, 13)}
                  </p>
                  <p style={{ color: "#8690A3", fontSize: "14px" }}>
                    {(
                      (doc?.file ? doc.file.size : doc.byte_size) / 1024
                    ).toFixed(1)}{" "}
                    KB
                  </p>
                </a>
                <span style={{ margin: "0 auto" }}></span>
                <img
                  src={xIcon}
                  style={{
                    cursor: "pointer",
                    width: "20px",
                    height: "20px",
                  }}
                  alt="x icon"
                  onClick={() => {
                    const docsFiltered = imageFile.filter(
                      (value: any, index: number) => {
                        return index != i;
                      }
                    );
                    setImageFile(docsFiltered);
                    setIsBtnDisabled(false);
                  }}
                />
              </div>
            );
          })}
        </Grid>
      ) : (
        <Button
          variant="contained"
          component="label"
          sx={{
            color: "#136588",
            background: "#F8F9FC",
            display: "flex",
            flexDirection: "column",
            boxShadow: "none",
            border: "1px dashed #88C8EE",
            padding: "20px 0",
            ":hover": {
              color: "#136588",
              background: "#F8F9FC",
              boxShadow: "none",
            },
          }}
        >
          <img
            src={uploadAttach}
            alt="Input icon"
            style={{ marginBottom: "5px" }}
          />
          <p style={{ textTransform: "none" }}>
            Attach report, prescription, doctors referral... etc
          </p>
          <input
            type="file"
            // accept="image/png, image/gif, image/jpeg"
            hidden
            multiple
            onChange={handleImagePreview}
          />
        </Button>
      )}
      <BlueButton
        style={{ marginTop: "15px" }}
        onClick={handleSendDocsToPatient}
        disabled={isBtnDisabled}
        loading={isLoading}
      >
        Send to patient
      </BlueButton>
    </>
  );
};

export default AttachDoctorDocs;
