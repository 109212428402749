import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandIcon from "./icons/ExpandIcon";
import sideImage1 from "app/assets/images/sidebar-icons/1.svg";
import sideImage2 from "app/assets/images/sidebar-icons/2.svg";
import sideImage3 from "app/assets/images/sidebar-icons/3.svg";
import sideImage4 from "app/assets/images/sidebar-icons/4.svg";
import sideImage5 from "app/assets/images/sidebar-icons/5.svg";
import sideImage6 from "app/assets/images/sidebar-icons/6.svg";
import sideImage7 from "app/assets/images/sidebar-icons/7.svg";
import sideImage8 from "app/assets/images/sidebar-icons/8.svg";
import sideImage9 from "app/assets/images/sidebar-icons/9.svg";
import sideImage10 from "app/assets/images/sidebar-icons/10.svg";
import { urlRole } from "services";

const ExpandedBar = ({ setExpandedBar, unReadMessagesCount }: any) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      item
      direction="column"
      sx={{
        height: "100%",
        background: "#136588",
        padding: "10px",
        borderRadius: "20px",
        maxWidth: "240px",
        alignItems: "flex-start",
      }}
    >
      <ExpandIcon
        image={sideImage1}
        setExpandedBar={setExpandedBar}
        index={0}
      />

      <ExpandIcon
        image={sideImage2}
        label1={t("Dashboard", "Dashboard")}
        index={1}
        navigationURL={"/"}
      />
      <ExpandIcon
        image={sideImage3}
        label1={t("Request", "Request")}
        label2={t("Management", "Management")}
        index={2}
        navigationURL={"/request-management"}
      />
      <ExpandIcon
        image={sideImage4}
        label1={t("Messages", "Messages")}
        index={3}
        navigationURL={"/messages"}
        unReadMessagesCount={unReadMessagesCount}
      />
      <ExpandIcon
        image={sideImage5}
        label1={t("Revenue", "Revenue")}
        index={4}
        navigationURL={"/revenue"}
      />
      {urlRole == "doctor" && (
        <ExpandIcon
          image={sideImage6}
          label1={t("Emergency", "Emergency")}
          label2={t("Appointment", "Appointment")}
          index={5}
          navigationURL={"/emergency-appointments"}
        />
      )}
      {urlRole == "doctor" && (
        <ExpandIcon
          image={sideImage7}
          label1={t("Calendar", "Calendar")}
          index={6}
          navigationURL={"/calendar"}
        />
      )}
      <ExpandIcon
        image={sideImage8}
        label1={t("Insights", "Insights")}
        index={7}
        navigationURL={"/insights"}
      />
      <ExpandIcon
        image={sideImage10}
        label1={
          urlRole == "organization"
            ? t("List of Doctors", "List of Doctors")
            : t("Doctor Profile", "Doctor Profile")
        }
        index={8}
        navigationURL={
          urlRole == "organization"
            ? "/profile-settings?tabId=2"
            : "/profile-settings?tabId=0"
        }
      />
      <div style={{ margin: "auto" }}></div>
      <ExpandIcon
        image={sideImage9}
        index={9}
        label1={t("Account Settings", "Account Settings")}
        navigationURL={"/account-settings?tabId=0"}
      />
    </Grid>
  );
};

export default ExpandedBar;
