import { Box, Typography } from "@mui/material";
import StatusIndicator from "./StatusIndicator";

interface IChangePercentage {
  changePercentage: number;
}

const ChangePercentage = ({ changePercentage }: IChangePercentage) => {
  const fontColor = (() => {
    if (changePercentage === 0) return "#172B4D";
    if (changePercentage > 0) return "#1D93C7";
    if (changePercentage < 0) return "#CC0000";
  })();

  return (
    <Box
      sx={{
        display: "flex",
        gap: "0.5rem",
        alignItems: "center",
      }}
    >
      <StatusIndicator changePercentage={changePercentage} />
      <Typography
        sx={{
          fontSize: "0.875rem",
          fontWeight: 600,
          color: fontColor,
        }}
      >
        {Math.abs(+changePercentage.toFixed(2))} %
      </Typography>
    </Box>
  );
};

export default ChangePercentage;
