import { fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { prepareHeaders } from "./prepareHeaders";

export const staggeredBaseQueryWithBailOut =
  (baseUrl: string, addHeaders: boolean) =>
  async (args: any, api: any, extraOptions: any) => {
    const result = await fetchBaseQuery(
      addHeaders ? { baseUrl, prepareHeaders } : { baseUrl }
    )(args, api, extraOptions);
    // bail out of re-tries immediately if unauthorized,
    // because we know successive re-retries would be redundant
    if (
      result.error?.status === 401 ||
      result.error?.status === 403 ||
      result.error?.status === 500
    ) {
      retry.fail(result.error);
    }
    return result;
  };
