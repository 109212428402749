import { Box, Typography } from "@mui/material";
import StatusIndicator from "./StatusIndicator";

interface IChangePercentage {
  curr: number;
  prev: number;
}

const ChangePercentage = ({ curr, prev }: IChangePercentage) => {
  const changePercentage = (() => {
    if (curr === 0) {
      if (curr - prev === 0) return 0;
      return -100;
    }
    return +(((curr - prev) / curr) * 100).toFixed(2);
  })();

  const fontColor = (() => {
    if (changePercentage === 0) return "#172B4D";
    if (changePercentage > 0) return "#09C0D1";
    if (changePercentage < 0) return "#EB3131";
  })();

  return (
    <Box sx={{ display: "flex", gap: "0.5rem" }}>
      <StatusIndicator changePercentage={changePercentage} />
      <Typography
        sx={{
          width: "3.375rem",
          fontSize: "0.875rem",
          fontWeight: 600,
          lineHeight: "1.25rem",
          color: fontColor,
        }}
      >
        {Math.abs(changePercentage)}
        <span style={{ marginLeft: "0.2rem" }}>%</span>
      </Typography>
    </Box>
  );
};

export default ChangePercentage;
