import { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  Container,
  Grid,
  Typography,
  Box,
  Link,
  Button,
  IconButton,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import * as yup from "yup";
import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import { isLoading, setLoading } from "app/store/genericSlice";
import { useCreateCustomTokenFirebaseMutation } from "services/user";
import { useLoginMutation } from "services/authApi";
import Logo from "app/assets/images/logo.svg";
import LoginMainImage from "app/assets/images/login-right.png";
import { useAppSelector, useAppDispatch } from "app/store/hooks";
import { cognitoLogin, serverLogin } from "../authSlice";
import PrimaryButton from "generic/Buttons/PrimaryButton";
import InputWithLabel from "generic/Inputs/InputWithLabel";
import BasicLink from "generic/Links/BasicLink";
import Text from "generic/Text/Text";
import { EmailIcon, EyeIcon, PasswordIcon } from "app/assets/icons";
import { storeUserData } from "../authSlice";
import api from "services";
import { signInWithCustomToken } from "firebase/auth";
import { auth } from "../../../firebase";
import Snackbar from "generic/Snackbar/Snackbar";

const LargeImage = styled("img")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

const Login = () => {
  const loading = useAppSelector(isLoading);
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const { t } = useTranslation();

  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [type, setType] = useState("password");
  const [submitLogin, setSubmitLogin] = useState(false);

  const [login, response] = useLoginMutation();
  const [CreateCustomToken, CustomToken] =
    useCreateCustomTokenFirebaseMutation();

  const handleNavigation = () => {
    const invitationToken = localStorage.getItem("invitationToken");
    if (invitationToken) {
      navigate(`/invitations?token=${invitationToken}`);
    } else {
      window.location.href = "/";
    }
  };

  const handleLogin = async (email: string, password: string) => {
    try {
      dispatch(setLoading(true));
      const cognitoLogin = await Auth.signIn(email, password);
      const result: any = await login({
        id_token: cognitoLogin.signInUserSession.idToken.jwtToken,
      });
      console.log({ result });
      dispatch(setLoading(false));
      if (result.data.status == "success") {
        localStorage.setItem(
          "accessToken",
          cognitoLogin.signInUserSession.accessToken.jwtToken
        );
        localStorage.setItem(
          "idToken",
          cognitoLogin.signInUserSession.idToken.jwtToken
        );
        localStorage.setItem("role", result?.data?.data?.role);
        dispatch(storeUserData(result.data.data));

        const OneSignal = window.OneSignal || [];
        OneSignal.push(function () {
          OneSignal.setExternalUserId(
            result.data.data.one_signal.external_id,
            result.data.data.one_signal.external_id_hash
          );
        });

        dispatch(api.util.resetApiState());
        // const invitationToken = localStorage.getItem("invitationToken");
        // if (invitationToken) {
        //   navigate(`/invitations?token=${invitationToken}`);
        // } else {
        //   navigate("/");
        //   // window.location.href = "/";
        // }
      }
    } catch (e: any) {
      dispatch(setLoading(false));
      setIsError(true);
      setErrorMsg(e.message);

      if (e.name === "UserNotConfirmedException") {
        navigate("/signup", { state: { email, step: 3, role: "doctor" } });
      }
    }
  };
  const createFirebaseToken = () => {
    CreateCustomToken("").then((res: any) => {
      console.log({ auth, res });
      signInWithCustomToken(auth, res.data.data.token)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          console.log("userCredential", user);
          handleNavigation();
          // ...
        })
        .catch((error) => {
          // const errorCode = error.code;
          const errorMessage = error.message;
          console.log("errorMessage", errorMessage);
          handleNavigation();
          // ...
        });
    });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,

    onSubmit: (values) => {
      handleLogin(values.email, values.password).then(() => {
        createFirebaseToken();
      });
    },
  });

  return (
    <Grid container style={{ height: "100vh" }}>
      <Grid item xs={12} sm={12} md={8} style={{ height: "80%" }}>
        <Container
          sx={{ m: 8 }}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <img src={Logo} alt="login image" style={{ width: "150px" }} />
          <form
            style={{ margin: "auto 0", maxWidth: "60%" }}
            onSubmit={formik.handleSubmit}
          >
            <Text
              sx={{ fontWeight: 600, fontSize: "32px", color: "#172B4D" }}
              label="Log In"
            />
            <Text
              sx={{
                mb: 4,
                fontWeight: 500,
                fontSize: "14px",
                color: "#172B4D",
              }}
              label="Welcome back, login with your credentials to access your account."
            />

            <InputWithLabel
              label="Email Address*"
              id="email"
              name="email"
              placeholder={t("Enter Email Address", "Enter Email Address")}
              autoFocus={true}
              startIcon={<EmailIcon />}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />

            <InputWithLabel
              label="Password*"
              id="password"
              name="password"
              placeholder={t("Enter Password", "Enter Password")}
              type={type}
              endIcon={<EyeIcon />}
              endIconOnClick={() =>
                setType((prevType: string) =>
                  prevType === "password" ? "text" : "password"
                )
              }
              startIcon={<PasswordIcon />}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />

            <Link
              href="/forgot-password"
              variant="body2"
              sx={{
                color: "#136588",
                textDecoration: "none",
                fontWeight: 600,
                float: "right",
              }}
            >
              <Trans>Forgot password?</Trans>
            </Link>

            <PrimaryButton title="Log In" type="submit" loading={loading} />

            <Typography sx={{ color: "#172B4D", fontWeight: 400 }}>
              <Trans>Don't have an account?</Trans>
              <BasicLink title="Sign Up" href={"/signup"} />
            </Typography>

            <Snackbar
              open={isError}
              onClose={() => setIsError(false)}
              message={errorMsg}
            />
          </form>
        </Container>
      </Grid>
      <Grid
        container
        item
        xs={4}
        justifyContent="flex-end"
        style={{ height: "100%" }}
      >
        <LargeImage
          src={LoginMainImage}
          alt="login image"
          style={{ height: "100%" }}
        />
      </Grid>
    </Grid>
  );
};

export default Login;
