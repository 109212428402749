import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store/store";
import { cognitoLoginService, serverLoginService } from "./login/service";

export interface LoginState {
  loading: boolean;
  loginSuccess: boolean;
  user: any;
}

export interface userCredentials {
  email: string;
  password: string;
}

const initialState: LoginState = {
  loading: false,
  loginSuccess: false,
  user: {},
};

export const cognitoLogin = createAsyncThunk(
  "registration/login",
  async (userCredentials: userCredentials) => {
    const response = await cognitoLoginService(
      userCredentials.email,
      userCredentials.password
    );
    return response.signInUserSession.idToken.jwtToken;
  }
);

export const serverLogin = createAsyncThunk(
  "registration/login",
  async (token: string) => {
    const response = await serverLoginService(token);
    return response.signInUserSession.idToken.jwtToken;
  }
);

export const authSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    storeUserData(state, action: PayloadAction<any>) {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(cognitoLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(cognitoLogin.fulfilled, (state, action) => {
        // console.log({ state, action });
        state.loading = true;
        state.loginSuccess = true;
      });
  },
});

export const loginSuccess = (state: RootState) => state.auth.loginSuccess;

export const userData = (state: RootState) => state.auth.user;
export const { storeUserData } = authSlice.actions;

export default authSlice.reducer;
