import { Select, InputLabel } from "@mui/material";
import Text from "generic/Text/Text";

const InputSelect = ({
  id,
  name,
  label,
  value,
  onChange,
  error,
  placeholder,
  children,
}: any) => {
  return (
    <>
      <InputLabel
        sx={{
          mb: 1,
          fontWeight: 600,
          fontSize: "14px",
          color: "#172B4D",
        }}
      >
        {label}
      </InputLabel>
      <Select
        id={id}
        name={name}
        displayEmpty
        value={value}
        onChange={onChange}
        error={error}
        renderValue={(selected: any) => {
          if (selected?.length === 0) {
            return <Text sx={{ color: "#909090" }} label={placeholder} />;
          }

          return selected;
        }}
        sx={{ width: "100%", mb: 2 }}
      >
        {children}
      </Select>
    </>
  );
};
export default InputSelect;
