import { useState, useEffect } from "react";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  Divider,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
} from "@mui/material";
import Text from "generic/Text/Text";
import { XIcon } from "app/assets/icons";
import PrimaryButton from "generic/Buttons/PrimaryButton";
import {
  useGetAllInsuranceCompaniesQuery,
  useGetAllEmbassiesQuery,
} from "services/profileSettings";
import InputWithLabel from "generic/Inputs/InputWithLabel";
import { useUpdateProfileMutation } from "services/profileSettings";
import { PlusIcon } from "app/assets/icons";
import Snackbar from "generic/Snackbar/Snackbar";

interface Company {
  id: number;
  name: string;
}
interface Embassy {
  id: number;
  country: string;
}

const Insurance = ({ doctorData }: any) => {
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [customCompany, setCustomCompany] = useState("");

  const [updateProfile, updateProfileResult] = useUpdateProfileMutation();
  const {
    data: companiesData,
    error: companiesError,
    isLoading: companiesLoading,
    isSuccess: companiesSuccess,
  } = useGetAllInsuranceCompaniesQuery();

  const {
    data: embassiesData,
    error: embassiesError,
    isLoading: embassiesLoading,
    isSuccess: embassiesSuccess,
  } = useGetAllEmbassiesQuery();

  const [allCompanies, setAllCompanies] = useState<Company[]>([]);
  const [companies, setCompanies] = useState<any[]>([]);

  const [allEmbassies, setAllEmbassies] = useState<Embassy[]>([]);
  const [embassies, setEmbassies] = useState<any[]>([]);

  const [customCompanies, setCustomCompanies] = useState<any[]>([]);

  const [someonePay, setSomeonePay] = useState(false);
  const [costCoverage, setCostCoverage] = useState(false);

  const embassiesAttribute =
    localStorage.getItem("role") == "doctor"
      ? "doctor_embassies_attributes"
      : "organization_embassies_attributes";

  const insuranceCompaniesAttribute =
    localStorage.getItem("role") == "doctor"
      ? "doctor_insurance_companies_attributes"
      : "organization_insurance_companies_attributes";

  useEffect(() => {
    setCompanies(doctorData?.insurance_companies);
    setEmbassies(doctorData?.embassies);
    setCustomCompanies(doctorData?.custom_insurance_companies);
    setSomeonePay(doctorData?.accept_another_pay);
    setCostCoverage(doctorData?.accept_client_cover_cost);
  }, [doctorData]);

  useEffect(() => {
    if (companiesData?.length) setAllCompanies(companiesData);
  }, [companiesData]);

  useEffect(() => {
    if (embassiesData?.length) setAllEmbassies(embassiesData);
  }, [embassiesData]);

  const handleSelectCompany = (e: any) => {
    const isExist = companies.filter(
      (comp) => comp.insurance_company_id == e.target.value
    );
    const addedComp = allCompanies.find((comp) => comp.id == e.target.value);
    if (isExist.length) {
      if (isExist[0]._destroy)
        setCompanies((prevComps: any) => {
          let updatedArray = prevComps.map((comp: any) => {
            if (comp.insurance_company_id == e.target.value)
              return { ...comp, _destroy: 0 };
            else return comp;
          });
          return updatedArray;
        });
    } else {
      setCompanies((prevComps: any) => {
        return [
          ...prevComps,
          { insurance_company_id: addedComp?.id, name: addedComp?.name },
        ];
      });
    }
  };

  const handleSelectEmbassy = (e: any) => {
    const isExist = embassies.filter((emb) => emb.embassy_id == e.target.value);
    const addedEmb = allEmbassies.find((emb) => emb.id == e.target.value);
    if (isExist.length) {
      setEmbassies((prevEmbs: any) => {
        let updatedArray = prevEmbs.map((emb: any) => {
          if (emb.embassy_id == e.target.value) return { ...emb, _destroy: 0 };
          else return emb;
        });
        return updatedArray;
      });
    } else {
      setEmbassies((prevEmbs: any) => {
        return [
          ...prevEmbs,
          { embassy_id: addedEmb?.id, country: addedEmb?.country },
        ];
      });
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    await updateProfile({
      accept_another_pay: someonePay,
      accept_client_cover_cost: costCoverage,
      [embassiesAttribute]: embassies,
      [insuranceCompaniesAttribute]: companies,
      custom_insurance_companies_attributes: customCompanies,
    });

    setLoading(false);
    setIsError(true);
    setErrorMsg("successfully updated your profile");
  };

  return (
    <form
      style={{ margin: "auto 0", maxWidth: "100%" }}
      onSubmit={(e: any) => handleSubmit(e)}
    >
      <Grid container>
        <Grid item xs={12} sm={12} md={6}>
          <Text
            sx={{ color: "#172B4D", fontSize: "14px", fontWeight: 600, mb: 1 }}
            label="Accredited insurance companies"
          />
          <Select
            id="insurance_company"
            displayEmpty
            value=""
            onChange={(e) => handleSelectCompany(e)}
            renderValue={(selected: any) => {
              if (selected.length === 0) {
                return (
                  <Text sx={{ color: "#909090" }} label="Search Companies" />
                );
              }

              return selected;
            }}
            sx={{ width: "100%", mb: 2 }}
          >
            {allCompanies.map((comp) => (
              <MenuItem value={comp.id}>{comp.name}</MenuItem>
            ))}
          </Select>

          <Grid container>
            {companies?.length
              ? companies.map(
                  (company) =>
                    !company._destroy && (
                      <Grid item sx={{ mb: 2 }}>
                        <InputLabel
                          sx={{
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "#136588",
                            display: "inline",
                            background: "#F4F5F8",
                            padding: "10px",
                            mr: 2,
                            borderRadius: "5px",
                          }}
                        >
                          {company.name}{" "}
                          <IconButton
                            onClick={() => {
                              setCompanies((prevcomps: any) => {
                                let filtered = prevcomps.filter(
                                  (comp: any) =>
                                    comp.insurance_company_id !=
                                    company.insurance_company_id
                                );
                                return [
                                  ...filtered,
                                  { ...company, _destroy: 1 },
                                ];
                              });
                            }}
                          >
                            <XIcon
                              style={{
                                marginBottom: "2px",
                                marginLeft: "-2px",
                              }}
                            />
                          </IconButton>
                        </InputLabel>
                      </Grid>
                    )
                )
              : null}
          </Grid>

          <InputWithLabel
            label="Add custom company"
            id="custom_company"
            name="custom_company"
            placeholder="Enter Custom Company"
            required={false}
            value={customCompany}
            onChange={(e: any) => setCustomCompany(e.target.value)}
            endIcon={<PlusIcon />}
            endIconOnClick={() => {
              let isCompanyExist = customCompanies.filter(
                (singleComp: any) => singleComp.name == customCompany
              );
              if (isCompanyExist.length === 0)
                setCustomCompanies((prev: any) => [
                  ...prev,
                  { name: customCompany },
                ]);
              setCustomCompany("");
            }}
          />

          <Grid container>
            {customCompanies?.length
              ? customCompanies.map(
                  (company) =>
                    !company._destroy && (
                      <Grid item sx={{ mb: 2 }}>
                        <InputLabel
                          sx={{
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "#136588",
                            display: "inline",
                            background: "#F4F5F8",
                            padding: "10px",
                            mr: 2,
                            borderRadius: "5px",
                          }}
                        >
                          {company.name}{" "}
                          <IconButton
                            onClick={() => {
                              setCustomCompanies((prevcomps: any) => {
                                let filtered = prevcomps.filter(
                                  (comp: any) => comp.name != company.name
                                );
                                return [
                                  ...filtered,
                                  { ...company, _destroy: 1 },
                                ];
                              });
                            }}
                          >
                            <XIcon
                              style={{
                                marginBottom: "2px",
                                marginLeft: "-2px",
                              }}
                            />
                          </IconButton>
                        </InputLabel>
                      </Grid>
                    )
                )
              : null}
          </Grid>

          <Divider sx={{ mt: 1, mb: 3 }} />

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={someonePay}
                  onChange={() => setSomeonePay((prev: boolean) => !prev)}
                />
              }
              disableTypography
              label="I accept that someone else than the client itself pays"
            />
          </FormGroup>

          <Divider sx={{ my: 3 }} />

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={costCoverage}
                  onChange={() => setCostCoverage((prev: boolean) => !prev)}
                />
              }
              disableTypography
              label="I accept that the client itself covers the cost"
            />
          </FormGroup>

          <Divider sx={{ my: 3 }} />

          <Text
            sx={{ color: "#172B4D", fontSize: "14px", fontWeight: 600, mb: 1 }}
            label="Select which Embassys i accept to cover the cost"
          />
          <Select
            id="insurance_company"
            displayEmpty
            value=""
            onChange={(e) => handleSelectEmbassy(e)}
            renderValue={(selected: any) => {
              if (selected.length === 0) {
                return (
                  <Text sx={{ color: "#909090" }} label="Search Embassys" />
                );
              }

              return selected;
            }}
            sx={{ width: "100%", mb: 2 }}
          >
            {allEmbassies.map((emb) => (
              <MenuItem value={emb.id}>{emb.country}</MenuItem>
            ))}
          </Select>

          <Grid container>
            {embassies?.length
              ? embassies.map(
                  (embassy) =>
                    !embassy._destroy && (
                      <Grid item sx={{ mb: 2 }}>
                        <InputLabel
                          sx={{
                            fontWeight: 500,
                            fontSize: "1rem",
                            color: "#136588",
                            display: "inline",
                            background: "#F4F5F8",
                            padding: "10px",
                            mr: 2,
                            borderRadius: "5px",
                          }}
                        >
                          {embassy.country}{" "}
                          <IconButton
                            onClick={() => {
                              setEmbassies((prevEmbs: any) => {
                                let filtered = prevEmbs.filter(
                                  (emb: any) =>
                                    emb.embassy_id != embassy.embassy_id
                                );
                                return [
                                  ...filtered,
                                  { ...embassy, _destroy: 1 },
                                ];
                              });
                            }}
                          >
                            <XIcon
                              style={{
                                marginBottom: "2px",
                                marginLeft: "-2px",
                              }}
                            />
                          </IconButton>
                        </InputLabel>
                      </Grid>
                    )
                )
              : null}
          </Grid>
        </Grid>
      </Grid>
      <PrimaryButton
        title="Save Changes"
        type="submit"
        loading={loading}
        sx={{ fontSize: "1rem", fontWeight: 600 }}
      />
      <Snackbar
        open={isError}
        onClose={() => setIsError(false)}
        message={errorMsg}
      />
    </form>
  );
};

export default Insurance;
