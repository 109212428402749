import { createApi } from "@reduxjs/toolkit/query/react";
import { staggeredBaseQueryWithBailOut } from "utils/servicesUtilities";

export const noHeadersApi = createApi({
  reducerPath: "noHeadersapi",
  baseQuery: staggeredBaseQueryWithBailOut(
    `${process.env.REACT_APP_API_URL}`,
    false
  ),
  tagTypes: [],
  endpoints: (builder) => ({
    uploadAWS: builder.mutation({
      query: ({ url, headers, body }) => ({
        url,
        method: "PUT",
        headers,
        body,
      }),
    }),

    checkEmail: builder.query<any, string | void>({
      query: (email) => {
        return {
          url: `/api/v1/auth/check_email/${email}`,
          headers: {
            Authorization:
              "Basic " +
              btoa(
                unescape(
                  encodeURIComponent(
                    "leviomed_basic" + ":" + "c6*nk7%3Fv!pC8Jq"
                  )
                )
              ),
            "accept-language": `en`,
          },
        };
      },
    }),

    getTermsGeneral: builder.query<any, any>({
      query: ({ lang = "en", termType = 0 }: any) => {
        return {
          url: `/api/v1/admin/system_terms?locale=${lang}&filters[term_type]=${termType}&filters[role]=1`,
          headers: {
            "accept-language": `en`,
          },
        };
      },
    }),

    getInvitationData: builder.query<any, any>({
      query: (token: string) => {
        return {
          url: `/api/v1/invitations/${token}`,
        };
      },
    }),
  }),
});

export const {
  useUploadAWSMutation,
  useLazyCheckEmailQuery,
  useGetTermsGeneralQuery,
  useGetInvitationDataQuery,
} = noHeadersApi;
