import { useEffect, useState } from "react";
import { Grid, Typography, Box, styled } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import Layout from "modules/layout/Layout";
import Card from "generic/Cards/Card";
import EditEmail from "./tabs/EditEmail";
import ChangePassword from "./tabs/ChangePassword";
import TermsAndConditions from "./tabs/TermsAndConditions";
import PrivacyPolicy from "./tabs/PrivacyPolicy";
import Impress from "./tabs/Impress";
import ContactAndHelp from "./tabs/ContactAndHelp/ContactAndHelp";
import FAQ from "./tabs/FAQ/FAQ";
import DeleteAccount from "./tabs/DeleteAccount";
import ReportPatient from "./tabs/ReportPatient/ReportPatient";
import Tab from "generic/Tabs/Tab";
import Tabs from "generic/Tabs/Tabs";
import { useGetMyProfileQuery } from "services/profileSettings";
import { urlRole, profileUser } from "services";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{ p: 3, height: "100%" }}
      {...other}
    >
      {value === index && <>{children}</>}
    </Box>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AccountSettings = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState(searchParams.get("tabId"));

  const { data } = useGetMyProfileQuery();

  useEffect(() => setSearchParams({ tabId: value ? value : "0" }), []);

  const handleChange = (event: any, newValue: string) => {
    setValue(newValue);
    setSearchParams({ tabId: newValue });
  };

  return (
    <Layout title="Account Settings">
      <Grid
        container
        item
        direction="row"
        sx={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
        <Card
          xs={12}
          sx={{
            width: "100%",
            overflowY: "hidden",
            border: 0,
            mt: "0.625rem",
            pt: 0,
            pb: "1.5rem",
            px: 0,
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ borderBottom: 1, borderColor: "#DFE1E6", px: "1.5rem" }}>
              <Tabs
                value={value ? parseInt(value) : 0}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="profile tabs"
              >
                <Tab label="Edit Email" {...a11yProps(0)} />
                <Tab label="Change Password" {...a11yProps(1)} />
                <Tab label="Terms and Conditions" {...a11yProps(2)} />
                <Tab label="Privacy Policy" {...a11yProps(3)} />
                <Tab label="Impress" {...a11yProps(4)} />
                <Tab label="Contact and Help" {...a11yProps(5)} />
                <Tab label="FAQ" {...a11yProps(6)} />
                <Tab label="Delete Account" {...a11yProps(7)} />
                <Tab label="Report a patient" {...a11yProps(8)} />
              </Tabs>
            </Box>
            <Box sx={{ px: "0.5rem" }}>
              <TabPanel value={value ? parseInt(value) : 0} index={0}>
                <EditEmail userData={data?.[urlRole]?.[profileUser]} />
              </TabPanel>
              <TabPanel value={value ? parseInt(value) : 0} index={1}>
                <ChangePassword />
              </TabPanel>
              <TabPanel value={value ? parseInt(value) : 0} index={2}>
                <TermsAndConditions />
              </TabPanel>
              <TabPanel value={value ? parseInt(value) : 0} index={3}>
                <PrivacyPolicy />
              </TabPanel>
              <TabPanel value={value ? parseInt(value) : 0} index={4}>
                <Impress />
              </TabPanel>
              <TabPanel value={value ? parseInt(value) : 0} index={5}>
                <ContactAndHelp />
              </TabPanel>
              <TabPanel
                sx={{ px: "1rem", py: "1.5rem" }}
                value={value ? parseInt(value) : 0}
                index={6}
              >
                <FAQ />
              </TabPanel>
              <TabPanel value={value ? parseInt(value) : 0} index={7}>
                <DeleteAccount />
              </TabPanel>
              <TabPanel value={value ? parseInt(value) : 0} index={8}>
                <ReportPatient />
              </TabPanel>
            </Box>
          </Box>
        </Card>
      </Grid>
    </Layout>
  );
};

export default AccountSettings;
