import { Box, Typography } from "@mui/material";
import moment from "moment";
import Rating from "./Rating";
import Avatar from "app/assets/images/avatar.png";

interface IReviewCard {
  avatarUrl: string;
  reviewerName: string;
  date: string;
  comment: string;
  ratingPercentage: number;
}
const ReviewCard = ({
  avatarUrl,
  reviewerName,
  date,
  comment,
  ratingPercentage,
}: IReviewCard) => {
  return (
    <Box style={{ display: "flex" }}>
      <img
        src={avatarUrl || Avatar}
        style={{
          width: "3.75rem",
          height: "3.75rem",
          marginRight: "1rem",
          borderRadius: "50%",
        }}
        alt="reviewer avatar"
      />
      <Box sx={{ flexGrow: 1 }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="subtitle2"
            sx={{ fontSize: "1rem", color: "#172B4D", fontWeight: 700 }}
          >
            {reviewerName}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              color: "#7F8CA9",
              fontSize: "14px",
              ml: "auto",
              fontWeight: 400,
            }}
          >
            {moment(date).format("DD/MM/yyyy")}
          </Typography>
        </div>
        <Rating ratingPercentage={ratingPercentage} />
        <Typography
          variant="body2"
          sx={{
            fontSize: "1rem",
            overflowWrap: "break-word",
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            wordBreak: "break-word",
            color: "#172B4D",
          }}
        >
          {comment}
        </Typography>
      </Box>
    </Box>
  );
};

export default ReviewCard;
