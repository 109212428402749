import { Box } from "@mui/material";
import { StatsArrowIcon } from "app/assets/icons";

const StatusIndicator = ({
  changePercentage,
}: {
  changePercentage: number;
}) => {
  const arrowColor = changePercentage > 0 ? "#09C0D1" : "#EB3131";
  const arrowDirection = changePercentage > 0 ? "" : "rotate(180deg)";

  return (
    <Box sx={{ display: "inline-grid", placeItems: "center" }}>
      {changePercentage === 0 ? (
        <Box
          sx={{ backgroundColor: "#172B4D", width: "0.7rem", height: "0.2rem" }}
        />
      ) : (
        <StatsArrowIcon
          style={{ color: arrowColor, transform: arrowDirection }}
        />
      )}
    </Box>
  );
};

export default StatusIndicator;
