import { Box, Divider } from "@mui/material";
import Loader from "generic/Loader/loader";
import { usePatientsStatsQuery } from "services/insights";
import NoContentCard from "generic/NoContentCard/NoContentCard";
import PatientsStatsCard, { IGenderStats } from "./PatientsStatsCard";

const PatientsStats = () => {
  const { data: stats, isLoading } = usePatientsStatsQuery();

  if (isLoading) return <Loader />;

  if (stats && stats.length === 0) {
    return <NoContentCard message="No patients Analytics" />;
  }

  const currStats = stats[stats.length - 1];
  const prevStats = stats[stats.length - 2];

  const maleStats: IGenderStats = {
    prev: prevStats
      ? {
          genderCount: prevStats.male_count,
          totalCount: prevStats.total_count,
        }
      : null,
    curr: {
      genderCount: currStats.male_count,
      totalCount: currStats.total_count,
    },
  };

  const femaleStats: IGenderStats = {
    prev: prevStats
      ? {
          genderCount: prevStats.female_count,
          totalCount: prevStats.total_count,
        }
      : null,
    curr: {
      genderCount: currStats.female_count,
      totalCount: currStats.total_count,
    },
  };

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <PatientsStatsCard
        gender="Male"
        genderStats={maleStats}
        sx={{ flexGrow: 0.5 }}
      />
      <Divider sx={{ borderColor: "#DFE1E6", my: "1.5rem" }} />
      <PatientsStatsCard
        gender="Female"
        genderStats={femaleStats}
        sx={{ flexGrow: 0.5 }}
      />
    </Box>
  );
};

export default PatientsStats;
