import crypto from "crypto-js";
import { Buffer } from "buffer";
import { resizeFile } from "./resizeFile";

const calculateChecksum = (imageFile: any, callback: any) => {
  console.log({ imageFile });
  var reader = new FileReader();

  reader.addEventListener("load", function () {
    const result: any = this.result;
    var wordArray = crypto.lib.WordArray.create(result);
    var md5 = crypto.MD5(wordArray).toString();
    var base64 = Buffer.from(md5, "hex").toString("base64");
    callback(base64);
  });

  reader.readAsArrayBuffer(imageFile);
};

const createPreSignedRequestBody = (imageFile: any, checkSum: any) => {
  return {
    filename: imageFile.name,
    checksum: checkSum,
    byte_size: imageFile.size,
    content_type: imageFile.type,
    metadata: {
      message: "test",
    },
  };
};

export const uploadFileAndUpdate = async ({
  imageFile,
  createPresignedURL,
  uploadAWS,
  UpdateHook,
  resize,
}: any) => {
  let file: any;
  if (resize) file = await resizeFile(imageFile);
  else file = imageFile;

  calculateChecksum(file, async (checkSum: any) => {
    const presignedURL: any = await createPresignedURL(
      createPreSignedRequestBody(file, checkSum)
    );

    const uploadedFile = await uploadAWS({
      url: presignedURL.data.direct_upload.url,
      headers: presignedURL.data.direct_upload.headers,
      body: file,
    });
    UpdateHook(
      presignedURL.data.blob_signed_id,
      presignedURL.data.direct_upload.url
    );
  });
};
