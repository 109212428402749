import { Button, CircularProgress } from "@mui/material";
import { Trans } from "react-i18next";

const LoadingButton = ({ ...rest }: any) => (
  <Button
    fullWidth
    variant="contained"
    disabled
    sx={{
      my: 3,
      background: "#136588",
      color: "#FFFFFF",
      fontWeight: 600,
      fontSize: "14px",
      width: "200px",
      height: "50px",
      textTransform: "none",
      ...rest,
    }}
  >
    <CircularProgress />
  </Button>
);

export default LoadingButton;
