import Card from "generic/Cards/Card";
import BasicLink from "generic/Links/BasicLink";
import { Box, Typography } from "@mui/material";
import { useGetReviewsQuery } from "services/reviews";
import ReviewsList from "modules/insights/components/reviews/ReviewsList";
import NoContentCard from "generic/NoContentCard/NoContentCard";

const LatestReviews = () => {
  const {
    data: response,
    isLoading,
    isSuccess,
  } = useGetReviewsQuery({
    page: 1,
    perPage: 3,
  });

  return (
    <Card xs={12} sm={12} md={6}>
      <Box sx={{ display: "flex", marginBottom: "2.125rem" }}>
        <Typography
          style={{ color: "#172B4D", fontSize: "1.125rem", fontWeight: 600 }}
        >
          Latest Reviews
        </Typography>
        <BasicLink
          sx={{ ml: "auto", fontWeight: 700 }}
          title="View All"
          href="/insights"
        />
      </Box>
      {isSuccess && (
        <>
          {response?.data.length ? (
            <ReviewsList reviews={response?.data} />
          ) : (
            <Box>
              <NoContentCard message="No reviews" />
            </Box>
          )}
        </>
      )}
    </Card>
  );
};

export default LatestReviews;
