import { Box, Typography } from "@mui/material";
import NoContentCard from "generic/NoContentCard/NoContentCard";
import Pagination from "generic/Pagination/Pagination";
import { useState } from "react";
import { useGetReviewsQuery } from "services/reviews";
import ReviewsList from "./ReviewsList";

const perPage = 3;

const Reviews = () => {
  const [currPage, setCurrPage] = useState<number>(1);

  const {
    data: response,
    isLoading,
    isSuccess,
  } = useGetReviewsQuery({
    page: currPage,
    perPage: 3,
  });

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "2.125rem",
        }}
      >
        <Typography
          style={{
            color: "#172B4D",
            fontSize: "1.125rem",
            fontWeight: 600,
          }}
        >
          Reviews
        </Typography>
        <Box sx={{ ml: "auto" }}>
          {response?.data.length > 0 && (
            <Pagination
              perPage={perPage}
              currPage={currPage}
              onChange={setCurrPage}
              pagination={response?.pagination}
            />
          )}
        </Box>
      </Box>

      {isSuccess && (
        <>
          {response?.data.length ? (
            <ReviewsList reviews={response?.data} />
          ) : (
            <NoContentCard message="No reviews" />
          )}
        </>
      )}
    </Box>
  );
};

export default Reviews;
