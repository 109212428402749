import { useState } from "react";
import { Container, Grid, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Folder from "app/assets/images/folder.png";
import SignupMainImage from "app/assets/images/signup-right.png";
import PrimaryButton from "generic/Buttons/PrimaryButton";
import { cognitoLogin, loginSuccess } from "./authSlice";
import { useAppSelector, useAppDispatch } from "app/store/hooks";
import { isLoading, setLoading } from "app/store/genericSlice";
import { useCreateCustomTokenFirebaseMutation } from "services/user";
import { useLoginMutation } from "services/authApi";
import { Auth } from "aws-amplify";
import { storeUserData } from "./authSlice";
import { useUpdatePersonalInfoMutation } from "services/profileSettings";
import Snackbar from "generic/Snackbar/Snackbar";
import { auth } from "../../firebase";
import { signInWithCustomToken } from "firebase/auth";

const LargeImage = styled("img")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const CongratsScreen = () => {
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  let navigate = useNavigate();
  const location: any = useLocation();
  const loading = useAppSelector(isLoading);
  const dispatch = useAppDispatch();

  const email = location?.state?.email;
  const password = location?.state?.password;
  const role = location?.state?.role;

  const [login, response] = useLoginMutation();
  const [updatePersonalInfo] = useUpdatePersonalInfoMutation();
  const [CreateCustomToken, CustomToken] =
    useCreateCustomTokenFirebaseMutation();

  const handleLogin = async (e: any) => {
    e.preventDefault();
    try {
      dispatch(setLoading(true));
      const cognitoLogin = await Auth.signIn(email, password);
      const result: any = await login({
        id_token: cognitoLogin.signInUserSession.idToken.jwtToken,
        role,
      });
      dispatch(setLoading(false));
      if (result.data.status == "success") {
        const updateData: any = await updatePersonalInfo({
          user: { verified: true },
        });
        localStorage.setItem(
          "accessToken",
          cognitoLogin.signInUserSession.accessToken.jwtToken
        );
        localStorage.setItem(
          "idToken",
          cognitoLogin.signInUserSession.idToken.jwtToken
        );
        localStorage.setItem("role", result?.data?.data?.role);
        dispatch(storeUserData({ ...result.data.data, verified: true }));

        const invitationToken = localStorage.getItem("invitationToken");
        if (invitationToken) {
          navigate(`/invitations?token=${invitationToken}`);
        } else {
          navigate("/");
        }
      }
    } catch (e: any) {
      dispatch(setLoading(false));
      setIsError(true);
      setErrorMsg(e.message);

      if (e.name === "UserNotConfirmedException") {
        navigate("/signup", { state: { email, step: 3, role: "doctor" } });
      }
    }
  };
  const createFirebaseToken = () => {
    CreateCustomToken("").then((res: any) => {
      signInWithCustomToken(auth, res.data.data.token)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          console.log("userCredential", user);
          // ...
        })
        .catch((error) => {
          // const errorCode = error.code;
          const errorMessage = error.message;
          console.log("errorMessage", errorMessage);
          // ...
        });
    });
  };
  const onSubmit = (e: any) => {
    handleLogin(e).then(() => {
      createFirebaseToken();
    });
  };
  return (
    <Grid container style={{ height: "100vh" }}>
      <Grid item xs={12} sm={12} md={8} style={{ height: "80%" }}>
        <Container
          sx={{ m: 8 }}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <img
            src={Folder}
            alt="folder image"
            style={{ width: "150px", marginBottom: "20px" }}
          />

          <Typography
            sx={{ fontWeight: 600, fontSize: "32px", color: "#172B4D" }}
          >
            Account Created
          </Typography>
          <Typography
            sx={{ mb: 4, fontWeight: 400, fontSize: "14px", color: "#172B4D" }}
          >
            Your account has been successfully created.
          </Typography>
          <PrimaryButton
            title="Go to Homepage"
            type="button"
            loading={loading}
            onClick={onSubmit}
          />
          <Snackbar
            open={isError}
            onClose={() => setIsError(false)}
            message={errorMsg}
          />
        </Container>
      </Grid>
      <Grid
        container
        item
        xs={4}
        justifyContent="flex-end"
        style={{ height: "100%" }}
      >
        <LargeImage
          src={SignupMainImage}
          alt="signup image"
          style={{ height: "100%" }}
        />
      </Grid>
    </Grid>
  );
};

export default CongratsScreen;
