import { CircularProgress } from "@mui/material";

const BlueButton = ({
  onClick,
  children,
  style,
  disabled = false,
  loading = false,
}: any) => {
  return (
    <button
      style={{
        background: disabled ? "gray" : "#1D93C7",
        border: "none",
        color: "white",
        height: "3rem",
        width: "15rem",
        borderRadius: "10px",
        fontWeight: 600,
        fontSize: "16px",
        cursor: disabled ? "default" : "pointer",
        ...style,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {loading ? <CircularProgress /> : children}
    </button>
  );
};

export default BlueButton;
