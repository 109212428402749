import { useState } from "react";
import { Box, MenuItem } from "@mui/material";
import Loader from "generic/Loader/loader";
import NoContentCard from "generic/NoContentCard/NoContentCard";
import { IOrganizationDoctor } from "services/organizationDoctors";
import DoctorsMenuCard from "./DoctorsMenuCard";

interface INotificationsList {
  doctors: IOrganizationDoctor[] | undefined;
  setDoctorId: React.Dispatch<React.SetStateAction<number>>;
  selectedNum: number;
  setSelectedNum: React.Dispatch<React.SetStateAction<number>>;
}

const DoctorsList = ({
  doctors,
  setDoctorId,
  selectedNum,
  setSelectedNum,
}: INotificationsList) => {
  if (doctors === undefined)
    return (
      <Box
        sx={{
          width: "30rem",
          height: "10rem",
          display: "inline-grid",
          placeItems: "center",
        }}
      >
        <Loader />
      </Box>
    );

  if (doctors.length === 0)
    return (
      <Box
        sx={{
          width: "30rem",
          height: "5rem",
          display: "inline-grid",
          placeItems: "center",
        }}
      >
        <NoContentCard message="No Doctors" />
      </Box>
    );

  return (
    <Box
      sx={{ maxHeight: "200px", overflowY: "auto", paddingBottom: "0.75rem" }}
    >
      {doctors?.map((doctor, i) => {
        return (
          <MenuItem sx={{ p: 0 }}>
            <DoctorsMenuCard
              doctor={doctor}
              isSelected={selectedNum === i}
              onClick={() => {
                setSelectedNum(i);
                setDoctorId(doctor?.id);
              }}
            />
          </MenuItem>
        );
      })}
    </Box>
  );
};

export default DoctorsList;
