import { useState } from "react";
import { sanitize } from "dompurify";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography, Link } from "@mui/material";
import Text from "generic/Text/Text";
import { GoBackArrowIcon } from "app/assets/icons";
import Card from "generic/Cards/Card";
import { useGetTermsGeneralQuery } from "services/noHeaders";
import Tab from "generic/Tabs/Tab";
import Tabs from "generic/Tabs/Tabs";
import TermsAndConditions from "./termsTabs/TermsAndConditions";
import PrivacyPolicy from "./termsTabs/PrivacyPolicy";
import Impress from "./termsTabs/Impress";
import { useSearchParams } from "react-router-dom";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{ p: 3, height: "100%" }}
      {...other}
    >
      {value === index && <>{children}</>}
    </Box>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TermsGeneral = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState(searchParams.get("tabId"));

  const {
    data: response,
    error,
    isLoading,
    isSuccess,
  } = useGetTermsGeneralQuery({ lang: "en", termType: 0 });

  const navigate = useNavigate();

  const handleChange = (event: any, newValue: string) => {
    setValue(newValue);
    setSearchParams({ tabId: newValue });
  };

  return (
    <div
      style={{
        height: "100%",
        padding: "30px",
        paddingLeft: "20px",
        paddingTop: 0,
        borderRadius: "20px",
        display: "flex",
        flexDirection: "column",
        width: "85vw",
        marginLeft: "100px",
      }}
    >
      <Grid
        container
        item
        direction="row"
        style={{
          padding: "0 10px",
          position: "fixed",
          zIndex: "1000",
          paddingRight: "160px",
          paddingTop: "22px",
          background: "#EDF3F7",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "28px",
            color: "#172B4D",
            textAlign: "center",
            // marginBottom: "20px",
          }}
        >
          <Link
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/signup")}
          >
            <span style={{ marginRight: "10px" }}>
              <GoBackArrowIcon />
            </span>
          </Link>
          Terms and conditions
        </Typography>
      </Grid>
      <Box sx={{ flexGrow: 1, mt: 1 }} style={{ marginTop: "100px" }}>
        <Grid
          container
          item
          direction="row"
          sx={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          <Card
            xs={12}
            sx={{
              width: "100%",
              overflowY: "hidden",
              border: 0,
              mt: "0.625rem",
              pt: 0,
              pb: "1.5rem",
              px: 0,
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{ borderBottom: 1, borderColor: "#DFE1E6", px: "1.5rem" }}
              >
                <Tabs
                  value={value ? parseInt(value) : 0}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="profile tabs"
                >
                  <Tab label="Terms and Conditions" {...a11yProps(0)} />
                  <Tab label="Privacy Policy" {...a11yProps(1)} />
                  <Tab label="Impress" {...a11yProps(2)} />
                </Tabs>
              </Box>
              <Box sx={{ px: "0.5rem" }}>
                <TabPanel value={value ? parseInt(value) : 0} index={0}>
                  <TermsAndConditions />
                </TabPanel>
                <TabPanel value={value ? parseInt(value) : 0} index={1}>
                  <PrivacyPolicy />
                </TabPanel>
                <TabPanel value={value ? parseInt(value) : 0} index={2}>
                  <Impress />
                </TabPanel>
              </Box>
            </Box>
          </Card>
        </Grid>
      </Box>
    </div>
  );
};

export default TermsGeneral;
