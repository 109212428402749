import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { useState, useEffect } from "react";
import { Grid, Typography, Link, Box, Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import Sidebar from "./sidebar/Sidebar";
import ExpandedBar from "./sidebar/ExpandedBar";
import NotificationsMenu from "./menus/Notifications/NotificationsMenu";
import OptionsMenu from "./menus/OptionsMenu";
import { useGetMyProfileQuery } from "services/profileSettings";
import { useWindowDimensions } from "utils/hooks/useWindowDimensions";
import Modal from "generic/Modals/Modal";
import {
  auth,
  ConversationsCollection,
  DoctorIdField,
  firestore,
  LastMessageField,
} from "../../firebase";
import { signOut } from "firebase/auth";
import { onSnapshot } from "firebase/firestore";
import { useLazyGetPatientInfoQuery } from "services/patients";
import { urlRole, profileUser } from "services";

const Layout = ({
  children,
  title,
  icon,
  handleIconClick,
  childrenStyles,
}: any) => {
  const [expandedBar, setExpandedBar] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [unReadMessagesCount, setUnReadMessagesCount] = useState<Number>();
  const [unReadMessagesClone, setUnReadMessagesClone] = useState<any[]>([]);

  const [newMessageChecker, setNewMessageChecker] = useState<boolean>();

  const [newMessage, setNewMessage] = useState<any>();
  const [mainContainerStyle, setMainContainerStyle] = useState<any>({
    height: "100%",
    padding: "30px",
    paddingLeft: "20px",
    paddingTop: 0,
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    width: "85vw",
    marginLeft: "100px",
  });
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { data: myProfile, isSuccess } = useGetMyProfileQuery();
  const [getPatientInfo] = useLazyGetPatientInfoQuery();

  useEffect(() => {
    if (expandedBar)
      setMainContainerStyle((prev: any) => {
        return {
          ...prev,
          marginLeft: "250px",
        };
      });
    else
      setMainContainerStyle((prev: any) => {
        return {
          ...prev,
          marginLeft: "100px",
        };
      });
  }, [expandedBar]);

  useEffect(() => {
    if (width > 900)
      setMainContainerStyle((prev: any) => {
        return { ...prev, width: expandedBar ? "75vw" : "95vw" };
      });
    else
      setMainContainerStyle((prev: any) => {
        return { ...prev, width: expandedBar ? "75vw" : "100vw" };
      });
  }, [width]);
  useEffect(() => {
    if (isSuccess && myProfile?.[urlRole]?.[profileUser]?.id) {
      onSnapshot(
        firestore
          .collection(ConversationsCollection)
          .where(DoctorIdField, "==", myProfile?.[urlRole]?.[profileUser].id)
          .where(LastMessageField, "!=", false),
        async (res: any) => {
          let UnReadMessages =
            res &&
            res.docs.filter(
              (doc: any) =>
                !doc
                  .data()
                  .ReadBy?.includes(myProfile?.[urlRole]?.[profileUser].id)
            );

          if (
            (unReadMessagesCount || unReadMessagesCount === 0) &&
            UnReadMessages.length > unReadMessagesCount
          ) {
            const newMessageDetails = UnReadMessages.filter(
              (id1: any) =>
                !unReadMessagesClone.some(
                  (id2: any) => id2.data().PatientId === id1.data().PatientId
                )
            ).sort(
              (a: any, b: any) =>
                b.data().LastMessageTimestamp - a.data().LastMessageTimestamp
            );
            getPatientInfo(newMessageDetails[0].data().PatientId)
              .then((res) => res?.data?.data && setNewMessage(res.data.data))
              .finally(() => {
                setNewMessageChecker(true);
                setUnReadMessagesCount(UnReadMessages.length);
                setUnReadMessagesClone(UnReadMessages);
              });
          }
          setUnReadMessagesCount(UnReadMessages.length);
          setUnReadMessagesClone(UnReadMessages);
        }
      );
    }
  }, [isSuccess, unReadMessagesCount]);

  const handleLogout = async () => {
    localStorage.removeItem("idToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    await Auth.signOut();
    await signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error: any) => {
        // An error happened.
      });

    navigate("/login");
  };

  return (
    <Box
      sx={{
        background: "#EDF3F7",
        width: "100vw",
        minHeight: "100vh",
        height: "100vh",
        display: "flex",
      }}
    >
      <Box
        sx={{
          height: "100vh",
          padding: "12px",
          paddingRight: 0,
          position: "fixed",
          zIndex: "2000",
        }}
      >
        {expandedBar ? (
          <ExpandedBar
            setExpandedBar={setExpandedBar}
            unReadMessagesCount={unReadMessagesCount}
          />
        ) : (
          <Sidebar
            setExpandedBar={setExpandedBar}
            unReadMessagesCount={unReadMessagesCount}
          />
        )}
      </Box>

      <div style={mainContainerStyle}>
        <Grid
          container
          item
          direction="row"
          style={{
            padding: "0 10px",
            position: "fixed",
            zIndex: "1000",
            paddingRight: expandedBar ? "310px" : "160px",
            paddingTop: "22px",
            background: "#EDF3F7",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "28px",
              color: "#172B4D",
              textAlign: "center",
              // marginBottom: "20px",
            }}
          >
            {icon ? (
              <Link style={{ cursor: "pointer" }} onClick={handleIconClick}>
                <span style={{ marginRight: "10px" }}>{icon}</span>
              </Link>
            ) : null}
            {title}
          </Typography>
          <div style={{ margin: "auto" }}></div>
          <div
            style={{
              display: "inline-grid",
              placeItems: "center",
              marginRight: "40px",
            }}
          >
            <NotificationsMenu />
          </div>{" "}
          <OptionsMenu
            avatarURL={myProfile?.[urlRole]?.[profileUser]?.avatar_url}
            setIsLogoutModalOpen={setIsLogoutModalOpen}
          />
        </Grid>
        <Box
          className={childrenStyles}
          sx={{ flexGrow: 1, mt: 1 }}
          style={{ marginTop: "100px" }}
        >
          {children}
        </Box>
      </div>
      <Modal
        open={isLogoutModalOpen}
        setOpen={setIsLogoutModalOpen}
        primaryTitle="Yes"
        secondaryTitle="Cancel"
        primaryOnclick={handleLogout}
      >
        Are you sure you want to log out?
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={newMessageChecker}
        message={`New Message ${
          newMessage
            ? "from " + newMessage.first_name + " " + newMessage.last_name
            : ""
        }  `}
        autoHideDuration={3000}
        onClose={() => setNewMessageChecker(false)}
        onClick={() =>
          newMessage &&
          navigate("/messages", {
            state: {
              patientId: newMessage.id,
              patientInfo: {
                id: newMessage.id,
                first_name: newMessage.first_name,
                last_name: newMessage.last_name,
                avatar_url: newMessage.avatar_url,
              },
            },
          })
        }
      />
    </Box>
  );
};

export default Layout;
