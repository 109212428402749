import { Box, Typography } from "@mui/material";
import {
  PaginationLeftArrowIcon,
  PaginationRightArrowIcon,
} from "app/assets/icons";
import { useEffect, useState } from "react";

interface IPagination {
  currPage: number | undefined;
  perPage: number;
  onChange: (pageNumber: number) => void;
  pagination: {
    next: number | null;
    previous: number | null;
    count: number;
  };
}

const Pagination = ({
  currPage,
  perPage,
  pagination,
  onChange,
}: IPagination) => {
  const [prevPage, setPrevPage] = useState<number | null>();
  const [nextPage, setNextPage] = useState<number | null>(null);
  const [count, setCount] = useState<number>();

  const start = () => {
    if (currPage) return (currPage - 1) * perPage + 1;
  };

  const end = () => {
    if (!currPage) return;
    const res = currPage * perPage;
    if (res > (count || 0)) return count;
    return res;
  };

  const onPrev = () => {
    if (!prevPage) return;
    onChange(prevPage);
  };

  const onNext = () => {
    if (!nextPage) return;
    onChange(nextPage);
  };

  useEffect(() => {
    if (!pagination) return;
    const { previous, next, count } = pagination;
    setPrevPage(previous);
    setNextPage(next);
    setCount(count);
  }, [pagination]);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography
        sx={{
          color: "#7F8CA9",
          fontSize: "0.75rem",
          marginRight: "1rem",
        }}
      >
        {start()}-{end()} of {count}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <PaginationLeftArrowIcon
          onClick={onPrev}
          style={{ cursor: "pointer" }}
        />
        <PaginationRightArrowIcon
          onClick={onNext}
          style={{ cursor: "pointer" }}
        />
      </Box>
    </Box>
  );
};

export default Pagination;
