import { Box } from "@mui/material";
import "./style.css";

export default function Loader() {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "inline-grid",
        placeItems: "center",
      }}
    >
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </Box>
  );
}
