import { Link } from "@mui/material";
import { Trans } from "react-i18next";

const BasicLink = ({ title, href = "#", sx }: any) => (
  <Link
    href={href}
    variant="body2"
    sx={{ color: "#136588", textDecoration: "none", fontWeight: 600, ...sx }}
  >
    <Trans>{title}</Trans>
  </Link>
);

export default BasicLink;
