import * as React from "react";
import Typography from "@mui/material/Typography";
import { XIcon } from "app/assets/icons";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { Divider, Grid, Modal } from "@mui/material";
import BlueButton from "../BlueButton";
import { useGetMedicalHistoryQuestionsQuery } from "services/medicalHistory";
import Loader from "generic/Loader/loader";
import { handleTranslationToEn } from "utils/handleTranslation";
import { usePDF } from "react-to-pdf";
import PrimaryButton from "generic/Buttons/PrimaryButton";

interface IMedicalHistory {
  answers: any;
  speciality: any;
}

type Choice = {
  id: number;
  choice: string;
};

export default function MedicalHistory({
  answers,
  speciality,
}: IMedicalHistory) {
  const { data: response, isLoading } = useGetMedicalHistoryQuestionsQuery({
    specialityId: speciality?.id,
    locale: "en",
  });

  const [open, setOpen] = React.useState(false);

  const { toPDF, targetRef } = usePDF({ filename: "medical-history.pdf" });

  const renderNumberOrStringQuestion = (question: any) => {
    const answer = answers[question.id];
    return (
      <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
        <Typography
          sx={{
            color: "#172B4D",
            fontSize: "1rem",
            fontWeight: 500,
          }}
        >
          {question.title}
        </Typography>
        <Chip
          label={answer}
          sx={{
            backgroundColor: "rgba(184, 208, 219, 0.8)",
            color: "#172B4D",
            fontSize: "1rem",
            fontWeight: 400,
            borderRadius: "0.5rem",
          }}
          size="medium"
        />
      </Box>
    );
  };

  const RenderTextQuestion = ({ question }: any) => {
    const [translatedAnswer, setTranslatedAnswer] = React.useState("");

    const answer = answers[question.id];
    handleTranslationToEn({
      originalText: answer,
      setTranslatedAnswer,
    });
    return (
      <Box>
        <Typography
          sx={{
            color: "#172B4D",
            fontSize: "1rem",
            fontWeight: 700,
            mb: "0.5rem",
          }}
        >
          {question.title}
        </Typography>
        {translatedAnswer ? "Original answer:" : ""}
        {answer ? (
          <div
            style={{
              backgroundColor: "rgba(184, 208, 219, 0.8)",
              color: "#172B4D",
              fontSize: "1rem",
              fontWeight: 400,
              borderRadius: "0.5rem",
              marginBottom: "10px",
              padding: "7px",
            }}
          >
            {answer}
          </div>
        ) : null}
        <br />
        {translatedAnswer && (
          <>
            Translated answer:
            <div
              style={{
                backgroundColor: "rgba(184, 208, 219, 0.8)",
                color: "#172B4D",
                fontSize: "1rem",
                fontWeight: 400,
                borderRadius: "0.5rem",
                marginBottom: "10px",
                padding: "7px",
              }}
            >
              {translatedAnswer}
            </div>
          </>
        )}
      </Box>
    );
  };

  const renderMultipleChoicesQuestion = (question: any) => {
    const chosen = answers[question.id];
    return (
      <Box>
        <Typography
          sx={{
            color: "#172B4D",
            fontSize: "1rem",
            fontWeight: 700,
            mb: "0.5rem",
          }}
        >
          {question.title}
        </Typography>
        <Box sx={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
          {question.choices.map((choice: Choice) =>
            chosen?.includes(choice.id) ? (
              <Chip
                label={choice.choice}
                sx={{
                  backgroundColor: chosen?.includes(choice.id)
                    ? "#136588"
                    : "rgba(184, 208, 219, 0.8)",
                  color: chosen?.includes(choice.id) ? "white" : "#172B4D",
                  fontSize: "1rem",
                  fontWeight: chosen?.includes(choice.id) ? 500 : 400,
                  borderRadius: "0.5rem",
                }}
                size="medium"
              />
            ) : null
          )}
        </Box>
      </Box>
    );
  };

  const renderRowGroupQuestions = (question: any) => {
    return (
      <Box>
        <Typography
          sx={{
            color: "#172B4D",
            fontSize: "1rem",
            fontWeight: 700,
            mb: "1rem",
          }}
        >
          {question.title}
        </Typography>
        <Box sx={{ display: "flex", gap: "3rem" }}>
          {question.questions.map((singleQuestion: any) => {
            return renderNumberOrStringQuestion(singleQuestion);
          })}
        </Box>
      </Box>
    );
  };

  const renderNewSectionQuestions = (question: any) => {
    return (
      <Box>
        <Typography
          sx={{
            color: "#172B4D",
            fontSize: "1rem",
            fontWeight: 700,
            mb: "1rem",
          }}
        >
          {question.title}
        </Typography>
        <Box>
          {question.questions.map((singleQuestion: any, idx: number) => {
            return renderQuestion(
              singleQuestion,
              "",
              idx === question.questions.length - 1
            );
          })}
        </Box>
      </Box>
    );
  };

  const renderQuestion = (
    question: any,
    dividerColor: string,
    lastItem: boolean
  ) => {
    let rendered;
    if (question.type === "number" || question.type === "string") {
      rendered = renderNumberOrStringQuestion(question);
    }
    if (question.type === "text") {
      rendered = <RenderTextQuestion question={question} />;
    }
    if (question.type === "new_section_questions") {
      rendered = renderNewSectionQuestions(question);
    }
    if (question.type === "row_group_questions") {
      rendered = renderRowGroupQuestions(question);
    }
    if (
      question.type === "mcq_single_select" ||
      question.type === "mcq_multiple_select"
    ) {
      rendered = renderMultipleChoicesQuestion(question);
    }

    return (
      <>
        {rendered}
        {!lastItem && (
          <Divider
            sx={{ my: "1rem", background: dividerColor, height: "1px" }}
          />
        )}
      </>
    );
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          sx={{
            color: "#172B4D",
            fontSize: "16px",
            fontWeight: 700,
          }}
        >
          Medical History
        </Typography>
        <BlueButton
          onClick={() => {
            setOpen(true);
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "5rem",
            height: "2.5rem",
            marginLeft: "auto",
          }}
        >
          Show
        </BlueButton>
      </Box>
      <Box>
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            overflowY: "scroll",
          }}
        >
          <div ref={targetRef}>
            <Box
              id="medicalHistoryPage"
              sx={{
                ml: "50%",
                my: "5rem",
                transform: "translate(-50%, 0%)",
                width: "1240px",
                minHeight: "1754px",
                background: "#FFFFFF",
                boxShadow: 24,
                p: "2rem",
                borderRadius: "1.5rem",
              }}
            >
              <Grid container direction="row" justifyContent="flex-end">
                <XIcon
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => setOpen(false)}
                />
              </Grid>

              <PrimaryButton onClick={() => toPDF()} title="Download PDF" />

              <Box>
                {isLoading ? (
                  <Box sx={{ height: "50%" }}>
                    <Loader />
                  </Box>
                ) : (
                  <Box>
                    {response?.data?.common_questions?.length
                      ? response?.data?.common_questions.map(
                          (question: any, idx: number) =>
                            renderQuestion(
                              question,
                              "#172B4D",
                              idx === response.data.common_questions.length - 1
                            )
                        )
                      : null}
                    <Divider
                      sx={{
                        my: "1rem",
                        background: "#172B4D",
                        height: "1px",
                      }}
                    />
                    {response?.data?.speciality_questions?.length
                      ? response?.data?.speciality_questions.map(
                          (question: any, idx: number) =>
                            renderQuestion(
                              question,
                              "#172B4D",
                              idx ===
                                response.data.speciality_questions.length - 1
                            )
                        )
                      : null}
                  </Box>
                )}
              </Box>
            </Box>
          </div>
        </Modal>
      </Box>
    </>
  );
}
