import { BarChart, Bar, Cell, ResponsiveContainer } from "recharts";
import { IView } from "services/insights";

interface IMonthlyViewsBarChart {
  monthlyVisits: IView[];
}

const MonthlyViewsBarChart = ({ monthlyVisits }: IMonthlyViewsBarChart) => {
  const maxVisitCount = Math.max(...monthlyVisits.map((v) => v.count));

  return (
    <ResponsiveContainer
      width={monthlyVisits.length * 40 + (monthlyVisits.length - 1) * 8}
      height="100%"
    >
      <BarChart
        data={monthlyVisits}
        margin={{ top: 0, bottom: 0, right: 0, left: 0 }}
      >
        <Bar dataKey="count" barSize={40} radius={4}>
          {monthlyVisits.map((visit) => (
            <Cell
              key={visit.group}
              fill={visit.count === maxVisitCount ? "#1D93C7" : "#DCF1F3"}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default MonthlyViewsBarChart;
