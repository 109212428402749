import moment from "moment";

const REFERENCE = moment();
const TODAY = REFERENCE.clone().startOf("day");
const YESTERDAY = REFERENCE.clone().subtract(1, "days").startOf("day");

export const renderTimestamp = (timestamp: string) => {
  const momentTimestamp = moment(timestamp);
  if (momentTimestamp.isSame(TODAY, "d"))
    return momentTimestamp.format("h:mm A");
  if (momentTimestamp.isSame(YESTERDAY, "d")) return "Yesterday";
  return momentTimestamp.format("M/D/YYYY");
};
