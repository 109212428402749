import { Divider, Typography } from "@mui/material";
import { getStatus } from "utils/getStatus";

const CancelledHeader = ({ consultation }: any) => {
  const { FEStatus, color, background } = getStatus(consultation.status);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <Typography
          style={{
            color: "#172B4D",
            fontSize: "16px",
            fontWeight: 600,
            marginRight: "10px",
          }}
        >
          Appointment Status
        </Typography>

        <Typography
          style={{
            color,
            background,
            padding: "5px 10px",
            borderRadius: "20px",
            fontWeight: 500,
          }}
        >
          {FEStatus}
        </Typography>
      </div>
      <Divider sx={{ my: 3 }} />
      <div>
        <Typography
          style={{
            color: "#172B4D",
            fontSize: "16px",
            fontWeight: 600,
            marginRight: "10px",
            marginBottom: "10px",
          }}
        >
          Cancellation Reason:
        </Typography>
        <p>{consultation?.reason}</p>
      </div>
    </>
  );
};

export default CancelledHeader;
