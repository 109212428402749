import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Grid, Link } from "@mui/material";
import * as yup from "yup";
import { Trans } from "react-i18next";
import { useFormik } from "formik";
import { useAppSelector, useAppDispatch } from "app/store/hooks";
import { isLoading, setLoading } from "app/store/genericSlice";
import InputWithLabel from "generic/Inputs/InputWithLabel";
import PrimaryButton from "generic/Buttons/PrimaryButton";
import { EmailIcon, PlusIcon } from "app/assets/icons";
import { storeUserData } from "modules/auth/authSlice";
import {
  confirmSignUp,
  resendConfirmationCode,
  verifyCurrentEmail,
  verifyEmailSubmit,
} from "modules/auth/signup/service";
import {
  useUpdatePersonalInfoMutation,
  useChangeEmailMutation,
} from "services/profileSettings";
import Snackbar from "generic/Snackbar/Snackbar";
import { useLazyVerifyEmailQuery } from "services/profileSettings";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});

const EditEmail = ({ userData }: any) => {
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [verificationCode, setVerificationCode] = useState("");

  const [updatePersonalInfo] = useUpdatePersonalInfoMutation();
  const [verifyEmail] = useLazyVerifyEmailQuery();

  const dispatch = useAppDispatch();

  const [changeEmail] = useChangeEmailMutation();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (form_data, { resetForm }) => {
      const result: any = await changeEmail(form_data.email);
      // const sendCode = await verifyCurrentEmail();
      if (result?.error) {
        setIsError(true);
        setErrorMsg(result?.error?.error?.data?.message);
      } else {
        setIsError(true);
        setErrorMsg("Successfully changed your email");
        // resetForm();
      }
    },
  });

  const handleVerifyingEmail = async () => {
    try {
      const result = await verifyEmailSubmit(verificationCode);
      if (result?.error) {
        setIsError(true);
        setErrorMsg(result?.message);
      } else {
        const updateData: any = await updatePersonalInfo({
          user: { verified: true },
        });
        dispatch(storeUserData(updateData?.data?.data));
        setIsError(true);
        setErrorMsg("Successfully verified your email!");
      }
    } catch (e: any) {
      setIsError(true);
      setErrorMsg(e.message);
    }
  };

  const handleResendCode = async () => {
    try {
      const result: any = await verifyEmail(0);
      if (result?.error) {
        dispatch(setLoading(false));
        setIsError(true);
        setErrorMsg(result?.error?.data?.data);
      } else {
      setIsError(true);
      setErrorMsg("Code resent successfully");
      }
    } catch (e: any) {
      dispatch(setLoading(false));
      setIsError(true);
      setErrorMsg(e.message);
    }
  };

  return (
    <form
      style={{ margin: "auto 0", maxWidth: "100%" }}
      onSubmit={formik.handleSubmit}
    >
      <p style={{ color: "#172B4D", fontSize: "16px", marginBottom: "25px" }}>
        You will have to verify your email in order to preform certain actions.
      </p>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <InputWithLabel
            label="Edit Email"
            id="email"
            name="email"
            placeholder="Enter New Email"
            startIcon={<EmailIcon />}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
      </Grid>
      <PrimaryButton title="Save Changes" type="submit" />

      {!userData?.verified && (
        <div style={{ marginTop: "30px" }}>
          <p
            style={{ color: "#172B4D", fontSize: "16px", marginBottom: "15px" }}
          >
            Your current email is not verified, please enter the verification
            code sent to your email below.
          </p>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <InputWithLabel
                label="Verify Email"
                id="email"
                name="email"
                placeholder="Enter Verification Code"
                endIcon={<h4>Verify</h4>}
                endIconOnClick={handleVerifyingEmail}
                value={verificationCode}
                onChange={(e: any) => {
                  const isNumber = /^[0-9]$/i.test(
                    e.target.value[e.target.value.length - 1]
                  );
                  if (isNumber || e.target.value == "")
                    setVerificationCode(e.target.value);
                }}
              />
              <Link
                variant="body2"
                sx={{
                  color: "#136588",
                  textDecoration: "none",
                  fontWeight: 600,
                  float: "right",
                  cursor: "pointer",
                }}
                onClick={handleResendCode}
              >
                <Trans>Resend Code?</Trans>
              </Link>
            </Grid>
          </Grid>
        </div>
      )}

      <Snackbar
        open={isError}
        onClose={() => setIsError(false)}
        message={errorMsg}
      />
    </form>
  );
};

export default EditEmail;
