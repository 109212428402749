import { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useAppDispatch } from "app/store/hooks";
import { useUpdateProfileMutation } from "services/profileSettings";
import { setLoading } from "app/store/genericSlice";
import InputWithLabel from "generic/Inputs/InputWithLabel";
import PrimaryButton from "generic/Buttons/PrimaryButton";
import { FacebookIcon, InstagramIcon, TwitterIcon } from "app/assets/icons";
import { counterSlice } from "modules/counter/counterSlice";
import Snackbar from "generic/Snackbar/Snackbar";

const urlValidation = yup
  .string()
  .matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    "Enter correct url!"
  );

const validationSchema = yup.object({
  facebook_url: urlValidation,
  instagram_url: urlValidation,
  twitter_url: urlValidation,
});

const SocialMedia = ({ doctorData }: any) => {
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [updateProfile, updateProfileResult] = useUpdateProfileMutation();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      facebook_url: doctorData?.facebook_url ? doctorData.facebook_url : "",
      instagram_url: doctorData?.instagram_url ? doctorData.instagram_url : "",
      twitter_url: doctorData?.twitter_url ? doctorData.twitter_url : "",
    },
    validationSchema: validationSchema,
    onSubmit: async ({ facebook_url, instagram_url, twitter_url }) => {
      setLoading(true);
      await updateProfile({
        facebook_url,
        instagram_url,
        twitter_url,
      });

      setLoading(false);
      setIsError(true);
      setErrorMsg("successfully updated your profile");
    },
  });

  return (
    <form
      style={{ margin: "auto 0", maxWidth: "100%" }}
      onSubmit={formik.handleSubmit}
    >
      <Grid container>
        <Grid item xs={12} sm={12} md={5}>
          <InputWithLabel
            label="Facebook"
            id="facebook_url"
            name="facebook_url"
            placeholder="Enter URL here"
            required={false}
            startIcon={<FacebookIcon />}
            value={formik.values.facebook_url}
            onChange={formik.handleChange}
            error={
              formik.touched.facebook_url && Boolean(formik.errors.facebook_url)
            }
            helperText={
              formik.touched.facebook_url && formik.errors.facebook_url
            }
          />
          <InputWithLabel
            label="Instagram"
            id="instagram_url"
            name="instagram_url"
            placeholder="Enter URL here"
            required={false}
            startIcon={<InstagramIcon />}
            value={formik.values.instagram_url}
            onChange={formik.handleChange}
            error={
              formik.touched.instagram_url &&
              Boolean(formik.errors.instagram_url)
            }
            helperText={
              formik.touched.instagram_url && formik.errors.instagram_url
            }
          />
          <InputWithLabel
            label="Twitter"
            id="twitter_url"
            name="twitter_url"
            placeholder="Enter URL here"
            required={false}
            startIcon={<TwitterIcon />}
            value={formik.values.twitter_url}
            onChange={formik.handleChange}
            error={
              formik.touched.twitter_url && Boolean(formik.errors.twitter_url)
            }
            helperText={formik.touched.twitter_url && formik.errors.twitter_url}
          />
        </Grid>
      </Grid>
      <PrimaryButton
        title="Save Changes"
        type="submit"
        loading={loading}
        sx={{ fontSize: "1rem", fontWeight: 600 }}
      />
      <Snackbar
        open={isError}
        onClose={() => setIsError(false)}
        message={errorMsg}
      />
    </form>
  );
};

export default SocialMedia;
