import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Trans } from "react-i18next";
import { Typography, Box, Link } from "@mui/material";
import InputWithLabel from "generic/Inputs/InputWithLabel";
import PrimaryButton from "generic/Buttons/PrimaryButton";
import { useAppSelector, useAppDispatch } from "app/store/hooks";
import { confirmSignUp, resendConfirmationCode } from "./../service";
import { isLoading, setLoading } from "app/store/genericSlice";
import Snackbar from "generic/Snackbar/Snackbar";

const VerifyAccount = ({ email, password, role }: any) => {
  const [isValid, setIsValid] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const loading = useAppSelector(isLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (verificationCode.length == 6) setIsValid(true);
    else setIsValid(false);
  }, [verificationCode]);

  const handleVerifyingAccount = async () => {
    try {
      dispatch(setLoading(true));
      const result = await confirmSignUp(email, verificationCode);
      dispatch(setLoading(false));
      navigate("/congrats", { state: { email, password, role } });
    } catch (e: any) {
      dispatch(setLoading(false));
      setIsError(true);
      setErrorMsg(e.message);
    }
  };

  const handleResendCode = async () => {
    try {
      const result = await resendConfirmationCode(email);
      setIsError(true);
      setErrorMsg("Code resent successfully");
    } catch (e: any) {
      dispatch(setLoading(false));
      setIsError(true);
      setErrorMsg(e.message);
    }
  };

  return (
    <Box sx={{ my: "auto", mx: 0, maxWidth: "60%" }}>
      <Typography sx={{ fontWeight: 600, fontSize: "32px", color: "#172B4D" }}>
        Verify Account
      </Typography>
      <Typography
        sx={{ mb: 4, fontWeight: 400, fontSize: "14px", color: "#172B4D" }}
      >
        Enter the 6-numbers verification code that we sent to your email
        address.
      </Typography>

      <InputWithLabel
        label="Verification Code"
        id="verification-code"
        name="verification-code"
        placeholder="Enter Verification Code"
        value={verificationCode}
        onChange={(e: any) => {
          const isNumber = /^[0-9]$/i.test(
            e.target.value[e.target.value.length - 1]
          );
          if (isNumber || e.target.value == "")
            setVerificationCode(e.target.value);
        }}
      />

      <Link
        variant="body2"
        sx={{
          color: "#136588",
          textDecoration: "none",
          fontWeight: 600,
          float: "right",
          cursor: "pointer",
        }}
        onClick={handleResendCode}
      >
        <Trans>Resend Code?</Trans>
      </Link>

      <PrimaryButton
        title="Next"
        type="button"
        loading={loading}
        disabled={!isValid}
        onClick={handleVerifyingAccount}
      />

      <Snackbar
        open={isError}
        onClose={() => setIsError(false)}
        message={errorMsg}
      />
    </Box>
  );
};

export default VerifyAccount;
