import { Grid, Typography } from "@mui/material";

const TransactionsHeader = () => {
  return (
    <Grid
      container
      sx={{
        width: "100%",
        py: "0.5rem",
        backgroundColor: "#F4F5F8",
        borderRadius: "0.25rem",
        display: "flex",
        alignItems: "center",
        mb: "0.5rem",
      }}
    >
      <Grid item xs={3} sm={3} md={6} sx={{ pl: "1rem" }}>
        <Typography
          sx={{
            fontSize: "0.75rem",
            fontWeight: 600,
            lineHeight: "1.25rem",
            textTransform: "uppercase",
            color: "#95A0B8",
          }}
        >
          payment
        </Typography>
      </Grid>
      <Grid item xs={3} sm={3} md={2}>
        <Typography
          sx={{
            fontSize: "0.75rem",
            fontWeight: 600,
            lineHeight: "1.25rem",
            textTransform: "uppercase",
            color: "#95A0B8",
            textAlign: "center",
          }}
        >
          consultation fees
        </Typography>
      </Grid>
      <Grid item xs={3} sm={3} md={2}>
        <Typography
          sx={{
            fontSize: "0.75rem",
            fontWeight: 600,
            lineHeight: "1.25rem",
            textTransform: "uppercase",
            color: "#95A0B8",
            textAlign: "center",
          }}
        >
          app fees
        </Typography>
      </Grid>
      <Grid item xs={3} sm={3} md={2}>
        <Typography
          sx={{
            fontSize: "0.75rem",
            fontWeight: 600,
            lineHeight: "1.25rem",
            textTransform: "uppercase",
            color: "#95A0B8",
            textAlign: "center",
          }}
        >
          status
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TransactionsHeader;
