import { useState } from "react";
import { Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Folder from "app/assets/images/folder.png";
import PrimaryButton from "generic/Buttons/PrimaryButton";
import { cognitoLogin } from "../../authSlice";
import { useAppSelector, useAppDispatch } from "app/store/hooks";
import { isLoading, setLoading } from "app/store/genericSlice";
import { useCreateCustomTokenFirebaseMutation } from "services/user";
import { useLoginMutation } from "services/authApi";
import { Auth } from "aws-amplify";
import { storeUserData } from "../../authSlice";
import Snackbar from "generic/Snackbar/Snackbar";
import { signInWithCustomToken } from "firebase/auth";
import { auth } from "../../../../firebase";

const CongratsScreen = ({ email, password }: any) => {
  const loading = useAppSelector(isLoading);
  const dispatch = useAppDispatch();

  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [CreateCustomToken, CustomToken] =
    useCreateCustomTokenFirebaseMutation();
  let navigate = useNavigate();
  const [login, response] = useLoginMutation();

  const handleLogin = async (e: any) => {
    e.preventDefault();
    try {
      dispatch(setLoading(true));
      const cognitoLogin = await Auth.signIn(email, password);
      const result: any = await login({
        id_token: cognitoLogin.signInUserSession.idToken.jwtToken,
      });
      dispatch(setLoading(false));
      if (result.data.status == "success") {
        localStorage.setItem(
          "accessToken",
          cognitoLogin.signInUserSession.accessToken.jwtToken
        );
        localStorage.setItem(
          "idToken",
          cognitoLogin.signInUserSession.idToken.jwtToken
        );
        localStorage.setItem("role", result?.data?.data?.role);
        dispatch(storeUserData(result.data.data));
        navigate("/");
      }
    } catch (e: any) {
      dispatch(setLoading(false));
      setIsError(true);
      setErrorMsg(e.message);

      if (e.name === "UserNotConfirmedException") {
        navigate("/signup", { state: { email, step: 3, role: "doctor" } });
      }
    }
  };
  const createFirebaseToken = () => {
    CreateCustomToken("").then((res: any) => {
      signInWithCustomToken(auth, res.data.data.token)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          console.log("userCredential", user);
          // ...
        })
        .catch((error) => {
          // const errorCode = error.code;
          const errorMessage = error.message;
          console.log("errorMessage", errorMessage);
          // ...
        });
    });
  };

  const onSubmit = (e: any) => {
    handleLogin(e).then(() => {
      createFirebaseToken();
    });
  };
  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <img
        src={Folder}
        alt="folder image"
        style={{ width: "150px", marginBottom: "20px" }}
      />

      <Typography sx={{ fontWeight: 600, fontSize: "32px", color: "#172B4D" }}>
        Password Changed
      </Typography>
      <Typography
        sx={{ mb: 4, fontWeight: 400, fontSize: "14px", color: "#172B4D" }}
      >
        You can go to homepage now to control your dashboard.
      </Typography>
      <PrimaryButton
        title="Go to Homepage"
        type="button"
        loading={loading}
        onClick={onSubmit}
      />
      <Snackbar
        open={isError}
        onClose={() => setIsError(false)}
        message={errorMsg}
      />
    </Container>
  );
};

export default CongratsScreen;
