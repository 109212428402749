import { Box, Divider } from "@mui/material";
import ReviewCard from "./ReviewCard";

interface IReview {
  patient: {
    avatar_url: string;
    first_name: string;
    last_name: string;
  };
  comment: string;
  rating_percentage: number;
  created_at: string;
}
interface IReviewsList {
  reviews: IReview[];
}

const ReviewsList = ({ reviews }: IReviewsList) => {
  return (
    <Box>
      {reviews.map((review: any, index: number) => (
        <Box key={review.id}>
          <ReviewCard
            avatarUrl={review.patient.avatar_url}
            reviewerName={`${review.patient.first_name} ${review.patient.last_name}`}
            date={review.created_at}
            comment={review.comment}
            ratingPercentage={review.rating_percentage}
          />
          {index !== reviews.length - 1 && <Divider sx={{ my: "1.5rem" }} />}
        </Box>
      ))}
    </Box>
  );
};

export default ReviewsList;
