import { Auth } from "aws-amplify";

export const prepareHeaders = async (
  headers: Headers,
  {
    getState,
  }: {
    getState: () => any;
  }
) => {
  return Auth.currentSession().then((session) => {
    const token = session.getAccessToken();
    if (token) {
      headers.set("Authorization", `Bearer ${token.getJwtToken()}`);
      headers.set("accept-language", `en`);
    }
    return headers;
  });
};
