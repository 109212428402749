import { useState } from "react";
import { useLocation } from "react-router-dom";
import i18next from "i18next";
import { Container, Grid, Stepper, Step, StepLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import CreateNewAccount from "./step-one/CreateNewAccount";
import VerifyAccount from "./step-four/VerifyAccount";
import CreatePassword from "./step-two/CreatePassword";
import AddDetails from "./step-three/AddDetails";
import Logo from "app/assets/images/logo.svg";
import SignupMainImage from "app/assets/images/signup-right.png";
import { Auth } from "aws-amplify";
import { useAppDispatch } from "app/store/hooks";
import { setLoading } from "app/store/genericSlice";
import Text from "generic/Text/Text";
import PrimaryButton from "generic/Buttons/PrimaryButton";
import SecondaryButton from "generic/Buttons/SecondaryButton";

const steps = [
  i18next.t("Create New Account", "Create New Account"),
  i18next.t("Create Password", "Create Password"),
  i18next.t("Add Details", "Add Details"),
  i18next.t("Verify Account", "Verify Account"),
];

const LargeImage = styled("img")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

type Role = {};

const Signup = () => {
  const dispatch = useAppDispatch();
  const location: any = useLocation();

  const [currentStep, setCurrentStep] = useState(
    location?.state?.step ? location?.state?.step : 0
  );
  const [email, setEmail] = useState(
    location?.state?.email ? location?.state?.email : ""
  );
  const [password, setPassword] = useState("");

  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [role, setRole] = useState<String | null>(
    location?.state?.role ? location?.state?.role : null
  );

  const handleSignup = async (values: any) => {
    try {
      dispatch(setLoading(true));
      const result = await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          given_name: values.first_name,
          family_name: values.last_name,
          gender: values.gender,
          birthdate: values.birthdate,
          address: values.address,
          "custom:specialty": values.speciality,
          "custom:phone_number": values.phone_number,
          "custom:country": values.country,
          "custom:locale": "en",
        },
      });
      dispatch(setLoading(false));
      setCurrentStep((prevStep: any) => ++prevStep);
    } catch (e: any) {
      dispatch(setLoading(false));
      setIsError(true);
      setErrorMsg(e.message);
    }
  };

  const displayStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <CreateNewAccount
            setCurrentStep={setCurrentStep}
            email={email}
            setEmail={setEmail}
          />
        );

      case 1:
        return (
          <CreatePassword
            setCurrentStep={setCurrentStep}
            setPassword={setPassword}
          />
        );

      case 2:
        return (
          <AddDetails
            handleSignup={handleSignup}
            isError={isError}
            setIsError={setIsError}
            errorMsg={errorMsg}
          />
        );

      case 3:
        return <VerifyAccount email={email} password={password} role={role} />;
    }
  };
  return (
    <Grid container style={{ minHeight: "100vh" }}>
      <Grid item xs={12} sm={12} md={9} style={{ height: "80%" }}>
        <Container
          sx={{ m: 8 }}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            minHeight: "100%",
          }}
        >
          <img
            src={Logo}
            alt="login image"
            style={{ width: "150px", marginBottom: "20px" }}
          />
          {!role ? (
            <>
              <Text
                sx={{
                  fontWeight: 600,
                  fontSize: "32px",
                  color: "#172B4D",
                  marginTop: "100px",
                }}
                label="Sign up"
              />
              <Text
                sx={{
                  mb: 4,
                  fontWeight: 500,
                  fontSize: "14px",
                  color: "#172B4D",
                }}
                label="Choose your role, in order to start the sign up flow."
              />
              <Grid container direction="row" sx={{ mt: 2 }}>
                <PrimaryButton
                  title="Hospital"
                  onClick={() => setRole("organization_admin")}
                />
                <span style={{ margin: "0 10px" }}></span>
                <SecondaryButton
                  title="Doctor"
                  onClick={() => setRole("doctor")}
                />
              </Grid>
            </>
          ) : (
            <>
              <Stepper activeStep={currentStep} alternativeLabel sx={{ my: 5 }}>
                {steps.map((label: any) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              {displayStep()}
            </>
          )}
        </Container>
      </Grid>
      <Grid container item xs={3} justifyContent="flex-end">
        <LargeImage
          src={SignupMainImage}
          alt="signup image"
          style={{
            maxWidth: "100%",
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Signup;
