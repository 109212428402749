import { Grid } from "@mui/material";

const Card = ({ xs, sm, md, children, sx }: any) => (
  <Grid
    item
    xs={xs}
    sm={sm}
    md={md}
    sx={{
      background: "#fff",
      margin: "0 auto",
      borderRadius: "20px",
      border: "10px solid #EDF3F7",
      padding: "25px",
      ...sx,
    }}
  >
    {children}
  </Grid>
);

export default Card;
