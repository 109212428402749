import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { onSnapshot, Timestamp } from "firebase/firestore";
import { storage } from "../../../firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { useEffect, useRef, useState } from "react";
import ChatMessage from "./chatMessage/chatMessage";
import {
  firestore,
  ConversationsCollection,
  MessagesCollection,
  TimestampField,
} from "../../../firebase";
import { ReactComponent as EmojiChatIcon } from "app/assets/icons/emojiChat.svg";
import { ReactComponent as SendButtonIcon } from "app/assets/icons/sendButton.svg";

import { ReactComponent as ViewDocIcon } from "app/assets/icons/viewDoc.svg";
import { Box, CircularProgress } from "@mui/material";

import data from "@emoji-mart/data";
// @ts-ignore
import Picker from "@emoji-mart/react";
import { IconButton, InputBase, Paper, Popover } from "@mui/material";
import React from "react";

interface IChatRoom {
  PatientId?: Number;
  doctorId?: Number;
  PatientInfo?: any;
  disabledPatientsID: number[];
  SpecialistId: Number;
  role: "doctor" | "organization";
  translation: { active: boolean; language: string };
  // getLastMesssageTime: any;
}

const ChatRoom = ({
  PatientId,
  doctorId,
  PatientInfo,
  disabledPatientsID,
  SpecialistId,
  role,
  translation,
}: // getLastMesssageTime,
IChatRoom) => {
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const [Messages, setMessages] = useState<any[]>();
  const [LastSnapshot, setLastSnapshot] = useState<any>();
  const scrollContainerRef = useRef<null | HTMLDivElement>(null);
  const scrollMessagesRef = useRef<any>(null);
  const lastMessagesRef = useRef<any>(null);

  const [UploadProgress, setUploadProgress] = useState<any>();
  const UploadInputRef = useRef<any>();

  const isChatDisabled = disabledPatientsID.includes(PatientId as number);
  const disabledStyles = {
    opacity: "0.2",
    cursor: "not-allowed",
  };

  useEffect(() => {
    setMessages([]);
    setLastSnapshot(undefined);

    const lastmessagesRef = firestore
      .collection(ConversationsCollection)
      .doc(`${doctorId}-${PatientId}`);
    lastmessagesRef.update({
      ReadBy: firebase.firestore.FieldValue.arrayUnion(doctorId),
    });
    return onSnapshot(
      firestore
        .collection(ConversationsCollection)
        .doc(`${doctorId}-${PatientId}`)
        .collection(MessagesCollection)
        .orderBy(TimestampField, "desc")
        .limit(50),
      (snapshot: any) => {
        setMessages(snapshot.docs.map((doc: any) => doc.data()).reverse());
        setLastSnapshot(snapshot.docs[snapshot.docs.length - 1]);
        scrollToBottom();
      }
    );
  }, [PatientId]);
  useEffect(() => {
    const readByRef = firestore
      .collection(ConversationsCollection)
      .doc(`${doctorId}-${PatientId}`);
    if (Messages) {
      lastMessagesRef.current = Messages[0];
      // getLastMesssageTime(Messages[0]?.Timestamp);
      readByRef.update({
        ReadBy: firebase.firestore.FieldValue.arrayUnion(doctorId),
      });
    }
  }, [Messages]);

  const [formValue, setFormValue] = useState<any>(" ");
  const [showEmojis, setShowEmojis] = useState(false);
  const scrollToBottom = () => {
    if (scrollContainerRef.current)
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current?.scrollHeight;
  };

  if (scrollContainerRef.current && Messages) {
    scrollContainerRef.current.onscroll = async function () {
      if (scrollContainerRef.current?.scrollTop === 0) {
        loadMore();
      }
    };
  }

  const loadMore = async () => {
    if (Messages && LastSnapshot) {
      await firestore
        .collection(ConversationsCollection)
        .doc(`${doctorId}-${PatientId}`)
        .collection(MessagesCollection)
        .orderBy(TimestampField, "desc")
        .limit(10)
        .startAfter(LastSnapshot!)
        .get()
        .then((res) => {
          // if (Messages) lastMessagesRef.current = Object.values(Messages[0]);
          lastMessagesRef.current = Messages[0];
          let temp = res.docs.map((res) => res.data()).reverse();
          setLastSnapshot(res.docs[res.docs.length - 1]);
          setMessages([...temp, ...Messages]);
          scrollMessagesRef.current.scrollIntoView();
          // getLastMesssageTime(Messages[0]?.Timestamp);
        });
    }
  };
  const sendMessage = async (e: any) => {
    e.preventDefault();
    if (!formValue || formValue.trim() === "" || formValue.trim().length === 0)
      return;

    const lastmessagesRef = firestore
      .collection(ConversationsCollection)
      .doc(`${doctorId}-${PatientId}`);
    const docRef = firestore
      .collection(ConversationsCollection)
      .doc(`${doctorId}-${PatientId}`)
      .collection(MessagesCollection);
    const payload = {
      TextMessage: { Text: formValue },
      Timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      FromUserId: doctorId,
      MessageType: "TextMessage",
      SpecialistId: SpecialistId ? SpecialistId : null,
      role,
    };
    setFormValue(" ");

    await docRef.add(payload);
    await lastmessagesRef.update({
      LastMessage: formValue,
      LastMessageTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
      ReadBy: [doctorId],
    });

    scrollToBottom();
  };

  const addEmoji = (e: any) => {
    let sym = e.unified.split("-");
    let codesArray: any = [];
    sym.forEach((el: any) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    setFormValue(formValue + emoji);
  };
  const uploadFile = (e: any) => {
    e.preventDefault();
    let file = e.target.files[0];

    if (!file) return;
    const storageRef = ref(
      storage,
      `${ConversationsCollection}/${doctorId}-${PatientId}/${file.name}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);
    let fileURL: any;
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        setUploadProgress(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        switch (snapshot.state) {
          case "paused":
            break;
          case "running":
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      async () => {
        // Upload completed successfully, now we can get the download URL
        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          fileURL = downloadURL;
        });
        const lastmessagesRef = firestore
          .collection(ConversationsCollection)
          .doc(`${doctorId}-${PatientId}`);
        const docRef = firestore
          .collection(ConversationsCollection)
          .doc(`${doctorId}-${PatientId}`)
          .collection(MessagesCollection);
        const payload = {
          AttachmentMessage: {
            FileName: file.name,
            FileSize: file.size,
            FileType: file.type,
            FileUrl: fileURL,
          },
          Timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          FromUserId: doctorId,
          MessageType: "AttachmentMessage",
        };

        docRef.add(payload);
        lastmessagesRef.update({
          LastMessage: file.name,
          LastMessageTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
          ReadBy: [doctorId],
        });
        UploadInputRef.current.value = "";
      }
    );
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        height: "100%",
      }}
    >
      <div
        ref={scrollContainerRef}
        style={{
          marginLeft: "10px",
          height: "100%",
          overflowY: "auto",
          width: "98%",
        }}
      >
        <div
          style={{ marginLeft: "20px", marginRight: "20px", display: "grid" }}
        >
          {LastSnapshot && (
            <div
              style={{
                justifySelf: "center",
                fontSize: "12px",
                color: "#172B4D",
              }}
            ></div>
          )}
          {Messages && PatientId === PatientInfo?.id ? (
            Messages.map((msg: any, index: any, arr) => (
              <div
                onChange={(e: any) => e.preventDefault()}
                key={msg.id}
                style={{
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  alignItems: "end",
                  alignSelf: "end",
                  wordBreak: "break-all",
                }}
                ref={
                  JSON.stringify(arr[index]) ===
                  JSON.stringify(lastMessagesRef.current)
                    ? scrollMessagesRef
                    : null
                }
              >
                <ChatMessage
                  PatientInfo={PatientInfo}
                  doctorId={doctorId!}
                  msg={msg}
                  translation={translation}
                />
              </div>
            ))
          ) : (
            <>
              <div>loading</div>
            </>
          )}
          <div ref={messagesEndRef} />
        </div>

        <Popover
          open={showEmojis}
          onClose={() => setShowEmojis(false)}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
        >
          <Picker data={data} onEmojiSelect={addEmoji} />
        </Popover>
      </div>
      <div
        style={{ flexShrink: "0", marginLeft: "24px", paddingBottom: "32px" }}
      >
        <form onSubmit={sendMessage}>
          <Paper
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: "97%",
              height: "4rem",
              marginTop: "25px",
              border: "solid",
              borderColor: isChatDisabled ? "#d3d3d3" : "#136588",
              borderWidth: "thin",
              boxShadow: "none",
              ...(isChatDisabled && disabledStyles),
            }}
          >
            <InputBase
              maxRows={2}
              multiline={true}
              sx={{
                ml: 1,
                flex: 1,
                ...(isChatDisabled && disabledStyles),
              }}
              placeholder="Type Here..."
              inputProps={{
                "aria-label": "Type Here",
                sx: {
                  ...(isChatDisabled && disabledStyles),
                },
              }}
              onChange={(e) => setFormValue(e.target.value)}
              value={formValue}
              disabled={isChatDisabled}
            />
            <div>
              {UploadProgress !== 100 && (
                <CircularProgress
                  variant="determinate"
                  value={UploadProgress}
                  size={25}
                />
              )}
            </div>
            <label
              htmlFor="raised-button-file"
              style={{ ...(isChatDisabled && { cursor: "not-allowed" }) }}
            >
              <IconButton
                type="submit"
                sx={{ ...(isChatDisabled && disabledStyles), p: 0, pr: "1rem" }}
                aria-label="sendButton"
                disabled={isChatDisabled}
              >
                <SendButtonIcon
                  style={{ width: "16.97px", height: "16.97px" }}
                />
              </IconButton>
            </label>
            <input
              style={{ display: "none" }}
              id="raised-button-file"
              type="file"
              onChange={uploadFile}
              ref={UploadInputRef}
              disabled={isChatDisabled}
            />
            <label
              htmlFor="raised-button-file"
              style={{ ...(isChatDisabled && { cursor: "not-allowed" }) }}
            >
              <IconButton
                disabled={isChatDisabled}
                component="span"
                sx={{ ...(isChatDisabled && disabledStyles), p: 0, pr: "1rem" }}
              >
                {" "}
                <ViewDocIcon style={{ width: "16.72px", height: "18px" }} />
              </IconButton>
            </label>

            <IconButton
              type="button"
              sx={{
                ...(isChatDisabled && disabledStyles),
                p: 0,
                pr: "1rem",
              }}
              aria-label="emoji"
              onClick={() => setShowEmojis(!showEmojis)}
              disabled={isChatDisabled}
            >
              <EmojiChatIcon style={{ width: "20px", height: "20px" }} />
            </IconButton>
          </Paper>
        </form>
      </div>
    </div>
  );
};

export default ChatRoom;
