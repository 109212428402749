import api from "./index";

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ id_token, role }) => ({
        url: "/api/v1/auth/login",
        method: "POST",
        body: { auth: { id_token, role } },
      }),
      invalidatesTags: [{ type: "Profile" }],
      transformResponse: (response: any, meta: any) => {
        const accessToken = meta.response.headers.get("access-token");
        const uid = meta.response.headers.get("uid");
        const client = meta.response.headers.get("client");

        return {
          ...response,
          token: accessToken,
          uid,
          client,
        };
      },
    }),

    createPresignedURL: builder.mutation({
      query: ({ filename, byte_size, checksum, content_type }) => ({
        url: `/api/v1/presigned_url`,
        method: "POST",
        body: { file: { filename, byte_size, checksum, content_type } },
      }),
    }),

    getUser: builder.query({
      query: (usersIds) => {
        let rolesFilter = usersIds
          .map((r: any) => `filters[id][]=${r}`)
          .join("&");
        return `/api/v1/users?${rolesFilter}&page=-1`;
      },
    }),

    createCustomTokenFirebase: builder.mutation({
      query: () => ({
        url: `/api/v1/firebase/create_custom_token`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useCreatePresignedURLMutation,
  useLazyGetUserQuery,
  useCreateCustomTokenFirebaseMutation,
} = userApi;
