import { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Box,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
} from "@mui/material";
import Text from "generic/Text/Text";
import PrimaryButton from "generic/Buttons/PrimaryButton";
import InputWithLabel from "generic/Inputs/InputWithLabel";
import Avatar from "app/assets/images/avatar.png";
import CoverPlaceholder from "app/assets/images/cover-placeholder.png";
import * as yup from "yup";
import { useFormik } from "formik";
import { NameIcon, EmailIcon, XIcon } from "app/assets/icons";
import InputSelect from "generic/Inputs/InputSelect";
import {
  useUpdatePersonalInfoMutation,
  useUpdateProfileMutation,
  useLazyGetMyProfileQuery,
} from "services/profileSettings";
import { useCreatePresignedURLMutation } from "services/user";
import { useUploadAWSMutation } from "services/noHeaders";
import { uploadFileAndUpdate } from "utils/fileUpload";
import AllLanguages from "utils/languages.json";
import Snackbar from "generic/Snackbar/Snackbar";
import { urlRole } from "services";

const PersonalInformation = ({ doctorData }: any) => {
  const [imagePreview, setImagePreview] = useState("");
  const [imageFile, setImageFile] = useState<any>(null);

  const [coverPreview, setCoverPreview] = useState("");
  const [coverFile, setCoverFile] = useState<any>(null);
  const [languages, setLanguages] = useState<string[]>([]);

  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  let profileUser =
    localStorage.getItem("role") === "doctor" ? "user" : "admin";

  const validationSchema = yup.object(
    urlRole == "doctor"
      ? {
          first_name: yup.string().required("First name is mandatory"),
          last_name: yup.string().required("Last name is mandatory"),
          email: yup
            .string()
            .email("Enter a valid email")
            .required("Email is mandatory"),
          gender: yup.mixed().oneOf(["male", "female"]).required(),
          speciality: yup.string().required(),
        }
      : {
          name: yup.string().required("Hospital name is mandatory"),
          email: yup
            .string()
            .email("Enter a valid email")
            .required("Email is mandatory"),
        }
  );

  useEffect(() => {
    if (doctorData) {
      setImagePreview(doctorData?.[profileUser]?.avatar_url);
      setCoverPreview(doctorData?.cover_picture_url);
      if (
        typeof doctorData?.languages == "string" &&
        doctorData?.languages?.length
      )
        setLanguages(doctorData.languages.split(","));
    }
  }, [doctorData]);

  const [updatePersonalInfo, updatePersonalResult] =
    useUpdatePersonalInfoMutation();
  const [updateProfile, updateProfileResult] = useUpdateProfileMutation();
  const [createPresignedURL, urlResponse] = useCreatePresignedURLMutation();
  const [uploadAWS, uploadResponse] = useUploadAWSMutation();
  const [getMyProfile, { isLoading, data, error }] = useLazyGetMyProfileQuery();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: doctorData?.name ? doctorData?.name : "",
      first_name: doctorData?.[profileUser]?.first_name
        ? doctorData?.[profileUser]?.first_name
        : "",
      last_name: doctorData?.[profileUser]?.last_name
        ? doctorData?.[profileUser]?.last_name
        : "",
      email: doctorData?.[profileUser]?.email
        ? doctorData?.[profileUser]?.email
        : "",
      gender: doctorData?.[profileUser]?.gender
        ? doctorData?.[profileUser]?.gender
        : "",
      speciality: doctorData?.speciality_description
        ? doctorData?.speciality_description
        : "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      if (imageFile) {
        uploadFileAndUpdate({
          resize: true,
          imageFile,
          createPresignedURL,
          uploadAWS,
          UpdateHook: async (avatar: any) => {
            await updatePersonalInfo({
              user: { avatar },
            });
            getMyProfile();
          },
        });
      }
      if (coverFile) {
        uploadFileAndUpdate({
          resize: true,
          imageFile: coverFile,
          createPresignedURL,
          uploadAWS,
          UpdateHook: async (cover_picture: any) => {
            await updateProfile({ cover_picture });
            getMyProfile();
          },
        });
      }
      await updatePersonalInfo({
        user: {
          first_name: values.first_name,
          last_name: values.last_name,
          gender: values.gender,
        },
      });

      await updateProfile({
        name: values.name,
        languages: languages.length ? languages.join(", ") : "",
        speciality_description: values.speciality,
      });

      setLoading(false);
      setIsError(true);
      setErrorMsg("successfully updated your profile");
    },
  });

  const handleImagePreview = (e: any) => {
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    let image_as_files = e.target.files[0];
    setImagePreview(image_as_base64);
    setImageFile(image_as_files);
  };

  const handleCoverPreview = (e: any) => {
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    let image_as_files = e.target.files[0];
    setCoverPreview(image_as_base64);
    setCoverFile(image_as_files);
  };

  return (
    <form
      style={{ margin: "auto 0", maxWidth: "100%" }}
      onSubmit={formik.handleSubmit}
    >
      <Grid container>
        <Grid item xs={12} sm={12} md={5}>
          <div style={{ display: "flex", margin: "20px 0" }}>
            <img
              src={imagePreview ? imagePreview : Avatar}
              style={{ width: "110px", height: "110px", borderRadius: "50%" }}
              alt="avatar"
            />
            <Button
              variant="contained"
              component="label"
              sx={{
                color: "#136588",
                background: "#fff",
                textTransform: "none",
                boxShadow: "none",
                fontWeight: 700,
                fontSize: "1rem",
                ":hover": {
                  background: "white",
                  boxShadow: "none",
                  color: "#035578",
                },
              }}
            >
              Upload Profile Picture
              <input
                type="file"
                accept="image/png, image/gif, image/jpeg"
                hidden
                onChange={handleImagePreview}
              />
            </Button>
          </div>
        </Grid>
        <span style={{ margin: "0 20px" }}></span>

        <Grid item xs={12} sm={12} md={5}>
          <div style={{ display: "flex", margin: "20px 0" }}>
            <img
              src={coverPreview ? coverPreview : CoverPlaceholder}
              style={{ width: "180px", height: "110px" }}
              alt="avatar"
            />
            <Button
              variant="contained"
              component="label"
              sx={{
                color: "#136588",
                background: "#fff",
                textTransform: "none",
                boxShadow: "none",
                fontWeight: 700,
                fontSize: "1rem",
                ":hover": {
                  background: "white",
                  boxShadow: "none",
                  color: "#035578",
                },
              }}
            >
              Upload Cover Photo
              <input
                type="file"
                accept="image/png, image/gif, image/jpeg"
                hidden
                onChange={handleCoverPreview}
              />
            </Button>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={5}>
          {urlRole === "organization" && (
            <InputWithLabel
              label="Hospital Name"
              id="name"
              name="name"
              placeholder="Enter Hospital Name"
              startIcon={<NameIcon />}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          )}

          {urlRole === "doctor" && (
            <InputWithLabel
              label="First Name"
              id="first_name"
              name="first_name"
              placeholder="Enter First Name"
              startIcon={<NameIcon />}
              value={formik.values.first_name}
              onChange={formik.handleChange}
              error={
                formik.touched.first_name && Boolean(formik.errors.first_name)
              }
              helperText={formik.touched.first_name && formik.errors.first_name}
            />
          )}

          <InputWithLabel
            label="Email Address"
            id="email"
            name="email"
            placeholder="Enter Email Address"
            startIcon={<EmailIcon />}
            value={formik.values.email}
            disabled={true}
            // onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          {/* {urlRole === "doctor" && ( */}
          <InputWithLabel
            label="Specialty"
            id="speciality"
            name="speciality"
            placeholder="Tell people something about yourself"
            multiline={true}
            rows={3}
            value={formik.values.speciality}
            onChange={formik.handleChange}
            error={
              formik.touched.speciality && Boolean(formik.errors.speciality)
            }
            helperText={formik.touched.speciality && formik.errors.speciality}
          />
          {/* )} */}
        </Grid>
        <span style={{ margin: "0 20px" }}></span>
        <Grid item xs={12} sm={12} md={5}>
          {urlRole === "doctor" && (
            <InputWithLabel
              label="Last Name"
              id="last_name"
              name="last_name"
              placeholder="Enter Last Name"
              startIcon={<NameIcon />}
              value={formik.values.last_name}
              onChange={formik.handleChange}
              error={
                formik.touched.last_name && Boolean(formik.errors.last_name)
              }
              helperText={formik.touched.last_name && formik.errors.last_name}
            />
          )}

          {urlRole === "doctor" && (
            <InputSelect
              id="gender"
              name="gender"
              label={"Gender"}
              placeholder={"Select Your Gender"}
              value={formik.values.gender}
              onChange={formik.handleChange}
              error={formik.touched.gender && Boolean(formik.errors.gender)}
            >
              <MenuItem value={"male"}>Male</MenuItem>
              <MenuItem value={"female"}>Female</MenuItem>
            </InputSelect>
          )}

          <InputLabel
            sx={{ mb: 1, fontWeight: 600, fontSize: "14px", color: "#172B4D" }}
          >
            Languages
          </InputLabel>
          <Select
            id="languages"
            displayEmpty
            value=""
            onChange={(e) =>
              setLanguages((prevLangs: string[]) => {
                return languages.includes(e.target.value)
                  ? prevLangs
                  : [...prevLangs, e.target.value];
              })
            }
            renderValue={(selected: any) => {
              if (selected.length === 0) {
                return (
                  <Text sx={{ color: "#909090" }} label="Select Languages" />
                );
              }

              return selected;
            }}
            sx={{ width: "100%", mb: 2 }}
          >
            {AllLanguages?.en?.map((lang: any) => {
              return <MenuItem value={lang?.name}>{lang?.name}</MenuItem>;
            })}
          </Select>
          <Grid container>
            {languages.map((lang) => (
              <Grid item sx={{ mb: 2 }}>
                <InputLabel
                  sx={{
                    my: 2,
                    fontWeight: 400,
                    fontSize: "1rem",
                    color: "#136588",
                    display: "inline",
                    background: "#F4F5F8",
                    padding: "10px",
                    mr: 3,
                    borderRadius: "5px",
                  }}
                >
                  {lang}{" "}
                  {languages.length > 1 && (
                    <IconButton
                      onClick={() =>
                        setLanguages(languages.filter((item) => item != lang))
                      }
                    >
                      <XIcon
                        style={{ marginBottom: "2px", marginLeft: "-2px" }}
                      />
                    </IconButton>
                  )}
                </InputLabel>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <PrimaryButton
        title="Save Changes"
        type="submit"
        loading={loading}
        sx={{ fontSize: "1rem", fontWeight: 600 }}
      />
      <Snackbar
        open={isError}
        onClose={() => setIsError(false)}
        message={errorMsg}
      />
    </form>
  );
};

export default PersonalInformation;
