import api from "./index";
import { urlRole } from "./index";
interface Company {
  id: number;
  name: string;
}
interface Embassy {
  id: number;
  country: string;
}
interface Speciality {
  id: number;
  parent_id: number | null;
  name: string;
  icon: string | null;
  created_at: Date;
}

const profileAttribute =
  localStorage.getItem("role") == "doctor"
    ? "doctor_profile"
    : "organization_profile";

export const profileSettings = api.injectEndpoints({
  endpoints: (builder) => ({
    getMyProfile: builder.query<any, void>({
      query: () => `/api/v1/${urlRole}/profile`,
      providesTags: [{ type: "Profile" }],
    }),

    updatePersonalInfo: builder.mutation({
      query: (body) => ({
        url: `/api/v1/user`,
        method: "PUT",
        body,
      }),
      invalidatesTags: [{ type: "Profile" }],
    }),

    updateProfile: builder.mutation({
      query: (profileData: any) => ({
        url: `/api/v1/${urlRole}/profile`,
        method: "PUT",
        body: { [profileAttribute]: profileData },
      }),
      transformResponse: (response: any) => {
        return response?.[urlRole];
      },
      invalidatesTags: [{ type: "Profile" }],
    }),

    getAllInsuranceCompanies: builder.query<Company[], void>({
      query: () => `/api/v1/${urlRole}/insurance_companies`,
      transformResponse: (response: any, meta: any) => {
        return response.companies;
      },
    }),

    getAllEmbassies: builder.query<Embassy[], void>({
      query: () => `/api/v1/${urlRole}/embassies`,
      transformResponse: (response: any, meta: any) => {
        return response.embassies;
      },
    }),

    getSpecialities: builder.query<Speciality[], void>({
      query: () => `/api/v1/${urlRole}/specialities`,
      providesTags: () => [{ type: "Specialities" }],
      transformResponse: (response: any, meta: any) => {
        return response.specialities;
      },
    }),

    getSpeciality: builder.query<any, any>({
      query: (specialityId) =>
        `/api/v1/${urlRole}/specialities/${specialityId}`,
      transformResponse: (response: any, meta: any) => {
        return response.speciality;
      },
    }),

    getAllCountries: builder.query({
      query: () => "/api/v1/countries",
    }),
    getAllStates: builder.query({
      query: ({ countryId }) =>
        `/api/v1/states?filters[country_id]=${countryId}`,
    }),
    getAllCities: builder.query({
      query: ({ countryId, stateId }) =>
        `/api/v1/cities?filters[country_id]=${countryId}&filters[state_id]=${stateId}`,
    }),

    changeEmail: builder.mutation({
      query: (email) => ({
        url: `/api/v1/user/change_email`,
        method: "PUT",
        body: {
          user: { email },
        },
      }),
      invalidatesTags: [{ type: "Profile" }],
    }),

    verifyEmail: builder.query({
      query: () => `/api/v1/user/verify_email`,
    }),
  }),
});

export const {
  useGetMyProfileQuery,
  useLazyGetMyProfileQuery,
  useUpdatePersonalInfoMutation,
  useUpdateProfileMutation,
  useGetAllInsuranceCompaniesQuery,
  useGetAllEmbassiesQuery,
  useGetSpecialitiesQuery,
  useGetSpecialityQuery,
  useLazyGetSpecialityQuery,
  useGetAllCountriesQuery,
  useLazyGetAllStatesQuery,
  useLazyGetAllCitiesQuery,
  useChangeEmailMutation,
  useLazyVerifyEmailQuery,
} = profileSettings;
