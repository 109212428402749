import { styled } from '@mui/material/styles';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: '1rem 0',
  paddingTop: '0.5rem',
  fontSize: '1rem',
  fontWeight: 400
}));

export default AccordionDetails;