import { Box, SxProps, Theme, Typography } from "@mui/material";
import ChangePercentage from "../shared/ChangePercentage";
import CircularPieChart from "./CircularPieChart";

interface IStats {
  genderCount: number;
  totalCount: number;
}
export interface IGenderStats {
  prev: IStats | null;
  curr: IStats;
}
interface IPatientsStatsCard {
  gender: "Male" | "Female";
  genderStats: IGenderStats;
  sx?: SxProps<Theme>;
}

const PatientsStatsCard = ({ gender, genderStats, sx }: IPatientsStatsCard) => {
  const { prev: prevStats, curr: currStats } = genderStats;
  const { genderCount: currCount, totalCount } = currStats;
  const prevCount = prevStats?.genderCount || 0;

  const genderPercentage = +((currCount / totalCount) * 100).toFixed(2);

  return (
    <Box sx={{ display: "flex", alignItems: "center", ...sx }}>
      <Box sx={{ mr: "2rem" }}>
        <CircularPieChart
          percentage={genderPercentage}
          primaryColor={gender === "Male" ? "#136588" : "#09C0D1"}
        />
      </Box>
      <Box>
        <Typography
          sx={{
            fontSize: "1.5rem",
            fontWeight: 600,
            lineHeight: "1.25rem",
            color: "#172B4D",
          }}
        >
          {genderPercentage}%
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            fontSize: "0.875rem",
            fontWeight: 400,
            lineHeight: "1.5rem",
            color: "#7F8CA9",
            mt: "1rem",
          }}
        >
          {gender} patients
        </Typography>
      </Box>
      <Box sx={{ ml: "auto" }}>
        {prevStats && <ChangePercentage curr={currCount} prev={prevCount} />}
      </Box>
    </Box>
  );
};

export default PatientsStatsCard;
