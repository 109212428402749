import { Typography } from "@mui/material";

export const DisplayNote = ({ title, note }: any) => {
  const displayNote = (title: string, note: string) => (
    <>
      <Typography
        style={{
          color: "#172B4D",
          fontSize: "16px",
          fontWeight: 700,
          marginTop: "15px",
          marginBottom: "5px",
        }}
      >
        {title}
      </Typography>
      <p>{note}</p>
    </>
  );
  return (
    <>
      <Typography
        style={{
          color: "#172B4D",
          fontSize: "16px",
          fontWeight: 700,
          marginTop: "15px",
          marginBottom: "5px",
        }}
      >
        {title}
      </Typography>
      <p>{note}</p>
      {/* {consultation?.notes && displayNote("Doctor note", consultation?.notes)}
      {consultation?.organization_notes &&
        displayNote("Organization note", consultation?.organization_notes)} */}
    </>
  );
};

export default DisplayNote;
