import { Box, Typography } from "@mui/material";
import Avatar from "generic/Avatar/Avatar";
import { useNavigate } from "react-router-dom";
import { IOrganizationDoctor } from "services/organizationDoctors";
import { renderTimestamp } from "utils/renderTimestamp";

interface IDoctorCard {
  doctor: IOrganizationDoctor;
  isSelected: boolean;
  onClick: () => void;
}

const DoctorsMenuCard = ({ doctor, isSelected, onClick }: IDoctorCard) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        background: isSelected ? "rgba(184, 208, 219, 0.3)" : "fff",
        mb: "3px",
        px: "1rem",
        py: "0.625rem",
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
      onClick={onClick}
    >
      <Box
        sx={{ marginRight: "0.75rem", display: "flex", alignItems: "center" }}
      >
        <Avatar src={doctor.avatar_url} size="2.5rem" />
      </Box>
      <Box sx={{ display: "flex", flexGrow: 1 }}>
        <Box sx={{ maxWidth: "300px" }}>
          <Typography
            variant="subtitle2"
            sx={{
              fontSize: "16px",
              color: "#172B4D",
              fontWeight: 700,
              lineHeight: "1.685rem",
            }}
          >
            {doctor.first_name} {doctor.last_name}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default DoctorsMenuCard;
